<template>
    <div class="GlobalFormDemo">
      <GlobalForm  :init-data="initData" :form-item-list="formItemList" :inline="true" 
        :labelWidth="100" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="仓库名称" slot="storeName" style="margin:0" >
        <el-select v-model="companyId" placeholder="请输入仓库名称查询" filterable clearable>
          <el-option v-for="item in merchantSelectList" :key="item.id" :label="item.warehouseName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="仓库使用名称" slot="storeUseName" style="margin:0">
        <el-input v-model="storeUseName" placeholder="请输入仓库使用名称查询" clearable>
        </el-input>
      </el-form-item>
      <el-form-item class="el_from" label="仓库负责人" slot="storeManage" style="margin:0">
        <el-input v-model="storeManage" placeholder="请输入人员姓名查询" clearable>
        </el-input>
      </el-form-item>
      <el-form-item class="el_from" label="创建时间" slot="turnoverSelect"
        style="margin: 0" label-width="75px">
        <el-date-picker v-model="turnTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      </GlobalForm>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  import { http } from "../../../utils/request/storeManage";
  import moment from "moment";
  export default {
    name: "Form",
    data() {
      return {
        storeUseName: "",
        storeManage: "",
        initData: null,
        // 入库
        formItemList: [
          { slotName: "storeName" },
          { slotName: "storeUseName" },
          { slotName: "storeManage" },
          { slotName: "turnoverSelect" },
          {slot:"AddSlot"}
        ],
        merchantSelectList: [],
        companyId: "",
        // 弹框
        turnTime: null, //成交时间
      };
    },
    created() {
this.handleConfirm();
http.makeStoreSelect().then(res=>{
  if (res.code === 1) {
        this.merchantSelectList = res.data;
      }
}).catch(err=>{

})
    },
    methods: {
      handleConfirm(data,cd) {
      let SeachParams = {};
      let startTime = "";
      let endTime = "";
      if (this.turnTime) {
        startTime = Number(moment(this.turnTime[0]).format("x"));
        endTime = Number(moment(this.turnTime[1]).format("x"));
      } else {
        startTime = "";
        endTime = "";
      }
      if (data) {
          SeachParams = {
            warehouseShipName: this.storeUseName,
            liabilityerName:this.storeManage,
            warehouseId: this.companyId,
            startTime: startTime,
            endTime: endTime,
            disuseLoding: () => {
              if (typeof cd === 'function') {
                cd()
              }
            },
          };
        
      }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    },
  };
  </script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
  .GlobalFormDemo {

  }
  </style>