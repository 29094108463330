export const matrixDialogProp = {
    title: "切换矩阵",
    width: "47%",
    tabData: [],
    isSearch:false,
    // 是否推送最高价
    isAgainPushHigh:false,
    // 切换最高价
    params: {
        pageNum: 1,
        pageSize: 5,
        isOut:false,
        // companyId:""
    },
    selectMatrixId:"",
    tableColumns: [
        {
            prop: "select",
            label: "单选",
            width: "60px"
        },
        {
            prop: "matrixName",
            label: "矩阵",
        },
        {
            prop: "companyName",
            label: "矩阵门店商户",
        },
        {
            prop: "storeNum",
            label: "门店数量",
        },
        {
            prop: "staffNum",
            label: "店员数量",
        },
        {
            prop: "merchantNum",
            label: "报价回收商",
        },
    ],
}

// 谷歌动态码
export const codeProp = {
    isCode: true,
    isTip: false,
    show:false,
    code:"",
    title:"确认操作",
    isMessage: true,
    message:"",
    codeWidth: "100%",
    codeLabelWidth:"69px"
};

// 关联外部商户
export const associationProp = {
    isCode:true,
    isTip:true,
    show:false,
    code:"",
    title:"安全验证"
}

// 矩阵报价切换弹窗
export const againPushHighProp = {
    isTip:true,
    tipText:"",
    title: "确认操作",
}
