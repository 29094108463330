<template>
    <div class="class-list">

      <GlobalInfoBar
        title="仓库管理"
        content="创建或管理仓库"
      />
      <!-- <Info/> -->
      <GlobalChunk icon="search" title="筛选查询">
        <div slot="filter">
          <From />
        </div>
        <Table />
      </GlobalChunk>
    </div>
  </template>
  
  <script>
  import From from "./Form";
  import Table from "./Table";
  export default {
    name: "class-list",
    components: {
      From,
      Table
    },
    data() {
      return {
      };
    },
    methods: {
    },
  };
  </script>
  
  <style>
  </style>