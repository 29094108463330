<template>
  <div class="mobile-store-Manage-table">
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          class="global-form"
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="100"
          round
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item
            label="移动商户"
            slot="companyId"
            style="margin: 0"
            label-width="80px"
          >
            <el-select
              v-model="companyId"
              placeholder="请输入或选择商户"
              filterable
              clearable
            >
              <el-option
                v-for="item in bindCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="80px" label="省市查询" slot="provinceCode" style="margin:0">
            <el-select
              style="margin-right: 10px;"
              size="small"
              v-model="provinceCode"
              @change="selectProvince"
              clearable
              filterable
              placeholder="请选择省"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              size="small"
              :disabled="!provinceCode"
              v-model="cityCode"
              clearable
              filterable
              :placeholder="provinceCode?'请选择市':'请先选择省'"
            >
              <el-option
                v-for="item in cityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加时间" slot="creatTime" style="margin-bottom: 0;" label-width="80px">
            <el-date-picker
              v-model="creatTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="绑定时间" slot="bindTime" style="margin-bottom: 0;" label-width="80px">
            <el-date-picker
              v-model="bindTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </GlobalForm>
      </div>
      <div class="flex flex-jb mb-20">
        <GardenSlwct
          :tab-data="tabData"
          :activeIndex="tabActiveIndex"
          width="80px"
          border-radius="40px"
          @tabAction="tabAction"
        />
        <div>
          <!-- <el-button
            size="small"
            type="danger"
            icon="el-icon-refresh"
            @click="updateData"
            >更新数据</el-button
          > -->
          <el-button
            icon="el-icon-download"
            size="small"
            type="success"
            :loading="exportLoading"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="tableloading"
        :columns="tableColumns"
        :data="tableDataList"
        :currentPage="page.pageNum"
        :total="page.total"
        :pageSize="page.pageSize"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column label="所属门店" slot="cmccStoreName" align="center">
          <template slot-scope="{ row }">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.cmccStoreName"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.cmccStoreName }}
                </div>
              </el-tooltip>
            </div>
            <span v-if="row.isBindStore" style="color: #0981FF;">已绑定</span>
            <span v-else style="color: #ff8695;">未绑定</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" slot="state" align="center">
          <template slot-scope="{ row }">
            <span :style="row.state!=='1'?'color:#ff8695;':''">{{ row.state==='1'?'正常':row.state==='2'?'冻结':'注销'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定繁星店员" slot="staffName" align="center" v-if="tabActiveIndex === 1">
          <template slot-scope="{ row }">
            <span v-if="row.staffId">{{ row.staffName }}-{{ row.staffMobile }}({{ row.isMajor?'创建人':row.staffType==='01'?'子账号':row.staffType==='02'?'总监':row.staffType==='03'?'店长':'店员' }})</span>
            <span v-else>未绑定</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定商户" slot="companyName" align="center" v-if="tabActiveIndex === 1">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.companyName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="绑定时间" slot="bindTime" align="center" v-if="tabActiveIndex === 1">
          <template slot-scope="{ row }">
            <span>{{ row.bindTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定人" slot="adminName" align="center" v-if="tabActiveIndex === 1">
          <template slot-scope="{ row }">
            <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column width="250" label="操作" slot="operation" align="center" v-if="tabActiveIndex === 0">
          <template slot-scope="{ row }">
            <el-button style="color: #ffbf54;text-decoration: underline" type="text" @click="handleBtn(row, 'add')">添加为新员工</el-button>
            <el-button style="text-decoration: underline" type="text" @click="handleBtn(row, 'bind')">绑定到已有员工</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
    
    <el-dialog
      :title="operatStaffDiaTitle"
      :visible.sync="operatStaffDiaShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="operatStaffDiaClosed"
    >
      <div class="flex flex-jb mb-10">
        <p>移动门店：<span style="color: #0981FF;">{{rowObj.cmccStoreName}}</span></p>
        <p>门店编码：<span style="color: #0981FF;">{{rowObj.cmccStoreCode}}</span></p>
      </div>
      <div>
        <el-form ref="operatform" :model="operatform" label-width="85px">
          <el-form-item label="已绑定门店">
            <el-input
              size="small"
              disabled
              placeholder="请先完成绑定门店"
              v-model.trim="operatform.storeName"
            ></el-input>
            <div class="tip" v-if="!operatform.storeName">暂未绑定繁星门店，请先绑定后再添加店员</div>
          </el-form-item>
          <template v-if="operatform.storeName && operatStaffType==='add'">
            <el-form-item label="店员姓名">
              <el-input
                size="small"
                disabled
                placeholder="请输入店员姓名"
                v-model.trim="operatform.staffName"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                size="small"
                disabled
                placeholder="请输入店员手机号"
                v-model.trim="operatform.staffMobile"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="operatform.storeName && operatStaffType==='bind'">
            <el-form-item label="选择店员">
              <el-select
                size="small"
                v-model="operatform.staffId"
                placeholder="请选择店员"
              >
                <el-option
                  v-for="item in staffSelectList"
                  :key="item.staffId"
                  :label="item.staffName"
                  :value="item.staffId"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
          
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="operatStaffDiaShow = false">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click.native="operatStaffSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
export default {
  components: {
    GardenSlwct,
  },
  props: {
    provinceList: {
      type: Array,
      default: () => [],
    },
    bindCompanyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabActiveIndex: 0,
      state: '00',
      tabData: [
        {
          text: "待绑定",
          value: "00",
          hideNum: true,
        },
        {
          text: "已绑定",
          value: "01",
          hideNum: true,
        },
      ],
      seachParams: {},
      initData: null,
      companyId: "",
      provinceCode: "", //省
      cityCode: "",// 市
      cityList: [],
      creatTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      bindTime: null,
      formItemList: [
        { slotName: "companyId" },
        {
          lableWidth: "80px",
          key: "storeName",
          type: "input",
          labelName: "门店名称",
          placeholder: "请输入门店名称查询",
        },
        { slotName: "provinceCode" },
        {
          lableWidth: "80px",
          key: "staffName",
          type: "input",
          labelName: "店员姓名",
          placeholder: "请输入姓名查询",
        },
        {
          lableWidth: "90px",
          key: "staffMobile",
          type: "input",
          labelName: "店员手机号",
          placeholder: "请输入精确手机号查询",
        },
        { slotName: "creatTime" },
        { slotName: "bindTime" },
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      exportLoading: false,
      tableloading: false,
      tableDataList: [],
      tableColumns: [
        { label: "店员ID", prop: "empId" },
        { label: "店员姓名", prop: "empName" },
        { label: "手机号", prop: "empTel" },
        { label: "门店编码", prop: "cmccStoreCode" },
        { slotName: "cmccStoreName" },
        { slotName: "state" },
        { label: "添加时间", prop: "createTime" },
        { label: "最近更新时间", prop: "updateTime" },
        { slotName: "staffName" },
        { slotName: "companyName" },
        { slotName: "bindTime" },
        { slotName: "adminName" },
        { slotName: "operation" }
      ],
      operatStaffDiaShow: false,// 添加店员/绑定店员弹框
      operatStaffDiaTitle: "",
      rowObj: {}, // 操作的当前行数据
      operatStaffType: "", //操作的当前行事件类型
      staffSelectList: [], //店员列表
      submitLoading: false,
      operatform: {
        empId: '',
        companyId: '',
        storeName: '',
        staffName: '',
        staffMobile: '',
        staffId: '',
      }
    };
  },
  created() {
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data, cd) {
      let createTimeStart = "";
      let createTimeEnd = "";
      if (this.creatTime === null) {
        createTimeStart = "";
        createTimeEnd = "";
      } else {
        createTimeStart = moment(this.creatTime[0]).format("x");
        createTimeEnd = moment(this.creatTime[1]).format("x");
      }
      // 绑定时间
      let bindTimeStart = "";
      let bindTimeEnd = "";
      if (this.bindTime === null) {
        bindTimeStart = "";
        bindTimeEnd = "";
      } else {
        bindTimeStart = moment(this.bindTime[0]).format("x");
        bindTimeEnd = moment(this.bindTime[1]).format("x");
      }
      let SeachParams = {
        createTimeStart,
        createTimeEnd,
        bindTimeStart,
        bindTimeEnd,
      };
      if (data) {
        SeachParams.storeName = data.storeName || ""
        SeachParams.staffName = data.staffName || ""
        SeachParams.staffMobile = data.staffMobile || ""
        SeachParams.disuseLoding = () => {
          if (typeof cd === "function") {
            cd();
          }
        }
      }
      this.seachParams = SeachParams;
      this.handleCurrentChange(1)
    },
    // 获取市区
    getCity(v) {
      _api.cmccDistrict({ code: v, type: "01" }).then((r) => {
        this.cityList = r.data.map((v) => {
          return {
            label: v.key,
            value: v.value,
          };
        });
      });
    },
    selectProvince(e) {
      if (e) {
        this.cityCode = "";
        this.getCity(e);
      } else {
        this.cityCode = "";
        this.cityList = [];
      }
    },
    //   切换页
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        ...this.seachParams,
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        state: this.state,
        companyId: this.companyId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.tableloading = true;
      _api
        .cmccEmpList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            this.tableDataList = res.data.records;
            this.page.total = res.data.total;
            this.page.pageNum = res.data.current;
          }
          this.tableloading = false;
        })
        .finally(() => {
          if (typeof this.seachParams.disuseLoding === "function") {
            this.seachParams.disuseLoding();
          }
        });
    },
    tabAction(val) {
      this.tabActiveIndex = val;
      this.state = this.tabData[val].value
      if(this.state === '00'){
        this.creatTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.bindTime = null;
      }else{
        this.creatTime = null;
        this.bindTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
      }
      
      this.handleConfirm();
    },
    updateData() {

    },
    exportExcel() {
      this.exportLoading = true;
      let baseRequest = {
        ...this.seachParams,
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        state: this.state,
        companyId: this.companyId,
      };
      _api
        .excelCmccStaff(baseRequest)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "服务异常");
              }
            } catch (err) {
              const fileName = "移动店员列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    },
    handleBtn(row,type) {
      this.rowObj = row
      this.operatform.companyId = row.companyId
      this.operatform.empId = row.empId
      this.operatform.storeName = row.storeName
      this.operatform.storeId = row.storeId
      this.operatform.staffName = row.empName
      this.operatform.staffMobile = row.empTel
      this.operatform.type = type==='add'?'00':'01'
      console.log(this.operatform,'operatformoperatformoperatform');
      
      this.operatStaffType = type
      this.operatStaffDiaTitle = type==='add'?'添加为新店员':'绑定已有店员'
      this.operatStaffDiaShow = true
      if(type==='bind' && row.companyId && row.storeId){
        _api.getStaffSelectList({companyId:row.companyId,storeId:row.storeId}).then(res => {
          if(res.code === 1){
            this.staffSelectList = res.data
          }
        })
      }
    },
    
    operatStaffDiaClosed(){
      this.operatform = {
        empId: '',
        companyId: '',
        storeName: '',
        staffName: '',
        staffMobile: '',
        staffId: '',
      }
    },
    operatStaffSubmit() {
      if(!this.operatform.storeName){
        return this.$message.error('暂未绑定繁星门店，请先绑定后再添加店员')
      }
      this.submitLoading = true
      _api.cmccBindEmp(this.operatform).then(res => {
        if(res.code === 1){
          this.operatStaffDiaShow = false
          this.handleCurrentChange(1)
        }
      }).finally(()=>{
        this.submitLoading = false
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-store-Manage-table {
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .txt-underline {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
  }
  /deep/.el-form--inline {
    /deep/.el-form-item {
      margin-right: 0px !important;
    }
  }

  .tip {
    color: #ff687b;
  }
  .mb-10{
    margin-bottom: 10px;
  }
}
</style>
