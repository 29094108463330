<template>
  <div class="merchant-Feed">
    <GlobalInfoBar
        title="意见反馈"
        content="用户端小程序提交的投诉建议展示查看，平台可回复用户处理意见"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
        :padding="[20,20,20,20]"
    >

      <div slot="filter">
        <Form @changeNav="changeNavFrom"></Form>
      </div>
      <GlobalNav
          :navList="[
            {id:'', name: '全部', numKey: 'index'},
            {id:'01', name: '未处理', numKey: 'index2', isRed: true},
            {id:'02', name: '已处理', numKey: 'index3'},
          ]"
          :initNav="{id:'', name: '全部', numKey: 'index'}"
          :numList="{index: totalIndex,index2:totalIndex2,index3:totalIndex3}"
          @changeNav="changeNav"
          style="margin-bottom: 21px;"
      />

      <Table :tableData="tableDataIndex" :Feedtotal="Feedtotal" :FeedpageNum="FeedpageNum" @handleCurrentChange="handleCurrentChange"></Table>
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form"
import Table from "./Table"
import _api from "@/utils/request";
export default {
  name: 'merchant-Feed',
  components: {Form, Table},
  
  data(){
    return{
      tableDataIndex:[],
      totalIndex:"",
      totalIndex2:"",
      totalIndex3:"",
      state:"",
      named:"",
      complaintTarget:"",
      entryTime:"",
      Feedtotal:0,
      FeedpageNum:1,
      FeedpageSize:10,
    }
  },
  created(){
    // this.FeedDataList()
  },
  methods: {
    //分页
    handleCurrentChange(val){
      this.FeedpageNum = val
      this.FeedDataList()
    },
    //查询
    changeNavFrom(val){
      console.log(val,'va;l');
      this.named = val.named
      this.complaintTarget = val.complaintTarget
      this.startTime = val.startTime
      this.endTime = val.endTime
      const SeachParams ={
        named :val.named,
        complaintTarget :val.complaintTarget,
        startTime :val.startTime,
        endTime :val.endTime,
        state:this.state,
        pageNum:1,
        pageSize:10,
      }
      _api.complaintList(SeachParams).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data.complaints.records
          this.Feedtotal = res.data.complaints.total
          this.FeedpageNum = 1
          this.totalIndex = res.data.allCount
          this.totalIndex2 = res.data.noReply
          this.totalIndex3 = res.data.reply
        }
        
      }).finally(() => {
        if (typeof val.disuseLoding === 'function') {
          val.disuseLoding();
        }
      })
    },
    //tab页
    changeNav(val) {
      this.state = val.id
      this.FeedpageNum = 1
      console.log(val)
      this.FeedDataList()
    },
    //列表请求
    FeedDataList(){
      const vo ={
        state:this.state,
        pageNum:this.FeedpageNum,
        pageSize:this.FeedpageSize,
        named:this.named,
        complaintTarget:this.complaintTarget,
        startTime:this.startTime,
        endTime:this.endTime
      }
      _api.complaintList(vo).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data.complaints.records
          this.Feedtotal = res.data.complaints.total
          this.totalIndex = res.data.allCount
          this.totalIndex2 = res.data.noReply
          this.totalIndex3 = res.data.reply
        }
      })
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-Feed{}
</style>
