<template>
  <div class="GlobalTable">
    <!-- 胶囊切换 -->
    <div class="button_top">
      <div class="faultBtn">
        <div :class="type === '00' ? 'active' : 'none'" @click="btnClick('00')">
          已签收待收货<span v-if="type === '00'">({{ signNum || 0 }})</span>
        </div>
        <div :class="type === '001' ? 'active' : 'none'" @click="btnClick('001')">
          签收失败<span v-if="type === '001'">({{ signFailNum || 0 }})</span>
        </div>
        <div :class="type === '01' ? 'active' : 'none'" @click="btnClick('01')">
          暂停收货中<span v-if="type === '01'">({{ pauseNum || 0 }})</span>
        </div>
        <div :class="type === '02' ? 'active' : 'none'" @click="btnClick('02')">
          暂停收货失败<span v-if="type === '02'">({{ pauseFailNum || 0 }})</span>
        </div>
        <div :class="type === '03' ? 'active' : 'none'" @click="btnClick('03')">
          已完成收货<span v-if="type === '03'">({{ finishNum || 0 }})</span>
        </div>
      </div>
      <div style="margin-left: 60px;" v-if="type === '001'">
            <el-checkbox v-model="isFinishSenderAddress">筛选降价收货操作签收订单</el-checkbox>
       </div>
       <!-- <div style="margin-left: 60px;" v-if="type === '03'">
            <el-checkbox v-model="isValidity">筛选已超过外部追差时效订单</el-checkbox>
       </div> -->
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-if="refresh"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 订单号 -->
      <el-table-column label="订单号" align="center" slot="orderNo" width="130px">
        <template v-slot="{ row }">
          <a
            v-if="row.orderNo !== '--'"
            :href="`/RecallOrder/Details?type=edit&id=${row.orderNo}`"
            target="_blank"
            style="text-decoration: underline; color: #0981ff"
          >
            {{ row.orderNo }}
          </a>
          <span v-else>--</span>
          <p v-if="row.imei">串号:{{ row.imei }}</p>
        </template>
      </el-table-column>
      <!-- 机型 -->
      <el-table-column label="机型" align="center" slot="phoneName" width="100px">
        <template v-slot="{ row }">
          <span>{{ row.phoneName || "--" }}</span>
        </template>
      </el-table-column>
      <!-- 订单状态 -->
      <el-table-column label="订单状态" align="center" slot="orderState" width="100px">
        <template v-slot="{ row }">
          <span>{{
              row.orderState == "00"
                ? "待确认"
                : row.orderState == "02"
                ? "待支付"
                : row.orderState == "01"
                ? "已确认"
                : row.orderState == "03"
                ? "已绑码"
                : row.orderState == "04"
                ? "已收货"
                : row.orderState == "05"
                ? "降价收货"
                : row.orderState == "10"
                ? "已取消"
                : row.orderState == "20"
                ? "已作废"
                : "已退回"
            }}</span>
        </template>
      </el-table-column>
      <!-- 签收渠道 -->
      <el-table-column
        label="签收渠道"
        align="center"
        slot="signChannel"
        width="120"
      >
        <template v-slot="{ row }">
          <span>
            {{
              row.signChannel == "00"
                ? "平台后台"
                : row.signChannel == "01"
                ? "回收商后台"
                : row.signChannel == "02"
                ? "回收小程序"
                : row.signChannel == "03"
                ? "仓库后台"
                : "嗨回收调用"
            }}
          </span>
        </template>
      </el-table-column>
       <!-- 签收时间 -->
       <el-table-column label="签收时间" v-if="type !== '001'" align="center" slot="signTime">
        <template v-slot="{ row }">
          <span>{{ row.signTime || "--" }}</span>
        </template>
      </el-table-column> 
      <!-- 签收失败时间 -->
      <el-table-column label="签收失败时间" v-if="type == '001'" align="center" slot="signFailTime">
        <template v-slot="{ row }">
          <span>{{ row.signFailTime || "--" }}</span>
        </template>
      </el-table-column>
      <!-- 成交天数 -->
      <el-table-column label="成交天数" align="center" slot="afterAckDays">
        <template v-slot="{ row }">
          <span v-if="row.ackDays !== '--'" style="color: #ff687b"
            >{{ row.ackDays }}天</span
          >
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 是否入库 -->
      <el-table-column
        label="是否入库"
        v-if="type == '00'"
        align="center"
        slot="staffWaitSendNum"
      >
        <template v-slot="{ row }">
          <span style="color: #ff687b">
            {{ row.isInto == "00" ? "是" : "否" }}
          </span>
          <p v-if="row.isInto == '00'">{{ row.intoWarehouseName || "--" }}</p>
        </template>
      </el-table-column>
      <!-- 入库异常标记 -->
      <el-table-column
        v-if="type == '00'"
        label="入库异常标记"
        align="center"
        width="100px"
        slot="storeWaitSendNum"
      >
        <template v-slot="{ row }">
          <span v-if="row.isInto == '01'" style="color: #ff687b"
            >超{{ row.intoFlag.numValue }}天未入库</span
          >
          <p v-else>--</p>
        </template>
      </el-table-column>
      <!-- 出库时间 -->
      <el-table-column
        label="出库时间"
        v-if="type == '00'"
        align="center"
        slot="handlerUseDays"
      >
        <template v-slot="{ row }">
          <div v-if="row.outFlag!=null">
            <span
            style="color: #ff687b"
            v-if="row.outFlag.numValue < row.outFlag.splitValue[0]"
            >出库不足{{ row.outFlag.numValue }}天</span
          >
          <span v-else>
            <p v-if="row.outFlag.numValue!=null">已出库{{ row.outFlag.numValue }}天</p>
            <p v-else>--</p>
          </span>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <!-- 签收操作人 -->
      <el-table-column
        v-if="type == '00'"
        label="签收操作人"
        align="center"
        slot="errorTrackFollowCount"
        width="120"
      >
        <template v-slot="{ row }">
          <span>{{ row.signer ||'--'}}</span>
        </template>
      </el-table-column>
      <!-- X天后完结收货 -->
      <el-table-column
        v-if="type == '00'"
        label="X天后完结收货"
        align="center"
        slot="errorTrackFollowContent"
        width="120"
      >
        <template v-slot="{ row }">
          <div class="noteWrap" v-if="row.finishFlag!=null">
             <p v-if="row.finishFlag.numValue!=null">
              {{ Math.floor(row.finishFlag.numValue/24) }}天
              {{ row.finishFlag.numValue%24 }}小时
            </p>
             <p v-else>--</p>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <!-- 暂停发起方 -->
      <el-table-column
        label="暂停发起方"
        v-if="type == '01' || type == '02'"
        align="center"
        slot="stopSponsor"
      >
        <template v-slot="{ row }">
          <span>{{ row.pauseChannel == "00" ? "繁星平台" : "回收商" }}</span>
        </template>
      </el-table-column>
      <!-- 暂停收货操作人 -->
      <el-table-column
        label="暂停收货操作人"
        v-if="type == '01' || type == '02'"
        align="center"
        slot="stopPlople"
      >
        <template v-slot="{ row }">
          <span>{{ row.pauser }}</span>
        </template>
      </el-table-column>
      <!-- 暂停收货记录 -->
      <el-table-column
        label="暂停收货记录"
        v-if="type == '01' || type == '02'"
        align="center"
        slot="pauseNum"
      >
        <template v-slot="{ row }">
          <span v-if="row.pauseNum" class="blue_text" @click="openStopGoodRecords(row)">{{ row.pauseNum }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 暂停收货时间 -->
      <el-table-column
        label="暂停收货时间"
        v-if="type == '01' || type == '02'"
        align="center"
        slot="stopGoodStime"
      >
        <template v-slot="{ row }">
          <span>{{ row.pauseTime }}</span>
        </template>
      </el-table-column>
      <!-- 暂停收货原因 -->
      <el-table-column
        label="暂停收货原因"
        align="center"
        v-if="type == '01' || type == '02'"
        slot="stopDvielyReson"
      >
        <template v-slot="{ row }">
          <span>{{
            row.pauseCauseId == "1"
              ? "门店发错货"
              : row.pauseCauseId == "2"
              ? "申请追责"
              : row.pauseCauseId == "3"
              ? "物流丢失"
              : "其他"
          }}</span>
          <div class="blue_text" @click="actionClick('查看详情', row)">
            查看详情
          </div>
        </template>
      </el-table-column>
      <!-- 是否需要回寄 -->
      <el-table-column
        v-if="type == '01'"
        label="是否需要回寄"
        align="center"
        slot="isReturn"
      >
        <template v-slot="{ row }">
          <span>{{ row.isSendBack ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <!-- 失败时间 -->
      <el-table-column
        label="失败时间"
        v-if="type == '02'"
        align="center"
        slot="failTime"
      >
        <template v-slot="{ row }">
          <span>{{ row.failTime }}</span>
        </template>
      </el-table-column>
      <!-- 失败原因 -->
      <el-table-column
        label="失败原因"
        v-if="type == '02'"
        align="center"
        slot="failReson"
      >
        <template v-slot="{ row }">
          <span>{{ row.failCause }}</span>
        </template>
      </el-table-column>
      <!-- 完成收货类型 -->
      <el-table-column
        label="完成收货类型"
        v-if="type == '03'"
        align="center"
        slot="finishType"
      >
        <template v-slot="{ row }">
          <span>{{
            (row.finishType == "00" ? "到期外部自动收货" : "暂停收货已完结")
          }}</span>
        </template>
      </el-table-column>
      <!-- 完结时间 -->
      <el-table-column
        label="完结时间"
        v-if="type == '03'"
        align="center"
        slot="finishTime"
      >
        <template v-slot="{ row }">
          <span>{{ row.finishTime }}</span>
        </template>
      </el-table-column>
      <!-- 完结人 -->
      <el-table-column
        label="完结人"
        v-if="type == '03'"
        align="center"
        slot="finishPlople"
      >
        <template v-slot="{ row }">
          <span>{{ row.finisher }}</span>
        </template>
      </el-table-column>
      <!--X天后不可追差-->
      <!-- <el-table-column
        label="X天后不可追差"
        v-if="type == '03'"
        align="center"
        slot="finishPlople"
      >
        <template v-slot="{ row }">
          <span>{{ row.finisher }}</span>
        </template>
      </el-table-column> -->
      <!-- 完成收货备注 -->
      <el-table-column
        label="完成收货备注"
        v-if="type == '03'"
        align="center"
        slot="finishRemarck"
      >
        <template v-slot="{ row }">
          <span>{{ row.finishNote||"--" }}</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        v-if="type != '03'"
        fixed="right"
        label="操作"
        :width="type == '01'||type == '00'?'200px':'110px'"
        align="center"
        slot="action"
      >
        <template v-slot="{ row }">
          <el-button
            type="danger"
            round
            v-if="type == '00'"
            size="mini"
            @click="actionClick('暂停收货', row)"
            >暂停收货</el-button
          >
          <el-button
            type="primary"
            round
            v-if="type == '01'||type == '00'"
            size="mini"
            @click="actionClick('已完结', row)"
            >完结收货</el-button
          >
          <el-button
            type="danger"
            round
            v-if="type == '02'||type=='001'"
            size="mini"
            @click="actionClick('重新发起', row)"
            >重新发起</el-button
          >
          <el-button
            type="danger"
            round
            v-if="type == '01'"
            size="mini"
            plain
            @click="actionClick('暂停中重新发起', row)"
            >重新发起</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 完结收货 -->
    <el-dialog
      title="操作提示"
      :visible.sync="traceLogShow"
      width="500px"
      @close="overClose"
    >
      <div class="offline_box">
        <p>是否确认完结收货？</p>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="remarckruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="完结收货备注"
          prop="finishNote"
          label-width="110px"
        >
          <el-input
            type="textarea"
            maxlength="200"
            v-model="ruleForm.finishNote"
            placeholder="请输入备注内容，最多200字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="traceLogShow = false">关 闭</el-button>
        <el-button type="primary" @click="getFollow('remarckruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  重新发起 -->
    <GlobalDialog
      @onClose="expediteGoodsShow = false"
      @resetPopupData="expediteGoodsShow = false"
      :show="expediteGoodsShow"
      title="操作提示"
      top="6%"
      :width="500"
      @submitPopupData="expediteGoodsConfig"
    >
      <div class="offline_box">
        <p>是否确认重新发起暂停收货？</p>
      </div>
    </GlobalDialog>
      <!--  重新发起 -->
      <GlobalDialog
      @onClose="signGoodsShow = false"
      @resetPopupData="signGoodsShow = false"
      :show="signGoodsShow"
      title="操作提示"
      top="6%"
      :width="500"
      @submitPopupData="signGoodsConfig"
    >
     <p style="color: #ff687b;">说明：若订单为降价收货，系统将自动在已签收后重新发起暂停收货</p>
      <div class="offline_box">
        <p>是否确认重新发起已签收？</p>
      </div>
    </GlobalDialog>
    <DialogTbale
    :show="stopGoodRecordsShow"
    :loading="stopGoodRecordsLoading"
    title="暂停收货记录"
    :columns="stopGoodRecordsTableColumns"
    :data="stopGoodRecords"
    :isPagination="false"
    :isIndex="false"
    :width="800"
    @onClose="stopGoodRecordsShow = false">
      <el-table-column
        prop="index"
        type="index"
        label="序号"
        slot="index"
        align="center"
      ></el-table-column>
      <el-table-column label="暂停收货原因" slot="pauseCauseId" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pauseCauseId=='1'?'门店发错货':row.pauseCauseId=='2'?'申请追责':row.pauseCauseId=='3'?'物流丢失':'其他' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="暂停收货操作人" slot="pauser" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pauser }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operate" align="center">
        <template slot-scope="{ row }">
          <el-button size="small" type="primary" @click="seeStopGoodsDetail(row)">查看详情</el-button>
        </template>
      </el-table-column>
      <div slot="tableFooter" style="height: 20px;"></div>
    </DialogTbale>
    <stopGoods
      :stopShopShow="stopShopShow"
      :stopShopdata="currentData"
      @submitForm="submitForm"
      @stopResetForm="stopResetForm"
    >
    </stopGoods>
    <stopGoodsDetail
      :stopShopdetailShow="stopShopdetailShow"
      :detaildata="currentData"
      :pauseId="pauseId"
      @stopdetailclose="stopdetailclose"
    ></stopGoodsDetail>
  </div>
</template>

<script>
import _api from "@/utils/request";
import stopGoods from "../components/stopGoods.vue";
import stopGoodsDetail from "../components/stopGoodsDetail.vue";
export default {
  name: "Table",
  components: {
    stopGoods,
    stopGoodsDetail,
  },
  data() {
    return {
      ruleForm: {
        finishNote: "",
      },
      rules: {
        finishNote: [
          { required: true, message: "请输入收货备注", trigger: "blur" },
        ],
      },
      isFinishSenderAddress:'',
      stopShopdetailShow: false,
      stopShopShow: false,
      refresh: false,
      finishNum: "",
      pauseFailNum: "",
      pauseNum: "",
      signNum: "",
      signFailNum:'',
      currentData: {},
      tableColumns: [
       {
          prop: "companyName",
          label: "门店商家",
        },
        { slotName: "orderNo" }, //	订单号
        {
          prop: "outOrderNo",
          label: "外部单号",
          width: "130",
        },
        { slotName: "phoneName" }, //机型
        {
          prop: "merchantName",
          label: "回收商家",
        },
        {
          prop: "receiverMerchantName",
          label: "收货方",
          width: "155",
        },
        { slotName: "afterAckDays" }, //	成交天数
        {
          prop: "expressTime",
          label: "发货时间",
        },
        {
          prop: "expressNo",
          label: "发货单号",
          width: "140",
        },
        { slotName: "orderState" },
        { slotName: "signChannel" },
        { slotName: "signTime" },//签收时间
        { slotName: "signFailTime" },//签收失败时间
        { slotName: "staffWaitSendNum" }, //	是否入库
        { slotName: "storeWaitSendNum" }, //	入库异常标记
        { slotName: "handlerUseDays" }, //出库时间
        { slotName: "errorTrackFollowCount" }, //	签收操作人
        { slotName: "errorTrackFollowContent" }, //	X天后完结收货
        { slotName: "finishType" }, //	完成收货类型
        { slotName: "stopSponsor" }, //	暂停发起方
        { slotName: "stopPlople" }, //	暂停收货操作人
        { slotName: "pauseNum" }, //	暂停收货记录
        { slotName: "stopGoodStime" }, //暂停收货时间
        { slotName: "stopDvielyReson" }, //	暂停收货原因
        { slotName: "isReturn" }, //	是否需要回寄
        { slotName: "failTime" }, //	失败时间
        { slotName: "failReson" }, //	失败原因
        { slotName: "finishTime" }, //	完结时间
        { slotName: "finishPlople" }, //	完结人
        { slotName: "finishRemarck" }, //	完成收货备注
        { slotName: "action" }, //操作
      ],
      type: "00",
      seachDataList: [],
      loading: false,
      excelLoading: false,
      allExcelLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      traceLogShow: false, //完结收货
      command: "", // 谷歌验证码
      expediteGoodsShow: false, //重新发起
      signGoodsShow:false,
      stopGoodRecords: [],
      stopGoodRecordsShow: false,
      stopGoodRecordsLoading: false,
      stopGoodRecordsTableColumns: [
        { slotName: "index" },
        { slotName: "pauseCauseId" },
        { slotName: "pauser" },
        { label: "暂停收货时间", prop: "pauseTime" },
        { slotName: "operate" },
      ],
      pauseId: "",
      // isValidity:"",//是否超过时效
    };
  },
  created() {
    this.type = this.$route.query.type || '00'
    this.refresh = true;
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    isFinishSenderAddress:{
      handler(old,newa){
        this.$store.commit("tagsView/SETFINISH",this.isFinishSenderAddress);
        this.handleCurrentChange();
      }
    },
    // isValidity:{
    //   handler(old,newa){
    //     this.$store.commit("tagsView/SETFINISH",this.isValidity)
    //     this.handleCurrentChange();
    //   }
    // }
  },
  methods: {
    overClose() {
      this.$refs["remarckruleForm"].resetFields();
    },
    stopdetailclose() {
      this.pauseId = ""
      this.stopShopdetailShow = false;
    },
    submitForm(data) {
      console.log(data);
      this.stopShopShow = false;
    },
    stopResetForm() {
      this.stopShopShow = false;
      this.handleCurrentChange();
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        signPosi:this.isFinishSenderAddress===true?'01':this.isFinishSenderAddress===false?'':'',
        ackStartTime: this.SeachParams.ackStartTime,
        ackEndTime: this.SeachParams.ackEndTime,
        finishEndTime: this.SeachParams.finishEndTime,
        finishStartTime: this.SeachParams.finishStartTime,
        pauseEndTime: this.SeachParams.pauseEndTime,
        pauseStartTime: this.SeachParams.pauseStartTime,
        signEndTime: this.SeachParams.signEndTime,
        signStartTime: this.SeachParams.signStartTime,
        intoEndTime: this.SeachParams.intoEndTime,
        intoStartTime: this.SeachParams.intoStartTime,
        outEndTime: this.SeachParams.outEndTime,
        outStartTime: this.SeachParams.outStartTime,
        expressNo: this.SeachParams.expressNo,
        imei: this.SeachParams.imei,
        manualMerchantId: this.SeachParams.manualMerchantId,
        orderNo: this.SeachParams.orderNo,
        outOrderNo: this.SeachParams.outOrderNo,
        isInto: this.SeachParams.isInto,
        isOut: this.SeachParams.isOut,
        orderState: this.SeachParams.orderState,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        state: this.type, // 	状态(00：已签收待收货，001：签收失败，01：暂停收货中，02：暂停收货失败：，03：已完成收货)
      };
      this.loading = true;
      _api
        .gethaihiushoutakeList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            console.log("~~~", res.data);
            this.seachDataList = res.data.list.records;
            this.finishNum = res.data.finishNum;
            this.pauseFailNum = res.data.pauseFailNum;
            this.pauseNum = res.data.pauseNum;
            this.signNum = res.data.signNum;
            this.signFailNum = res.data.signFailNum;
            this.page.total = res.data.list.total;
            this.page.pageNum = res.data.list.current;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding === "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 胶囊按钮切换
    btnClick(type) {
      if (this.type == type || this.loading) {
        return false;
      }
      this.seachDataList =[]
      this.isFinishSenderAddress=''
      this.refresh = false;
      this.type = type;
      this.$store.commit("tagsView/GOODSTRACKSTATE", type);
      this.page.pageNum = 1;
      this.refresh = true;
      // this.isValidity=""
      this.$forceUpdate();
    },
    actionClick(type, row) {
      console.log("@@@", row);
      this.currentData = row;
      switch (type) {
        case "暂停收货":
          this.stopShopShow = true;
          break;
        case "已完结":
          this.traceLogShow = true;
          break;
        case "重新发起":
          if(this.type=='001'){
            this.signGoodsShow=true
          }else{
            this.expediteGoodsShow = true;
          }
          break;
        case "查看详情":
          this.stopShopdetailShow = true;
          break;
        case "暂停中重新发起":
          this.stopShopShow = true;
          break;
      }
    },
    getFollow(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api
            .gethaihiushoufinish({
              orderNo: this.currentData.orderNo,
              finishNote: this.ruleForm.finishNote,
            })
            .then((res) => {
              this.$message.success("操作成功");
              this.traceLogShow = false;
              this.handleCurrentChange();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    signGoodsConfig() {
      _api.renREWSignhaihiushou({
        orderNo: this.currentData.orderNo,
      }).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.signGoodsShow = false;
          // 重拉请求
          this.handleCurrentChange(1);
        }
      });
    },
    // 重新发起
    expediteGoodsConfig() {
      _api.renREWhaihiushou({
        orderNo: this.currentData.orderNo,
      }).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.expediteGoodsShow = false;
          // 重拉请求
          this.handleCurrentChange(1);
        }
      });
    },
    // 打开暂停收货记录
    openStopGoodRecords(row) {
      this.stopGoodRecordsShow = true
      this.stopGoodRecordsLoading = true
      this.currentData = row;
      _api.stopPauseList({orderNo:row.orderNo}).then(res => {
        if(res.code === 1){
          this.stopGoodRecords = res.data
        }
        this.stopGoodRecordsLoading = false
      })
    },
    //暂停收货记录查看详情
    seeStopGoodsDetail(row) {
      this.pauseId = row.pauseId
      this.stopShopdetailShow = true;
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/ .el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    min-width: 800px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981ff;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.logStyle {
  border: 1px solid #5a8eff;
  width: 590px;
  min-height: 50px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 6px;
}

.noteWrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  .cancel_offline_note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}

.remarks_box {
  width: 100%;

  > h2 {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.apply_for_tip {
  margin-bottom: 20px;
  transform: translateX(-10px);
}

/deep/ .apply_for_box .el-dialog .el-dialog__body {
  padding-left: 30px;
  padding-right: 30px;
}

.enable_box {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 6px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 6px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }

  /deep/ .el-switch__core {
    background: #b6b9d0;
  }

  /deep/ .el-switch.is-checked .el-switch__core {
    background-color: #0981ff;
  }
}

.logistics-switch-dialog {
  /deep/ .header_messgae {
    margin-bottom: 34px;
    color: #ff7586;
  }

  /deep/ .tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
}
.blue_text {
  cursor: pointer;
  color: rgb(9, 129, 255);
  text-decoration: underline;
}
</style>
