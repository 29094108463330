<template>
  <div class="home">
    <!-- <topTitle /> -->
    <div class="top_model">
      <div class="left flex">
        <span
          class="ellipsis"
          style="max-width: 900px;font-weight:600;height:66px; line-height: 66px;margin-right: 90px; font-size: 22px;font-family: FZLanTingHei-DB-GBK;color: #333333;"
          >{{ this.nname }}</span
        >
      </div>
    </div>
    <div style="overflow:hidden;">
      <orderModel :id="iiid" :partition="partition" />
    </div>
    <!-- <div>
      <Settle />
    </div> -->
     <div class="flex-table">
        <div class="leftTable">
        <div class="flex-jb flex-ac">
          <div class="txt-333 font-w card-title">回收数据分析</div>
        </div>
          <div class="flex_tab">
            <div>
              <div
                v-for="item in tabList"
                @click="tabclick(item.val)"
                :key="item.val"
                :class="{ active_tab: item.val == tabval }"
              >
                {{ item.name }}
              </div>
            </div>
            <div>
              <el-button v-if="tabval != '3'" type="warning" size="small" @click="$refs['newMachineAnalysisDia'].open($refs[`child${tabval}`].getParams())">新机指标分析</el-button>
              <el-button type="success" size="small" @click="exportExcel">
                <div style="display: flex;align-items:center">
                  <!-- <img src="https://image.fanxinghuishou.cn/20221008/840842886.png"> -->
                  导出表格
                </div>
              </el-button>
            </div>
            <!-- <div @click="exportExcel">
              <img
                src="https://image.fanxinghuishou.cn/20221008/840842886.png"
                alt=""
              />
              导出表格
            </div> -->
          </div>
          <template v-if="tabval == '1'">
            <TableRecall :iiid="iiid" ref="child1" :partition="partition"
          /></template>
          <template v-if="tabval == '2'">
            <TableInquiry :iiid="iiid" ref="child2" :partition="partition" />
          </template>
          <template v-if="tabval == '3'"
            ><TableOrder :iiid="iiid" ref="child3" :partition="partition"
          /></template>
        </div>
    </div>
    <div class="rightTable">
      <TableProfit :iiid="iiid" :partition="partition" />
    </div>
    <div class="flex-table">
        <div class="leftTable">
        <div class="flex-jb flex-ac">
          <div class="txt-333 font-w card-title">换购统计</div>
        </div>
        <div class="tradeInRadio" style="margin: 20px 0 0 10px;">
          <el-radio-group v-model="tradeInRadio" size="medium">
            <el-radio-button label="01">换购成交数据</el-radio-button>
            <el-radio-button label="02">换购录音统计</el-radio-button>
          </el-radio-group>
        </div>
        <template v-if="tradeInRadio === '01'">
          <div class="flex_tab">
            <div>
              <div
                v-for="item in newSubsidy"
                @click="newTabclick(item.val)"
                :key="item.val"
                :class="{ active_tab: item.val == newTabVal }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <template v-if="newTabVal == '4'">
            <TableNewStore :iiid="iiid" ref="child4" :partition="partition"
          /></template>
          <template v-if="newTabVal == '5'">
            <TableNewstaff :iiid="iiid" ref="child5" :partition="partition" />
          </template>
          <template v-if="newTabVal == '6'"
            ><TableNewShop :iiid="iiid" ref="child6" :partition="partition"
          /></template>
        </template>
        <template v-if="tradeInRadio === '02'">
          <div class="flex_tab">
            <div>
              <div
                v-for="item in newSubsidyRecording"
                @click="recordingTabclick(item.val)"
                :key="item.val"
                :class="{ active_tab: item.val == recordingTabVal }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <template v-if="recordingTabVal == '4'">
            <RecordingTableStore :iiid="iiid" ref="child4" :partition="partition"
          /></template>
          <template v-if="recordingTabVal == '5'">
            <RecordingTableStaff :iiid="iiid" ref="child5" :partition="partition" />
          </template>
        </template>
        </div>
      </div>
    <div class="HomeModel">
      <echartsOrder :iiid="iiid" />
    </div>
    <div class="HomeModel">
      <echartsDeal :iiid="iiid" />
    </div>

    <div class="bottom_phone">
      <el-tag
        closable
        v-for="tag in dynamicTags"
        :key="tag"
        :disable-transitions="false"
        @close="handleClose(tag)"
        effect="dark"
      >
        {{ tag }}
      </el-tag>
    </div>
    <NewMachineAnalysisDia ref="newMachineAnalysisDia" />
  </div>
</template>

<script>
import topTitle from './component/topTitle'
import orderModel from "./component/orderModel";
import Settle from './component/Settle'
import echartsOrder from "./component/echarts/echartsOrder";
import TableProfit from "./component/TableProfit";
import TableInquiry from "./component/TableInquiry";
import echartsDeal from "./component/echarts/echartsDeal";
import TableOrder from "./component/TableOrder";
import TableRecall from "./component/TableRecall";
import TableNewShop from "./component/TableNewShop";
import TableNewstaff from "./component/TableNewstaff";
import TableNewStore from "./component/TableNewStore";
import NewMachineAnalysisDia from './component/NewMachineAnalysisDia.vue';
import RecordingTableStaff from "./component/RecordingTableStaff";
import RecordingTableStore from "./component/RecordingTableStore";
import _api from "@/utils/request";
export default {
  components: {
    orderModel,
    echartsOrder,
    echartsDeal,
    TableOrder,
    TableRecall,
    TableProfit,
    TableInquiry,
    TableNewShop,
    TableNewstaff,
    TableNewStore,
    NewMachineAnalysisDia,
    RecordingTableStaff,
    RecordingTableStore
  },
  name: "home",
  data() {
    return {
      dynamicTags: ["繁星的客服电话：028-86116033"],
      partition: [],
      tabList: [
        {
          name: "各门店交易数据统计",
          val: "1",
        },
        {
          name: "员工询价数据统计",
          val: "2",
        },
        {
          name: "各回收商交易数据统计",
          val: "3",
        },
      ],
      newSubsidy: [
        {
          name: "按门店统计",
          val: "4",
        },
        {
          name: "按员工统计",
          val: "5",
        },
        {
          name: "按换购商品统计",
          val: "6",
        },
      ],
      newSubsidyRecording: [
        {
          name: "按门店统计",
          val: "4",
        },
        {
          name: "按员工统计",
          val: "5",
        },
      ],
      tabval: "1",
      newTabVal: "4",
      tradeInRadio: "01",
      recordingTabVal: "4",
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.iiid = this.$route.query.id;
    this.nname = this.$route.query.name;
    this.getNotPInspectorSelect()
    this.getStoreList()
    this.getTagList()
    console.log(this.nname, "8888888888888888888888888888888");
  },
  methods: {
    tabclick(val) {
      this.tabval = val;
    },
    newTabclick (val) {
      this.newTabVal = val
    },
    recordingTabclick(val) {
      this.recordingTabVal = val;
    },
    exportExcel() {
      console.log(`child${this.tabval}`);
      console.log(this.$refs[`child${this.tabval}`], 'this.$refs[`child${this.tabval}`]');
      this.$refs[`child${this.tabval}`].exportExcel();
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    getNotPInspectorSelect() {
      _api.inspectorAreaSelect({ scope: "00", companyId: this.iiid }).then((res) => {
        console.log(res, "总监区域选择");
        this.partition = res.data;
      });
    },
    getStoreList() {
      _api.getStoreSelectList({companyId: this.iiid}).then((res) => {
        this.$store.commit("tagsView/SET_STORELIST", res.data);
      });
    },
    getTagList() {
      _api.getStorePcTagSelect({companyId: this.iiid}).then((res) => {
        this.$store.commit("tagsView/SET_TAGLIST", res.data);
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.home {
  width: 100%;
  /deep/ .el-table th {
    padding: 3px 0 !important;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .top_model {
    margin-bottom: 10px;
    width: 100%;
    height: 66px;
    background: #e4ecfd;
    border-radius: 33px;
    .left {
      float: left;
      margin-left: 2%;
      width: 50%;
      height: 66px;
      line-height: 66px;
      .store_le {
        div {
          font-size: 22px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: #333333;
        }
        div:last-child {
          font-size: 22px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: rgba(9, 129, 255, 1);
        }
      }
      .poople_le {
        margin-left: 90px;
        div {
          font-size: 22px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: #333333;
        }
        div:last-child {
          font-size: 22px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: rgba(9, 129, 255, 1);
        }
      }
    }
    .right {
      height: 66px;
      line-height: 66px;
      float: right;
      margin-right: 28px;
      font-size: 16px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
    }
  }
  .HomeModel {
    padding-top: 20px;
  }
  .flex-table {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }
  .leftTable {
    flex: 1 1 0%;
    // margin-right: 14px;
    min-width: 666px;
    background: #fff;
  }
  .rightTable {
    width: 100%;
    margin-top: 20px;
    background: #fff;
  }
  .bottom_phone {
    margin: 20px 0;
    /deep/ .el-tag--dark .el-tag__close {
      margin-left: 204px;
    }
    /deep/ .el-tag--dark {
      background: rgba(9, 129, 255, 1) !important;
      opacity: 0.5;
      border-radius: 18px;
      font-size: 16px;
    }
  }
}
.error-page {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-page-conent {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .error-tip {
    text-align: center;
    color: #0c82ff;
    font-size: 18px;
  }
}
.flex_tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  border-bottom: 1px solid #eff2f7;
  background: #fff;
  padding-right: 16px;
  box-sizing: border-box;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    height: 100%;
    > div {
      font-size: 16px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 600;
      color: #8492a6;
      padding: 0 16px;
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // > div:nth-child(2) {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 14px;
  //   font-family: FZLanTingHei-M-GBK;
  //   font-weight: 400;
  //   color: #ffffff;
  //   cursor: pointer;
  //   > img {
  //     width: 16px;
  //     height: 16px;
  //     margin-right: 2px;
  //   }
  //   width: 92px;
  //   height: 30px;
  //   background: #50cd85;
  //   border-radius: 4px;
  // }
  // > div:nth-child(2):hover {
  //   opacity: 0.8;
  // }
}
.active_tab {
  color: #333333 !important;
}
.active_tab:after {
  content: "";
  width: 90px;
  height: 4px;
  background: #20a0ff;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.flex-jb{
  margin-top: 5px;
}
.font-w {
  font-weight: bold;
}
.font-333 {
  color: #333333;
}
.card-title {
  position: relative;
  padding-left: 16px;
}
.card-title::before {
  position: absolute;
  content: " ";
  width: 4px;
  height: 18px;
  background: #4966d2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
}
.tradeInRadio{
  /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner{
    background-color: #20a0ff;
    border-color: #20a0ff;
  }
}
</style>
