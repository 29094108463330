import request from './request'

export const http = {
    //配置收货地址编码
    getAddressCode(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformAddress/updateAddressChannelRandom',
            data: params
        })
    },
    //创建仓库
    makeStore(params){
        return request({
            method:"POST",
            url:"/platform/pc/warehouse/add",
            data:params
        })

    },
    //仓库详情
    storeDetail(params){
            return request({
                method:"GET",
                url:"/platform/pc/warehouse/info",
               params
            })
    },
        //仓库列表
        storeList(params){
            return request({
                method:"POST",
                url:"/platform/pc/warehouse/list",
                data:params
            })
    },
        //创建下拉框
        makeStoreSelect(params){
            return request({
                method:"GET",
                url:"/platform/pc/warehouse/pull/down",
                data:params
            })
    },
        //编辑仓库
        editStore(params){
            return request({
                method:"POST",
                url:"/platform/pc/warehouse/update",
                data:params
            })
    }
}