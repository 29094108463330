import request from './request'

export const _http = {
    //获取修改当换同串号重评配置
    getFindDhOrderSameImeiQuote() {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/findDhOrderSameImeiQuote',
        })
    },
    //修改当换同串号重评配置
    getUpdateDhOrderSameImeiQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/updateDhOrderSameImeiQuote',
            data:params
        })
    },
}