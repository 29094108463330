// 相关定义数据
export const listFormList = [
    {
        labelName: "矩阵名称",
        key: 'matrixId',
        type: "selectFilterable",
        placeholder: "请选择矩阵",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "矩阵门店商户",
        key: 'companyId',
        type: "selectFilterable",
        placeholder: "请选择门店商",
        lableWidth: '110px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "矩阵回收商户",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请选择回收商",
        clear: true,
        lableWidth: '110px',
        option: [],
        clearFun: () => false
    },
    {
        labelName: "是否外部渠道",
        key: 'isOut',
        type: "select",
        lableWidth: '110px',
        placeholder: "请选择",
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '是',
                value: true
            },
            {
                label: '否',
                value: false
            }
        ]
    },
    // 门店商户
    {
        labelName: "门店商户",
        key: 'innerCompanyId',
        type: "selectFilterable",
        lableWidth: '80px',
        placeholder: "请选择商户名称",
        clear: true,
        clearFun: () => false,
        option: []
    },
    // 回收商户
    {
        labelName: "报价回收商",
        key: 'innerMerchantId',
        type: "selectFilterable",
        lableWidth: '90px',
        clear: true,
        clearFun: () => false,
        placeholder: "请选择商户名称",
        option: []
    },
    {
        labelName: "筛选未加入矩阵回收商",
        key: 'notInnerMerchantId',
        type: "selectFilterable",
        lableWidth: '160px',
        clear: true,
        clearFun: () => false,
        placeholder: "请选择商户名称",
        option: []
    },
]

// list列
export const listColumns = [
    {
        prop: "index",
        label: "序号",
        width: "60px"
    },
    {
        prop: "matrixName",
        label: "矩阵名称",
    },
    {
        prop: "companyName",
        label: "矩阵门店商户",
    },
    {
        prop: "merchantName",
        label: "矩阵回收商",
    },
    {
        prop: "storeNum",
        label: "门店数量",
    },
    {
        prop: "staffNum",
        label: "员工数量",
    },
    {
        prop: "companyNum",
        label: "包含门店商户",
    },
    {
        prop: "merchantNum",
        label: "报价回收商",
    },
    {
        prop: "isOut",
        label: "是否外部渠道",
    },
    {
        prop: "Outchannel",
        label: "外部商户号/渠道",
    },
    {
        prop:"fxChannelCode",
        label:"繁星渠道编码"
    },
    {
        prop: "isManufacturers",
        label: "是否厂家订单",
    },
    {
        prop: "pushFactor",
        label: "消息推送值",
    },
    {
        prop: "action",
        label: "操作",
    },
];

// 创建矩阵表单
export const createMatrixForm = {
    channelCode: null,
    companyId: "",
    isOut: false,
    matrixName: "",
    merchantId: "",
    singleStoreStaffNum: 10,
    storeNum: 50,
    command: ""
};

// 创建矩阵表单list
export const createMatrixFormList = [
    {
        key: "matrixName",
        label: "矩阵名称",
        placeholder: "请输入矩阵名称，最多15个字",
        type: "input",
        isShow: true
    },
    {
        key: "storeNum",
        label: "配置门店数量",
        placeholder: "请输入配置门店数量，最多100个",
        type: "input",
        disabled: false,
        isShow: true
    },
    {
        key: "singleStoreStaffNum",
        label: "单个门店店员数量",
        placeholder: "请输入单个门店数量，最多10个",
        type: "input",
        disabled: true,
        isShow: true
    },
    {
        key: "isOut",
        label: "是否为外部渠道商",
        placeholder: "请选择是否为外部渠道",
        type: "radio",
        options: [
            {
                label: "否",
                value: false
            },
            {
                label: "是",
                value: true
            }
        ],
        isShow: true
    },
    {
        key: "channelCode",
        label: "选择外部渠道",
        placeholder: "请选择外部渠道商",
        type: "radio",
        options: [],
        isShow: false
    },
    {
        key: "companyId",
        label: "关联门店商户",
        placeholder: "请选择关联门店商户",
        type: "select",
        options: [],
        isShow: true
    },
    {
        key: "merchantId",
        label: "关联回收商户",
        placeholder: "请选择关联回收商户",
        type: "select",
        options: [],
        isShow: true
    },
]

// 新建矩阵表單规则验证
export const createMatrixRules = {
    companyId: [
        {
            required: true,
            message: "请选择门店商",
            trigger: "blur"
        }
    ],
    isOut: [
        {
            required: "true",
            message: "请选择是否为外部渠道",
            trigger: "blur"
        }
    ],
    matrixName: [
        {
            required: true,
            message: "请输入矩阵名称",
            trigger: "blur"
        }
    ],
    merchantId: [
        {
            required: true,
            message: "请选择回收商",
            trigger: "blur"
        }
    ],
    singleStoreStaffNum: [
        {
            required: true,
            message: "请配置单个门店店员数量"
        }
    ],
    storeNum: [
        {
            required: true,
            message: "请配置单个门店数量"
        }
    ]
}

// 新建矩阵谷歌验证码
export const createMatrixCodeProp = {
    isCode: true,
    isTip: false,
    show: false,
    title: "安全验证",
    codeLabel: "谷歌验证码：",
    code: "",
    loading:false,
};

// 添加回收商弹窗
export const addRecoveryProps = {
    title: "选择商户",
    width: "47%",
    tabData: [],
    isResetForm: false,
    formItemList: [
        {
            labelName: "商家名称",
            key: 'merchantName',
            type: "input",
            placeholder: "请输入商家名称查询",
        },
    ],
    params: {
        pageNum: 1,
        pageSize: 5,
        isOutMerchant:"02",
        merchantType: "02",
        isNiceMerchant: "02",
        queryOrderInfo: false,
        isFilterMatrix: "01"
    },
    tableColumns: [
        {
            prop: "select",
            label: "单选",
            width: "60px"
        },
        {
            prop: "merchantName",
            label: "商家名称",
        },
        {
            prop: "contactName",
            label: "联系人",
        },
        {
            prop: "contactPhone",
            label: "联系电话",
        },
        {
            prop: "fullAddress",
            label: "地址",
        },
        {
            prop: "entryTime",
            label: "入驻时间",
        }
    ],
}


// 矩陣报价回收商列表
export const recoveryNumProps = {
    title: "矩阵报价回收商",
    width: "1100px",
    tabData: [],
    isResetForm: false,
    formItemList: [
        {
            labelName: "商家名称",
            key: 'merchantName',
            type: "input",
            placeholder: "请输入商家名称查询",
        },
        {
            labelName: "状态",
            key: 'companyIsEnable',
            type: "select",
            lableWidth: '110px',
            placeholder: "请选择",
            option: [
                {
                    label: '启用',
                    value: "01"
                },
                {
                    label: '禁用',
                    value: "02"
                }
            ]
        }
    ],
    isBtn: false,
    params: {
        pageNum: 1,
        pageSize: 5,
        companyId: ""
    },
    tableColumns: [
        {
            prop: "index",
            label: "序号",
            width: "60px"
        },
        {
            prop: "merchantName",
            label: "回收商",
        },
        {
            prop: "contact",
            label: "创建人",
        },
        {
            prop: "phone",
            label: "联系电话",
        },
        {
            prop: "staffCount",
            label: "员工数量",
        },
        {
            prop: "configStores",
            label: "配置合作门店",
        },
        {
            prop: "warehouseName",
            label: "分转仓",
            width: "160px"
        },
        {
            prop: "middleTime",
            label: "添加时间",
        },
        {
            prop: "matrixEnableTime",
            label: "最近启用时间",
        },
        {
            prop: "KAmerchantname",
            label: "显示真实KA名称",
        },
        {
            prop: "action",
            label: "操作",
        },
    ]
};

export const storeListProp = {
    title: "门店列表",
    configText: "确定移除",
    isExcelLoading:false,
    width: "53%",
    tabData: [],
    allTabData: [],
    isAction:true,
    isSearch: true,
    isBtn: true,
    isSlot: true,
    isPage: false,
    isResetForm: false,
    // 当前选择数据
    currentData: {},
    // 选择的所有数据
    allSelectData: [],
    // 当前选择项
    selectData: [],
    params: {
        enable: "",
        matrixId: 0,
        merchantId: 0
    },
    isTabSelection: true,
    formItemList: [
        {
            labelName: "区域/总监",
            key: 'staffId',
            type: "selectFilterable",
            placeholder: "请选择区域",
            clear: true,
            option: [],
            clearFun: () => false
        },
        {
            labelName: "门店名称",
            key: 'name',
            type: "input",
            placeholder: "请输入商家名称查询",
        },
        {
            labelName: "回收商数量",
            key:"merchantNum",
            type:"input",
            isNumber:true,
            // 为避免其他组件对其使用，额外添加一个条件判断，只有本人操作才会执行
            numYypeClear:true,
            placeholder: "请输入回收商数量"
        }
    ],
    tableColumns: [
        {
            prop: "index",
            label: "序号",
            width: "60px"
        },
        {
            prop: "storeName",
            label: "门店名称",
        },
        {
            prop: "storeAddress",
            label: "门店地址",
        },
        {
            prop: "storeManager",
            label: "门店店长",
        },
        {
            prop: "storeManagerPhone",
            label: "店长联系方式",
        },
        {
            prop: "inquiryMerchantName",
            label: "回收商名称"
        },
        {
            prop: "inquiryMerchantNum",
            label: "回收商数量"
        },
        {
            prop: "staffCount",
            label: "店员数量",
        },
    ]
};
// 矩阵回收商关联门店列表

//禁用启用提示
export const quotationTipProp = {
    isCode: true,
    isTip: true,
}

// 表格数据
export const tableDatas = {
    tableProps: {
        border: true,
        size: 'small',
        "header-cell-style": {
            background: '#E4ECFD', padding: 0
        },
    }

}
