import request from './request'

export const http = {
    //是否限制本单继续追差
   postChasGap(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/oc-limit-apply',
            data: params
        })
    },
}