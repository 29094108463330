<template>
  <div>
    <el-dialog
      title="添加绑定"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="900px"
    >
      <p style="color: #ff8080;margin-bottom: 25px;">说明：请先查询本地已有机型</p>
      <el-row :gutter="12">
        <el-col :span="10">
          <el-card>
            <div class="title">待绑定机型</div>
            <div class="localModel">{{ row.outMachineName }}</div>
            <div class="bindInfo" style="display: flex;align-items: center;justify-content: space-between">
              <span>来源：{{ row.channelName }}</span>
              <span>外部品牌：{{ row.outBrandName }}</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="14">
          <el-card>
            <div class="title">查询绑定本地机型</div>
            <div style="display: flex;align-items: center;margin-bottom: 10px;" v-if="row.channel === 'cmcc'">
              <span style="flex-shrink: 0;">所属分类：</span>
              <el-select v-model="typeId" placeholder="请选择机型" style="width: 100%">
                <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
            <div>
              <el-select v-model="machineId" filterable remote :remote-method="remoteMethod" placeholder="请选择机型" style="width: 100%" @change="getBindInfo">
                <el-option v-for="item in selectList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
            <div v-if="bindInfo" class="bindInfo">所选机型绑定{{ bindInfo.channelName }}机型：{{bindInfo.outMachineName}}<span class="link danger" style="margin-left: 20px" @click="cancelBind">取消绑定</span></div>
            <div v-else-if="machineId" class="bindInfo">当前机型未绑定{{ row.channelName }}机型，可直接绑定</div>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'

export default {
  name: "AddModelBindDia",
  props:{
    classList:Array,
  },
  data() {
    return {
      visible: false,
      loading: false,

      type: '',
      row: {},
      machineId: '',
      typeId: "",
      selectList: [],
      bindInfo: null,
    };
  },
  methods: {
    open(type, row) {
      this.type = type
      this.row = row
      this.machineId = ''
      this.typeId = ''
      this.selectList = []
      this.bindInfo = null
      // this.pageNum = 1
      // this.getData()
      this.visible = true
    },
    cancelBind() {
      const { channel, channelName, machineId, machineName, outMachineName } = this.bindInfo
      confirm({
        title: '取消绑定提示',
        width: '550px',
        // desc: `说明：取消绑定后，无法通过选择该机型查询原${ channel == 'chx' ? '采货侠' : '分毫'}关联机型的预估价`,
        // message: `是否确认取消<span style="color: red">${machineName}</span>与${ channel == 'chx' ? '采货侠' : '分毫'}机型<span style="color: red">${ outMachineName }</span>的绑定关系？`,
        desc: `说明：取消绑定后，无法通过选择该机型查询原${ channelName }关联机型的预估价`,
        message: `是否确认取消<span style="color: red">${machineName}</span>与${ channelName }机型<span style="color: red">${ outMachineName }</span>的绑定关系？`,
        confirm: () => {
          _api.cancelBind({
            channel,
            machineId
          }).then(() => {
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
            this.getBindInfo();
          })
        }
      })
    },
    remoteMethod(name) {
      _api.platformMachineSelectList({
        channel: this.type,
        name: name,
        outTypeId: this.row.outTypeId,
        typeId: this.typeId,
      }).then(res => {
        this.selectList = res.data
      })
    },
    getBindInfo() {
      _api.getPlatformMachine({
        channel: this.type,
        machineId: this.machineId
      }).then(res => {
        console.log(res)
        this.bindInfo = res.data || null
      })
    },
    confirm() {
      if (!this.machineId) {
        this.$message.error('请选择机型')
        return
      }
      if (this.bindInfo) {
        this.$message.error('请先解绑')
        return
      }
      _api.doBindInPlatform({
        channel: this.type,
        machineId: this.machineId,
        outMachineId: this.row.outMachineId,
      }).then(() => {
        this.$message.success('操作成功')
        this.visible = false
        this.$emit('confirm')
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.title{
  display: flex;
  line-height: 1em;
  margin-bottom: 20px;
  &::before{
    content: '';
    width: 4px;
    background: #0981ff;
    margin-right: 10px;
  }
}
.bindInfo{
  margin-top: 20px;
}
.localModel{
  height: 40px;
  line-height: 40px;
  text-align: center;
  // display: flex;
  // align-items: center;
}
.link{
  text-decoration: underline;
  cursor: pointer;
  &.primary{
    color: #0981ff;
  }
  &.danger{
    color: #ff687b;
  }
}
</style>