<template>
  <div class="box">
    <div class="header">
      <div class="title">
        <img src="../../../assets/images/icon_cydd_.png" alt="">
        <h3>差异订单</h3>
        <p>处理、查看回收商发起的差异订单，并完成打款</p>
        <!--        <p class="order_num">共有<span class="lv_fc_red" style="text-decoration: underline;">{{ num }}</span>单超5天未处理-->
        <!--        </p>-->
      </div>
      <div class="search">
        <GlobalForm :inline="true" :needBtnLoading="true" :init-data="initData" config-icon="el-icon-search" :form-item-list="formItemList"
          confirm-btn-name="查询" @handleChange="handleChange" @handleConfirm="handleConfirm">
          <el-form-item class="el_from" :label-width="'100px'" label="申请追差金额" slot="applyAmount" style="margin: 0">
            <el-input style="width: 120px" v-model="startDiffFund" min="0" type="number" clearable />
          </el-form-item>
          <el-form-item class="el_from" :label-width="'0px'" slot="applyAmount"
            style="margin: 0;position: relative;margin-left: 20px;">
            <i style="font-size: 22px;position: absolute;left: -17px;top: 3px">~</i>
            <el-input style="width: 120px" v-model="endDiffFund" type="number" clearable />
          </el-form-item>
          <el-form-item class="el_from" :label-width="'80px'" label="追差类型" slot="catalogId" style="margin: 0">
            <el-select v-model="catalogType" placeholder="请选择" clearable>
              <el-option v-for="item in catalogList" :key="item.id" :label="item.catalogName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="el_from" :label-width="'80px'" label="追差内容" slot="itemId" style="margin: 0">
            <el-select v-model="catalogContent" clearable placeholder="请先选择追差类型" :disabled="ifsureSelect">
              <el-option v-for="item in cataloginfoList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 追差款使用 -->
          <el-form-item label="追差款使用" slot="moneyUseTypeList" style="margin:0" class="query">
            <el-select multiple collapse-tags v-model="moneyUseTypeList" @change="changeSelect" placeholder="请选择"
              filterable clearable>
              <el-option v-for="(item, index) in moneyUseTypeOption" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 暂存人 -->
          <el-form-item v-if="activeIndex === 5" class="el_from" :label-width="'80px'" label="暂存人" slot="staAdminId"
            style="margin: 0">
            <el-select filterable v-model="staAdminId" clearable placeholder="请选择">
              <el-option v-for="item in staAdminList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </GlobalForm>
      </div>
    </div>
    <div class="body" v-loading="tableData.loading">
      <div class="tab_action">
        <TabSelect :tab-data="tabData" :activeIndex="activeIndex" border-radius="4px" @tabAction="tabAction" />
        <div class="taab_left">
          <el-button v-if="activeIndex === 1" type="warning" plain @click.native="viewImportRecords"
            size="mini">线下打款导入记录</el-button>
          <el-button v-if="activeIndex === 1" type="warning" @click.native="importVisible = true"
            size="mini">导入线下打款订单</el-button>
          <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="excelLoading"
          @click="excelClick"
          >导出Excel</el-button
        >
        </div>
      </div>
      <div v-if="tableData.data.length <= 0"
        style="width: 100%; text-align: center; padding: 80px 0px; font-size: 24px; color: rgb(102, 102, 102); background: white; font-weight: bold;">
        暂无订单~
      </div>
      <div class="table" v-for="(item, index) in tableData.data" :key="index">
        <div class="table_header">
          <div class="table_header_left">
            <p class="table_index">{{ item.index }}</p>
            <p>
              订单编号：<span :id="'order-no-' + index">{{ item.orderNo }}</span>
              <img style="width: 18px;height: 18px;cursor: pointer;transform: translate(6px,3px)"
                @click="copy('order-no-' + index); $message.success('复制成功')" src="../../../assets/images/fztb.png" alt="">
            </p>
            <p>申请时间：{{ item.applyTime }}</p>
            <p>成交回收商：{{ item.manualMerchantName }}</p>
            <p v-if="item.orderNoOneline !== '--'">外部订单号：{{ item.orderNoOneline }}</p>
            <p v-if="item.channelName !== '--'" class="channel_name">{{ item.channelName }}</p>
            <p v-if="item.orderType === '01' && item.outType === 'fx'" class="matrix_name">矩阵订单</p>
            <!-- 新增：发起X次 -->
            <p class="apply_times">发起{{ item.orderApplyTimes }}次</p>
            <!-- 新增：是否显示不可再次追差 -->
            <p class="apply_times" v-if="item.isLimitApply">不可再次追差</p>
            <!-- 线下打款/线上打款() -->
            <p v-if="activeIndex === 3" class="apply_times" :class="item.moneyWay === '00' ? 'blue_color' : ''">{{
              item.moneyWay === '00' ? '线下打款' : '线上打款' }}</p>
          </div>
          <div class="table_header_right" v-if="params.state !== 'G10'">
            <p class="day_num" style="color: #FF687B;" v-if="params.state === '04'">差异共处理{{ item.handleDay }}天</p>
            <p class="day_num" style="color: #FF687B;" v-if="item.applyAprDays > 0 && params.state !== '04'">
              已发起差异{{ item.applyAprDays }}天，请尽快处理</p>
          </div>
        </div>
        <div>
          <el-table v-if="tableData.data.length > 0" ref="tableName" size="small"
            :header-cell-style="{ padding: 0, backgroundColor: '#F9FBFD' }" :data="[item]">
            <template v-for="item in tableColumns">
              <el-table-column :prop="item.prop" :key="item.prop + item.state" align="center" :label="item.label"
                :width="item.width" :class-name="item.prop === 'action' ? 'action_style' : 'current_row_style'"
                :fixed="item.prop === 'action' ? 'right' : null" v-if="!item.isHide">
                <template v-slot="{ row }">
                  <!-- 旧机照片 -->
                  <div v-if="item.prop === 'photoList'" class="photo_list">
                    <img :src="row[item.prop][0]" alt="">
                    <p @click="voucherView(row, 'photoList')" class="lv_fc_line">共{{ row[item.prop].length }}张</p>
                  </div>
                  <!-- 串号 -->
                  <div v-else-if="item.prop === 'imei'">
                    <p>{{ row[item.prop] }}</p>
                    <p class="lv_fc_line" @click="getFunction(row.orderNo, row.outType)">功能情况</p>
                  </div>
                  <!-- 信息 -->
                  <div v-else-if="item.prop === 'createStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.createStaffIsMajor">创建人</p>
                    <p v-else>({{ getStoreRole(row.createStaffType) }})</p>
                  </div>
                  <!-- 参与报价回收商 -->
                  <div v-else-if="item.prop === 'quoteMerchants'">
                    <p @click="openStoreList(row)" class="lv_fc_line">{{ row[item.prop] }}</p>
                  </div>
                  <!-- 门店商户 -->
                  <div v-else-if="item.prop === 'createCompanyName'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.orderType === '01'&& row.outType === 'fx'">（{{ row.oriCompanyName || '--' }}）</p>
                  </div>
                  <!--申请人-->
                  <div v-else-if="item.prop === 'applyStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.applyStaffIsMajor">(创建人)</p>
                    <p v-else-if="row.applyStaffType === '01'">(子账号)</p>
                    <p v-else-if="row.applyStaffType === '02'">(报价师)</p>
                    <p v-else>--</p>
                  </div>
                  <!--追差内容-->
                  <div v-else-if="item.prop === 'items'">
                    <div class="lv_ycyc2">{{ row[item.prop].join(",") }}</div>
                  </div>
                  <!-- 申請追差金额 -->
                  <div v-else-if="item.prop === 'arguePrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!-- 初审人 -->
                  <div v-else-if="item.prop === 'auditFirstInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.auditFirstTime">{{ row.auditFirstTime }}</p>
                  </div>
                  <!--初审金额-->
                  <div v-else-if="item.prop === 'auditPrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <div v-else-if="item.prop === 'rePayPrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!--复核人-->
                  <div v-else-if="item.prop === 'auditInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.auditReAdminTime">{{ row.auditReAdminTime }}</p>
                  </div>
                  <!--门店待打款-->
                  <div v-else-if="item.prop === 'mdq'">
                    <p class="lv_fc_red">￥{{ row.rePayPrice }}</p>
                    <p class="lv_fc_red">超时自动打款</p>
                  </div>
                  <!--实际追差金额-->
                  <div v-else-if="item.prop === 'canArguePrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!--追差关闭操作人-->
                  <div v-else-if="item.prop === 'closeInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.rejectMark === '03' || row.rejectMark === '04'">({{ row.closeRoleType }})</p>
                  </div>
                  <!-- 备注 -->
                  <div v-else-if="item.prop === 'noteNum'">
                    <div class="remarkBtn" @click="handleRemarkBtn(row, 'see')">{{ row[item.prop] === '--' ? 0 :
                      row[item.prop]
                    }}</div>
                    <div class="remarkBtn" @click="handleRemarkBtn(row, 'add')">添加备注</div>
                  </div>
                  <!-- 备注 -->
                  <div v-else-if="item.prop === 'isImportUpAccount'">
                    <div :style="!row.isImportUpAccount?'color:#FF687B;':''">{{row.isImportUpAccount === '--'?row.isImportUpAccount:row.isImportUpAccount?'是':'否'}}</div>
                  </div>
                  <!-- subArgueNo -->
                  <div v-else-if="item.prop === 'honorState'">
                    <template v-if="row.outType == 'honor'">
                      <div v-if="row.subArgueNo == '--'">
                        <div>未申请</div>
                        <el-button
                          type="danger"
                          size="mini"
                          @click="$refs['reviewApplyDia'].open(row, 'create')"
                          >发起申请</el-button
                        >
                      </div>
                      <div v-else>
                        <div>
                          {{ optionsFilter(row.honorState, honorStateOptions) }}
                        </div>
                        <el-button
                          type="danger"
                          size="mini"
                          plain
                          @click="$refs['reviewApplyDia'].open(row, 'detail')"
                          >申请详情</el-button
                        >
                      </div>
                    </template>
                    <template v-else-if="row.outType == 'dh'">
                      <div v-if="row.subArgueNo == '--'">
                        <div>未申请</div>
                        <el-button
                          type="danger"
                          size="mini"
                          @click="$refs['danghuanApply'].open(row, 'create')"
                          >发起申请</el-button
                        >
                      </div>
                      <div v-else>
                        <div>
                          {{ optionsFilter(row.dhState, dhStateOptions) }}
                        </div>
                        <el-button
                          type="danger"
                          size="mini"
                          plain
                          @click="$refs['danghuanApply'].open(row, 'detail')"
                          >申请详情</el-button
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div v-if="row.isDisplayOutApr">
                        <div v-if="row.subArgueNo == '--'">
                          <div>未申请</div>
                          <el-button
                            type="danger"
                            size="mini"
                            @click="
                              $refs['externalApplication'].open(row, 'create')
                            "
                            >发起申请</el-button
                          >
                        </div>
                        <div v-else>
                          <div>
                            {{
                              optionsFilter(row.honorState, honorStateOptions)
                            }}
                          </div>
                          <el-button
                            type="danger"
                            size="mini"
                            plain
                            @click="
                              $refs['externalApplication'].open(row, 'detail')
                            "
                            >申请详情</el-button
                          >
                        </div>
                      </div>
                      <div v-else>--</div>
                    </template>
                  </div>
                  <div v-else-if="item.prop === 'arguePriceHonor'">
                    <el-link v-if="row.arguePriceHonor !== '--' || row.amount !== '--'" type="danger" :underline="false">{{row.arguePriceHonor}}/{{row.amount}}</el-link>
                    <div v-else>--</div>
                  </div>
                  <!-- 操作 -->
                  <div v-else-if="item.prop === 'action'" class="action_box" :key="item.state">
                    <!--                    <p class="lv_fc_line" v-if="params.state === '02'">追差进度</p>-->
                    <p class="lv_fc_line" v-if="params.state === '02' || params.state === '04' || params.state === 'G10'"
                      @click="openAction(`/difference/process?argueNo=${row.argueNo}`)">查看详情</p>

                    <p class="lv_fc_line" style="color: #FF0000" v-if="params.state === '01' || params.state === '02'"
                      @click="rejectClick(row)">驳回差异</p>

                    <p class="lv_fc_line" v-if="params.state === '01'"
                      @click="openAction(`/difference/process?argueNo=${row.argueNo}`)">复核打款</p>

                    <p class="lv_fc_line" v-if="params.state === '00' || params.staState === '01'" style="color: #FF9204"
                      @click="openAction(`/difference/process?argueNo=${row.argueNo}`)">审核差异</p>
                  </div>
                  <div v-else>
                    {{ row[item.prop] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <Pagination :meta="meta"
        @sizeChange="(v) => { meta = { ...meta, pageSize: v }; params = { ...params, pageSize: v } }"
        @pageChange="(v) => { meta = { ...meta, pageNum: v }; params = { ...params, pageNum: v } }" />
    </div>
    <PhotoPreview :photoVisible="photoDialogProp.show" :photoArr="photoDialogProp.photoArr" title="旧机照片"
      @close="() => photoDialogProp.show = false" />
    <!-- 参数报价回收商（复制代码） -->
    <el-dialog :close-on-click-modal="false" :width="`80%`" title="查看参与报价回收商" :visible="recyclerData.show"
      @close="recyclerData.show = false" top="15%">
      <div class="img-box">
        <p class="lv_fc_red" style="margin-bottom: 10px;">
          说明：若该门店商户预留差异基金，则门店所看到的报价金额需加上差异基金才是商户的初始报价</p>
        <div class="table_list">
          <el-table :data="recyclerData.storeList" border style="width: 100%" id="excelOrder" :cell-style="rowStyle"
            @cell-click="Bargain">
            <el-table-column prop="index" type="index" label="序号"></el-table-column>
            <el-table-column prop="recycleMerchantName" label="报价商家" align="center" width="200">
              <template scope="{ row }">
                <!-- <span
                  class="position isdeal"
                  v-if="row.recycleMerchantName == merchantName"
                  >成交</span
                > -->
                <el-tag v-if="row.recycleMerchantName === recyclerData.merchantName" size="mini" type="danger"
                  effect="dark" style="margin-right: 10px">
                  成交
                </el-tag>
                <span>{{ row.recycleMerchantName }}</span>
                <div>
                  <div style="background:rgb(245, 245, 255);border-radius: 4px;padding: 0px 8px;margin-top: 4px;display:inline-block" v-if="row.virtualName && row.virtualName !=='--'&&baoPricestate=='00'">{{ row.virtualName }}</div>
                </div>
                <div>
                  <el-tag v-if="row.isFixHighQuote &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixHighQuote != '--'
                    " size="mini" type="warning" effect="dark" style="margin-right: 10px">
                    首次取价
                  </el-tag>
                  <el-tag v-if="row.isFixExpPrice &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixExpPrice != '--'
                    " size="mini" type="warning" effect="dark">
                    议价取价
                  </el-tag>
                  <el-tag v-if="row.isAddPrice && row.isAddPrice != '--'
                    " size="mini" type="success" effect="dark">
                    平台加价
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="quotePrice" width="100" label="报价金额" align="center"></el-table-column>
            <el-table-column prop="exclusiveTime" width="100" label="抢单时间" align="center"></el-table-column>
            <el-table-column prop="quoteTime" width="180" label="最新报价时间" align="center"></el-table-column>
            <el-table-column prop="staffName" label="报价人" align="center"></el-table-column>
            <el-table-column prop="staffMobile" label="账号" align="center"></el-table-column>
            <el-table-column prop="staffType" label="角色" align="center"></el-table-column>
            <el-table-column prop="quoteNum" width="100" label="报价次数" align="center"></el-table-column>
            <el-table-column prop="state" width="100" label="报价状态" align="center">
              <template scope="{ row }">
                <span>{{
                  row.state == "00-0"
                  ? "待报价"
                  : row.state == "00-1"
                    ? "报价中"
                    : row.state == "00-2"
                      ? "已报价"
                      : row.state == "01"
                        ? "已确认"
                        : row.state == "10" && row.exclusiveCancelTime != "--"
                          ? `取消抢单${row.exclusiveCancelTime}`
                          : "交易取消"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isNiceDevice" width="100" label="是否标记靓机" align="center">
              <template scope="{ row }">
                <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteNote" width="100" label="报价备注" align="center">
              <template scope="{ row }">
                <el-tooltip v-if="row.quoteNote != '--'" class="item" effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote" placement="bottom">
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <!-- 新增 -->
            <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
            <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看报价记录" :visible="viewrecords"
      @close="viewrecordsClose" top="15%" bottom="10%">
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table :data="getUpdatedQuoteLog" max-height="300" border style="width: 100%" id="updatedOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="160" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
        </el-table>
        <div style="color: #0981ff; font-size: 16px; margin: 20px 0" v-if="remakeQuoteLog && remakeQuoteLog.length">
          补拍后报价记录
        </div>
        <el-table v-if="remakeQuoteLog && remakeQuoteLog.length" :data="remakeQuoteLog" max-height="300" border
          style="width: 100%" id="remakeOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="remakeTime" width="100" label="补拍时间" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="100" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 旧机功能情况 -->
    <el-dialog v-bind="imeiTestProps" :before-close="() => imeiTestProps.show = false" :visible.sync="imeiTestProps.show">
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :externalorderType="externalorderType" :datainfo="detailData" />
        </div>
        <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
            <div class="test-result" v-if="detailData.functionView.errorList.length">
              <Title :title="'异常项（共'+ detailData.functionView.errorList.length +'项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="detailData.unableDetectionCause &&
                detailData.detectionType == '01'
                ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="detailData.detectionType == '02'">
                <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList"></TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
              <Title :title="'正常项（共'+ detailData.functionView.normalList.length +'项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList"></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0;">
          <Title title="店员备注" />
          <Remark :remark="detailData.remark" :againPhotoRemark="detailData.againPhotoRemark" />
        </div>
      </div>
      <div slot="footer">
        <el-button v-if="imeiTestProps.type === '01'" @click="imeiTestProps.show = false" plain type="primary">关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 驳回差异 -->
    <DialogAction v-bind="rejectProp" @close="rejectProp.show = false; rejectProp.isReset = false" @config="rejectConfig">
      <template v-slot:from>
        <div class="rejetct_box">
          <div class="reject_duty">
            <div style="margin-right: 10px;">审批判责：</div>
            <el-radio disabled>回收商责任（驳回追差）</el-radio>
          </div>
          <div class="reject_basis">
            <JudgementsList :modal="false" :source-data="rejectProp.judgements" :is-reset="rejectProp.isReset" state="00"
              @dataChange="(v) => rejectChange(v)" />
          </div>
        </div>
      </template>
    </DialogAction>
    <!-- 添加备注弹框 -->
    <el-dialog class="acc_dialog" title="添加备注" :visible.sync="addRemarkShow" :close-on-click-modal="false" width="500px" @closed="Remarks_log_cl">
      <el-form ref="remarkFormRef" :model="remarkForm" :rules="remarkRules" label-position="top">
        <el-form-item label="上传图片（非必填，最多上传3张）">
          <PasteUploadDia @confirm="(v) => remarkForm.images.push(v)">
            <template slot-scope="scope">
              <div class="paste-img-box">
                <div v-for="(item, index) in remarkForm.images" :key="item" class="img-item">
                  <i class="el-icon-circle-close" @click="remarkForm.images.splice(index, 1)" />
                  <el-image class="img" :src="item" :preview-src-list="[item]" />
                </div>
                <div class="upload_img" v-if="remarkForm.images.length < 3" @click="scope.open()">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </template>
          </PasteUploadDia>
        </el-form-item>
        <el-form-item label="备注内容" prop="note">
          <el-input v-model="remarkForm.note" maxlength="500" type="textarea" :rows="4" placeholder="请输入备注内容,最多500字" />
        </el-form-item>
      </el-form>
      <!-- <div style="margin-bottom: 20px">上传图片（非必填，最多上传3张）</div> -->
      <!-- <div style="margin-bottom: 20px">备注内容</div> -->
      

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Remarks_log_cl">取消</el-button>
        <el-button type="primary" @click.native="Remarks_log_sure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 备注记录弹框 -->
    <el-dialog :close-on-click-modal="false" :width="`60%`" title="备注记录" :visible="remarkRecords"
      @close="remarkRecords = false" top="15%">
      <el-table :data="DialogDataList" border style="width: 100%" id="excelOrder">
        <el-table-column prop="index" type="index" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="备注时间" align="center"></el-table-column>
        <el-table-column prop="adminName" label="备注人" align="center">
          <template scope="{ row }">
            <div>{{ row.adminName }} - {{ row.adminAcc }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="images" label="上传图片" align="center">
          <template scope="{ row }">
            <div v-if="row['images'].length" class="photo_list">
              <img :src="row['images'][0]" alt="">
              <p @click="voucherView(row, 'images')" class="lv_fc_line">共{{ row['images'].length }}张</p>
            </div>
            <div v-else>--</div>
            <PasteUploadDia @confirm="(v) => addNoteImg(v, row)">
              <template slot-scope="scope">
                <el-link type="warning" @click="scope.open()">补充图片</el-link>
              </template>
            </PasteUploadDia>
          </template>
        </el-table-column>
        <el-table-column prop="note" label="备注内容" align="center"></el-table-column>
        <el-table-column prop="noteType" width="100" label="备注环节" align="center">
          <template scope="{ row }">
            <div>{{ row.noteType === '00' ? '初审时' : row.noteType === '01' ? '复核时' : '/' }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 30px;"></div>
    </el-dialog>
    <!-- 导入线下打款订单弹框 -->
    <el-dialog title="导入线下打款差异单" :visible.sync="importVisible" :close-on-click-modal="false"
      class="import-business-dialog" width="540px" @close="exportinDialogClosed">
      <div class="cance_tip">
        <div>
          说明：仅支持导入在待复核打款中的差异订单，订单号必须与待复核打款的订单对应得上，确认线下打款后，则系统自动完结订单并标记线下打款。
        </div>
      </div>
      <el-button type="success" icon="el-icon-download" size="small" @click="download">下载数据模板</el-button>
      <!-- 上传 -->
      <el-form ref="importForm" :model="importForm" :rules="importRules" label-width="80px" style="margin-top: 20px;">
        <el-form-item label="表格上传" prop="fileName" class="fileName">
          <el-input v-model="importForm.fileName" @clear="fileClear" :disabled="true" size="medium" clearable>
          </el-input>
          <div style="float: right; position: relative">
            <el-button size="medium" type="warning" icon="el-icon-upload2" :loading="importBtnLoading">上传表格</el-button>
            <input accept=".xls, .xlsx" ref="fileUpload" @change="onUploadList" type="file" style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                " />
          </div>
          <div style="color: #ff687b">
            仅支持.xls及.xlsx格式,单次上传大小不超过5M
          </div>
        </el-form-item>
      </el-form>
      <div class="inquery" v-if="isImported">共计查询到 <span style="color:#0981FF;">{{ importList.length }}单</span> 待复核打款</div>
      <div v-if="isImported" style="display: flex;align-items: center;margin-top: 10px;">
        <span style="width: 100px">导入备注：</span>
        <el-input
          placeholder="请输入导入备注，如 7.18-7.24小米订单追差款上账"
          v-model.trim="aprReason"
          maxlength="100"
          clearable>
        </el-input>
      </div>
      <span slot="footer">
        <el-button @click.native="exportinDialogClosed">取消</el-button>
        <el-button type="primary" :loading="importLoading" @click.native="importSuccess">确定线下打款</el-button>
      </span>
    </el-dialog>
    <DanghuanApply ref="danghuanApply"  @confirm="getData()"  />
    <ReviewApplyDia ref="reviewApplyDia" @confirm="getData()" />
    <ExternalApplication ref="externalApplication" @confirm="getData()" />
  </div>
</template>
<script>

import {
  formItemList,
  photoDialogProp,
  platformTableColumns, rejectMarkOption, rejectProp,
  tabData,
  tableData
} from "@/views/difference/platformReview/initState";
import _api from "@/utils/request";
import moment from "moment";
import {
  copy,
  getHaoTime,
  getIndex,
  getMeta, getRecoveryType,
  getRecycleState,
  getStoreRole, getStoreRoleTwo,
  guideExcelAction,
  infoConcat, openAction,
  pageSize,
  setValue
} from "@/utils/common";
import PhotoPreview from "@/components/common/PhotoPreview.vue";
import TabSelect from "@/components/common/TabSelect.vue";
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import Pagination from "@/components/common/Pagination.vue";
import { mapState } from "vuex";
// ***********
import DialogAction from "@/components/common/DialogAction.vue";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "@/views/RecallOrder/componets/Title.vue"
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import JudgementsList from "@/views/difference/componets/JudgementsList.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import { encryptByDES } from "@/utils/3DES";
import ExternalApplication from "../componets/ExternalApplication.vue";
import PasteUploadDia from '../componets/PasteUploadDia.vue';
import ReviewApplyDia from '../componets/ReviewApplyDia.vue';
import DanghuanApply from "../componets/DanghuanApply.vue";
export default {
  components: {
    JudgementsList,
    DialogAction,
    Pagination,
    GlobalForm,
    TabSelect,
    PhotoPreview,
    TestTable,
    TestDeviceInfo,
    Title,
    Remark,
    PasteUploadDia,
    ReviewApplyDia,
    DanghuanApply,
    ExternalApplication
  },
  computed: {
    adminButton() {
      return this.$store.state.tagsView.adminButton
    },
  },

  data() {
    const startTime = moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD");
    const endTime = moment().endOf('day').format("YYYY-MM-DD");
    const time = [startTime, endTime];
    const { orderNo } = this.$route.query; //订单详情跳转过来携带订单号,//差异详情跳转过来携带state
    console.log(tabData);
    return {
      baoPricestate:"",//订单状态
      moneyUseTypeOption: [//追差款使用
        {
          label: '仅使用差异基金',
          value: '00'
        },
        {
          label: '仅门店打款',
          value: "01"
        },
        {
          label: '差异基金+门店打款',
          value: "02"
        }
      ],
      honorStateOptions: [ // 荣耀推送状态
        { label: '待审核', value: 0 },
        { label: '审核通过', value: 1 },
        { label: '售后完成', value: 2 },
        { label: '售后驳回', value: -2 },
        { label: '作废', value: -1 },
      ],
     dhStateOptions: [ // 当换推送状态
        { label: '待审核', value: 0 },
        { label: '预通过', value: 1 },
        { label: '售后完成', value: 2 },
        { label: '售后驳回', value: -2 },
        { label: '作废', value: -1 },
      ],
      moneyUseTypeList: [],
      // ***************************
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      // 导入线下打款订单
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },
      paramsExcel: {},
      importList: [],
      aprReason: "",
      isImported:false,//控制是否上传了数据
      importVisible: false,
      // 备注************
      addRemarkShow: false,
      remarkForm: {
        note: '',
        images: []
      },
      remarkRules: {
        note: [{ required: true, message: '请输入备注内容', trigger: 'change' }]
      },
      
      RemarksTxt: '',//备注内容
      RemarksImages: [],//备注内容
      // 备注记录
      argueNo: '',
      remarkRecords: false,
      DialogTableColumns: [
        { label: "备注时间", prop: "staffPhone" },
        { slotName: "staffName" },//备注人
        { label: "备注内容", prop: "staffPhone" },
        { slotName: "staffName" },//备注环节
      ],
      DialogDataList: [],//列表数据
      reTotal: 0,
      rePageNum: 1,
      rePageSize: 10,
      // ****************
      externalorderType: '',//订单类型
      tabActive: '00',//tab
      staAdminList: [],//暂存人列表
      staAdminId: window.localStorage.getItem('userName'),//暂存人绑定
      startDiffFund: "",//	开始申请追差金额
      endDiffFund: "",//	结束申请追差金额
      catalogType: "",
      catalogContent: "",
      catalogList: [],//追差类型
      cataloginfoList: [],//追差内容
      ifsureSelect: true,//是否选中追差类型
      // 功能情况接口
      detailData: {
        functionView:{
          normalList:[],
          errorList:[],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: []
      },
      activeIndex: 0,//tab初始选择索引
      imeiTestProps,//功能情况
      tabData,
      tableData,
      // 嵌套过深，element无法检测到
      tableColumns: JSON.parse(JSON.stringify(platformTableColumns)),
      initData: {
        applyTime: time,//申请时间默认62天
        // auditTime: time,
        // closeTime: time,
        orderType: null,
        outOrder: null,
        orderNo,
      },
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      // 搜索参
      params: {
        pageSize,
        pageNum: 1,
        state: '00',
        staState: '00', //00：无暂存，01：暂存
        orderNo,
        applyTime: time,//申请时间默认62天
        // auditTime: time,
        // closeTime: time,
      },
      // 搜索列表
      formItemList,
      // 旧机照片弹窗
      photoDialogProp,
      recyclerData: {
        show: false,
        storeList: [],
        merchantName: ''
      },
      // 驳回差异
      rejectProp,
      //差异单数超5天
      // num: 0,
      excelLoading: false,
      viewrecords: false,
      getUpdatedQuoteLog: [],
      remakeQuoteLog: [],
      isExecl:false,
      importBtnLoading: false,
      importLoading: false
    }
  },
  created() {
    // 下拉
    this.getSelectList();
    this.getCatalogList()
    // 列表
    //差异详情跳转过来携带state
    const { activeIndex } = this.$route.query;
    if (activeIndex) {
      this.activeIndex = 5
    } else {
      this.activeIndex = 0
      this.getData('00');
    }
    // this.getDataNum();
  },
  watch: {
    params(v) {
      this.getData();
    },
    catalogType(data) {
      console.log(data);
      if (data) {
        this.ifsureSelect = false
        this.getcatalogInfo(data)
      } else {
        this.ifsureSelect = true
      }
      this.catalogContent = ''
      this.cataloginfoList = []
    },
    startDiffFund: {
      handler(onl, newew) {
        console.log(onl);
        this.startDiffFund = onl.replace(/^(0+)|[^\d]+/g, '')
      }
    },
    endDiffFund: {
      handler(onl, newew) {
        console.log(onl);
        this.endDiffFund = onl.replace(/^(0+)|[^\d]+/g, '')
      }
    }
  },
  methods: {
    changeSelect(val) {
      console.log(val);
      if (!val || !val.length) {
        this.moneyUseTypeList = []
        return
      }
      this.moneyUseTypeList = val
      console.log(this.moneyUseTypeList, 'val333333333333');
    },
    // ************
    // 导入线下打款订单
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入待复核打款订单数据模板.xlsx";
      link.setAttribute("download", "导入待复核打款订单数据模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 上传文件
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
      this.uploadFile()
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      // fd.append("supplierId", this.supplierId); //出库商家id
      // fd.append("warehouseId", this.warehouseId); //仓库id
      console.log(fd);
      this.repeatData = []
      // 发起请求
      this.importBtnLoading = true
      _api
        .aprImport(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          this.importList = res.data
          this.isImported=true
          console.log(this.importList, '4444444444444444444');
          if (res.code === 1) {
            // this.importForm.fileName = "";
            // this.$refs.fileUpload.value = "";
            let erraction = JSON.parse(JSON.stringify(res))
            let erractioncopy = JSON.parse(JSON.stringify(res))
            //if(res.data && res.data.length){
            //  this.seachDataList.unshift(...res.data)
            //  this.$message({
            //    type: "success",
            //    message: '解析成功！',
            //  });
            //}
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
          this.importBtnLoading = false
        })
        .catch((err) => {
          this.importBtnLoading = false
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
        });
    },
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    importSuccess() {
      if(!this.$refs.fileUpload.value){
        return this.$message.error('请先上传导入文件')
      }
      if(!this.importList.length){
        return this.$message.error('导入数据不能为空')
      }
      if(!this.aprReason){
        return this.$message.error('导入备注不能为空')
      }
      this.importLoading = true
      _api.importSave({ list: this.importList,aprReason: this.aprReason }).then(res => {
        if (res.code === 1) {
          this.importVisible = false
          this.importList = []
          this.isImported=false
            this.$message({
              type: "success",
              message: res.msg || '操作成功',
            });
        }
      }).finally(() => {
        this.importLoading = false
      });
    },
    exportinDialogClosed() {
      this.importVisible = false
      this.importList = []
      this.isImported=false
      this.aprReason = ""
      this.importForm.fileName = "";
      this.$refs.fileUpload.value = "";
     },
    // 备注*************
    handleRemarkBtn(row, type) {
      // console.log(row, 'row33333333333');
      // 添加备注
      this.argueNo = row.argueNo
      if (type === 'add') {
        this.remarkForm = {
          note: '',
          images: []
        }
        this.$refs['remarkFormRef']?.resetFields()
        this.$nextTick(() => {
          this.addRemarkShow = true
        })
      } else {
        this.remarkRecords = true
        this.handleCurrentChangeDialog()
      }
    },
    // 添加备注--取消
    Remarks_log_cl(row) {
      this.addRemarkShow = false;
      this.RemarksTxt = ''
      this.RemarksImages = []
    },
    // 添加备注--确定
    Remarks_log_sure(row) {
      this.$refs['remarkFormRef'].validate((vaild) => {
        if (vaild) {
          _api
            .aprAddNote({
              ...this.remarkForm,
              argueNo: this.argueNo,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.addRemarkShow = false;
              this.getData()
            });
        }
      })
    },
    // 备注明细
    handleCurrentChangeDialog() {
      _api.noteRecord({ argueNo: this.argueNo }).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data;
          this.reTotal = res.data.total;
        }
      });
    },
    addNoteImg(image, item) {
      _api.aprAddNoteImage({
        argueNo: item.argueNo,
        images: [image],
        noteId: item.id
      }).then(res => {
        this.$message.success('添加成功')
        this.handleCurrentChangeDialog()
      })
    },
    onClose() {
      this.remarkRecords = false
    },
    // *********************
    copy,
    openAction,
    // 追差分类列表
    getCatalogList() {
      _api.catalogList().then((res) => {
        this.catalogList = res.data || [];
      });
    },
    // 追差分类详情
    getcatalogInfo(id) {
      _api.catalogInfo({ id }).then((res) => {
        this.cataloginfoList = res.data.itemList || [];
      });
    },
    // 差异单数超5天
    getDataNum() {
      _api.sta().then(r => {
        this.num = r.data.unHandleNum;
      })
    },
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } = this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if ((isSystem || unableDetectionCause) && functionList.length && (!v.isOneline || (v.isOneline && v.outType === 'fx'))) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType === 'fx')) {
        return this.imeiTestProps.type = '02';
      }
    },

    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let { isFinenessShow, functionList, systemTestList, photoArr, allSystemTestList } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness) ? fineness.fineness : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === 'lb' || v.outType === 'lb2') {
        functionList = functionList.filter(v => v.faultName !== '是否正常开机');
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map(item => {
          if (v.outType === 'lb' || v.outType === 'lb2') {
            item.value = item.value ?? [];
            item.value = item.value.map(v => {
              return {
                valueText: v.isNormal ? '正常' : '异常',
                isNormal: v.isNormal
              }
            })
          }
          return {
            name: item.label,
            value: item.value
          }
        })
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map(v => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause
        }
      });
      // 检测类型动态更改值的key
      const systemKeys = ['mobileNetworks', 'wlan', 'bluetooth'];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map(item => {
          item.value = v.detectionInfo[item.key] ?? '--';
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === '01') {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ")
          }
          return item
        });
      }


      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront)
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear)
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then(res => {
        this.detailData = res.data;
        if(res.data.againPhotoRemark){
          this.detailData.againPhotoRemark=res.data.againPhotoRemark.split("//")
        }else{
          this.detailData.againPhotoRemark=[]
        }
        this.imeiTestProps.isLoadding = false
      })
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: '',
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "",//下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      }
      // 请求详情
      this.getDetail(v);
    },
    getStoreRole,
    //设置时间戳
    setParamsTIme(params, key, startKey, endKey) {
      return {
        ...params,
        [startKey]: getHaoTime(params[key][0], 'start'),
        [endKey]: getHaoTime(params[key][1], 'end'),
        [key]: null,
      };
    },
    // time时间是否重置
    resetTime(params, key, startKey, endKey) {
      if (Array.isArray(params[key])) {
        params = this.setParamsTIme(params, key, startKey, endKey);
      } else {
        params[key] = null;
        params[startKey] = null;
        params[endKey] = null;
      }
      return params;
    },
    // 各种状态的数量设置
    setStateNum(data) {
      this.tabData = this.tabData.map(v => {
        if (data[v.key] >= 1000) {
          v.num = (data[v.key] / 1000) + 'k'
        } else {
          v.num = data[v.key] || 0;
        }
        return v;
      })
    },
    getData(state) {
      this.tableData.loading = true;
      this.tableData.data = [];
      this.params.moneyUseTypeList = this.moneyUseTypeList;
      let params = this.params;
      params = this.resetTime(params, 'applyTime', 'applyStartTime', 'applyEndTime');
      // 新加成交时间
      params = this.resetTime(params, 'ackTime', 'ackStartTime', 'ackEndTime');
      // 新加初审时间
      params = this.resetTime(params, 'auditFirstTime', 'auditFirstStartTime', 'auditFirstEndTime');
      params = this.resetTime(params, 'auditTime', 'auditReAdminTimeStartTime', 'auditReAdminTimeEndTime');
      params = this.resetTime(params, 'closeTime', 'closeStartTime', 'closeEndTime');
      // this.params.staAdminId = this.staAdminId
      if (state) {
        params.state = state;
      }
      console.log(this.activeIndex, ' this.activeIndex111');
      let myparams = {}
      if (this.activeIndex === 5) {//暂存订单
        myparams = {
          ...params,
          staAdminId: this.staAdminId === window.localStorage.getItem('userName') ? Number(window.localStorage.getItem('userId')) : this.staAdminId
        }
      } else {
        myparams = {
          ...params,
          staAdminId: ''
        }
      }
      _api.aprList(myparams).then(r => {
        this.tableData.loading = false;
        const data = r.data ?? {};
        const page = data.page ?? {};
        this.setStateNum(data);
        const records = Array.isArray(page.records) ? page.records : [];
        // 分页
        this.meta = getMeta(page.total, params.pageNum, params.pageSize);
        this.tableData.data = records.map((v, index) => {
          // 下单人
          v.createStaffInfo = infoConcat(v.createStaffName, v.createStaffPhone);
          // 申请人
          v.applyStaffInfo = infoConcat(v.applyStaffName, v.applyStaffMobile);
          // 初审人
          v.auditFirstInfo = infoConcat(v.auditFirstAdminName, v.auditFirstAdminAcc);
          //复核人
          v.auditInfo = infoConcat(v.auditReAdminName, v.auditReAdminAcc);
          // 撤回人
          v.cancelStaffInfo = infoConcat(v.cancelStaffName, v.cancelStaffPhone);

          //追差关闭的几种情况
          // 平台审核
          if (['00', '01', '02'].indexOf(v.rejectMark) >= 0) {
            //驳回
            v.closeInfo = infoConcat(v.rejectAdminName, v.rejectAdminAcc);
            v.closeActionTime = v.rejectTime;
          } else if (v.rejectMark === '03') {
            // 门店驳回
            v.closeInfo = infoConcat(v.rejectStaffName, v.rejectStaffMobile)
            v.closeActionTime = v.rejectTime;
            // 是否创建人
            if (v.rejectStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              // 子账号、店长、店员等角色名获取
              v.closeRoleType = this.getStoreRole(v.rejectStaffType);
            }
          } else if (v.rejectMark === '04') {
            // 撤回
            v.closeInfo = infoConcat(v.cancelStaffName || v.cancelPcStaffName, v.cancelStaffPhone || v.cancelPcStaffPhone);
            v.closeActionTime = v.cancelTime;
            v.closeRoleType = "";
            if (v.cancelStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              v.closeRoleType = getRecoveryType(v.cancelStaffType);
            }
          } else {
            v.closeInfo = "--";
            v.closeActionTime = "--";
          }
          const rejectMarkIndex = rejectMarkOption.findIndex(item => item.value === v.rejectMark);
          if (rejectMarkIndex >= 0) {
            v.rejectMarkText = rejectMarkOption[rejectMarkIndex].label;
          } else {
            v.rejectMarkText = '--'
          }
          v = setValue(v);
          const currentIndex = getIndex(params.pageNum, params.pageSize, index);
          let photoList=[]
          if(v.images!='--'){
            photoList=v.images.map(v => v.img)
          }
          return {
            ...v,
            index: currentIndex >= 10 ? currentIndex : '0' + currentIndex,
            photoList:photoList,
            orderState: getRecycleState(v.orderState),
          };
        });
      }).finally(() => {
        if (typeof this.params.disuseLoding == "function") {
            this.params.disuseLoding();
          }
      })
    },
    getSelectList() {
      const data = {
        pageNum: 1,
        pageSize: 9999
      }
      // 回收商rq
      _api.listSimpleMerchantView(data).then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'merchantId');
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.merchantName,
            value: v.merchantId
          }
        })
      });
      // 询价商
      _api.getSelectList().then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'companyId');
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.name,
            value: v.id
          }
        })
        // 矩阵原商家
        const index2 = this.formItemList.findIndex(v => v.key === 'oriCompanyId');
        const data2 = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index2].option = data2.map(v => {
          return {
            label: v.name,
            value: v.id
          }
        })
      });
      // 撮合渠道下拉
      _api.getMatchChannelSelect().then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'channelId');
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map(v => {
          return {
            value: v.id,
            label: v.name
          }
        })
      });
      // 人下拉
      _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then(r => {
        const auditFirstAdminIndex = this.formItemList.findIndex(v => v.key === 'auditFirstAdminId');
        const auditReAdminIndex = this.formItemList.findIndex(v => v.key === 'auditReAdminId');
        const data = Array.isArray(r.data.records) ? r.data.records : [];
        const result = data.map(v => {
          return {
            value: v.id,
            label: v.userName
          }
        });
        this.formItemList[auditFirstAdminIndex].option = result;
        this.formItemList[auditReAdminIndex].option = result;
        this.staAdminList = result
      })
    },
    // 门店下拉
    getShopList(companyId) {
      _api.getStoreSelectList({ companyId }).then(r => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(v => v.key === 'storeId');
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.value,
            value: v.key
          }
        })
      })
    },
    // 搜索变化
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(v => v.key === v1.childrenKey);
        if (v2) {
          this.formItemList[index].disabled = false;
          this.getShopList(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
    },
    // 搜索确定
    handleConfirm(v,cd) {
      console.log(this.staAdminList, '666666666666');
      console.log(v, '搜索参数1111');
      if (Number(this.startDiffFund) < 0) {
        this.startDiffFund = 0;
        return this.$message.error("开始申请追差金额不能小于0");
      }
      if (Number(this.endDiffFund) < 0) {
        return this.$message.error("结束申请追差金额不能小于0");
      }
      if (Number(this.startDiffFund) > Number(this.endDiffFund)) {
        return this.$message.error('申请追差金额范围不正确');
      }
      this.params.startDiffFund = this.startDiffFund
      this.params.endDiffFund = this.endDiffFund
      this.params.catalogId = this.catalogType
      this.params.itemId = this.catalogContent
      this.params.staAdminId = this.staAdminId
      this.params = {
        ...this.params,
        ...v,
        pageNum: 1,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      }
    },
    // 根据状态设置列/和查询条件项
    setStateColumns(v) {
      this.tabActive = v
      const tableColumns = JSON.parse(JSON.stringify(platformTableColumns));
      // 平台待审核
      if (v === '00') {
        this.activeIndex = 0
        this.staAdminId = ''
        return this.tableColumns = tableColumns;
      }
      // 复核打款
      if (v === '01') {
        this.activeIndex = 1
        this.staAdminId = ''
        // 初核信息
        const infoIndex = tableColumns.findIndex(v => v.prop === 'auditFirstInfo');
        // 初审金额
        const auditPriceIndex = tableColumns.findIndex(v => v.prop === 'auditPrice');
        // 外部追差状态
        const honorStateIndex = tableColumns.findIndex(v => v.prop === 'honorState');
        // 外部申请/通过金额
        const arguePriceHonorIndex = tableColumns.findIndex(v => v.prop === 'arguePriceHonor');
        tableColumns[infoIndex].isHide = false;
        tableColumns[auditPriceIndex].isHide = false;
        tableColumns[honorStateIndex].isHide = false;
        tableColumns[arguePriceHonorIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
      // 门店打款
      if (v === '02') {
        this.activeIndex = 2
        this.staAdminId = ''
        // 复核信息
        const infoIndex = tableColumns.findIndex(v => v.prop === 'auditInfo');
        // 复核金额
        const rePayPriceIndex = tableColumns.findIndex(v => v.prop === 'rePayPrice');
        // 门店打款
        const mdqIndex = tableColumns.findIndex(v => v.prop === 'mdq');
        tableColumns[infoIndex].isHide = false;
        tableColumns[rePayPriceIndex].isHide = false;
        tableColumns[mdqIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }

      // 追差完成
      if (v === '04') {
        this.activeIndex = 3
        this.staAdminId = ''
        // 初审人
        const auditFirstInfoIndex = tableColumns.findIndex(v => v.prop === 'auditFirstInfo');
        const canArguePriceIndex = tableColumns.findIndex(v => v.prop === 'canArguePrice');
        // 外部追差状态
        const honorStateIndex = tableColumns.findIndex(v => v.prop === 'honorState');
        // 外部申请/通过金额
        const arguePriceHonorIndex = tableColumns.findIndex(v => v.prop === 'arguePriceHonor');
        const isImportUpAccountIndex = tableColumns.findIndex(v => v.prop === 'isImportUpAccount');
        tableColumns[auditFirstInfoIndex].isHide = false;
        tableColumns[canArguePriceIndex].isHide = false;
        tableColumns[honorStateIndex].isHide = false;
        tableColumns[arguePriceHonorIndex].isHide = false;
        tableColumns[isImportUpAccountIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }

      // 追差关闭
      if (v === 'G10') {
        this.activeIndex = 4
        // 关闭原因
        this.staAdminId = ''
        const rejectCauseIndex = tableColumns.findIndex(v => v.prop === 'rejectMarkText');
        const closeInfoIndex = tableColumns.findIndex(v => v.prop === 'closeInfo');
        const closeTimeIndex = tableColumns.findIndex(v => v.prop === 'closeActionTime');
        // 外部追差状态
        const honorStateIndex = tableColumns.findIndex(v => v.prop === 'honorState');
        // 外部申请/通过金额
        const arguePriceHonorIndex = tableColumns.findIndex(v => v.prop === 'arguePriceHonor');
        tableColumns[rejectCauseIndex].isHide = false;
        tableColumns[closeInfoIndex].isHide = false;
        tableColumns[closeTimeIndex].isHide = false;
        tableColumns[honorStateIndex].isHide = false;
        tableColumns[arguePriceHonorIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
      // 暂存订单
      if (v === '') {
        this.activeIndex = 5
        this.staAdminId = window.localStorage.getItem('userName'),//暂存人：默认输入登录账号本人
          this.getSelectList() //请求下拉列表
        const temporaryPersonIndex = tableColumns.findIndex(v => v.prop === 'staAdminName');
        const temporaryTimeIndex = tableColumns.findIndex(v => v.prop === 'staTime');
        const temporaryReasonIndex = tableColumns.findIndex(v => v.prop === 'staCause');
        tableColumns[temporaryPersonIndex].isHide = false;
        tableColumns[temporaryTimeIndex].isHide = false;
        tableColumns[temporaryReasonIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
    },
    // 状态操作
    tabAction(v) {
      console.log(this.tabData[v]);
      const state = this.tabData[v].value;
      // 新增字段staState
      const staState = this.tabData[v].staState;
      this.params = {
        ...this.params,
        pageNum: 1,
        state,
        staState
      }
      this.setStateColumns(state);
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.RemarksImages = url
    },
    // 照片预览
    voucherView(v, key) {
      this.photoDialogProp = {
        ...this.photoDialogProp,
        show: true,
        photoArr: v[key]
      }
    },
    //驳回差异
    rejectClick(v) {
      // 不存在驳回权限
      const pathIndex = this.adminButton.findIndex(v => v.pageUrl === '/difference/process')
      const { buttonList } = this.adminButton[pathIndex];
      const i = buttonList.findIndex(v => v.buttonCode === 'Apr_Reject');
      if (i < 0) {
        return this.$message.error("当前无驳回操作权限")
      }
      this.rejectProp = {
        ...this.rejectProp,
        show: true,
        judgements: [],
        currentData: v,
        isReset: true
      }
    },

    // 依据变化
    rejectChange(v) {
      this.rejectProp.judgements = v.map(v => {
        return {
          ...v,
          judgeType: '02'
        }
      })
    },

    // 驳回确定
    rejectConfig() {
      const { judgements, currentData } = this.rejectProp;
      const { argueNo } = currentData;
      const flag = judgements.every(v => v.reason && v.url);
      if (!flag || !judgements.length) {
        return this.$message.error("请填写完整追责依据信息");
      }
      _api.aprRejecy({ argueNo, judgements }).then(r => {
        this.$message.success("操作成功");
        this.getData();
        this.rejectProp.show = false;
        this.rejectProp.isReset = false;
      })
    },


    // 以下是复制代码
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 8 && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        column.property == "quoteNum"
      ) {
        // this.storeShow = false;
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            this.getUpdatedQuoteLog = res.data.normal;
            this.remakeQuoteLog = res.data.remake;
          });
      }
    },
    viewrecordsClose() {
      this.viewrecords = false;
    },
    // 报价商家弹窗
    openStoreList(e) {
      this.recyclerData.merchantName = e.manualMerchantName;
      this.baoPricestate=e.state
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          this.recyclerData.storeList = res.data.map(v => setValue(v));
        }
      });
      this.recyclerData.show = true;
    },

    //导表
    excelClick() {
      let params = this.params;
      console.log(123);
      params = this.resetTime(params, 'applyTime', 'applyStartTime', 'applyEndTime');
      // 新加初审时间
      params = this.resetTime(params, 'auditFirstTime', 'auditFirstStartTime', 'auditFirstEndTime');
      // 新加成交时间
      params = this.resetTime(params, 'ackTime', 'ackStartTime', 'ackEndTime');
      params = this.resetTime(params, 'auditTime', 'auditReAdminTimeStartTime', 'auditReAdminTimeEndTime');
      params = this.resetTime(params, 'closeTime', 'closeStartTime', 'closeEndTime');
      this.excelLoading = true;
      let myparams = {}
      if (this.activeIndex === 5) {//暂存订单
        myparams = {
          ...params,
          staAdminId: this.staAdminId === window.localStorage.getItem('userName') ? Number(window.localStorage.getItem('userId')) : this.staAdminId
        }
      } else {
        myparams = {
          ...params,
          staAdminId: ''
        }
      }
      _api.aprExcel(myparams).then(r => {
        guideExcelAction(r, '平台审核差异单', () => {
          this.excelLoading = false;
        })
      })
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    // 查看线下导入记录
    viewImportRecords() {
      this.$router.push({ path: "/difference/importRecords" });
    }
  }
}
</script>
<style scoped lang="scss">
.fileName {
  /deep/.el-input {
    width: 260px;
  }

  /deep/.el-button {
    // border-radius: 10px;
    margin-right: 0px;
  }
}

.impor_bottom {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 30px;

  .inquery {
    color: #4C84FF;
    position: relative;
    padding-left: 20px;
  }

  .inquery::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #4C84FF;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.jus_spa{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jus_end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.import_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.impor_tip {
  color: #FF5C5C;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 64px;
}

.impor_inp {
  width: 200px;
  height: 34px;
  border: 1px solid #C1D1FF;
  background-color: #F9FBFD;
  border-radius: 6px;
}

.cance_tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.remarkBtn {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline
}

.function_box {
  max-height: 700px;
  overflow: auto;
}

.box {
  >.header {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 18px 20px 24px 15px;
    box-sizing: border-box;

    >.title {
      width: 100%;
      display: flex;
      align-items: center;

      >img {
        width: 30px;
        height: 30px;
        margin-right: 9px;
      }

      >h3 {
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        margin-right: 8px;
        transform: translateY(-1px);
      }

      >p {
        font-size: 14px;
        font-weight: 400;
        color: #4C84FF;
      }
    }

    >.search {

      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 14px 0px 14px;
      background: #F5F6FA;
      border-radius: 4px;
    }
  }
}

.box {
  /deep/ .current_row_style .cell {
    line-height: 20px;
    padding: 10px;
  }

  ///deep/ .action_style .cell{
  //  visibility: visible !important;
  //  position: relative;
  //  z-index: 1999;
  //}

  ///deep/ .action_style .action_box{
  //  overflow: hidden;
  //}
  //
  ///deep/ .el-table__body-wrapper .action_style .action_box{
  //  display: none;
  //}


  /deep/ .photo_list img {
    width: 60px;
    height: 60px;
  }

  >.body {
    margin-top: 15px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 14px 20px 14px 17px;
    box-sizing: border-box;

    >.table {
      margin-top: 14px;

      >.table_header {
        width: 100%;
        padding: 11px 0;
        padding-right: 10px;
        border-radius: 4px 4px 0px 0px;
        background: #E9F0FE;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.table_header_left {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #4C84FF;
          justify-content: space-between;

          >p {
            margin-right: 30px;
          }

          >.table_index {
            width: 30px;
            height: 18px;
            background: #F5B724;
            border-radius: 3px 9px 9px 3px;
            text-align: center;
            line-height: 18px;
            margin-right: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        >.table_header_right {
          >.day_num {
            font-size: 12px;
            font-weight: 400;
            color: #FF687B;
          }
        }
      }

      /deep/ .el-table .el-table__header th {
        background: #F9FBFD !important;
        border-width: 0;
        border-bottom-width: 1px;
      }
    }
  }
}

.tab_action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .taab_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .excel_btn {
    margin-left: 20px;
    height: 30px;
    cursor: pointer;
    background: #50CD85;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    align-items: center;
    padding: 0 9px;

    img {
      margin-right: 2px;
      width: 16px;
      height: 16px;
    }
  }
}

.photo_list {
  >img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}

.channel_name {
  padding: 0 11px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #FFAF29;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #FFAF29;
  line-height: 20px;
}

.matrix_name {
  padding: 0 11px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #21D8C2;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #21D8C2;
  line-height: 20px;
}

.apply_times {
  padding: 0 11px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #FF5C5C;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #FF5C5C;
  line-height: 20px;
}

.blue_color {
  border: 1px solid #4C84FF;
  color: #4C84FF;
}

.action_box {
  display: flex;
  align-items: center;
  justify-content: space-around;

  >p {
    flex: 1;
    text-align: center;
  }
}


// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.rejetct_box {
  .reject_duty {
    display: flex;
    align-items: center;
  }

  .reject_basis {
    margin-top: 10px;

    /deep/ .box .list_box p {
      width: 80px;
    }
  }
}

.order_num {
  margin-left: 10px;
  background: #E4ECFD;
  border-radius: 4px;
  padding: 6px;
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield;
}

///deep/.table_header_style{
//}

.paste-img-box{
  display: flex;
  .img-item{
    position: relative;
    margin-right: 20px;
    .el-icon-circle-close{
      font-size: 30px;
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 5;
      color: #FD677D;
    }
    .img{
      width: 100px;
      height: 100px;
    }
  }
}
</style>
