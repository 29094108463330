<template>
  <!-- 信息 -->
  <div class="info">
    <div class="left">
      <div class="info_title">
        <div>申请信息</div>
        <div>
          <span class="has_lable" v-if="detailData.isPreventFlag">有防拆标</span>
          <span class="launch_num" v-if="detailData.orderApplyTimes>=2">发起{{ detailData.orderApplyTimes }}次</span>
          <span class="records" v-if="detailData.orderApplyTimes>=2" @click="records">查看审核记录</span>
        </div>
      </div>
      <div class="left_inner info_inner scroll_style">
        <div>
          <div class="inner_line">
            <p class="inner_label">订单号：</p>
            <p class="inner_href_text click_hover"
              @click="openAction(`/RecallOrder/Details?type=edit&id=${detailData.orderNo}`)">{{ detailData.orderNo }}</p>
            <p style="margin-left: 10px;" v-if="detailData.outType !== '--'" class="inner_label">
              ({{ getOutNameMake(detailData.outType).make }}订单号：</p>
            <p class="inner_href_text" style="text-decoration:none;cursor: text" v-if="detailData.outType !== '--'">
              {{ detailData.orderNoOneline }})</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">申请商户：</p>
            <p>{{ detailData.applyMerchantName || '--' }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">申请人：</p>
            <p>{{ detailData.applyStaffInfo }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">申请时间：</p>
            <p>{{ detailData.applyTime }}</p>
          </div>
        </div>
        <div class="left_detail">
          <h4 class="inner_title">追差详情</h4>
          <div class="left_detail_inner">
            <!-- 金额 -->
            <div class="money">
              <div class="inner_line">
                <p class="inner_label">追差：</p>
                <p class="inner_title_red">{{ detailData.arguePrice }}元</p>
              </div>
              <div class="inner_line">
                <p class="inner_label">回收金额：</p>
                <p class="inner_title_red">{{ detailData.manualQuotePrice }}元</p>
              </div>
              <div v-if="$route.query.type !== 'store'" class="inner_line">
                <p class="inner_label">门店实报价：</p>
                <p class="inner_title_red">{{ detailData.storeQuotePrice }}元</p>
              </div>
            </div>
            <!-- 差异类型 -->
            <div class="type">
              <div class="inner_line" style="align-items: flex-start">
                <p class="inner_label" style="min-width: 70px;">差异类型：</p>
                <p class="inner_title_red">{{ detailData.items }}</p>
              </div>
            </div>
            <div class="note">
              <div class="inner_line" style="align-items: flex-start">
                <p class="inner_label" style="min-width: 43px;">备注：</p>
                <p class="inner_title_red">
                  {{ detailData.applyNote }}</p>
              </div>
            </div>
            <div class="video">
              <h4 class="inner_title">追差视频</h4>
              <Carousel :switer-list="detailData.vouchers" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="inner_line" style="margin-bottom: 0;">
        <p class="info_title">订单基本信息</p>
        <p class="matrix_icon click_hover" @click="matrixClick"
          v-if="detailData.outType === 'fx' && $route.query.type !== 'store'">
          {{ isMatrix ? '查看矩阵原订单' : '查看矩阵订单' }}
          <img src="../images/switch.png" alt="">
        </p>
      </div>
      <div class="right_inner info_inner scroll_style">
        <div style="width: 100%;overflow: hidden">
          <div class="inner_line" style="width: 100%">
            <p v-if="infoData.phoneName !== '--'">{{ infoData.phoneName }}</p>
            <p v-else-if="infoData.isElderlyMachine">功能机/老年机</p>
            <p v-else-if="!infoData.canStartingUp" class="inner_title" style="margin-right: 10px;">无法开机</p>
            <p v-else>其他机型</p>
            <p>（IMEI：<span id="IMEI">{{ infoData.imei }}</span><el-link type="success" style="vertical-align: baseline;margin-left: 10px" @click="copy('IMEI')">复制</el-link>）</p>
            <p class="inner_href_text click_hover" style="margin-left: 10px;" @click="getFunction">查看功能情况</p>
          </div>
          <!--          <div class="inner_line">-->
          <!--            <p class="inner_label">回收单号：</p>-->
          <!--            <p class="inner_href_text click_hover">SH202012031200</p>-->
          <!--          </div>-->
          <div class="inner_line">
            <p class="inner_label">下单商户：</p>
            <p>{{ infoData.companyName }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">下单门店：</p>
            <p>{{ infoData.storeName }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">下单店员：</p>
            <p>{{ infoData.staffInfo }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">下单时间：</p>
            <p>{{ infoData.createTime }}</p>
          </div>
          <div class="inner_line">
            <p class="inner_label">成交时间：</p>
            <p>{{ infoData.ackTime }}</p>
          </div>
          <el-row>
            <el-col :span="6" class="inner_line">
              <p class="inner_label">发货时间：</p>
              <p>{{ infoData.expressTime }}</p>
            </el-col>
            <el-col :span="9" class="inner_line">
              <p class="inner_label">物流单号：</p>
              <p>
                <span v-if="infoData.expressNo !== '--'" class="inner_href_text click_hover" @click="openAction(`/logisticsManage/order?id=${infoData.expressNo}`)">{{ infoData.expressNo }}</span>
                <template v-else>--</template>
                <template v-if="infoData.expressNo !== '--'">
                  <template v-if="infoData.expressChannel == '01'">
                  （签收时间：{{ infoData.signForTime }}）
                  </template>
                  <template v-else>
                  （线下物流，无签收时间）
                  </template>
                </template>
              </p>
            </el-col>
            <el-col :span="9" class="inner_line">
              <p class="inner_label">仓库出库时间：</p>
              <!-- erpState -->
              <!-- 00：未入库，01：未出库，02；已出库 -->
              <!-- 仓库出库时间：未入库到繁星仓
              仓库出库时间：尚未出库（繁星成都分转仓）
              仓库出库时间：已出库20天（繁星成都分转仓） -->
              <!-- <p>{{ infoData.expressTime }}</p> -->
              <p>
                <template v-if="infoData.erpState == '00'">未入库到繁星仓</template>
                <template v-else-if="infoData.erpState == '01'">尚未出库（{{infoData.intoWarehouseName}}）</template>
                <template v-else-if="infoData.erpState == '02'">已出库{{infoData.outStockDay}}天（{{infoData.outWarehouseName}}）</template>
              </p>
            </el-col>
          </el-row>
          <!-- <div class="inner_line">
            <p class="inner_label">发货时间：</p>
            <p>{{ infoData.expressTime }}</p>
          </div> -->
          <div class="inner_line">
            <p class="inner_label">回收金额：</p>
            <p @click="moneyDetail">
              {{ computedMoney(infoData.diffFundPrice, infoData.quotePrice) }}
              （差异基金{{ infoData.diffFundPrice }}元，报价金额{{ infoData.quotePrice }}元
              <span class="inner_href_text click_hover">查看明细</span>）
            </p>
          </div>
          <div class="inner_line">
            <p class="inner_title">旧机照片：</p>
          </div>
          <div>
            <Carousel :switer-list="infoData.images" />
          </div>
        </div>
      </div>
    </div>
    <!-- 旧机功能情况 -->
    <!--弹框-->
    <GlobalDialog title="旧机功能情况" :show="dialogVisibleOrder" :width="840" top="10vh" @resetPopupData="resetPopupData"
      :loading="isLoadding" @submitPopupData="submitPopupData" @onClose="resetPopupData">
      <div class="fault-box function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :externalorderType="externalorderType" :datainfo="reportData" />
        </div>
        <div>
           <!-- 估价单验机报告 -->
           <div class="test-box">
            <div class="test-result" v-if="reportData.functionView.errorList.length">
              <Title :title="'异常项（共'+ reportData.functionView.errorList.length +'项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="reportData.unableDetectionCause &&
                reportData.detectionType == '01'
                ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  reportData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="reportData.detectionType == '02'">
                <span class="fs16">检测机型：{{ reportData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ reportData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="reportData.functionView.errorList"></TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="reportData.functionView.normalList.length">
              <Title :title="'正常项（共'+ reportData.functionView.normalList.length +'项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="reportData.functionView.normalList"></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0;">
          <Title title="店员备注" />
          <Remark :remark="reportData.remark" :againPhotoRemark="reportData.againPhotoRemark" />
        </div>
      </div>
    </GlobalDialog>
    <!-- 查看成交明细  -->
    <el-dialog :visible.sync="dealDetailProps.show" :title="dealDetailProps.title"
      :before-close="() => dealDetailProps.show = false" width="30%">
      <!-- 已确认 -->
      <div v-if="dealDetailProps.type">
        <div class="deal_detail_title">回收商成交价：{{ dealDetailProps.data.quotePrice }}</div>
        <div class="deal_detail_inner">
          <div class="deal_detail_line-title">回收商实收价（扣除差异）：{{ dealDetailProps.data.netQuotePrice }}</div>
          <div class="deal_detail_line-title">差异金额 ：<span class="deal_detail_line-tips-red">-{{
            dealDetailProps.data.depreciatePrice
          }}</span></div>
        </div>
        <div class="deal_detail_title">订单总利润：{{ dealDetailProps.data.orderRebate }}</div>
        <div class="deal_detail_inner">
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">公司返利：{{ dealDetailProps.data.allAddPrice }}</p>
            <p class="deal_detail_line-tips">（<span class="deal_detail_line-tips-blue">+门店返利：{{
              dealDetailProps.data.storeAddPrice
            }}；+预留返利：{{ dealDetailProps.data.delayAddPrice }}；</span>
              <span class="deal_detail_line-tips-red">-加价挪用：{{ dealDetailProps.data.useAddPrice }}</span>）
            </p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">员工红包：{{ dealDetailProps.data.angPow }}</p>
            <p class="deal_detail_line-tips">--店长：实得红包：{{ dealDetailProps.data.angPowSmStaff }}
              <span class="deal_detail_line-tips-blue" style="margin-left: 16px;">+计算红包：{{
                dealDetailProps.data.orderAngPowSmStaff
              }}；</span>
              <span style="margin-left: 10px;" class="deal_detail_line-tips-red">-挪用红包{{
                dealDetailProps.data.usdAngPowSmStaff
              }}</span>
            </p>
            <p class="deal_detail_line-tips">--店员：实得红包：{{ dealDetailProps.data.angPowStaff }}<span
                class="deal_detail_line-tips-blue" style="margin-left: 16px;">+计算红包：{{
                  dealDetailProps.data.orderAngPowStaff
                }}；</span><span class="deal_detail_line-tips-red" style="margin-left: 10px;">-挪用红包{{
  dealDetailProps.data.usdAngPowStaff }}</span></p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">门店压价金额：{{ dealDetailProps.data.orderCutPrice }}</p>
            <p class="deal_detail_line-tips">--公司压价返利：{{ dealDetailProps.data.companyRebate }}；店长压价提成：{{
              dealDetailProps.data.cutPriceSmStaff
            }}；店员压价提成：{{ dealDetailProps.data.cutPriceStaff }}</p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">员工创收奖励（未加价提成）：{{ dealDetailProps.data.addPriceToStaff }}</p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">线上回收员工分享奖励：{{ dealDetailProps.data.miniMallShareReward }}</p>
          </div>
        </div>
        <div class="deal_detail_title">顾客成交价：{{ dealDetailProps.data.userPrice }}</div>
      </div>
      <!-- 未确认 -->
      <div v-else>
        <div class="deal_detail_title">回收商报价：{{ dealDetailProps.data.quotePrice }}</div>
        <div class="deal_detail_title">订单总利润：{{ dealDetailProps.data.orderRebate }}</div>
        <div class="deal_detail_inner">
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">公司返利：{{ dealDetailProps.data.allAddPrice }}</p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">员工红包：{{ dealDetailProps.data.angPow }}</p>
            <p class="deal_detail_line-tips">--店长：应得红包：{{ dealDetailProps.data.angPowSmStaff }} <span
                class="deal_detail_line-tips-blue" style="margin-left: 16px;">+设置红包：{{
                  dealDetailProps.data.orderAngPowSmStaff
                }}</span><span style="margin-left: 10px;" class="deal_detail_line-tips-red">-挪用金额{{
  dealDetailProps.data.usdAngPowSmStaff
}}</span></p>
            <p class="deal_detail_line-tips">--店员：应得红包：{{ dealDetailProps.data.angPowStaff }} <span
                class="deal_detail_line-tips-blue" style="margin-left: 16px;">+设置红包：{{
                  dealDetailProps.data.orderAngPowStaff
                }}</span><span class="deal_detail_line-tips-red" style="margin-left: 10px;">-挪用金额{{
  dealDetailProps.data.usdAngPowStaff }}</span></p>
          </div>
          <div class="deal_detail_line">
            <p class="deal_detail_line-title">门店压价金额：{{ dealDetailProps.data.orderCutPrice }}</p>
          </div>
          <div class="deal_detail_line" style="margin-bottom: 0">
            <p class="deal_detail_line-title">线上回收分享奖励：{{ dealDetailProps.data.miniMallShareReward }}</p>
          </div>
        </div>
        <div class="deal_detail_title">顾客成交价：{{ dealDetailProps.data.userPrice }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dealDetailProps.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 查看审核记录 -->
    <DialogTbale @onClose="auditRecordClose" :show="auditRecord" title="查看审核记录" 
      :columns="auditRecordColumns" :data="dialogList" :currentPage="auditRecordParams.pageNum"
      :total="auditRecordParams.total" :maxHeight="540" :pageSize="auditRecordParams.pageSize" :width="1100"
      @handleCurrentChange="getauditRecordList">
      <el-table-column  label="申请人" slot="applyStaffName" align="center">
        <template slot-scope="{ row }">
          {{ row.applyStaffName }}-{{ row.applyStaffMobile }}
        </template>
      </el-table-column>
      <el-table-column  label="初审人" slot="auditFirstAdminName" align="center">
        <template slot-scope="{ row }">
          {{ row.auditFirstAdminName }}-{{ row.auditFirstAdminAcc }}
        </template>
      </el-table-column>
      <el-table-column width="120" label="操作" slot="operate" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary"  @click="openAction(`/difference/process?argueNo=${row.argueNo}`)">查看审核详情
          </el-button>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>
<script>
import Carousel from "@/views/difference/componets/Carousel.vue";
import _api from "@/utils/request";
import { getOutNameMake, openAction, setValue, copy } from "@/utils/common";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Title from "@/views/RecallOrder/componets/Title.vue"
import Remark from "@/views/RecallOrder/componets/Remark.vue";
export default {
  name: "ProcessHeader",
  components: { Carousel, TestDeviceInfo, TestTable, Title, Remark },
  data() {
    const { type } = this.$route.query;
    return {
      // 查看审核记录弹框
      auditRecord: false,
      auditRecordColumns: [
        { label: "订单号", prop: "orderNo" },
        { slotName: "applyStaffName" },
        { label: "申请时间", prop: "applyTime" },
        { slotName: "auditFirstAdminName" },
        { label: "审核时间", prop: "auditFirstTime" },
        { label: "初审判责依据（仅文字）", prop: "reason" },
        { slotName: "operate" },
      ],
      dialogList: [],
      auditRecordParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // *********************
      externalorderType: "",
      // 功能情况接口
      reportData: {
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
        functionView: {
          errorList: [],
          normalList: []
        }
      },
      // 旧机功能情况***********
      dialogVisibleOrder: false,
      isLoadding: false,
      isSystem: false,
      isUnableTest: false,
      detectionInfo: null,
      cameraCause: [],
      // 功能情况
      functionList: [],
      faultsList: [],
      // ********************
      // 成交明细
      dealDetailProps: {
        show: false,
        data: {},
        type: null,// true代表已确认，fasle代表未确认
        title: "报价明细"
      },
    }
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
      }
    },
    infoData: {
      type: Object,
    },
    // 矩阵订单
    isMatrix: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    
  },
  methods: {
    //查看审核记录
    records() {
      this.auditRecord = true
      this.getauditRecordList()
    },
    auditRecordClose() {
      this.auditRecord = false
    },
    // 审核记录,
    getauditRecordList(val) {
      this.dialogList = []
      if (val) {
        this.auditRecordParams.pageNum = val;
      } else {
        this.auditRecordParams.pageNum = 1;
      }
      let params = {
        orderNo:this.detailData.orderNo,
        pageNum: this.auditRecordParams.pageNum,
        pageSize: this.auditRecordParams.pageSize
      }
      _api.auditRecord(params).then(res => {
        if (res.code === 1) {
          this.dialogList = res.data.records
          this.auditRecordParams.pageNum = res.data.current;
          this.auditRecordParams.total = res.data.total;
        }
      })
    },
    // ******************* 
    openAction,
    getOutNameMake,
    copy,
    // 旧机功能情况弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //旧机功能情况弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    //旧机功能情况
    getFunction() {
      console.log(this.infoData.outType);
      this.externalorderType = this.infoData.outType
      this.dialogVisibleOrder = true;
      this.isLoadding = true
      // 功能情况接口
      _api.getInspectionReport({ orderNo: this.infoData.orderNo }).then(res => {
        this.reportData = res.data;
        if(res.data.againPhotoRemark){
          this.reportData.againPhotoRemark=res.data.againPhotoRemark.split("//")
        }else{
          this.reportData.againPhotoRemark=[]
        }
      })
    },
    // 数字转换
    convertYypeNumer(num) {
      let v = num;
      // 是否类型正确
      let isType = true;
      if (typeof v !== 'number') {
        isType = false;
        v = Number(v);
        if (isNaN(v)) {
          v = 0;
        }
      }
      return {
        isType,
        v
      };
    },
    // 计算金额值
    computedMoney(num1, num2, isAdd = true) {
      const v1 = this.convertYypeNumer(num1);
      let v2 = this.convertYypeNumer(num2);
      if (!v1.isType && !v2.isType) {
        return '--'
      }
      v1.v = Math.abs(v1.v);
      v2.v = Math.abs(v2.v);

      return isAdd ? v1.v + v2.v : v1.v - v2.v;
    },
    //获取明秀
    getMoneyDetail(orderNo, cb) {
      _api.getQuoteCompute(orderNo).then(r => {
        let data = setValue(r.data);
        data = {
          ...data,
          // 回收商实收价
          netQuotePrice: this.computedMoney(data.quotePrice, data.depreciatePrice, false),
          // 员工红包
          angPow: this.computedMoney(data.angPowSmStaff, data.angPowStaff),
          // 公司压价返利
          companyRebate: this.computedMoney(data.orderCutPrice, data.allStaffCutPrice, false)
        }
        cb(data);
      })
    },
    // 查看明细
    moneyDetail() {
      const v = this.infoData;
      this.getMoneyDetail(v.orderNo, data => {
        this.dealDetailProps = {
          ...this.dealDetailProps,
          show: true,
          // 待确认和已取消代表未确认，返回false，其他作为true
          type: ['00', '10'].indexOf(v.state) < 0,
          data
        };
      })
    },
    // 查看矩阵订单两种情况
    matrixClick() {
      this.$emit('matrixSwitch');
    }
  },
  // matrixSwitch：矩阵点击切换
  emits: ['matrixSwitch']
}
</script>
<style scoped lang="scss">
.function_box {
  max-height: 700px;
  overflow: auto;
}

.fs16 {
  font-size: 14px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.faultBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;

  .faultBut_item {
    width: 46%;
    min-width: 46%;
    max-width: 46%;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background: #F5F6FA;
    border: 1px solid #E4ECFD;
    margin-bottom: 10px;

    >span {
      color: #0099ff;
    }
  }

  .faultSystem_item {
    background-color: #e4ecfd;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    width: 380px;

    >span {
      color: #0099ff;
    }
  }

  .oneline_item {
    width: 380px;
    min-height: 40px;
    background: #F5F6FA;
    border: 1px solid #E4ECFD;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 10px 18px;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: justify;
  }
}

.remark-title {
  font-size: 14px;
  font-weight: 700;
  margin-top: 6px;
}

.remark-cont {
  padding: 15px 10px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #f4f4f4;
  margin-top: 10px;
  margin-bottom: 15px;
}

.info {
  padding: 17px 20px 21px 20px;
  background: #fff;
  max-height: 570px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  width: 100%;

  >.left {
    margin-right: 22px;
    max-width: 570px;
    min-width: 570px;
  }

  >.right {
    margin-right: 22px;
    width: calc(100% - 588px);
    max-width: calc(100% - 588px);
    min-width: calc(100% - 588px);
    display: block;
    overflow: hidden;

    .right_inner {
      width: 100%;
    }
  }
}

.has_lable {
  border: 1px solid #FFAF29;
  border-radius: 20px;
  color: #FFAF29;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  padding: 4px 6px;
}

.launch_num {
  border: 1px solid #FF5C5C;
  border-radius: 20px;
  color: #FF5C5C;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  padding: 4px 6px;
}

.records {
  text-decoration: underline;
  cursor: pointer;
}

.records:hover {
  opacity: 0.8;
}

.info_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #4C84FF;
  position: relative;
  padding-left: 7px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 2px;
    height: 14px;
    background: #4C84FF;
    border-radius: 1px;
  }
}

.info_inner {
  margin-top: 16px;
  background: #F5F6FA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 21px;
  font-size: 14px;
  color: #333333;
  overflow: auto;
  height: 500px;
  min-height: 500px;
  max-height: 500px;
}

.matrix_icon {
  background: #ECFFFD;
  border: 1px solid #21D8C2;
  border-radius: 4px;
  padding: 5px 6px 5px 22px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  color: #17D1BB;
  position: relative;
  margin-left: 17px;
  cursor: pointer;

  img {
    position: absolute;
    left: 8px;
    top: 9px;
    width: 10px;
    height: 10px;
  }
}

.left_detail_inner {
  margin-top: 16px;

  .money {
    display: flex;
    align-items: center;

    >.inner_line {
      margin-right: 60px;
    }
  }

  .type {}
}

.video {
  overflow: hidden;
}

.inner_label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.inner_line {
  display: flex;
  margin-bottom: 16px;
  line-height: 16px;
  // align-items: center;
}

.inner_title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.inner_title_red {
  color: #FD677D;
}

.inner_href_text {
  color: #4C84FF;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}


/* 设置滚动条的宽度、高度、背景色和边框样式 */
::-webkit-scrollbar {
  width: 12px;
}

/* 设置滚动条滑块的背景色和圆角 */
::-webkit-scrollbar-thumb {
  background-color: #DCE0EE;
  height: 10px;
  border-radius: 4px;
}

/* 设置滚动条滑块在悬停状态下的背景色和圆角 */
//::-webkit-scrollbar-thumb:hover {
//  height: 10px;
//}

/* 设置滚动条轨道的背景色和圆角 */
::-webkit-scrollbar-track {
  background-color: rgba(220, 224, 238, .5);
}

/* 设置滚动条轨道在悬停状态下的背景色和圆角 */
//::-webkit-scrollbar-track:hover {
//  background-color: #ccc;
//  border-radius: 5px;
//}

.click_hover:hover {
  opacity: 0.8;
}

.deal_detail_title {
  width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0c82ff;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_inner {
  margin-bottom: 20px;
  background: #F2F2F2;
  font-size: 12px;
  color: #111;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
}

.deal_detail_line {
  margin-bottom: 16px;
}

.deal_detail_line-title {
  padding-left: 10px;
  position: relative;
  margin-bottom: 6px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_line-tips {
  transform: translateX(-5px);
}

.deal_detail_line-tips-blue {
  color: #0981FE;
}

.deal_detail_line-tips-red {
  color: #FF0000;
}
</style>
