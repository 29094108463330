<template>
  <div class="ConfigStoreDia">
    <el-dialog
      title="配置使用门店"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      top="50px"
      @close="close"
    >
      <el-form :inline="true" :model="diaForm">
        <el-form-item label="门店商户">
          <el-input
            placeholder="请输入商户名称查询"
            size="small"
            v-model="diaForm.companyName"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="dialogSearchCompany()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="ConfigStoreTable"
        :data="dialogTableData"
        v-loading="diaLoading"
        tooltip-effect="dark"
        max-height="500px"
        border
      >
        <el-table-column type="index" align="center" label="序号" width="50" />
        <el-table-column label="外部渠道" align="center">
          <template slot-scope="{ row }">{{
            row.channelName || "--"
          }}</template>
        </el-table-column>
        <el-table-column label="外部商户号/渠道ID" align="center">
          <template slot-scope="{ row }">
            <div>商户号：{{ row.outMerchantId || "--" }}</div>
            <div>渠道ID：{{ row.channelID || "--" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="门店商家" align="center">
          <template slot-scope="{ row }">{{
            row.companyName || "--"
          }}</template>
        </el-table-column>
        <el-table-column label="配置门店" align="center">
          <template slot-scope="{ row }">
            <span class="btn" @click="openStoreDia(row)">{{
              row.storeNum
            }}</span>
            <p
              style="cursor: pointer;color: #ffbf54;text-decoration: underline;"
              @click="openAddStoreDia(row)"
            >
              添加
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-bottom: 20px;">
        <el-pagination
          style="text-align: right; margin-top: 20px"
          @current-change="dialogSearchCompany"
          :current-page="diaForm.pageNum"
          layout="total,  prev, pager, next, jumper"
          :total="diaForm.total"
          :background="true"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      title="配置使用门店"
      :visible.sync="storeDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      top="50px"
    >
      <el-form :inline="true" :model="storeDiaForm">
        <el-form-item label="门店ID">
          <el-input
            placeholder="请输入门店ID查询"
            size="small"
            v-model="storeDiaForm.storeCode"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="dialogSearchStore()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 10px;">
        <el-button size="small" :disabled="!selectedStoreList.length" type="danger" @click="batchRemoveStore">批量移除</el-button>
      </div>
      <el-table
        ref="ConfigStoreTable"
        :data="storeDialogTableData"
        v-loading="storeDiaLoading"
        tooltip-effect="dark"
        max-height="500px"
        border
        @selection-change="handSelect"
        :row-key="
          (row) => {
            return row.storeCode;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" />
        <el-table-column label="门店ID" align="center">
          <template slot-scope="{ row }">{{ row.storeCode }}</template>
        </el-table-column>
        <el-table-column label="添加人" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.createName }}-{{ row.createAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column label="添加时间" align="center">
          <template slot-scope="{ row }">{{ row.createTime }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="danger" size="small" @click="delStroe(row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-bottom: 20px;">
        <el-pagination
          style="text-align: right; margin-top: 20px"
          @current-change="dialogSearchStore"
          :current-page="storeDiaForm.pageNum"
          layout="total,  prev, pager, next, jumper"
          :total="storeDiaForm.total"
          :background="true"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      title="添加门店"
      :visible.sync="addStoreVisible"
      :close-on-click-modal="false"
      width="500px"
      top="50px"
    >
      <div
        class="flex"
        style="margin-bottom: 10px;"
        v-for="(item, index) in storeCodeInputList"
        :key="index"
      >
        <span style="flex-shrink: 0;">{{ index + 1 }}.门店ID：</span>
        <el-input
          size="small"
          clearable
          v-model="item.val"
          placeholder="请输入门店ID"
          @input="val => item.val = val.replace(/[^a-zA-Z0-9]/g, '')"
        ></el-input>
        <i
          v-show="index === 0"
          class="el-icon-circle-plus-outline exist_icon"
          @click="createId"
        ></i>
        <i
          v-show="index !== 0"
          class="el-icon-remove-outline exist_icon exist_icon_del"
          @click="deleteId(index)"
        ></i>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addStoreVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="addStoreLoading"
          @click.native="addStoreSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :title="removeTitle" :visible.sync="dialogSureVisible" :close-on-click-modal="false"
      width="550px" @closed="rowStoreCode=''">
      <div style="font-size: 18px;color: #666666;text-align: center;height: 100px;line-height: 100px;">
        是否确认{{removeTitle=='批量移除提示'?'批量':''}}移除？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button type="primary" :loading="sureHandleBtnLoading" @click.native="sureHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    channelCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      diaForm: {
        brandId: "",
        pageNum: 1,
        pageSize: 10,
      },
      diaLoading: false,
      dialogTableData: [],
      companyId: "",
      storeDiaForm: {
        storeCode: "",
        pageNum: 1,
        pageSize: 10,
      },
      storeDialogVisible: false,
      storeDialogTableData: [],
      storeDiaLoading: false,
      addStoreVisible: false,
      storeCodeInputList: [{ val: "" }],
      addStoreLoading: false,
      selectedStoreList: [],

      dialogSureVisible: false,
      rowStoreCode: '',
      sureHandleBtnLoading: false,
      removeTitle: "",
    };
  },
  created() {
    this.dialogSearchCompany();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    dialogSearchCompany(pageNum) {
      this.diaLoading = true;
      _api
        .getCameImeiTimeLimitCompanyList({
          ...this.diaForm,
          channelCode: this.channelCode,
          pageNum: pageNum || 1,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaForm.pageNum = res.data.current;
          this.diaForm.total = res.data.total;
        })
        .finally(() => {
          this.diaLoading = false;
        });
    },
    openStoreDia(row) {
      this.companyId = row.companyId;
      this.storeDialogVisible = true;
      this.dialogSearchStore();
    },
    openAddStoreDia(row) {
      this.storeCodeInputList = [{ val: "" }],
      this.companyId = row.companyId;
      this.addStoreVisible = true;
    },
    dialogSearchStore(pageNum) {
      this.storeDiaLoading = true;
      _api
        .getCameImeiTimeLimitStoreList({
          ...this.storeDiaForm,
          channelCode: this.channelCode,
          companyId: this.companyId,
          pageNum: pageNum || 1,
        })
        .then((res) => {
          this.storeDialogTableData = res.data.records;
          this.storeDiaForm.pageNum = res.data.current;
          this.storeDiaForm.total = res.data.total;
        })
        .finally(() => {
          this.storeDiaLoading = false;
        });
    },
    // 移除操作
    delStroe(row) {
      this.removeTitle = "移除提示";
      this.rowStoreCode = row.storeCode;
      this.dialogSureVisible = true;
    },
    createId() {
      this.storeCodeInputList.push({ val: "" });
    },
    deleteId(index) {
      this.storeCodeInputList.splice(index, 1);
    },
    addStoreSubmit() {
      this.addStoreLoading = true;
      this.$confirm("是否确认添加?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let storeCodeList = [];
          this.storeCodeInputList.forEach((item) => {
            storeCodeList.push(item.val);
          });
          let params = {
            companyId: this.companyId,
            channelCode: this.channelCode,
            type: "00",
            storeCodeList,
          };
          _api.handlerCameImeiTimeLimit(params).then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "添加成功");
              this.addStoreVisible = false
              this.dialogSearchCompany(this.diaForm.pageNum);
            }
          }).finally(()=>{
            this.addStoreLoading = false
          })
        })
        .catch(() => {
          this.addStoreLoading = false;
        });
    },
    //多选操作
    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedStoreList = selection;
    },
    // 确认弹框确认
    sureHandleSuccess() {
      let params = {
        companyId: this.companyId,
        channelCode: this.channelCode,
        type: "01",
        storeCodeList: this.rowStoreCode
          ? [this.rowStoreCode]
          : this.selectedStoreList.map((item) => item.storeCode),
      };
      this.sureHandleBtnLoading = true;
      _api
        .handlerCameImeiTimeLimit(params)
        .then((res) => {
          if (res.code === 1) {
            this.selectedStoreList = []
            this.$refs.ConfigStoreTable.clearSelection();
            this.$message.success(res.msg);
            this.dialogSearchStore(this.storeDiaForm.pageNum);
            this.dialogSearchCompany(this.diaForm.pageNum);
          }
          this.dialogSureVisible = false;
        })
        .finally(() => {
          this.sureHandleBtnLoading = false;
        });
    },
    //批量移除机型
    batchRemoveStore() {
      this.removeTitle = "批量移除提示";
      this.dialogSureVisible = true;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.ConfigStoreDia {
  .flex {
    display: flex;
    align-items: center;
  }
  .exist_icon {
    color: #0981ff;
    font-size: 23px;
    cursor: pointer;
    display: inline-block;
    //transform: translateY(3px);
    padding-left: 8px;
  }

  .exist_icon_del {
    color: #f76453;
    transform: translateY(0.1px);
  }
  .btn {
    text-decoration: underline;
    cursor: pointer;
    color: #0981ff;
  }
  /deep/.el-form-item{
        margin-bottom: 10px !important;
    }
}
</style>
