<template>
  <div class="GlobalTable">
    <div class="title-item">
      <img src="@/assets/images/icon_shangjia.png" style="margin-right: 8px" alt="" />
      <span>商家信息</span>
    </div>
    <div class="business-info">
      <span>商家名称：{{ companyInfo.companyName }}</span>
      <span>联系人：{{ companyInfo.contact }}</span>
      <span>联系电话：{{ companyInfo.phone }}</span>
      <span>地址：{{ companyInfo.address }}</span>
    </div>
    <div class="title-item">
      <img src="@/assets/images/icon_lirun.png" style="margin-right: 8px" alt="" />
      <span>利润模式</span>
    </div>
    <div class="profit-type">
      <!-- :disabled="setType == 'true'" -->
      <div>
        <el-radio v-model="profitMode"  label="diff">差价模式</el-radio>
        <span>说明:
          差价模式下，该门店可在小程序端自定义设置报价比例，可自定义修改用户成交金额，所得利润为回收商家报价减去用户成交金额。</span>
      </div>
      <div>
        <el-radio v-model="profitMode"  label="after">后返利模式</el-radio>
        <span>说明:
          后返利模式下，该门店不可在小程序端自定义设置报价比例（小程序端将屏蔽设置入口），不可自定义修改用户成交金额，所得利润为预先设置的固定返利比例。</span>
      </div>
      <div class="after-style" v-if="profitMode == 'after'">
        <div>
          <span style="font-size: 14px; color: #333333">是否支持降价成交：</span>
          <el-radio-group v-model="afterDepreciateStyle">
            <el-radio label="01">是</el-radio>
            <el-radio label="02">否</el-radio>
          </el-radio-group>
          <span style="font-size: 12px; color: #ff687b; margin-left: 20px">说明：选择是店员确认交易可修改用户成交价，否则不可修改用户成交价</span>
        </div>
        <div>
          <span style="font-size: 14px; color: #333333">设置差价利润弹性比例：</span>
          <el-switch style="margin-left: 20px" v-model="isCutPrice" :disabled="afterDepreciateStyle == '02'"
            active-value="01" inactive-value="02" @click.native.prevent="isCutPriceClick">
          </el-switch>
          <span style="font-size: 12px; color: #ff687b; margin-left: 20px">说明：启用后可设置差价利润弹性比例及弹性上限，未启用则差价利润无上限</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div v-if="source == 'online'">
      <!-- <div class="line"></div> -->
      <div class="mb-10">
        <div class="title-item">
          <img src="@/assets/images/icon_collection.png" style="margin-right: 8px" alt="" />
          <span>用户收款设置</span>
        </div>
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明:
            如选择’可付款给用户‘则在小程序端可点击’用户收款‘（用户收款时直接从门店商户余额划拨），如选择’不可付款给用户‘则在小程序端屏蔽‘用户收款’</span>
        </div>
        <div class="mb-10">
          <span style="font-size: 14px; color: #333333">是否可付款给用户：</span>
          <!-- :disabled="setType == 'true'"   去掉限制 -->
          <el-radio-group v-model="userPayeeMode">
            <el-radio label="01">可付款给用户</el-radio>
            <el-radio label="02">不可付款给用户</el-radio>
          </el-radio-group>
        </div>
        <div v-if="userPayeeMode == '01'" class="mb-10">
          <span style="font-size: 14px; color: #333333">以旧换新是否可付款：</span>
          <el-radio-group v-model="userPayeeModeTrade">
            <el-radio label="01">是</el-radio>
            <el-radio label="02">否</el-radio>
          </el-radio-group>
          <span style="font-size: 14px; color: #ff687b; margin-left: 20px">以旧换新订单确认交易后，机款到创建人账户</span>
        </div>
        <div v-if="userPayeeMode == '01'">
          <span style="font-size: 14px; color: #333333">店员付款是否需要审核：</span>
          <el-radio-group v-model="isStaffPayUserAudit">
            <el-radio label="01">需审核</el-radio>
            <el-radio label="02">无需审核</el-radio>
          </el-radio-group>
          <span style="font-size: 14px; color: #ff687b; margin-left: 20px">由店长、总监、子账号、创建人任一账户审核</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="mb-10">
        <div class="title-item">
          <img src="@/assets/images/ttt.png" style="margin-right: 8px" alt="" />
          <span>退款审核人设置</span>
        </div>
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明:
            员工确认交易后，在小程序进行撤销订单或用户退款时审核人设置。若设置审核人角色，则对应审核人角色及以上人员退款时无需审核。</span>
        </div>
        <div class="mb-10">
          <span style="font-size: 14px; color: #333333">审核人角色：</span>
          <el-radio-group v-model="refundAuditRole">
            <el-radio label="03" @click.native.prevent="refundAuditRoleClick('03')">本店店长审核</el-radio>
            <el-radio label="02" @click.native.prevent="refundAuditRoleClick('02')">本店所属总监审核</el-radio>
          </el-radio-group>
        </div>
        <div class="mb-10">
          <div class="flex flex-jb mb-10">
            <div style="font-size: 14px; color: #333333">
              选择指定审核人（请至少选择一个审核人员）<span style="color:#009966;">指定审核人员仅返回子账号、创建者角色</span>
            </div>
            <el-button size="mini" type="primary" icon="el-icon-plus" @click="addWithdraw('04')">添加</el-button>
          </div>
          <GlobalTable ref="GlobalTable" v-loading="refundAuditListloading" :columns="storePreRebateAuditColumns"
            :data="refundAuditList" :isPagination="false">
            <el-table-column label="角色" slot="staffType" align="center">
              <template slot-scope="{ row }">
                <span>{{
                  row.isMajor
                  ? "创建人"
                  : row.staffType == "01"
                    ? "子账号"
                    : row.staffType == "02"
                      ? "总监"
                      : row.staffType == "03"
                        ? "店长"
                        : "店员"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
              <template slot-scope="{ row }">
                {{ row.isSubscribe ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" slot="operation" align="center">
              <template slot-scope="{ row, $index }">
                <el-button size="mini" type="danger" round
                  @click="handleBtn(row, 'del', 'examineRefund', $index)">移除</el-button>
              </template>
            </el-table-column>
          </GlobalTable>
        </div>
      </div>
      <div class="line"></div>
      <div class="mb-10">
        <div class="title-item">
          <img src="@/assets/images/icon_withdrawal.png" style="margin-right: 8px" alt="" />
          <span>公司账户提现设置</span>
        </div>
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明:
            如选择‘可提现’则需绑定指定人员提现（前端显示提现按钮），如选择‘不可提现’则无需绑定提现人员（前端不显示提现按钮）</span>
        </div>
        <div class="mb-10">
          <span style="font-size: 14px; color: #333333">账户余额是否可提现：</span>
          <!-- :disabled="isaccountWithdrawflag" 20240529去掉提现限制-->
          <el-radio-group v-model="accountWithdraw" >
            <el-radio label="01">可提现</el-radio>
            <el-radio label="02">不可提现</el-radio>
          </el-radio-group>
        </div>
        <div class="mb-10" v-if="accountWithdraw == '01'">
          <span style="font-size: 14px; color: #333333">提现到账方式：</span>
          <el-radio-group v-model="arrivalMethod">
            <el-radio label="01">微信零钱包</el-radio>
            <el-radio label="02">银行卡（门店承担千分之一手续费）</el-radio>
            <el-radio label="03">支付宝</el-radio>
          </el-radio-group>
        </div>
        <div v-if="accountWithdraw == '01'">
          <div class="flex flex-jb mb-10">
            <div style="font-size: 14px; color: #333333">
              绑定提现人员（最多可绑定3个提现人员）
            </div>
            <el-button size="mini" type="primary" icon="el-icon-plus" @click="addWithdraw('01')">添加</el-button>
          </div>
          <GlobalTable ref="GlobalTable" v-loading="withdrawalloading" :columns="withdrawalColumns"
            :data="withdrawalDataList" :isPagination="false">
            <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
              <template slot-scope="{ row }">
                {{ row.isSubscribe ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="微信头像" slot="headImg" align="center">
              <template slot-scope="{ row }">
                <img :src="row.headImg" style="width: 70px;height:70px" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="操作" slot="operation" align="center">
              <template slot-scope="{ row, $index }">
                <el-button size="mini" type="danger" round
                  @click="handleBtn(row, 'del', 'withdrawal', $index)">移除</el-button>
              </template>
            </el-table-column>
          </GlobalTable>
        </div>
      </div>
    </div>
    <div class="line" v-if="source == 'online'"></div>
    <div v-if="profitMode != 'diff'">
      <div class="title-item">
        <img src="@/assets/images/icon_jiage.png" style="margin-right: 8px" alt="" />
        <span>返利设置</span>
      </div>
      <!-- <div class="explain">
        <div class="remarks-title">备注说明:</div>
        <div class="remarks-li">报价区间范围取值判断（如1≤区间＜200）</div>
        <div class="remarks-li flex flex-aa">
          <div>报价除返利：</div>
          <div>
            <div>1.门店返利：门店每单从回收商获得的返利金额=报价金额/（1+返利比例）*返利比例，如报价1000，返利比例合计为10%，则返利金额计算公式为：1000/（1+10%）*10%=90.9；</div>
            <div>2.预留返利：表示员工在申请加价时，公司要预留部分利润，预留部分可申请加价给用户，报价除返利：预留金额 = 报价金额/(1+预留比例)*预留比例；</div>
          </div>
        </div>
        <div class="remarks-li flex flex-aa">
          <div>报价乘返利：</div>
          <div>
            <div>1.门店返利：门店每单从回收商获得的返利金额=报价金额*返利比例，如报价1000，返利比例合计为10%，则返利金额计算公式为1000*10%=100；</div>
            <div>2.预留返利：预留金额=报价金额*预留比例。</div>
          </div>
        </div>
      </div> -->
      <!-- :disabled="setType == 'true'" -->
      <div class="set-title flex">返利计算方式</div>
      <div class="mb-20">
        <el-radio-group v-model="rebateRateMethod" >
          <el-radio label="01">报价金额/(1+返利比例)*返利比例</el-radio>
          <el-radio label="02">报价金额*返利比例</el-radio>
        </el-radio-group>
      </div>
      <div class="line"></div>
      <div class="set-title flex" v-if="source == 'online'">结算方式</div>
      <div class="flex mb-10" v-if="source == 'online'">
        <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
        <span
          class="tip-color">说明：选择‘线上结算’则返利到账门店账户后，商户自主提现即可。选择‘线下结算（返利收货退回）’则返利部分在回收商收货时自动退回（如返利被门店申请使用，则只退回未使用部分）；选择‘线下结算（返利挂账）’则在报价时不预扣返利部分，返利仅做挂账记录。</span>
      </div>
      <div class="mb-20" v-if="source == 'online'">
        <el-radio-group v-model="addPriceSettleStyle">
          <el-radio label="01">线上结算</el-radio>
          <el-radio label="02">线下结算（返利收货退回）</el-radio>
          <el-radio label="03">线下结算（返利挂账）</el-radio>
        </el-radio-group>
      </div>
      <div class="line" v-if="source == 'online'"></div>
      <div class="set-title flex flex-jb">
        <div>
          <span>返利比例&审核</span>
          <span style="font-size:12px;color:#FF687B;margin-left:10px;font-weight: normal;">说明:
            添加个性配置前，请先设置公共配置，添加个性配置时将自动复制公共配置中的参数，个性配置最多添加10个，不同的个性配置中门店不能重复添加</span>
        </div>
        <div v-if="source == 'online'">
          <el-button size="small" type="warning" icon="el-icon-plus" @click="addTemplate('rebate')">添加个性配置</el-button>
        </div>
      </div>
      <el-tabs v-if="source == 'online'" v-model="rebateActiveName" @tab-click="handleClick">
        <el-tab-pane :label="item.templateName" :name="item.templateName" v-for="(item, index) in rebateTemplateList"
          :key="index"></el-tab-pane>
      </el-tabs>
      <div class="set-title flex">通用机型返利配置</div>
      <div class="button_top">
        <div class="flex">
          <div class="faultBtn" :style="rebateActiveName != '公共配置（默认）' ? 'width: 420px' : 'width: 280px'">
            <div :class="rebateTypeTab === '01' ? 'active' : 'none'" @click="rebateBtnClick('01')">
              比例设置
            </div>
            <div :class="rebateTypeTab === '02' ? 'active' : 'none'" @click="rebateBtnClick('02')">
              申请/审核设置
            </div>
            <div v-if="rebateActiveName != '公共配置（默认）'" :class="rebateTypeTab === '05' ? 'active' : 'none'"
              @click="rebateBtnClick('05')">
              关联门店
            </div>
          </div>
          <el-button v-if="rebateActiveName != '公共配置（默认）'" type="text" style="text-decoration: underline;margin-left:16px"
            @click="batchDel('rebateConfig')">删除该配置</el-button>
          <!-- <div
            v-if="source == 'online'"
            :class="rebateTypeTab === '03' ? 'active' : 'none'"
            @click="rebateBtnClick('03')"
          >
            挪用时机配置
          </div>
          <div
            v-if="source == 'online'"
            :class="rebateTypeTab === '04' ? 'active' : 'none'"
            @click="rebateBtnClick('04')"
          >
            结算方式
          </div> -->
        </div>
        <div>
          <el-button size="small" v-if="rebateTypeTab === '01'" type="primary" icon="el-icon-plus"
            @click="addRule('rebate')">添加规则</el-button>
        </div>
      </div>
      <GlobalTable v-if="rebateTypeTab == '01'" ref="GlobalTable" v-loading="rebateloading" :columns="rebateColumns"
        :data="rebateRegionList.slice(
          (rebatePage.pageNum - 1) * rebatePage.pageSize,
          rebatePage.pageNum * rebatePage.pageSize
        )
          " :currentPage="rebatePage.pageNum" :total="rebatePage.total" :pageSize="rebatePage.pageSize"
        @handleCurrentChange="rebateHandleCurrentChange">
        <el-table-column label="报价区间" slot="rank" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
          </template>
        </el-table-column>
        <el-table-column label="门店返利" slot="rebate" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.rebate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="门店返利上限" slot="rebateLimit" align="center">
          <template slot-scope="{ row }">
            <span>￥{{ row.rebateLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预留返利" slot="delayRebate" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.delayRebate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="预留返利上限" slot="delayRebateLimit" align="center">
          <template slot-scope="{ row }">
            <span>￥{{ row.delayRebateLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="差价利润弹性比例" slot="cutPriceScope" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.cutPriceScope }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="差价利润弹性上限" slot="cutPriceScopeLimit" align="center">
          <template slot-scope="{ row }">
            <span>￥{{ row.cutPriceScopeLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :label-width="'200px'" slot="operation" align="center">
          <template slot-scope="{ row, $index }">
            <el-button size="mini" type="primary" round @click="handleBtn(row, 'edit', 'rebate', $index)">编辑</el-button>
            <el-button size="mini" type="danger" round @click="handleBtn(row, 'del', 'rebate', $index)">删除</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <div v-if="rebateTypeTab == '02'">
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明:1. 门店返利如设置为‘不可申请’，则‘预留返利’则默认为不可申请。选择审核角色后，本角色以下的其他角色均需要审核授权（前端显示验证码输入入口）
            2.如使用方式选择‘按可申请比例输入范围内金额’则前端在选择加价时，可输入区间金额进行加价。</span>
        </div>
        <div>
          <div class="set-title-little">门店返利</div>
          <div class="mb-10">
            <span style="font-size: 14px; color: #333333">是否可申请：</span>
            <!-- <el-radio-group v-model="isRebateStore" :disabled="addPriceSettleStyle == '03'">返利挂账不能加价限制 -->
            <el-radio-group v-model="isRebateStore">
              <el-radio label="01">可申请</el-radio>
              <el-radio label="02">不可申请</el-radio>
            </el-radio-group>
            <div style="margin-left: 80px; display: inline-block" v-if="isRebateStore == '01'">
              <span style="font-size: 14px; color: #333333">是否需审核：</span>
              <el-radio-group v-model="isRebateStoreAudit">
                <el-radio label="01">需审核</el-radio>
                <el-radio label="02">无需审核</el-radio>
              </el-radio-group>
            </div>
            <div style="margin-left: 80px; display: inline-block;margin-bottom:10px"
              v-if="isRebateStore == '01' && isRebateStoreAudit == '01'">
              <span style="font-size: 14px; color: #333333">审核人角色：</span>
              <el-radio-group v-model="rebateAuditRole">
                <el-radio label="03" @click.native.prevent="rebateAuditRoleClick('03')">本店店长审核</el-radio>
                <el-radio label="02" @click.native.prevent="rebateAuditRoleClick('02')">本店所属总监审核</el-radio>
              </el-radio-group>
            </div>
            <div style="margin-left: 80px; display: inline-block;" v-if="isRebateStore == '01'">
              <span style="font-size: 14px; color: #333333">未使用加价是否提成：</span>
              <el-radio-group v-model="unUedAddToStaff">
                <el-radio label="01">是</el-radio>
                <el-radio label="02">否</el-radio>
              </el-radio-group>
              <div style="margin-left: 80px; display: inline-block" v-if="unUedAddToStaff == '01'">
                <span style="font-size: 14px; color: #333333">加价提成比例：</span>
                <div style="display: inline-block;width: 140px;">
                  <el-input size="small" placeholder="输入比例" v-model="addToStaffRate"
                    @input="numberCheckData('addToStaffRate')">
                    <template slot="append">%</template>
                  </el-input>
                </div>
                <span class="tip-color">（提成仅支持下单店员）</span>
              </div>
            </div>
          </div>
          <div v-if="isRebateStore == '01' && isRebateStoreAudit == '01'" class="mb-10">
            <div class="flex flex-jb mb-10">
              <div style="font-size: 14px; color: #333333">
                选择指定审核人（请至少选择一个审核人员）<span style="color:#009966;">指定审核人员仅返回子账号、创建者角色</span>
              </div>
              <el-button size="mini" type="primary" icon="el-icon-plus" @click="addWithdraw('03')">添加</el-button>
            </div>
            <GlobalTable ref="GlobalTable" v-loading="storeRebateAuditloading" :columns="storePreRebateAuditColumns"
              :data="storeRebateAuditList" :isPagination="false">
              <el-table-column label="角色" slot="staffType" align="center">
                <template slot-scope="{ row }">
                  <span>{{
                    row.isMajor
                    ? "创建人"
                    : row.staffType == "01"
                      ? "子账号"
                      : row.staffType == "02"
                        ? "总监"
                        : row.staffType == "03"
                          ? "店长"
                          : "店员"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
                <template slot-scope="{ row }">
                  {{ row.isSubscribe ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" slot="operation" align="center">
                <template slot-scope="{ row, $index }">
                  <el-button size="mini" type="danger" round
                    @click="handleBtn(row, 'del', 'examineStore', $index)">移除</el-button>
                </template>
              </el-table-column>
            </GlobalTable>
          </div>
        </div>
        <div class="line"></div>
        <div>
          <div class="set-title-little">预留返利</div>
          <div class="mb-10">
            <span style="font-size: 14px; color: #333333">是否可申请：</span>
            <el-radio-group v-model="isPreRebateStore" :disabled="isRebateStore == '02' || isOpenInsideBiz">
              <el-radio label="01">可申请</el-radio>
              <el-radio label="02">不可申请</el-radio>
            </el-radio-group>
            <div style="margin-left: 80px; display: inline-block" v-if="isPreRebateStore == '01'">
              <span style="font-size: 14px; color: #333333">是否需审核：</span>
              <el-radio-group v-model="isPreRebateStoreAudit">
                <el-radio label="01">需审核</el-radio>
                <el-radio label="02">无需审核</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div v-if="isPreRebateStore == '01' && isPreRebateStoreAudit == '01'" class="mb-10">
            <div class="flex flex-jb mb-10">
              <div style="font-size: 14px; color: #333333">
                选择审核人（请至少选择一个审核人员）<span style="color:#009966;">指定审核人员仅返回子账号、创建者角色</span>
              </div>
              <el-button size="mini" type="primary" icon="el-icon-plus" @click="addWithdraw('02')">添加</el-button>
            </div>
            <GlobalTable ref="GlobalTable" v-loading="storePreRebateAuditloading" :columns="storePreRebateAuditColumns"
              :data="storePreRebateAuditList" :isPagination="false">
              <el-table-column label="角色" slot="staffType" align="center">
                <template slot-scope="{ row }">
                  <span>{{
                    row.isMajor
                    ? "创建人"
                    : row.staffType == "01"
                      ? "子账号"
                      : row.staffType == "02"
                        ? "总监"
                        : row.staffType == "03"
                          ? "店长"
                          : "店员"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
                <template slot-scope="{ row }">
                  {{ row.isSubscribe ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" slot="operation" align="center">
                <template slot-scope="{ row, $index }">
                  <el-button size="mini" type="danger" round
                    @click="handleBtn(row, 'del', 'examine', $index)">移除</el-button>
                </template>
              </el-table-column>
            </GlobalTable>
          </div>
        </div>
        <div class="line"></div>
        <div v-if="isRebateStore == '01' || isPreRebateStore == '01'">
          <div class="set-title-little">门店申请加价使用加价</div>
          <div class="mb-10">
            <el-radio-group v-model="addPriceUseType" :disabled="isOpenInsideBiz">
              <el-radio label="01">按可申请比例直接选择</el-radio>
              <el-radio label="02">按可申请比例输入范围内金额</el-radio>
            </el-radio-group>
            <div class="flex mt-10" style="color: #333333;font-size: 14px" v-if="addPriceUseType == '02' && source == 'online'">
              <span>移动商户专用：</span>
              <el-switch v-model="isCmccAddPrice" active-text="使用加价券">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
      <div v-if="rebateTypeTab == '03'">
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明:
            如选择‘返利挪用时机’为‘确认交易时’，则店员加价仅能在前端确认交易页面进行加价操作。如选择‘返利挪用时机’为确认交易后，则店员在确认交易后申请领取返利部分金额（前端选择到账人员），则【申请/审核设置】中的‘使用方式’自动默认为：‘按可申请比例直接选择’</span>
        </div>
        <div class="mb-10">
          <div class="set-title-little">返利挪用时机</div>
          <el-radio-group v-model="rebateEmbezzleMode" :disabled="setType == 'true'">
            <el-radio label="ack">确认交易时</el-radio>
            <el-radio label="ack_after">确认交易后</el-radio>
          </el-radio-group>
          <div style="margin-left: 80px; display: inline-block" v-if="rebateEmbezzleMode == 'ack_after'">
            <span style="font-size: 14px; color: #333333">可领取返利人员：</span>
            <el-radio-group v-model="rebateEmbezzleStaff" :disabled="setType == 'true'">
              <el-radio label="staff">员工本人</el-radio>
              <el-radio label="manger">本店店长</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div v-if="rebateTypeTab == '04'">
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span
            class="tip-color">说明：选择‘线上结算’则返利到账门店账户后，商户自主提现即可。选择‘线下结算（返利收货退回）’则返利部分在回收商收货时自动退回（如返利被门店申请使用，则只退回未使用部分）；选择‘线下结算（返利挂账）’则在报价时不预扣返利部分，返利仅做挂账记录。</span>
        </div>
        <div class="mb-10">
          <div class="set-title-little">结算方式</div>
          <el-radio-group v-model="addPriceSettleStyle">
            <el-radio label="01">线上结算</el-radio>
            <el-radio label="02">线下结算（返利收货退回）</el-radio>
            <el-radio label="03">线下结算（返利挂账）</el-radio>
          </el-radio-group>
        </div>
      </div>
      <template v-if="rebateTypeTab == '05' && rebateActiveName != '公共配置（默认）'">
        <div class="flex flex-jb">
          <div>
            <el-button type="text" :disabled="isRebateStoreDel" style="text-decoration: underline;"
              @click="batchDel('batchRebate')">批量删除</el-button>
            <span style="margin-left:16px;">共<span style="color:#FF687B;">{{ storeNum }}</span>家门店，已关联<span
                style="color:#FF687B;">{{ rebateStoreList.length }}</span>家</span>
          </div>
          <div>
            <el-button size="small" type="primary" icon="el-icon-plus" @click="addStore('select', '02')">添加门店</el-button>
            <el-button size="small" type="warning" icon="el-icon-upload"
              @click="addStore('import', '02')">批量导入</el-button>
          </div>
        </div>
        <GlobalTable class="mb-10" ref="multipleTable" myKey="rebateStore" :isPagination="false"
          :columns="angPowStoreColumns" :isIndex="false" :data="rebateStoreList" :isSelection="isSelection"
          judgeValue="storeId" @handleSelect="(e) => storeHandleSelect(e, 'rebate')">
          <el-table-column label="区域/总监" width="200" slot="areaName" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.areaName && row.inspectorName">{{ row.areaName }}-{{ row.inspectorName }}</span>
              <span v-if="row.areaName && !row.inspectorName">{{ row.areaName }}</span>
              <span v-if="!row.areaName && row.inspectorName">{{ row.inspectorName }}</span>
              <span v-if="!row.areaName && !row.inspectorName">--</span>
            </template>
          </el-table-column>
          <el-table-column label="门店名称" width="200" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店长" width="200" slot="storeManager" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.storeManager">{{ row.storeManager }}-{{ row.storeManagerPhone }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="门店标签" slot="tagList" align="center">
            <template slot-scope="{ row }">
              <div class="tag-list" v-if="row.tagList && row.tagList.length"><span class="tag-style"
                  v-for="item in row.tagList" :key="item.id">{{ item.tagName }}</span></div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作" slot="operation" align="center">
            <template slot-scope="{ row, $index }">
              <el-button size="mini" type="danger" round
                @click="handleBtn(row, 'del', 'rebateStore', $index)">删除</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </template>
      <MachineTemplateList v-for="(item, index) in machineTemplateList" :key="index" v-bind="item"
        :machineTemplateList="machineTemplateList" :index="index" class="mb-20"
        @change="(d) => machineTemplateListChange(index, d)" @del="machineTemplateDel(index)" />
      <div v-if="source == 'online'" class="mb-20">
        <el-button size="small" type="warning" icon="el-icon-plus"
          @click="addTemplate('machineTemplate')">添加个性配置（指定机型）</el-button>
      </div>
    </div>
    <div class="line" v-if="profitMode != 'diff'"></div>
    <div class="title-item" style="justify-content: space-between">
      <div style="display: flex; align-items: center">
        <img src="@/assets/images/icon_hb.png" style="margin-right: 8px" alt="" />
        <span>红包设置</span>
        <el-switch style="margin-left: 20px" v-model="isAngPow" active-text="启用红包功能">
        </el-switch>
        <div class="ml-20">
          <span style="color: #ff687b; font-size: 14px;font-weight: 500;">说明：红包按报价金额计算，店长下单时红包为店长及店员的红包之和</span>
        </div>
      </div>
    </div>
    <div v-if="isAngPow">
      <!-- <div class="set-title flex" v-if="source == 'online'">到账方式配置</div>
      <div class="flex mb-10" v-if="source == 'online'">
        <img
          src="@/assets/images/ico_ts_red.png"
          style="margin-right: 8px"
          alt=""
        />
        <span class="tip-color"
          >说明:如选择‘到账员工个人账户’则在划拨回收商余额时，自动划拨对应红包金额到员工红包账户。如选择‘统一到账公司账户’则红包金额全部划拨到公司总账户</span
        >
      </div>
      <div class="mb-20" v-if="source == 'online'">
        <div class="mb-10">
          <span style="font-size: 14px; color: #333333">到账配置：</span>
          <el-radio-group
            v-model="apIntoMode"
            :disabled="setType == 'true' && isApIntoMode"
          >
            <el-radio label="per">到账员工个人账户</el-radio>
            <el-radio label="comp">统一到账公司账户</el-radio>
          </el-radio-group>
        </div>
        <div v-if="apIntoMode == 'per'">
          <span style="font-size: 14px; color: #333333">是否需要审核：</span>
          <el-radio-group v-model="isStaffWithdrawalAudit">
            <el-radio label="01">需审核</el-radio>
            <el-radio label="02">无需审核</el-radio>
          </el-radio-group>
          <span style="font-size: 14px; color: #ff687b; margin-left: 20px">单人单次提现大于等于300元，默认由子账号或创建人审核。</span>
        </div>
      </div>
      <div class="line"></div> -->

      <div class="flex flex-jb">
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明: 添加个性配置前，请先设置公共配置，添加个性配置时将自动复制公共配置中的参数，个性配置最多添加10个，不同的个性配置中，门店不能重复添加</span>
        </div>
        <div v-if="source == 'online'">
          <el-button size="small" type="warning" icon="el-icon-plus" @click="addTemplate('red')">添加个性配置</el-button>
        </div>
        <!-- <div v-else>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addRule('red')"
            >添加规则</el-button
          >
        </div> -->
      </div>
      <div class="flex flex-jb" v-if="source == 'online'">
        <el-tabs v-model="angPowActiveName" @tab-click="handleClick" class="flex1">
          <el-tab-pane :label="item.templateName" :name="item.templateName" v-for="(item, index) in angPowTemplateList"
            :key="index"></el-tab-pane>
        </el-tabs>
        <!-- <div v-if="isAngPow">
          <el-button
            v-if="angPowActiveName=='公共配置（默认）'"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addRule('red')"
            >添加规则</el-button
          >
        </div> -->
      </div>
      <div class="button_top" style="margin-bottom: 10px">
        <div class="flex">
          <div class="faultBtn" :style="angPowActiveName != '公共配置（默认）' ? 'width: 420px' : 'width: 280px'">
            <div :class="angPowTypeTab === '01' ? 'active' : 'none'" @click="angPowBtnClick('01')">
              金额设置
            </div>
            <div :class="angPowTypeTab === '03' ? 'active' : 'none'" @click="angPowBtnClick('03')">
              加价设置
            </div>
            <div v-if="angPowActiveName != '公共配置（默认）'" :class="angPowTypeTab === '02' ? 'active' : 'none'"
              @click="angPowBtnClick('02')">
              关联门店
            </div>
          </div>
          <el-button type="text" v-if="angPowActiveName != '公共配置（默认）'" style="text-decoration: underline;margin-left:16px"
            @click="batchDel('redConfig')">删除该配置</el-button>
        </div>
        <div v-if="angPowTypeTab == '01'">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addRule('red')">添加规则</el-button>
        </div>
      </div>
      <template v-if="angPowTypeTab == '01'">
        <GlobalTable ref="GlobalTable" myKey="redPacket" v-loading="redPacketloading" :columns="redPacketColumns" :data="apRegionList.slice(
          (redPacketPage.pageNum - 1) * redPacketPage.pageSize,
          redPacketPage.pageNum * redPacketPage.pageSize
        )
          " :currentPage="redPacketPage.pageNum" :total="redPacketPage.total" :pageSize="redPacketPage.pageSize"
          @handleCurrentChange="redHandleCurrentChange">
          <el-table-column label="报价区间" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店长红包" slot="shopownerReward" align="center">
            <template slot-scope="{ row }">
              <span>{{ rebateType == "02" ? "￥" : "" }}{{ row.shopownerReward
              }}{{ rebateType == "01" ? "%" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店长红包上限" slot="shopownerRewardLimit" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.shopownerRewardLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店员红包" slot="clerkReward" align="center">
            <template slot-scope="{ row }">
              <span>{{ rebateType == "02" ? "￥" : "" }}{{ row.clerkReward
              }}{{ rebateType == "01" ? "%" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店员红包上限" slot="clerkRewardLimit" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.clerkRewardLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :label-width="'200px'" slot="operation" align="center">
            <template slot-scope="{ row, $index }">
              <el-button size="mini" type="primary" round @click="handleBtn(row, 'edit', 'red', $index)">编辑</el-button>
              <el-button size="mini" type="danger" round @click="handleBtn(row, 'del', 'red', $index)">删除</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </template>
      <template v-if="angPowTypeTab == '02' && angPowActiveName != '公共配置（默认）'">
        <div class="flex flex-jb">
          <div>
            <el-button type="text" :disabled="isRedStoreDel" style="text-decoration: underline;"
              @click="batchDel('batchRed')">批量删除</el-button>
            <span style="margin-left:16px;">共<span style="color:#FF687B;">{{ storeNum }}</span>家门店，已关联<span
                style="color:#FF687B;">{{ angPowStoreList.length }}</span>家</span>
          </div>
          <div>
            <el-button size="small" type="primary" icon="el-icon-plus" @click="addStore('select', '01')">添加门店</el-button>
            <el-button size="small" type="warning" icon="el-icon-upload"
              @click="addStore('import', '01')">批量导入</el-button>
          </div>
        </div>
        <GlobalTable ref="multipleTable" myKey="angPowStore" :isPagination="false" :columns="angPowStoreColumns"
          :isIndex="false" :data="angPowStoreList" :isSelection="isSelection" judgeValue="storeId"
          @handleSelect="(e) => storeHandleSelect(e, 'red')">
          <el-table-column label="区域/总监" width="200" slot="areaName" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.areaName && row.inspectorName">{{ row.areaName }}-{{ row.inspectorName }}</span>
              <span v-if="row.areaName && !row.inspectorName">{{ row.areaName }}</span>
              <span v-if="!row.areaName && row.inspectorName">{{ row.inspectorName }}</span>
              <span v-if="!row.areaName && !row.inspectorName">--</span>
            </template>
          </el-table-column>
          <el-table-column label="门店名称" width="200" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店长" width="200" slot="storeManager" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.storeManager">{{ row.storeManager }}-{{ row.storeManagerPhone }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="门店标签" slot="tagList" align="center">
            <template slot-scope="{ row }">
              <div class="tag-list" v-if="row.tagList && row.tagList.length"><span class="tag-style"
                  v-for="item in row.tagList" :key="item.id">{{ item.tagName }}</span></div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作" slot="operation" align="center">
            <template slot-scope="{ row, $index }">
              <el-button size="mini" type="danger" round @click="handleBtn(row, 'del', 'redStore', $index)">删除</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </template>
      <template v-if="angPowTypeTab == '03'">
        <div class="tip-color mb-20">
          <span>说明：若设置红包可加价，店长店员所得红包将均可用于申请加价，店长店员的红包加价部分分配按照分别应得红包占总红包比例进行分配。（举例：若店长红包10元，店员红包30元，若申请红包加价20元，则分别使用店长红包5元，店员红包15元）</span>
        </div>
        <div class="mb-20">
          <span style="font-size: 14px; color: #333333">员工红包是否可用于加价：</span>
          <el-radio-group v-model="isAngPowAddPrice">
            <el-radio label="01">可加价</el-radio>
            <el-radio label="02">不可加价</el-radio>
          </el-radio-group>
        </div>
      </template>
    </div>
    <div class="line"></div>
    <div class="title-item" v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online'"
      style="justify-content: space-between">
      <div style="display: flex; align-items: center">
        <img src="@/assets/images/lrtcsz.png" style="margin-right: 8px" alt="" />
        <span>利润提成设置</span>
        <el-switch style="margin-left: 20px" v-model="isDepreciateShare" active-text="启用差价提成功能" @change="setChange">
        </el-switch>
        <div class="ml-20">
          <span style="color: #ff687b; font-size: 14px;font-weight: 500;">说明：启用差价提成，可按照差价比例来发放店长、店员提成</span>
        </div>
      </div>
      <div v-if="isDepreciateShare">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addRule('profit')">添加规则</el-button>
      </div>
    </div>
    <div class="set-title flex flex-jb mb-20"
      v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online' && isDepreciateShare">
      <div style="display: flex; align-items: center">
        <span>常规压价提成（成交即可获得常规压价提成）</span>
      </div>
    </div>
    <el-table v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online' && isDepreciateShare"
      :data="cutPriceRegionList" border class="mb-20 mt-20">
      <el-table-column label="序号" type="index" width="50">
      </el-table-column>
      <el-table-column prop="rank" align="center" :render-header="addCulateRule">
        <template slot-scope="{ row }">
          <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店长差价提成" prop="shopownerCutPrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.shopownerCutPrice }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="店长差价提成上限" prop="shopownerCutPriceLimit" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.shopownerCutPriceLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店员差价提成" prop="clerkCutPrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.clerkCutPrice }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="店员差价提成上限" prop="clerkCutPriceLimit" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.clerkCutPriceLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" :label-width="'200px'" prop="operation" align="center">
        <template slot-scope="{ row, $index }">
          <el-button size="mini" type="primary" round @click="profitHandleBtn(row, 'edit', $index)">编辑</el-button>
          <el-button size="mini" type="danger" round @click="profitHandleBtn(row, 'del', $index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="set-title flex flex-jb mb-20"
      v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online' && isDepreciateShare">
      <div style="display: flex; align-items: center">
        <span>外部压价提成（若最高价为外部商家，成交后可获得额外提成）</span>
        <!-- <el-switch
            style="margin-left: 20px"
            v-model="isOpenExtraDepreciateShare"
            active-text="启用外部压价提成"
            :disabled="!isOpenOutQuote"
        > -->
        <el-switch style="margin-left: 20px" v-model="isOpenExtraDepreciateShare" active-text="启用外部压价提成">
        </el-switch>
      </div>
    </div>
    <div class="flex flex-jb mt-20"
      v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online' && isOpenExtraDepreciateShare && isDepreciateShare">
      <span style="color: #ff687b; font-size: 14px;font-weight: 500;">说明：外部压价提成，是在与外部回收商成交时，用高出金额为基数进行提成。</span>
      <el-button size="small" type="primary" icon="el-icon-plus" @click="addRuleOut('rebate')">添加规则</el-button>
    </div>
    <el-table border
      v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online' && isDepreciateShare && isOpenExtraDepreciateShare"
      :data="extraCutPriceRegionList" class="mb-20 mt-20">
      <el-table-column label="序号" type="index" width="50">
      </el-table-column>
      <el-table-column prop="rank" align="center" :render-header="addCulateRule1">
        <template slot-scope="{ row }">
          <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店长差价提成" prop="shopownerCutPrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.shopownerCutPrice }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="店长差价提成上限" prop="shopownerCutPriceLimit" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.shopownerCutPriceLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店员差价提成" prop="clerkCutPrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.clerkCutPrice }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="店员差价提成上限" prop="clerkCutPriceLimit" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.clerkCutPriceLimit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" :label-width="'200px'" prop="operation" align="center">
        <template slot-scope="{ row, $index }">
          <el-button size="mini" type="primary" round @click="outCalculateHandleBtn(row, 'edit', $index)">编辑</el-button>
          <el-button size="mini" type="danger" round @click="outCalculateHandleBtn(row, 'del', $index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="line mt-10" v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online'"></div>
    <!-- v-if="(afterDepreciateStyle == '01' || profitMode == 'diff') && source == 'online'" -->
    <div class="title-item" style="justify-content: space-between" v-if="isOnlineEnable">
      <div style="display: flex; align-items: center">
        <img src="@/assets/images/icon_hb.png" style="margin-right: 8px" alt="" />
        <span>线上订单员工分享奖励</span>
        <el-switch style="margin-left: 20px" v-model="isOpenMiniMallShareReward" active-text="启用">
        </el-switch>
        <div class="ml-20">
          <span
            style="color: #ff687b; font-size: 14px;font-weight: 500;">说明：通过店员分享入驻的顾客，若顾客在线上下单且实际到店成交后，从回收单报价挪用部分奖励给分享店员。奖励可跨店发放。</span>
        </div>
      </div>
    </div>
    <div class="mb-10 flex" v-if="isOpenMiniMallShareReward && isOnlineEnable">
      <span style="font-size: 14px; color: #333333">店员分享绑定的顾客每成交一单获得奖励：</span>
      <el-input class="factor-rate" v-model.trim="miniMallShareReward" @input="limitInput($event, 'miniMallShareReward')"
        @blur="shareRewardBlur" size="mini"></el-input>
      <span style="margin-left:10px;">元</span>
    </div>
    <div class="line mt-10" v-if="isOnlineEnable" />
    <!-- 线上模式才显示员工账户设置 -->
    <div class="mb-10" v-if="source == 'online'">
      <div class="title-item">
        <img src="@/assets/images/ygzhsz.png" style="margin-right: 8px" alt="" />
        <span>员工账户设置</span>
      </div>
      <div class="set-title flex">到账设置</div>
      <div class="flex mb-10">
        <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
        <span class="tip-color">说明:如选择‘到账员工个人账户’则在划拨回收商余额时，自动划拨对应红包金额到员工红包账户。如选择‘统一到账公司账户’则红包金额全部划拨到公司总账户</span>
      </div>
      <div class="mb-20">
        <div class="mb-10">
          <span style="font-size: 14px; color: #333333">到账配置：</span>
          <el-radio-group v-model="apIntoMode">
            <el-radio label="per">到账员工个人账户</el-radio>
            <el-radio label="comp">统一到账公司账户</el-radio>
          </el-radio-group>
        </div>
        <div v-if="apIntoMode == 'per'" class="mb-10">
          <span style="font-size: 14px; color: #333333">提现到账方式：</span>
          <el-radio-group v-model="rewardWithdrawChannel">
            <el-radio label="wechat">提现到微信</el-radio>
            <el-radio label="alipay">提现到支付宝</el-radio>
          </el-radio-group>
          <!--          <span style="font-size: 14px; color: #ff687b; margin-left: 20px">单人单次提现大于等于300元，默认由子账号或创建人审核。</span>-->
        </div>
        <div v-if="apIntoMode == 'per'">
          <span style="font-size: 14px; color: #333333">是否需要审核：</span>
          <el-radio-group v-model="isStaffWithdrawalAudit">
            <el-radio label="01">需审核</el-radio>
            <el-radio label="02">无需审核</el-radio>
          </el-radio-group>
          <span style="font-size: 14px; color: #ff687b; margin-left: 20px">单人单次提现大于等于300元，默认由子账号或创建人审核。</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="set-title flex" v-if="apIntoMode == 'per'">到账时机</div>
      <div class="mb-20" v-if="apIntoMode == 'per'">
        <el-radio-group v-model="rewardLateArrivalType">
          <el-radio label="01">确认交易即到账</el-radio>
          <el-radio label="02">上传成交凭证到账</el-radio>
          <el-radio label="03" :disabled="true">完成发货到账</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="title-item" style="justify-content: space-between">
      <div style="display: flex; align-items: center">
        <img src="@/assets/images/icon_hb.png" style="margin-right: 8px" alt="" />
        <span>管理人员提成</span>
        <el-switch style="margin-left: 20px" v-model="isManagerReward" active-text="启用管理人员提成">
        </el-switch>
        <div class="ml-20">
          <span style="color: #ff687b; font-size: 14px;font-weight: 500;">说明: 按报价金额设置提成比例或固定提成金额</span>
        </div>
      </div>
    </div>
    <div class="mb-10 dis_center_space_between" v-if="isManagerReward">
      <div class="faultBtn" style="width: 420px">
        <div :class="ManagementTypeTab === '01' ? 'active' : 'none'" @click="ManageBtnClick('01')">
          发放提成人员
        </div>
        <div :class="ManagementTypeTab === '02' ? 'active' : 'none'" @click="ManageBtnClick('02')">
          提成规则
        </div>
        <div :class="ManagementTypeTab === '03' ? 'active' : 'none'" @click="ManageBtnClick('03')">
          到账方式
        </div>
      </div>
      <div>
        <el-button v-if="ManagementTypeTab === '02'" size="small" type="primary" icon="el-icon-plus"
          @click="addRoyaltyRule('add')">添加规则</el-button>
      </div>
    </div>
    <div>
      <div class="polpleSetstyle" v-if="isManagerReward">
        <div class="mb-10 dis_center_space_between" v-if="ManagementTypeTab === '01'">
          <el-checkbox-group v-model="Commissionofficer" @change="pelplochange">
            <el-checkbox label="1">发放提成给总监</el-checkbox>
            <el-checkbox label="2">发放提成给指定人员</el-checkbox>
          </el-checkbox-group>
          <el-button v-if="Commissionofficer.includes('2')" size="small" type="primary" icon="el-icon-plus"
            @click="addassign">添加指定人员</el-button>
        </div>
        <GlobalTable v-if="Commissionofficer.includes('2') && ManagementTypeTab === '01'" ref="GlobalTable"
          v-loading="refundAuditListloading" :columns="CommissionColumns" :data="RoyaltyAuditList" :isPagination="false">
          <el-table-column label="角色" slot="staffType" align="center">
            <template slot-scope="{ row }">
              <span>{{
                row.isMajor
                ? "创建人"
                : row.staffType == "01"
                  ? "子账号"
                  : row.staffType == "02"
                    ? "总监"
                    : row.staffType == "03"
                      ? "店长"
                      : "店员"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" slot="staffMobile" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.staffMobile || row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" slot="operation" align="center">
            <template slot-scope="{ row}">
              <el-button size="mini" type="danger" round @click="detaleAction(row)">移除</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <div class="polpleSetstyle" v-if="ManagementTypeTab === '02'">
        <el-table border :data="RoyaltyRegionList" class="mb-20 mt-20">
          <el-table-column label="序号" type="index" width="50" align="center">
          </el-table-column>
          <el-table-column prop="rank" label="报价区间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建人子账号提成" prop="founderReward" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.rebateType=='02'">￥{{ row.founderReward }}</span>
              <span v-else>{{ row.founderReward }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="创建人子账号提成上限" prop="founderRewardLimit" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.founderRewardLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="总监提成" prop="inspectorReward" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.rebateType=='02'">￥{{ row.inspectorReward }}</span>
              <span v-else>{{ row.inspectorReward }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="总监提成上限" prop="inspectorRewardLimit" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.inspectorRewardLimit }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :label-width="'200px'" prop="operation" align="center">
            <template slot-scope="{ row, $index }">
              <el-button size="mini" type="primary" round @click="RoyaltyHandleBtn(row, 'edit', $index)">编辑</el-button>
              <el-button size="mini" type="danger" round @click="RoyaltyHandleBtn(row, 'del', $index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="polpleSetstyle" v-if="ManagementTypeTab === '03'">
        <div class="flex mb-10">
          <img src="@/assets/images/ico_ts_red.png" style="margin-right: 8px" alt="" />
          <span class="tip-color">说明: 如选择到账管理人员个人账户，管理人员可通过小程序自行提现，如选择到账公司账户，需由公司提现后，线下发放给管理人员</span>
        </div>
        <div class="mb-20">
          <div class="mb-10">
            <span style="font-size: 14px; color: #333333">到账配置：</span>
            <el-radio-group v-model="managerIntoMode">
              <el-radio label="per">到账员工个人账户</el-radio>
              <el-radio label="comp">统一到账公司账户</el-radio>
            </el-radio-group>
          </div>
          <div class="mb-10">
            <span style="font-size: 14px; color: #333333">提现到账方式：</span>
            <el-radio-group v-model="managerRewardWithdrawChannel">
              <el-radio label="wechat">提现到微信</el-radio>
              <el-radio label="alipay">提现到支付宝</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <el-button size="small" @click="goBack">返回</el-button>
      <el-button size="small" type="primary" @click="confirmBtn">确认</el-button>
    </div>
    <!-- 指定人员选择 -->
    <el-dialog class="staff-dialog" title="指定提成人员" :visible.sync="RoyaltyDialogVisible" :close-on-click-modal="false"
      width="750px" @closed="staffDialogClosed">
      <div style="margin-bottom: 20px">
        <span class="tip-color">说明: 管理人员提成指定人员仅返回创建者及子账号角色的有效账号数据</span>
      </div>
      <GlobalTable ref="multipleTable" v-loading="staffListLoading" :currentPage="staffPage.pageNum"
        :total="staffPage.total" :isIndex="false" judgeValue="staffId"
        @handleCurrentChange="administrationstaffListChange" :columns="RoyaltystaffColumns" :maxHeight="500" :isPagination="false"
        :data="RoyaltystaffListData" :isSelection="true" @handleSelect="RoyaltystaffSelect">
        <el-table-column label="角色" slot="staffType" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.isMajor
              ? "创建人"
              : row.staffType == "01"
                ? "子账号"
                : row.staffType == "02"
                  ? "总监"
                  : row.staffType == "03"
                    ? "店长"
                    : "店员"
            }}</span>
          </template>
        </el-table-column>

      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RoyaltyDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="adRoyaltySuccess('01')">确定添加</el-button>
      </span>
    </el-dialog>
    <el-dialog title="移除确认" :visible.sync="plopoeDelVisible" :close-on-click-modal="false" width="380px">
      <div class="del-title">
        <div class="sure_tip">确认要移除该获得提成人员吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="plopoeDelVisible = false">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="SuccessactionDel">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="删除确认" :visible.sync="informationDelVisible" :close-on-click-modal="false" width="380px">
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="informationDelVisible = false">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="informationSuccessDel">确定</el-button>
      </span>
    </el-dialog>
    <!-- 管理人员提成规则 -->
    <el-dialog :title="RoyaltyRuleTitle" :visible.sync="RoyaltyVisible" :close-on-click-modal="false" width="650px"
      @closed="RoyaltyClosed">
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明: 添加前请向门店商户进行确认核实，取得授权后才进行设置数据</span>
        </div>
        <el-form :model="RoyaltyRuleForm" :rules="RoyaltyRules" ref="RoyaltyRuleForm" label-width="170px"
          class="demo-ruleForm">
          <el-form-item label="报价区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input v-model.trim="RoyaltyRuleForm.lower" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input v-model.trim="RoyaltyRuleForm.upper" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="返利方式" prop="rebateType">
            <el-radio-group :disabled="royaltyrebateType != ''" v-model="RoyaltyRuleForm.rebateType">
              <el-radio label="01">按比例返回</el-radio>
              <el-radio label="02">按固定金额返回</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="RoyaltyRuleForm.rebateType == '01'||RoyaltyRuleForm.rebateType == ''">
          <el-form-item label="创建人子账号提成比例:" prop="founderReward">
            <el-input placeholder="输入创建人子账号提成比例" v-model="RoyaltyRuleForm.founderReward"
              @input="RoyaltyNumberCheck('founderReward')" @blur="RoyaltyNumberFn('founderReward')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="创建人子账号提成上限:" prop="founderRewardLimit">
            <el-input v-model.trim="RoyaltyRuleForm.founderRewardLimit" oninput="this.value=value.replace(/[^0-9]/g,'')"
              placeholder="输入创建人子账号提成上限" maxlength="5" @input="() => {
                if (RoyaltyRuleForm.founderReward === 0) {
                  RoyaltyRuleForm.founderRewardLimit = 0
                }
                if (RoyaltyRuleForm.founderRewardLimit) {
                  RoyaltyRuleForm.founderRewardLimit = Number(RoyaltyRuleForm.founderRewardLimit)
                }
              }"></el-input>
          </el-form-item>
          <el-form-item label="总监提成比例:" prop="inspectorReward">
            <el-input placeholder="输入总监提成比例" v-model="RoyaltyRuleForm.inspectorReward"
              @input="RoyaltyNumberCheck('inspectorReward')" @blur="RoyaltyNumberFn('inspectorReward')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="总监提成上限:" prop="inspectorRewardLimit">
            <el-input v-model.trim="RoyaltyRuleForm.inspectorRewardLimit" oninput="this.value=value.replace(/[^0-9]/g,'')"
              placeholder="输入总监提成上限金额" maxlength="5" @input="() => {
                if (RoyaltyRuleForm.inspectorReward === 0) {
                  RoyaltyRuleForm.inspectorRewardLimit = 0
                }
                if (RoyaltyRuleForm.inspectorRewardLimit) {
                  RoyaltyRuleForm.inspectorRewardLimit = Number(RoyaltyRuleForm.inspectorRewardLimit)
                }
              }"></el-input>
          </el-form-item>
        </div>
          <div v-if="RoyaltyRuleForm.rebateType == '02'">
            <el-form-item label="创建人子账号提成:" prop="founderReward">
              <el-input size="small" v-model.trim="RoyaltyRuleForm.founderReward"
                oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入创建人子账号提成金额" @input="() => {
                  if (RoyaltyRuleForm.founderReward) {
                    RoyaltyRuleForm.founderReward = Number(RoyaltyRuleForm.founderReward)
                  }
                }"></el-input>
            </el-form-item>
            <el-form-item label="总监提成:" prop="inspectorReward" >
              <el-input size="small" v-model.trim="RoyaltyRuleForm.inspectorReward" oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="输入总监提成金额" @input="() => {
                  if (RoyaltyRuleForm.inspectorReward) {
                    RoyaltyRuleForm.inspectorReward = Number(RoyaltyRuleForm.inspectorReward)
                  }
                }"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RoyaltyVisible = false">取消</el-button>
        <el-button type="primary" @click.native="RoyaltyHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <DialogTbale :show="storeVisible" :loading="storeLoading" title="添加门店" :columns="storeDialogTableColumns"
      :data="storeDialogDataList" :currentPage="storeDialogPage.pageNum" :total="storeDialogPage.total"
      :pageSize="storeDialogPage.pageSize" orderPaginaPadd="10px" :width="900" :isIndex="true" :isSelection="isSelection"
      @onClose="storeDialogClose" @handleCurrentChange="storeHandleCurrentChangeDialog"
      @handleSelect="addStoreHandleSelect">
      <div style="font-size:12px;color:#FF8080;margin-bottom: 16px">说明: 已在其他个性配置中添加过的门店，无法重复添加</div>
      <div class="flex add-store-form" style="margin-bottom: 20px">
        <div class="flex item">
          <span style="width: 100px">门店名称：</span>
          <el-input v-model="storeDialogForm.storeName" size="small" placeholder="请输入门店名称" clearable></el-input>
        </div>
        <div class="flex item">
          <span style="width: 100px">门店标签：</span>
          <el-select v-model="storeDialogForm.tagIds" placeholder="请选择门店标签" size="small" multiple collapse-tags filterable
            clearable>
            <el-option v-for="item in storeTagList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="flex item">
          <span style="width: 100px">区域/总监：</span>
          <el-select v-model="storeDialogForm.inspectorIds" placeholder="请选择区域" multiple collapse-tags size="small"
            filterable clearable>
            <el-option v-for="item in inspectorAreaList" :key="item.staffId" :label="item.name"
              :value="item.staffId"></el-option>
          </el-select>
        </div>
        <el-button type="primary" style="margin-left: 10px" size="small" @click="searchStore">
          查询
        </el-button>
      </div>
      <el-table-column label="区域/总监" width="200" slot="areaName" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.areaName && row.inspectorName">{{ row.areaName }}-{{ row.inspectorName }}</span>
          <span v-if="row.areaName && !row.inspectorName">{{ row.areaName }}</span>
          <span v-if="!row.areaName && row.inspectorName">{{ row.inspectorName }}</span>
          <span v-if="!row.areaName && !row.inspectorName">--</span>
        </template>
      </el-table-column>
      <el-table-column label="门店名称" width="200" slot="storeName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店长" width="200" slot="storeManager" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeManager }}-{{ row.storeManagerPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店标签" slot="tagList" align="center">
        <template slot-scope="{ row }">
          <div class="tag-list" v-if="row.tagList && row.tagList.length"><span class="tag-style"
              v-for="item in row.tagList" :key="item.id">{{ item.tagName }}</span></div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <template slot="tableFooter">
        <div slot="footer" class="store-dialog-footer">
          <el-button size="small" @click.native="storeDialogClose">取消</el-button>
          <el-button type="primary" size="small" @click.native="addStoreSure">确定添加</el-button>
        </div>
      </template>
    </DialogTbale>
    <!-- 常规压价 -->
    <el-dialog :title="calculateTitle" :visible.sync="calculateNormalShow" :close-on-click-modal="false" width="850px">
      <div v-if="typeCalculate == 'normal'">
        <span>门店显示价为报价扣除返利红包的金额</span>
        <div class="normalShow mb-20 mt-20" style="background-color: #e4ecfd;">
          <div class="flex flex-jb">
            <span style="font-weight: 700;color: black;">与平台回收商成交时：</span> <span
              style="font-weight: 700;color: #0981ff;">常规压价金额=成交回收商报价门店显示价-顾客实际成交价 (不含换新补贴)</span>
          </div>
          <div style="font-size:12px;color:#666">例如: 回收商(非外部)门店成交价1200，顾客成交价800，则常规压价=1200-200=400元</div>
        </div>
        <div class="normalShow mb-20">
          <div class="flex flex-jb">
            <span style="font-weight: 700;color: black;">与外部回收商成交，未开通外部压价提成时：</span> <span
              style="font-weight: 700;color: #f76654;">常规压价金额=外部成交回收商报价的门店显示价-顾客实际成交价 (不含换新补贴)</span>
          </div>
          <div style="font-size:12px;color:#666">例如:外部回收商最高门店成交价1200，平台回收商最高门店成交价1000，顾客成交价800，则常规压价=1200-800=400元</div>
        </div>
        <div class="normalShow mb-20">
          <div class="flex flex-jb">
            <span style="font-weight: 700;color: black;">与外部回收商成交时，已开通外部压价提成时：</span> <span
              style="font-weight: 700;color: #f76654;">常规压价金额=平台最高回收商报价的门店显示价-顾客实际成交价(不含换新补贴)</span>
          </div>
          <div style="font-size:12px;color:#666">例如:外部回收商最高门店成交价1200，平台回收商最高门店成交价1000，顾客成交价800，则常规压价=1000-800=200元</div>
        </div>
      </div>
      <div v-else>
        <span>例如:外部回收商门店显示价=1500(门店成交价为报价扣除返利红包的金额)</span>
        <div class="normalShow mb-20  mt-20" style="background-color: #e4ecfd;">
          <div class="flex flex-jb">
            <span style="font-weight: 700;color: black;">顾客成交价小于平台最高回收商门店显示价时：</span> <span
              style="font-weight: 700;color: #0981ff;">外部压价金额=外部回收商报价的门店显示价-平台最高报价的门店显示价</span>
          </div>
          <div style="font-size:12px;color:#666">如顾客成交价800，平台最高回收商报价的门店显示价为1000，则外部压价金额1500-1000=500</div>
        </div>
        <div class="normalShow mb-20">
          <div class="flex flex-jb">
            <span style="font-weight: 700;color: black;">顾客成交价≥平台最高回收商门店显示价时：</span> <span
              style="font-weight: 700;color: #f76654;">外部压价金额=外部回收商报价的门店显示价-顾客成交价</span>
          </div>
          <div style="font-size:12px;color:#666">如顾客成交价1200，平台最高回收商报价的门店显示价为1000时，则外部高出金额1500-1200=300</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="calculateNormalShow = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="dialogRuleTitle" :visible.sync="dialogRuleVisible" :close-on-click-modal="false" width="550px"
      @closed="ruleDialogClosed">
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span v-if="configType == 'red'">说明:
            添加前请同门店商户进行确认核实，取得授权后才进行设置数据</span>
          <span v-else>说明：门店、预留返利和店员差价返利之和不可高于弹性返利</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
          <div class="set-title" v-if="configType == 'rebate'">
            <span style="color: #0981FF; font-weight: 500;font-size:16px;line-height: 16px;">常规返利设置</span>
          </div>
          <el-form-item label="报价区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input size="small" v-model.trim="ruleForm.lower" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input size="small" v-model.trim="ruleForm.upper" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <div v-if="configType == 'rebate'">
            <el-form-item label="返利比例:" prop="rebate">
              <el-input size="small" placeholder="输入返利比例" v-model="ruleForm.rebate" @input="numberCheck('rebate')"
                @blur="numberFn('rebate')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="返利预留比例:" prop="delayRebate">
              <el-input placeholder="输入返利预留比例" size="small" v-model="ruleForm.delayRebate"
                @input="numberCheck('delayRebate')" @blur="numberFn('delayRebate')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>

            <el-form-item label="返利上限:" required>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item prop="rebateLimit">
                    <el-input size="small" v-model.trim="ruleForm.rebateLimit"
                      oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入门店返利上限金额" @input="() => {
                        if (ruleForm.rebate === 0) {
                          ruleForm.rebateLimit = 0
                        }
                        if (ruleForm.rebateLimit) {
                          ruleForm.rebateLimit = Number(ruleForm.rebateLimit)
                        }
                      }"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item prop="delayRebateLimit">
                    <el-input size="small" v-model.trim="ruleForm.delayRebateLimit"
                      oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入预留返利上限金额" maxlength="5" @input="() => {
                        if (ruleForm.delayRebate === 0) {
                          ruleForm.delayRebateLimit = 0
                        }
                        if (ruleForm.delayRebateLimit) {
                          ruleForm.delayRebateLimit = Number(ruleForm.delayRebateLimit)
                        }
                      }"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <div class="set-title flex">
              <span style="width:170px;color: #0981FF; font-weight: 500;font-size:16px;line-height: 16px;">差价返利弹性设置</span>
              <span
                style="color: #ff8080;font-weight: 500;font-size:14px;line-height: 14px;">设置差价返利弹性比例后，店员差价不可超过弹性比例或弹性限额</span>
            </div>
            <el-form-item label="弹性比例:" prop="cutPriceScope">
              <el-input placeholder="输入弹性比例" size="small" v-model="ruleForm.cutPriceScope"
                @input="numberCheck('cutPriceScope')" @blur="numberFn('cutPriceScope')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="弹性上限:" prop="cutPriceScopeLimit">
              <el-input size="small" v-model.trim="ruleForm.cutPriceScopeLimit"
                oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入弹性上限金额" @input="() => {
                  if (ruleForm.cutPriceScope === 0) {
                    ruleForm.cutPriceScopeLimit = 0
                  }
                  if (ruleForm.cutPriceScopeLimit) {
                    ruleForm.cutPriceScopeLimit = Number(ruleForm.cutPriceScopeLimit)
                  }
                }"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="返利方式" prop="rebateType" v-if="configType == 'red'">
            <el-radio-group :disabled="rebateType != ''" v-model="ruleForm.rebateType">
              <el-radio label="01">按比例返回</el-radio>
              <el-radio label="02">按固定金额返回</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="(ruleForm.rebateType == '01' || ruleForm.rebateType == '') &&
            configType == 'red'
            ">
            <el-form-item label="店长返利:" prop="shopownerReward">
              <el-input size="small" placeholder="输入店长返利比例" v-model="ruleForm.shopownerReward"
                @input="numberCheck('shopownerReward')" @blur="numberFn('shopownerReward')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="店员返利:" prop="clerkReward">
              <el-input size="small" placeholder="输入店员返利比例" v-model="ruleForm.clerkReward"
                @input="numberCheck('clerkReward')" @blur="numberFn('clerkReward')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红包上限:" required>
              <el-row type="flex" justify="space-between">
                <el-col :span="11">
                  <el-form-item prop="shopownerRewardLimit">
                    <el-input size="small" v-model.trim="ruleForm.shopownerRewardLimit"
                      oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店长红包上限金额" maxlength="5" @input="() => {
                        if (ruleForm.shopownerReward === 0) {
                          ruleForm.shopownerRewardLimit = 0
                        }
                        if (ruleForm.shopownerRewardLimit) {
                          ruleForm.shopownerRewardLimit = Number(ruleForm.shopownerRewardLimit)
                        }
                      }"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item prop="clerkRewardLimit">
                    <el-input size="small" v-model.trim="ruleForm.clerkRewardLimit"
                      oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店员红包上限金额" maxlength="5" @input="() => {
                        if (ruleForm.clerkReward === 0) {
                          ruleForm.clerkRewardLimit = 0
                        }
                        if (ruleForm.clerkRewardLimit) {
                          ruleForm.clerkRewardLimit = Number(ruleForm.clerkRewardLimit)
                        }
                      }"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
          <div v-if="ruleForm.rebateType == '02'">
            <el-form-item label="店长红包:" prop="shopownerReward" v-if="configType == 'red'">
              <el-input size="small" v-model.trim="ruleForm.shopownerReward"
                oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店长红包发放金额" @input="() => {
                  if (ruleForm.shopownerReward) {
                    ruleForm.shopownerReward = Number(ruleForm.shopownerReward)
                  }
                }"></el-input>
            </el-form-item>
            <el-form-item label="店员红包:" prop="clerkReward" v-if="configType == 'red'">
              <el-input size="small" v-model.trim="ruleForm.clerkReward" oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="输入店员红包发放金额" @input="() => {
                  if (ruleForm.clerkReward) {
                    ruleForm.clerkReward = Number(ruleForm.clerkReward)
                  }
                }"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClose">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="staff-dialog" :title="staffTitle" :visible.sync="staffDialogVisible" :close-on-click-modal="false"
      width="750px" @closed="staffDialogClosed">
      <div style="margin-bottom: 20px">
        <span class="tip-color">{{ staffAddTip }}</span>
      </div>
      <GlobalTable ref="multipleTable" v-if="staffTitle == '添加审核人'" v-loading="staffListLoading"
        :columns="staffListColumns" :currentPage="staffPage.pageNum" :total="staffPage.total" :isIndex="false"
        :data="staffListData" :isSelection="isSelection" judgeValue="staffId" @handleSelect="handleSelect"
        @handleCurrentChange="staffListChange">
        <el-table-column label="角色" slot="staffType" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.isMajor
              ? "创建人"
              : row.staffType == "01"
                ? "子账号"
                : row.staffType == "02"
                  ? "总监"
                  : row.staffType == "03"
                    ? "店长"
                    : "店员"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
          <template slot-scope="{ row }">
            {{ row.isSubscribe ? "是" : "否" }}
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addClosed">取消</el-button>
        <el-button type="primary" @click.native="addHandleSuccess('01')">确定添加</el-button>
      </span>
    </el-dialog>
    <el-dialog class="staff-dialog" :title="staffTitle" :visible.sync="staffDialogVisible2" :close-on-click-modal="false"
      width="750px" @closed="staffDialogClosed">
      <div style="margin-bottom: 20px">
        <span class="tip-color">{{ staffAddTip }}</span>
      </div>
      <GlobalTable ref="multipleTable" v-if="staffTitle == '添加提现人'" v-loading="staffListLoading"
        :columns="staffListWithdrawalColumns" :currentPage="staffPage.pageNum" :total="staffPage.total" :isIndex="false"
        :data="staffListData" :isSelection="isSelection" judgeValue="staffId" @handleSelect="handleSelect"
        @handleCurrentChange="staffListChange">
        <!-- <el-table-column label="选择" slot="radioSelect" align="center" width="60">
          <template slot-scope="{row}">
            <el-radio v-model="currentRowId" size="medium" :label="row.staffId" @change="changeRedio($event,row)"></el-radio>
          </template>
        </el-table-column> -->
        <el-table-column label="角色" slot="staffType" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.isMajor
              ? "创建人"
              : row.staffType == "01"
                ? "子账号"
                : row.staffType == "02"
                  ? "总监"
                  : row.staffType == "03"
                    ? "店长"
                    : "店员"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
          <template slot-scope="{ row }">
            {{ row.isSubscribe ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="微信头像" slot="headImg" align="center">
          <template slot-scope="{ row }">
            <img :src="row.headImg" style="width: 70px;height:70px" alt="" />
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addClosed">取消</el-button>
        <el-button type="primary" @click.native="addHandleSuccess('02')">确定添加</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="delDialogTitle" :visible.sync="dialogDelVisible" :close-on-click-modal="false" width="380px"
      @closed="delDialogClosed">
      <div class="del-title">
        <!-- <div>说明: 删除前请同门店商户进行确认核实，取得授权后才进行删除数据</div> -->
        <div class="sure_tip" v-if="tableType == 'examine'">
          {{
            storePreRebateAuditList.length == 1
            ? "你已启用审核模式，请至少保留一个审核人员或关闭审核"
            : "确认要移除该审核人员码？"
          }}
        </div>
        <div class="sure_tip" v-else-if="tableType == 'redConfig'">确认要删除{{ angPowActiveName }}的全部设置数据吗？</div>
        <div class="sure_tip" v-else-if="tableType == 'rebateConfig'">确认要删除{{ rebateActiveName }}的全部设置数据吗？</div>
        <div class="sure_tip" v-else>确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="tableType == 'examine' && storePreRebateAuditList.length == 1">
          <el-button type="primary" @click.native="handleClose">知道了</el-button>
        </div>
        <div v-else>
          <el-button @click.native="handleClose">取消</el-button>
          <el-button type="primary" :loading="butLoading" @click.native="handleSuccessDel">确定</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog class="acc_dialog" title="安全验证" :visible.sync="commandDisableShow" :close-on-click-modal="false"
      width="500px" @closed="commandDialogClosed">
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="commandDisableSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="addTemplateTitle" :visible.sync="addTemplateShow" :close-on-click-modal="false" width="430px"
      @closed="addTemplateClosed">
      <div v-if="addTemplateType == 'machineTemplate'" style="color:#FF687B;font-size:12px;margin-bottom: 20px;">
        说明：添加个性配置将自动复制通用机型配置的返利参数，添加前请先设置通用机型返利（可自定义名称，限8字内）</div>
      <div v-else style="color:#FF687B;font-size:12px;margin-bottom: 20px;">说明:
        添加个性配置将自动复制公共配置的返利参数，添加前请先设置公共配置参数（可自定义名称，限8字内）</div>
      <div>
        <el-input v-model="inputTemplateName" placeholder="请输入个性配置名称" maxlength="8"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addTemplateClosed">取消</el-button>
        <el-button type="primary" @click.native="addTemplateSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量导入门店" :visible.sync="importDialogVisible" :close-on-click-modal="false" width="500px"
      @closed="importDialogClosed">
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="marginRight:12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content">请上传.xls或.xlsx格式的excel文件</span>
        </div>
        <div class="step2-content">
          <el-upload ref="upload" :limit="1" :auto-upload="false" :http-request="uploadFile" :headers="headers"
            :before-upload="onUpload" accept='.xls,.xlsx' action="customize">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="importDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="importSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogProfitRuleTitle" :visible.sync="dialogProfitRuleVisible" :close-on-click-modal="false"
      width="550px" @closed="profitRuleDialogClosed">
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明:
            添加前请同门店商户进行确认核实，取得授权后才进行设置数据</span>
        </div>
        <el-form :model="profitRuleForm" :rules="profitRules" ref="profitRuleForm" label-width="140px"
          class="demo-ruleForm">
          <el-form-item label="差价利润区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input v-model.trim="profitRuleForm.lower" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input v-model.trim="profitRuleForm.upper" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="店长差价提成:" prop="shopownerCutPrice">
            <el-input placeholder="输入店长差价提成比例" v-model="profitRuleForm.shopownerCutPrice"
              @input="profitNumberCheck('shopownerCutPrice')" @blur="profitNumberFn('shopownerCutPrice')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="店长差价提成上限:" prop="shopownerCutPriceLimit">
            <el-input v-model.trim="profitRuleForm.shopownerCutPriceLimit"
              oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店长差价提成上限金额" maxlength="5" @input="() => {
                if (profitRuleForm.shopownerCutPrice === 0) {
                  profitRuleForm.shopownerCutPriceLimit = 0
                }
                if (profitRuleForm.shopownerCutPriceLimit) {
                  profitRuleForm.shopownerCutPriceLimit = Number(profitRuleForm.shopownerCutPriceLimit)
                }
              }"></el-input>
          </el-form-item>
          <el-form-item label="店员差价提成:" prop="clerkCutPrice">
            <el-input placeholder="输入店员差价提成比例" v-model="profitRuleForm.clerkCutPrice"
              @input="profitNumberCheck('clerkCutPrice')" @blur="profitNumberFn('clerkCutPrice')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="店员差价提成上限:" prop="clerkCutPriceLimit">
            <el-input v-model.trim="profitRuleForm.clerkCutPriceLimit" oninput="this.value=value.replace(/[^0-9]/g,'')"
              placeholder="输入店员差价提成上限金额" maxlength="5" @input="() => {
                if (profitRuleForm.clerkCutPrice === 0) {
                  profitRuleForm.clerkCutPriceLimit = 0
                }
                if (profitRuleForm.clerkCutPriceLimit) {
                  profitRuleForm.clerkCutPriceLimit = Number(profitRuleForm.clerkCutPriceLimit)
                }
              }"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogProfitRuleVisible = false">取消</el-button>
        <el-button type="primary" @click.native="profitHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 外部压价设置 -->
    <el-dialog :title="dialogCalculateOutTitle" :visible.sync="calculateOutRuleShow" :close-on-click-modal="false"
      width="550px" @closed="calculateOutRuleDialogClosed">
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明:
            添加前请同门店商户进行确认核实，取得授权后才进行设置数据</span>
        </div>
        <el-form :model="calculateOutRuleForm" :rules="calculateOutRules" ref="calculateOutRuleForm" label-width="140px"
          class="demo-ruleForm">
          <el-form-item label="高出金额区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input v-model.trim="calculateOutRuleForm.lower" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input v-model.trim="calculateOutRuleForm.upper" oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="店长差价提成:" prop="shopownerCutPrice">
            <el-input placeholder="输入店长差价提成比例" v-model="calculateOutRuleForm.shopownerCutPrice"
              @input="profitNumberCheck1('shopownerCutPrice')" @blur="profitNumberFn1('shopownerCutPrice')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="店长差价提成上限:" prop="shopownerCutPriceLimit">
            <el-input v-model.trim="calculateOutRuleForm.shopownerCutPriceLimit"
              oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店长差价提成上限金额" maxlength="5" @input="() => {
                if (calculateOutRuleForm.shopownerCutPrice === 0) {
                  calculateOutRuleForm.shopownerCutPriceLimit = 0
                }
                if (calculateOutRuleForm.shopownerCutPriceLimit) {
                  calculateOutRuleForm.shopownerCutPriceLimit = Number(calculateOutRuleForm.shopownerCutPriceLimit)
                }
              }"></el-input>
          </el-form-item>
          <el-form-item label="店员差价提成:" prop="clerkCutPrice">
            <el-input placeholder="输入店员差价提成比例" v-model="calculateOutRuleForm.clerkCutPrice"
              @input="profitNumberCheck1('clerkCutPrice')" @blur="profitNumberFn1('clerkCutPrice')">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="店员差价提成上限:" prop="clerkCutPriceLimit">
            <el-input v-model.trim="calculateOutRuleForm.clerkCutPriceLimit"
              oninput="this.value=value.replace(/[^0-9]/g,'')" placeholder="输入店员差价提成上限金额" maxlength="5" @input="() => {
                if (calculateOutRuleForm.clerkCutPrice === 0) {
                  calculateOutRuleForm.clerkCutPriceLimit = 0
                }
                if (calculateOutRuleForm.clerkCutPriceLimit) {
                  calculateOutRuleForm.clerkCutPriceLimit = Number(calculateOutRuleForm.clerkCutPriceLimit)
                }
              }"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="calculateOutRuleShow = false">取消</el-button>
        <el-button type="primary" @click.native="calculateOutHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { encryptByDES } from '@/utils/3DES'
import MachineTemplateList from './components/MachineTemplateList';
export default {
  name: "Table",
  components: {
    MachineTemplateList
  },
  data() {
    return {
      headers: {
        'ACCESS_TOKEN': localStorage.getItem('token'),
        'channel': 'platformPc',
        'TOKEN': ''
      },
      royaltyrebateType:'',
      loading: false,
      seachDataList: [],
      setType: false,
      configType: "",
      source: "",
      butLoading: false,
      withdrawalloading: false,
      number: {},
      storeNum: 0,
      companyInfo: {
        address: "",
        companyName: "",
        contact: "",
        phone: "",
      },
      isOnlineEnable: false,
      rowId: "",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      profitMode: "", // 利润模式
      redType: "01",
      isAngPow: false,
      isManagerReward: false,
      isOpenExtraDepreciateShare: false,
      isOpenOutQuote: true,
      isOpenMiniMallShareReward: false,
      miniMallShareReward: "",
      isDepreciateShare: false,
      pageNum: 1,
      rebateAuditRole: "02",
      staffTitle: "",
      staffAddTip: "",
      command: "",
      preRebateAuditList: [],
      rebateAuditList: [],
      refundAuditDataList: [],
      dialogRuleVisible: false, // 弹框的出现与否
      commandDisableShow: false,
      calculateTitle: '',
      typeCalculate: '',
      calculateNormalShow: false,//常规压价
      calculateOutShow: false,//外部压价
      dialogCalculateOutTitle: '',
      calculateOutRuleShow: false,//外部压价设置弹框显示
      dialogRuleTitle: "", // 标题
      dialogProfitRuleTitle: "", //利润提成设置弹框标题
      dialogProfitRuleVisible: false, //利润提成设置弹框显示
      RoyaltyVisible: false,//管理人员提成
      RoyaltyRuleTitle: "",
      delDialogTitle: "删除确认",
      dialogDelVisible: false,
      storePreRebateAuditloading: false,
      storeRebateAuditloading: false,
      refundAuditListloading: false,
      tableOperation: "del",
      outTableOperation: "del",
      royaltyOperation: "del",
      rebateType: "",
      tableType: "",
      isApIntoMode: false,
      rebateTemplateList: [{ templateName: "公共配置（默认）", regionList: [], storeList: [], machineTemplateList: [] }], // 返利配置模板
      machineTemplateList: [],  // 指定机型模版
      angPowTemplateList: [{ templateName: "公共配置（默认）", regionList: [], storeList: [] }], // 红包配置模板
      angPowStoreList: [],
      rebateStoreList: [],
      afterDepreciateStyle: '02',
      apIntoMode: "comp", //到账配置
      managerIntoMode: "comp",//管理者提成-到账模式（到员工账户：per,到公司账户：comp）
      Commissionofficer: [],//提成人员
      rewardLateArrivalType: "01", //到账时机
      isCutPrice: "02",
      rewardWithdrawChannel: null,
      managerRewardWithdrawChannel: 'alipay',//管理者提成-奖励提现渠道(alipay:支付宝，wechat:微信)
      isStaffWithdrawalAudit: "01", //红包提现-是否审核
      accountWithdraw: "02", // 是否可提现
      isaccountWithdrawflag: false, // 是否可设置是否可提现
      arrivalMethod: "01",
      userPayeeMode: "01", // 用户收款设置
      userPayeeModeTrade: "01",
      isStaffPayUserAudit: "01", //店员付给用户-是否审核
      isRebateStore: "02", // 门店返利申请
      isRebateStoreAudit: "02", // 门店返利审核
      unUedAddToStaff: "02", //未使用加价部分是否提成给店员
      addToStaffRate: "", //未使用加价部分奖励比例(门店加价部分)
      isPreRebateStoreAudit: "02", // 预留返利审核
      isPreRebateStore: "02", // 预留返利申请
      addPriceUseType: "02", // 加价申请使用方式 01 固定比例 02 输入区间金额
      isCmccAddPrice: false, // 移动商户专用：是否使用加价券
      rebateEmbezzleMode: "ack", // 挪用时机
      addPriceSettleStyle: '01', // 结算方式
      rebateEmbezzleStaff: "", //可领取返利人员
      rebateRateMethod: "01", //返利计算方式
      rebateTypeTab: "01",
      ManagementTypeTab: "01",//管理人员提成
      rebateActiveName: "公共配置（默认）",
      angPowActiveName: "公共配置（默认）",
      addTemplateTitle: "",// 添加个性配置title
      addTemplateShow: false, //添加个性配置弹框显示
      addTemplateType: "", //添加个性配置类型
      angPowTypeTab: '01',// 红包金额/关联门店切换
      storeVisible: false,//绑定门店弹框
      storeLoading: false,
      plopoeDelVisible: false,//移除提成人员
      informationDelVisible: false,
      canEnddata: {},//提成人员信息
      isRedStoreDel: true, // 红包批量删除按钮
      isRebateStoreDel: true, // 返利批量删除按钮
      isAngPowAddPrice: '02', // 红包是否能挪用加价（01 能 02 不能）
      inputTemplateName: "",
      addType: "",
      isOpenInsideBiz: null,
      refundAuditRole: "03",
      selectWithdrawal: [],
      accountWithdrawStaff: [],
      storeTagList: [], // 标签下拉
      inspectorAreaList: [], // 区域下拉
      // currentRowId: null,
      redPacketPage: {
        pageNum: 1,
        pageSize: 100,
        total: 0,
      },
      staffPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      storeDialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      ruleForm: {
        lower: "",
        upper: "",
        rebate: "",
        delayRebate: "",
        shopownerReward: "",
        clerkReward: "",
        rebateType: "",
        shopownerRewardLimit: "",
        clerkRewardLimit: "",
        rebateLimit: "",
        delayRebateLimit: "",
        cutPriceScope: "",
        cutPriceScopeLimit: ""
      },
      profitRuleForm: {
        lower: "",
        upper: "",
        shopownerCutPrice: "",
        shopownerCutPriceLimit: "",
        clerkCutPrice: "",
        clerkCutPriceLimit: "",
      },
      RoyaltyRuleForm: {
        lower: "",
        upper: "",
        rebateType: "",
        founderReward: "",
        founderRewardLimit: "",
        inspectorReward: "",
        inspectorRewardLimit: "",
      },
      calculateOutRuleForm: {
        lower: "",
        upper: "",
        shopownerCutPrice: "",
        shopownerCutPriceLimit: "",
        clerkCutPrice: "",
        clerkCutPriceLimit: "",
      },
      storeDialogForm: {
        storeName: "",
        tagIds: [],
        inspectorIds: [],
        assignedStoreIds: [],
      },
      redPacketloading: false,
      staffListLoading: false,
      rebateloading: false,
      withdrawDialogVisible: false,
      staffDialogVisible: false,
      RoyaltyDialogVisible: false,
      staffDialogVisible2: false,
      importDialogVisible: false,
      isSelection: true,
      redPacketColumns: [
        { slotName: "rank" },
        { slotName: "shopownerReward" },
        { slotName: "shopownerRewardLimit" },
        { slotName: "clerkReward" },
        { slotName: "clerkRewardLimit" },
        { slotName: "operation" },
      ],
      angPowStoreColumns: [
        { slotName: "areaName" },
        { slotName: "storeName" },
        { slotName: "storeManager" },
        { slotName: "tagList" },
        { slotName: "operation" },
      ],
      rebateColumns: [
        { slotName: "rank" },
        { slotName: "rebate" },
        { slotName: "rebateLimit" },
        { slotName: "delayRebate" },
        { slotName: "delayRebateLimit" },
        { slotName: "cutPriceScope" },
        { slotName: "cutPriceScopeLimit" },
        { slotName: "operation" },
      ],
      withdrawalColumns: [
        { label: "提现人", prop: "staffName" },
        { label: "联系电话", prop: "staffMobile" },
        { slotName: "isSubscribe" },
        { label: "微信昵称", prop: "nickName" },
        { slotName: "headImg" },
        { slotName: "operation" },
      ],
      storePreRebateAuditColumns: [
        { label: "审核人", prop: "staffName" },
        { label: "联系电话", prop: "staffMobile" },
        { slotName: "staffType" },
        { slotName: "isSubscribe" },
        { slotName: "operation" },
      ],
      CommissionColumns: [
        { label: "以下人员可获得提成", prop: "staffName" },
        { slotName: "staffMobile" },
        { slotName: "staffType" },
        { slotName: "operation" },
      ],
      staffListColumns: [
        { label: "审核人", prop: "staffName" },
        { label: "联系电话", prop: "staffMobile" },
        { slotName: "staffType" },
        { slotName: "isSubscribe" },
      ],
      RoyaltystaffColumns: [
        { label: "姓名", prop: "staffName" },
        { label: "电话", prop: "staffMobile" },
        { slotName: "staffType" },
      ],
      staffListWithdrawalColumns: [
        // { slotName: "radioSelect" },
        { label: "提现人", prop: "staffName" },
        { label: "联系电话", prop: "staffMobile" },
        { slotName: "staffType" },
        { slotName: "isSubscribe" },
        { label: "微信昵称", prop: "nickName" },
        { slotName: "headImg" },
      ],
      storeDialogTableColumns: [
        { slotName: "areaName" },
        { slotName: "storeName" },
        { slotName: "tagList" },
      ],
      profitPacketColumns: [
        { slotName: "rank" },
        { slotName: "shopownerCutPrice" },
        { slotName: "shopownerCutPriceLimit" },
        { slotName: "clerkCutPrice" },
        { slotName: "clerkCutPriceLimit" },
        { slotName: "operation" },
      ],
      RoyaltySelectdata: [],
      storeDialogDataList: [],
      rebateRegionList: [],
      staffListData: [],
      RoyaltystaffListData: [],
      withdrawalDataList: [],
      storePreRebateAuditList: [],
      storeRebateAuditList: [],
      refundAuditList: [],
      RoyaltyAuditList: [],
      selectStaffList: [],
      selectAddStoreList: [],
      rebatePage: {
        pageNum: 1,
        pageSize: 100,
        total: 0,
      },
      apRegionList: [],
      cutPriceRegionList: [],
      extraCutPriceRegionList: [],
      RoyaltyRegionList: [],
      rules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        rebate: [
          { required: true, message: "请输入返利比例", trigger: "blur" },
        ],
        delayRebate: [
          { required: true, message: "请输入返利预留比例", trigger: "blur" },
        ],
        rebateType: [
          { required: true, message: "请选择返利方式", trigger: "change" },
        ],
        shopownerReward: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        clerkReward: [{ required: true, message: "请输入", trigger: "blur" }],
        shopownerRewardLimit: [
          { required: true, message: "输入店长红包上限金额", trigger: "blur" },
        ],
        clerkRewardLimit: [
          { required: true, message: "输入店员红包上限金额", trigger: "blur" },
        ],
        delayRebateLimit: [
          { required: true, message: "输入预留返利上限金额", trigger: "blur" },
        ],
        rebateLimit: [
          { required: true, message: "输入门店返利上限金额", trigger: "blur" },
        ],
        cutPriceScope: [
          { required: true, message: "请输入弹性比例", trigger: "blur" },
        ],
        cutPriceScopeLimit: [
          { required: true, message: "输入弹性上限金额", trigger: "blur" },
        ]
      },
      profitRules: {
        lower: [
          { required: true, message: "请输入差价利润区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入差价利润区间结束值", trigger: "blur" },
        ],
        shopownerCutPrice: [
          { required: true, message: "请输入店长差价提成", trigger: "blur" },
        ],
        shopownerCutPriceLimit: [
          { required: true, message: "输入店长差价提成上限金额", trigger: "blur" },
        ],
        clerkCutPrice: [
          { required: true, message: "请输入店员差价提成", trigger: "blur" },
        ],
        clerkCutPriceLimit: [
          { required: true, message: "输入店员差价提成上限金额", trigger: "blur" },
        ],
      },
      RoyaltyRules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        rebateType: [
          { required: true, message: '请选择返利方式', trigger: 'change' }
        ],
        founderReward: [
          { required: true, message: "请输入创建人及子账号提成", trigger: "blur" },
        ],
        founderRewardLimit: [
          { required: true, message: "请输入创建人及子账号提成上限", trigger: "blur" },
        ],
        inspectorReward: [
          { required: true, message: "请输入总监提成", trigger: "blur" },
        ],
        inspectorRewardLimit: [
          { required: true, message: "请输入总监提成上限", trigger: "blur" },
        ],
      },
      calculateOutRules: {
        lower: [
          { required: true, message: "请输入高出金额区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入高出金额区间结束值", trigger: "blur" },
        ],
        shopownerCutPrice: [
          { required: true, message: "请输入店长差价提成", trigger: "blur" },
        ],
        shopownerCutPriceLimit: [
          { required: true, message: "输入店长差价提成上限金额", trigger: "blur" },
        ],
        clerkCutPrice: [
          { required: true, message: "请输入店员差价提成", trigger: "blur" },
        ],
        clerkCutPriceLimit: [
          { required: true, message: "输入店员差价提成上限金额", trigger: "blur" },
        ],
      }
    };
  },
  computed: {},
  created() {
    this.companyId = this.$route.query.companyId;
    this.setType = this.$route.query.type;
    this.source = this.$route.query.source;
    this.getStoreTagSelect()
    this.inspectorAreaSelect()
    if (this.$route.query.source == "online") {
      _api
        .configInfo({ companyId: this.$route.query.companyId })
        .then((res) => {
          if (res.code === 1) {
            console.log(res);
            this.companyInfo.address = res.data.address;
            this.companyInfo.companyName = res.data.companyName;
            this.companyInfo.contact = res.data.contact;
            this.companyInfo.phone = res.data.phone;
            this.isOnlineEnable = res.data.isOnlineEnable;
            this.rewardWithdrawChannel = res.data.rewardWithdrawChannel;
            this.storeNum = res.data.storeNum;
            this.setType = res.data.profitMode ? "true" : "false"
            this.isOpenInsideBiz = res.data.isOpenInsideBiz
            if (this.$route.query.type == "true" || res.data.profitMode) {
              if (res.data.managerReward) {
                this.Commissionofficer = []
                if (res.data.managerReward.isManagerAssignFounder == '01') {
                  this.Commissionofficer.push('2')
                } 
                if (res.data.managerReward.isManagerInspector == '01') {
                  this.Commissionofficer.push('1')
                }
                this.managerIntoMode = res.data.managerReward.managerIntoMode;
                this.managerRewardWithdrawChannel = res.data.managerReward.managerRewardWithdrawChannel;
              }
              this.profitMode = res.data.profitMode;
              this.afterDepreciateStyle = res.data.afterDepreciateStyle,
                this.apIntoMode = res.data.apIntoMode;
              this.rewardLateArrivalType = res.data.rewardLateArrivalType;
              this.isApIntoMode = res.data.apIntoMode ? true : false;
              this.isAngPow = res.data.isAngPow == "01";
              this.isManagerReward = res.data.isManagerReward == "01";//	管理者提成-是否打开（开启：01，关闭：02）
              this.isOpenMiniMallShareReward = res.data.isOpenMiniMallShareReward == "01";
              this.isOpenExtraDepreciateShare = res.data.isOpenExtraDepreciateShare == '01'
              this.isOpenOutQuote = res.data.isOpenOutQuote
              this.miniMallShareReward = res.data.miniMallShareReward;
              this.isCutPrice = res.data.isCutPrice || "02"
              this.isDepreciateShare = res.data.isDepreciateShare == "01";
              (this.isStaffWithdrawalAudit =
                res.data.isStaffWithdrawalAudit || "01"),
                (this.isStaffPayUserAudit =
                  res.data.isStaffPayUserAudit || "01"),
                (this.userPayeeModeTrade =
                  res.data.userPayeeModeTrade || "01"),
                //
                this.angPowTemplateList = res.data.angPowTemplateList || this.angPowTemplateList;
              if (res.data.angPowTemplateList && res.data.angPowTemplateList.length) {
                (this.apRegionList = res.data.angPowTemplateList[0].regionList || []);
                this.redPacketPage.total =
                  res.data.angPowTemplateList[0].regionList && res.data.angPowTemplateList[0].regionList.length
                    ? res.data.angPowTemplateList[0].regionList.length
                    : 0;
                this.rebateType = res.data.angPowTemplateList[0].regionList
                  ? res.data.angPowTemplateList[0].regionList[0].rebateType
                  : "";
                this.isAngPowAddPrice = res.data.angPowTemplateList[0].isAngPowAddPrice || '02';
              }
              //
              this.rebateTemplateList = res.data.rebateTemplateList || this.rebateTemplateList
              if (res.data.rebateTemplateList && res.data.rebateTemplateList.length) {
                res.data.rebateTemplateList.forEach(item => {
                  if (item.storeRebateAuditList && item.storeRebateAuditList.length) {
                    item.rebateAuditList = []
                    item.storeRebateAuditList.forEach(ele => {
                      item.rebateAuditList.push({
                        auditType: "01",
                        staffId: ele.staffId,
                      })
                    })
                  }
                  if (item.storePreRebateAuditList && item.storePreRebateAuditList.length) {
                    item.preRebateAuditList = []
                    item.storePreRebateAuditList.forEach(ele => {
                      item.preRebateAuditList.push({
                        auditType: "02",
                        staffId: ele.staffId,
                      })
                    })
                  }
                })
                this.rebateRegionList = res.data.rebateTemplateList[0].regionList || [];
                this.machineTemplateList = res.data.rebateTemplateList[0].machineTemplateList || [];
                this.rebatePage.total =
                  res.data.rebateTemplateList[0].regionList && res.data.rebateTemplateList[0].regionList.length
                    ? res.data.rebateTemplateList[0].regionList.length
                    : 0;
                this.isRebateStore = res.data.rebateTemplateList[0].isRebateStore;
                this.isRebateStoreAudit = res.data.rebateTemplateList[0].isRebateStoreAudit || "02";
                this.unUedAddToStaff = res.data.rebateTemplateList[0].unUedAddToStaff || "02";
                this.addToStaffRate = res.data.rebateTemplateList[0].addToStaffRate || "";
                this.rebateAuditRole = res.data.rebateTemplateList[0].rebateAuditRole;
                this.isPreRebateStore = res.data.rebateTemplateList[0].isPreRebateStore;
                this.addPriceUseType = res.data.rebateTemplateList[0].addPriceUseType;
                this.isCmccAddPrice = res.data.rebateTemplateList[0].isCmccAddPrice || false;
                this.isPreRebateStoreAudit =
                  res.data.rebateTemplateList[0].isPreRebateStoreAudit || "02";
                if (
                  res.data.rebateTemplateList[0].storePreRebateAuditList &&
                  res.data.rebateTemplateList[0].storePreRebateAuditList.length
                ) {
                  res.data.rebateTemplateList[0].storePreRebateAuditList.forEach((item) => {
                    item.staffMobile = item.mobile;
                    this.preRebateAuditList.push({
                      auditType: "02",
                      staffId: item.staffId,
                    });
                  });
                  this.storePreRebateAuditList = res.data.rebateTemplateList[0].storePreRebateAuditList;
                }
                if (
                  res.data.rebateTemplateList[0].storeRebateAuditList &&
                  res.data.rebateTemplateList[0].storeRebateAuditList.length
                ) {
                  res.data.rebateTemplateList[0].storeRebateAuditList.forEach((item) => {
                    item.staffMobile = item.mobile;
                    this.rebateAuditList.push({
                      auditType: "01",
                      staffId: item.staffId,
                    });
                  });
                  this.storeRebateAuditList = res.data.rebateTemplateList[0].storeRebateAuditList;
                }
              }
              this.userPayeeMode = res.data.userPayeeMode;
              //是否可设置提现
              this.isaccountWithdrawflag = res.data.accountWithdraw == '01' ? false : true
              this.accountWithdraw = res.data.accountWithdraw;
              console.log(res.data.accountWithdrawStaffInfo);
              if (res.data.accountWithdrawStaffInfo) {
                res.data.accountWithdrawStaffInfo.staffMobile =
                  res.data.accountWithdrawStaffInfo.mobile;
                res.data.accountWithdrawStaffInfo.forEach((item) => {
                  item.staffMobile = item.mobile;
                  this.accountWithdrawStaff.push(item.staffId);
                });
                this.withdrawalDataList = res.data.accountWithdrawStaffInfo;
                // this.currentRowId = res.data.accountWithdrawStaffInfo.staffId
              }
              this.refundAuditRole = res.data.refundAuditRole;
              this.rebateEmbezzleMode = res.data.rebateEmbezzleMode;
              this.addPriceSettleStyle = res.data.addPriceSettleStyle;
              this.rebateEmbezzleStaff = res.data.rebateEmbezzleStaff;
              if (res.data.refundAuditList && res.data.refundAuditList.length) {
                res.data.refundAuditList.forEach((item) => {
                  item.staffMobile = item.mobile;
                  this.refundAuditDataList.push({
                    auditType: "03",
                    staffId: item.staffId,
                  });
                });
                this.refundAuditList = res.data.refundAuditList;
              }
              if (res.data.cutPriceRegionList && res.data.cutPriceRegionList.length) {
                this.cutPriceRegionList = res.data.cutPriceRegionList
              }
              if (res.data.extraCutPriceRegionList && res.data.extraCutPriceRegionList.length) {
                this.extraCutPriceRegionList = res.data.extraCutPriceRegionList
              }
              //管理人员提成规则
              if (res.data.managerReward && res.data.managerReward.managerRewardRegionList.length) {
                this.RoyaltyRegionList = res.data.managerReward.managerRewardRegionList
              }
             
              //指定管理人员
              if (res.data.managerReward && res.data.managerReward.assignFounderList.length) {
                this.RoyaltyAuditList = res.data.managerReward.assignFounderList
              }
              this.rebateRateMethod = res.data.rebateRateMethod
              this.arrivalMethod = res.data.arrivalMethod
            }
          }
        });
    } else {
      _api
        .companyConfigInfo({ companyId: this.$route.query.companyId })
        .then((res) => {
          if (res.code === 1) {
            console.log(res);
            this.companyInfo.address = res.data.address;
            this.companyInfo.companyName = res.data.companyName;
            this.companyInfo.contact = res.data.contact;
            this.companyInfo.phone = res.data.phone;
            this.isOnlineEnable = res.data.isOnlineEnable,
              this.setType = res.data.profitMode ? "true" : "false"
            this.isOpenInsideBiz = res.data.isOpenInsideBiz
            if (this.$route.query.type == "true" || res.data.profitMode) {
              this.afterDepreciateStyle = res.data.afterDepreciateStyle,
                this.isAngPow = res.data.isAngPow == "01";
              this.isOpenMiniMallShareReward = res.data.isOpenMiniMallShareReward == "01";
              this.isOpenExtraDepreciateShare = res.data.isOpenExtraDepreciateShare == '01'
              this.isOpenOutQuote = res.data.isOpenOutQuote
              this.miniMallShareReward = res.data.miniMallShareReward;
              this.isCutPrice = res.data.isCutPrice || "02";
              this.isDepreciateShare = res.data.isDepreciateShare == "01";
              this.apRegionList = res.data.apRegionList || [];
              this.redPacketPage.total =
                res.data.apRegionList && res.data.apRegionList.length
                  ? res.data.apRegionList.length
                  : 0;
              this.rebateType = res.data.apRegionList
                ? res.data.apRegionList[0].rebateType
                : "";
              this.rebateRegionList = res.data.rebateRegionList || [];
              this.rebatePage.total =
                res.data.rebateRegionList && res.data.rebateRegionList.length
                  ? res.data.rebateRegionList.length
                  : 0;
              this.isPreRebateStoreAudit =
                res.data.isPreRebateStoreAudit || "02";
              this.isRebateStore = res.data.isRebateStore;
              this.isAngPowAddPrice = res.data.isAngPowAddPrice || '02';
              this.isRebateStoreAudit = res.data.isRebateStoreAudit || "02";
              this.unUedAddToStaff = res.data.unUedAddToStaff || "02";
              this.addToStaffRate = res.data.addToStaffRate || "";
              this.profitMode = res.data.profitMode;
              this.isPreRebateStore = res.data.isPreRebateStore;
              this.addPriceUseType = res.data.addPriceUseType || '02';
              this.isCmccAddPrice = res.data.isCmccAddPrice || false;
              this.rebateAuditRole = res.data.rebateAuditRole;
              if (
                res.data.storePreRebateAuditList &&
                res.data.storePreRebateAuditList.length
              ) {
                res.data.storePreRebateAuditList.forEach((item) => {
                  item.staffMobile = item.mobile;
                  this.preRebateAuditList.push({
                    auditType: "02",
                    staffId: item.staffId,
                  });
                });
                this.storePreRebateAuditList = res.data.storePreRebateAuditList;
              }
              if (
                res.data.storeRebateAuditList &&
                res.data.storeRebateAuditList.length
              ) {
                res.data.storeRebateAuditList.forEach((item) => {
                  item.staffMobile = item.mobile;
                  this.rebateAuditList.push({
                    auditType: "01",
                    staffId: item.staffId,
                  });
                });
                this.storeRebateAuditList = res.data.storeRebateAuditList;
              }
              if (res.data.cutPriceRegionList && res.data.cutPriceRegionList.length) {
                this.cutPriceRegionList = res.data.cutPriceRegionList
              }
              if (res.data.extraCutPriceRegionList && res.data.extraCutPriceRegionList.length) {
                this.extraCutPriceRegionList = res.data.extraCutPriceRegionList
              }
              this.rebateRateMethod = res.data.rebateRateMethod
            }
          }
        });
    }
  },
  watch: {
    apRegionList(val) {
      if (!val.length) {
        this.rebateType = "";
      }
    },
    RoyaltyRegionList(val){
      if (!val.length) {
        this.royaltyrebateType = '';
      }
    },
    isRebateStore(val) {
      if (val == "02") {
        this.isPreRebateStore = "02";
        this.unUedAddToStaff = "02"
      }
      if (val == "01" && this.isOpenInsideBiz) {
        this.isPreRebateStore = "01";
        this.addPriceUseType = '02'
        this.isCmccAddPrice = false
      }
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.isRebateStore = val
        }
      })
    },
    unUedAddToStaff(val) {
      // if (val == "01") {
      //   this.isPreRebateStore = "02";
      // }
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.unUedAddToStaff = val
        }
      })
    },
    addToStaffRate(val) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.addToStaffRate = val
        }
      })
    },
    isAngPowAddPrice(val) {
      this.angPowTemplateList.forEach(item => {
        if (item.templateName == this.angPowActiveName) {
          item.isAngPowAddPrice = val
        }
      })
    },
    isPreRebateStore(val) {
      if (val == "02") {
        this.isPreRebateStoreAudit = "02";
      }
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.isPreRebateStore = val
        }
      })
    },
    isAngPow(val) {
      if (this.setType == "false") {
        if (!val) {
          this.apIntoMode = "";
        } else {
          this.apIntoMode = "comp";
        }
      } else {
        if (!this.isApIntoMode && val) {
          this.apIntoMode = "comp";
        } else if (!this.isApIntoMode && !val) {
          this.apIntoMode = "";
        }
      }
    },
    addPriceSettleStyle(val) {
      //返利挂账不能加价限制
      // if(val == '03'){
      //   this.isRebateStore = "02"
      // }
    },
    storePreRebateAuditList(arr) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.storePreRebateAuditList = arr
        }
      })
    },
    storeRebateAuditList(arr) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.storeRebateAuditList = arr
        }
      })
    },
    preRebateAuditList(arr) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.preRebateAuditList = arr
        }
      })
    },
    rebateAuditList(arr) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.rebateAuditList = arr
        }
      })
    },
    rebateActiveName(val) {
      this.rebateTypeTab = '01'
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == val) {
          this.rebateRegionList = JSON.parse(JSON.stringify(item.regionList)) || [];

          this.rebatePage.total =
            item.regionList && item.regionList.length
              ? item.regionList.length
              : 0;
          this.machineTemplateList = JSON.parse(JSON.stringify(item.machineTemplateList)) || []
          this.isRebateStore = item.isRebateStore || '02';
          this.isRebateStoreAudit = item.isRebateStoreAudit || "02";
          this.unUedAddToStaff = item.unUedAddToStaff || "02";
          this.addToStaffRate = item.addToStaffRate || "";
          this.rebateAuditRole = item.rebateAuditRole || "02";
          this.isPreRebateStore = item.isPreRebateStore || "02";
          this.addPriceUseType = item.addPriceUseType || '02';
          this.isCmccAddPrice = item.isCmccAddPrice || false;
          this.isPreRebateStoreAudit = item.isPreRebateStoreAudit || "02";
          if (
            item.storePreRebateAuditList &&
            item.storePreRebateAuditList.length
          ) {
            item.storePreRebateAuditList.forEach((ele) => {
              ele.staffMobile = ele.mobile;
              this.preRebateAuditList.push({
                auditType: "02",
                staffId: ele.staffId,
              });
            });
            this.storePreRebateAuditList = item.storePreRebateAuditList;
          } else {
            this.preRebateAuditList = [],
              this.storePreRebateAuditList = []
          }
          if (
            item.storeRebateAuditList &&
            item.storeRebateAuditList.length
          ) {
            item.storeRebateAuditList.forEach((ele) => {
              ele.staffMobile = ele.mobile;
              this.rebateAuditList.push({
                auditType: "01",
                staffId: ele.staffId,
              });
            });
            console.log(item.storeRebateAuditList);
            this.storeRebateAuditList = item.storeRebateAuditList;
          } else {
            this.rebateAuditList = [],
              this.storeRebateAuditList = []
          }
          this.rebateStoreList = JSON.parse(JSON.stringify(item.storeList)) || []
        }
      })

    },
    angPowActiveName(val) {
      console.log(this.angPowTemplateList);
      this.angPowTypeTab = '01'
      this.angPowTemplateList.forEach(item => {
        if (item.templateName == val) {
          console.log(item.regionList);
          this.apRegionList = JSON.parse(JSON.stringify(item.regionList)) || []
          this.redPacketPage.total =
            item.regionList && item.regionList.length
              ? item.regionList.length
              : 0;
          this.rebateType = item.regionList.length
            ? item.regionList[0].rebateType
            : "";
          this.angPowStoreList = JSON.parse(JSON.stringify(item.storeList)) || []
          this.isAngPowAddPrice = item.isAngPowAddPrice || '02';
        }
      })
    },
    angPowStoreList(arr) {
      if (!arr.length) {
        this.isRedStoreDel = true
      }
    },
    angPowTypeTab(val) {
      this.selectStoreDelList = []
      this.isRedStoreDel = true
    },
    rebateStoreList(arr) {
      if (!arr.length) {
        this.isRebateStoreDel = true
      }
    },
    rebateTypeTab(val) {
      this.selectStoreDelList = []
      this.isRebateStoreDel = true
    },
    isRebateStoreAudit(val) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.isRebateStoreAudit = val
        }
      })
    },
    rebateAuditRole(val) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.rebateAuditRole = val
        }
      })
    },
    addPriceUseType(val) {
      if(val==='01'){
        this.isCmccAddPrice = false
      }
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.addPriceUseType = val
        }
      })
    },
    isCmccAddPrice(val) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.isCmccAddPrice = val
        }
      })
    },
    isPreRebateStoreAudit(val) {
      this.rebateTemplateList.forEach(item => {
        if (item.templateName == this.rebateActiveName) {
          item.isPreRebateStoreAudit = val
        }
      })
    },
    afterDepreciateStyle(val) {
      if (val == '02') {
        this.isCutPrice = '02'
      }
    }
  },
  methods: {
    setChange(val) {
      if (!val) {
        this.isOpenExtraDepreciateShare = false
      }
    },
    addCulateRule() {
      return (
        <div>
          <span>常规压价金额</span>
          <el-button
            type="danger"
            plain
            style="padding:3px;margin-left:5px"
            size="mini"
            //加入点击事件---------
            onClick={() => this.calculate('normal')}
          //---------------------
          >计算方式</el-button></div>
      )
    },
    addCulateRule1() {
      return (
        <div>
          <span>外部压价金额</span>
          <el-button
            type="danger"
            plain
            style="padding:3px;margin-left:5px"
            size="mini"
            //加入点击事件---------
            onClick={() => this.calculate('out')}
          //---------------------
          >计算方式</el-button></div>
      )
    },
    calculate(type) {
      this.typeCalculate = type
      this.calculateTitle = type == 'normal' ? '常规压价金额' : '外部压价金额'
      this.calculateNormalShow = true
    },
    // 显示添加规则弹框
    addRule(type) {
      if (type != 'profit') {
        this.configType = type;
        this.rowId = "";
        if (type == "red") {
          this.ruleForm.rebateType = this.rebateType;
          this.dialogRuleTitle = "添加规则-红包设置";
        } else {
          this.dialogRuleTitle = "添加规则-返利设置";
        }
        this.dialogRuleVisible = true;
      } else {
        this.rowId = "";
        this.dialogProfitRuleTitle = "添加规则-差价提成设置";
        this.dialogProfitRuleVisible = true;
      }
    },
    //管理人员提成规则添加
    addRoyaltyRule() {
      if (this.Commissionofficer.includes('1') && this.Commissionofficer.length === 1) {
        this.RoyaltyRuleForm.founderReward = 0
        this.RoyaltyRuleForm.founderRewardLimit = 0
      } else if (this.Commissionofficer.includes('2') && this.Commissionofficer.length === 1) {
        this.RoyaltyRuleForm.inspectorReward = 0
        this.RoyaltyRuleForm.inspectorRewardLimit = 0
      }
      if(this.RoyaltyRegionList.length){
        this.royaltyrebateType = this.RoyaltyRegionList[0].rebateType;
        this.RoyaltyRuleForm.rebateType=this.RoyaltyRegionList[0].rebateType;
      }
      this.RoyaltyVisible = true
      this.RoyaltyRuleTitle = "添加规则"
    },
    addRuleOut() {
      this.rowId = "";
      this.dialogCalculateOutTitle = "添加规则-外部压价设置";
      this.calculateOutRuleShow = true;
    },
    ruleDialogClosed() {
      this.tableOperation = "del";
      this.ruleForm = {
        lower: "",
        upper: "",
        rebate: "",
        delayRebate: "",
        shopownerReward: "",
        clerkReward: "",
        rebateType: "",
        shopownerRewardLimit: "",
        clerkRewardLimit: "",
        rebateLimit: "",
        delayRebateLimit: "",
        cutPriceScope: "",
        cutPriceScopeLimit: ""
      };
      this.$refs["ruleForm"].resetFields();
    },
    // 利润提成设置弹框关闭
    profitRuleDialogClosed() {
      this.tableOperation = "del";
      this.profitRuleForm = {
        lower: "",
        upper: "",
        shopownerCutPrice: "",
        shopownerCutPriceLimit: "",
        clerkCutPrice: "",
        clerkCutPriceLimit: "",
      };
      this.$refs["profitRuleForm"].resetFields();
    },
    // 管理人员提成设置弹框关闭
    RoyaltyClosed() {
      this.royaltyOperation = "del";
      this.RoyaltyRuleForm = {
        lower: "",
        upper: "",
        rebateType: "",
        founderReward: "",
        founderRewardLimit: "",
        inspectorReward: "",
        inspectorRewardLimit: "",
      };
      this.rowId = ""
      this.$refs["RoyaltyRuleForm"].resetFields();
    },

    // 利润提成设置弹框关闭
    calculateOutRuleDialogClosed() {
      this.outTableOperation = "del";
      this.calculateOutRuleForm = {
        lower: "",
        upper: "",
        shopownerCutPrice: "",
        shopownerCutPriceLimit: "",
        clerkCutPrice: "",
        clerkCutPriceLimit: "",
      };
      this.$refs["calculateOutRuleForm"].resetFields();
    },
    //添加提成人员
    addassign() {
      this.administrationstaffListChange()
      this.RoyaltyDialogVisible = true
    },
    pelplochange(e) {
      console.log(e)
    },
    //移除提成人员
    detaleAction(data) {
      this.canEnddata = data
      this.plopoeDelVisible = true
    },
    //确认删除
    SuccessactionDel() {
      this.RoyaltyAuditList = this.RoyaltyAuditList.filter(item => item.staffId != this.canEnddata.staffId)
      this.plopoeDelVisible = false
    },
    informationSuccessDel() {
      this.RoyaltyRegionList = this.RoyaltyRegionList.filter(item => item.id != this.rowId)
      this.informationDelVisible = false
      this.rowId = ""
    },
    delDialogClosed() {
      this.dialogDelVisible = false;
      this.butLoading = false;
    },
    handleClose() {
      this.dialogRuleVisible = false;
      this.dialogDelVisible = false;
    },
    // 弹框规则确认
    handleSuccess() {
      console.log(this.ruleForm);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let ruleForm = this.ruleForm;
          console.log(ruleForm);
          if (Number(ruleForm.lower) > Number(ruleForm.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(ruleForm.lower) == Number(ruleForm.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            rank: ruleForm.lower + "≤区间" + ruleForm.upper,
            lower: ruleForm.lower,
            upper: ruleForm.upper,
          };
          if (this.configType == "rebate") {
            if (this.rebateRegionList.length && this.tableOperation != "edit") {
              let numArr = [];
              this.rebateRegionList.forEach((item) => {
                numArr.push(item.lower);
                numArr.push(item.upper);
              });
              let iMin = Math.min(...numArr); //最小值
              let iMAX = Math.max(...numArr); //最大值
              if (ruleForm.upper != iMin && ruleForm.lower != iMAX) {
                this.$message.error("价位区间不连续");
                return;
              }
              if (ruleForm.lower != iMAX && ruleForm.upper != iMin) {
                this.$message.error("价位区间不连续");
                return;
              }
            }
            params.rebate = ruleForm.rebate;
            params.delayRebate = ruleForm.delayRebate;
            params.rebateLimit = ruleForm.rebateLimit;
            params.delayRebateLimit = ruleForm.delayRebateLimit;
            params.cutPriceScope = ruleForm.cutPriceScope;
            params.cutPriceScopeLimit = ruleForm.cutPriceScopeLimit;
            params.configType = "01";
            if (this.rowId.toString()) {
              this.rebateRegionList.splice(this.rowId, 1, params);
              this.rebateTemplateList.forEach(item => {
                if (item.templateName == this.rebateActiveName) {
                  item.regionList = this.rebateRegionList;
                }
              })
            } else {
              this.rebateRegionList.push(params);
              this.rebateTemplateList.forEach(item => {
                if (item.templateName == this.rebateActiveName) {
                  item.regionList = this.rebateRegionList;
                }
              })
            }
            this.rebatePage.total = this.rebateRegionList.length;
          } else {
            if (this.apRegionList.length && this.tableOperation != "edit") {
              let numArr = [];
              this.apRegionList.forEach((item) => {
                numArr.push(item.lower);
                numArr.push(item.upper);
              });
              let iMin = Math.min(...numArr); //最小值
              let iMAX = Math.max(...numArr); //最大值
              if (ruleForm.upper != iMin && ruleForm.lower != iMAX) {
                this.$message.error("价位区间不连续");
                return;
              }
              if (ruleForm.lower != iMAX && ruleForm.upper != iMin) {
                this.$message.error("价位区间不连续");
                return;
              }
            }
            this.rebateType = ruleForm.rebateType;
            params.rebateType = ruleForm.rebateType;
            params.shopownerReward = ruleForm.shopownerReward;
            params.shopownerRewardLimit =
              ruleForm.rebateType == "01"
                ? ruleForm.shopownerRewardLimit
                : ruleForm.shopownerReward;
            params.clerkReward = ruleForm.clerkReward;
            params.clerkRewardLimit =
              ruleForm.rebateType == "01"
                ? ruleForm.clerkRewardLimit
                : ruleForm.clerkReward;
            params.configType = "02";
            console.log(params);
            if (this.rowId.toString()) {
              this.apRegionList.splice(this.rowId, 1, params);
              this.angPowTemplateList.forEach(item => {
                if (item.templateName == this.angPowActiveName) {
                  item.regionList = this.apRegionList;
                }
              })
            } else {
              this.apRegionList.push(params);
              this.angPowTemplateList.forEach(item => {
                if (item.templateName === this.angPowActiveName) {
                  item.regionList = this.apRegionList;
                }
              })
            }
            this.redPacketPage.total = this.apRegionList.length;
          }
          this.dialogRuleVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    calculateOutHandleSuccess() {
      this.$refs["calculateOutRuleForm"].validate((valid) => {
        if (valid) {
          let calculateOutRuleForm = this.calculateOutRuleForm;
          console.log(calculateOutRuleForm);
          if (Number(calculateOutRuleForm.lower) > Number(calculateOutRuleForm.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(calculateOutRuleForm.lower) == Number(calculateOutRuleForm.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: calculateOutRuleForm.lower,
            upper: calculateOutRuleForm.upper,
          };
          if (this.extraCutPriceRegionList.length && this.outTableOperation != "edit") {
            let numArr = [];
            this.extraCutPriceRegionList.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (calculateOutRuleForm.upper != iMin && calculateOutRuleForm.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (calculateOutRuleForm.lower != iMAX && calculateOutRuleForm.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.shopownerCutPrice = calculateOutRuleForm.shopownerCutPrice;
          params.shopownerCutPriceLimit = calculateOutRuleForm.shopownerCutPriceLimit;
          params.clerkCutPrice = calculateOutRuleForm.clerkCutPrice;
          params.clerkCutPriceLimit = calculateOutRuleForm.clerkCutPriceLimit;
          params.configType = "03";
          if (this.rowId.toString()) {
            this.extraCutPriceRegionList.splice(this.rowId, 1, params);
          } else {
            this.extraCutPriceRegionList.push(params);
          }
          this.calculateOutRuleShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    profitHandleSuccess() {
      this.$refs["profitRuleForm"].validate((valid) => {
        if (valid) {
          let profitRuleForm = this.profitRuleForm;
          console.log(profitRuleForm);
          if (Number(profitRuleForm.lower) > Number(profitRuleForm.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(profitRuleForm.lower) == Number(profitRuleForm.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: profitRuleForm.lower,
            upper: profitRuleForm.upper,
          };
          if (this.cutPriceRegionList.length && this.tableOperation != "edit") {
            let numArr = [];
            this.cutPriceRegionList.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (profitRuleForm.upper != iMin && profitRuleForm.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (profitRuleForm.lower != iMAX && profitRuleForm.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.shopownerCutPrice = profitRuleForm.shopownerCutPrice;
          params.shopownerCutPriceLimit = profitRuleForm.shopownerCutPriceLimit;
          params.clerkCutPrice = profitRuleForm.clerkCutPrice;
          params.clerkCutPriceLimit = profitRuleForm.clerkCutPriceLimit;
          params.configType = "03";
          if (this.rowId.toString()) {
            this.cutPriceRegionList.splice(this.rowId, 1, params);
          } else {
            this.cutPriceRegionList.push(params);
          }
          this.dialogProfitRuleVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //管理人员提成规则
    RoyaltyHandleSuccess() {
      this.$refs["RoyaltyRuleForm"].validate((valid) => {
        if (valid) {
          let RoyaltyRuleForm = this.RoyaltyRuleForm;
          console.log(RoyaltyRuleForm);
          if (Number(RoyaltyRuleForm.lower) > Number(RoyaltyRuleForm.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(RoyaltyRuleForm.lower) == Number(RoyaltyRuleForm.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: RoyaltyRuleForm.lower,
            upper: RoyaltyRuleForm.upper,
          };
          if (this.RoyaltyRegionList.length && this.royaltyOperation != "edit") {
            let numArr = [];
            this.RoyaltyRegionList.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (RoyaltyRuleForm.upper != iMin && RoyaltyRuleForm.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (RoyaltyRuleForm.lower != iMAX && RoyaltyRuleForm.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.rebateType = RoyaltyRuleForm.rebateType;
          params.founderReward = RoyaltyRuleForm.founderReward;
          params.inspectorReward = RoyaltyRuleForm.inspectorReward;
          // 判断按固定金额返回（02）或者按比例返回（01）
          params.founderRewardLimit =
          RoyaltyRuleForm.rebateType == "01"
                ? RoyaltyRuleForm.founderRewardLimit
                : RoyaltyRuleForm.founderReward;
          params.inspectorRewardLimit =
            RoyaltyRuleForm.rebateType == "01"
                ? RoyaltyRuleForm.inspectorRewardLimit
                : RoyaltyRuleForm.inspectorReward;
          if (this.rowId) {
            params.id = this.rowId;
            this.RoyaltyRegionList.splice(this.RoyaltyRegionList.findIndex(item => item.configId == this.rowId), 1, params);
          } else {
            params.id = Number(params.lower) + 1;
            this.RoyaltyRegionList.push(params);
          }
          this.rowId = ""
          this.RoyaltyVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 红包设置切换tab
    redBtnClick(type) {
      this.redType = type;
    },
    // 返利设置切换tab
    rebateBtnClick(type) {
      this.rebateTypeTab = type;
    },
    //管理人员提成设置
    ManageBtnClick(type) {
      if (this.Commissionofficer.length === 0 && (type == '02' || type == '03')) {
        return this.$message.error('请先选择发放提成人员！');
      }
      this.ManagementTypeTab = type
    },
    angPowBtnClick(type) {
      this.angPowTypeTab = type;
    },
    redHandleCurrentChange(val) {
      this.redPacketPage.pageNum = val;
    },
    rebateHandleCurrentChange(val) {
      this.rebatePage.pageNum = val;
    },
    handleBtn(row, type, tab, index) {
      console.log(row, type, tab, index);
      this.rowId = index;
      this.tableType = tab;
      this.tableOperation = type;
      if (type == "del") {
        if (tab == "examine") {
          this.delDialogTitle = "移除审核人";
        } else {
          this.delDialogTitle = "删除确认";
        }
        this.dialogDelVisible = true;
      } else {
        this.ruleForm.lower = row.lower;
        this.ruleForm.upper = row.upper;
        if (tab == "rebate") {
          this.rowId = index + (this.rebatePage.pageNum - 1) * this.rebatePage.pageSize;
          this.configType = "rebate";
          this.dialogRuleTitle = "编辑规则-返利设置";
          this.ruleForm.rebate = row.rebate;
          this.ruleForm.delayRebate = row.delayRebate;
          this.ruleForm.rebateLimit = row.rebateLimit;
          this.ruleForm.delayRebateLimit = row.delayRebateLimit;
          this.ruleForm.cutPriceScope = row.cutPriceScope;
          this.ruleForm.cutPriceScopeLimit = row.cutPriceScopeLimit;
        } else {
          this.rowId = index + (this.redPacketPage.pageNum - 1) * this.redPacketPage.pageSize;
          this.configType = "red";
          this.dialogRuleTitle = "编辑规则-红包设置";
          this.ruleForm.rebateType = this.rebateType;
          this.ruleForm.shopownerReward = row.shopownerReward;
          this.ruleForm.clerkReward = row.clerkReward;
          this.ruleForm.shopownerRewardLimit = row.shopownerRewardLimit;
          this.ruleForm.clerkRewardLimit = row.clerkRewardLimit;
        }
        console.log(this.ruleForm);
        this.dialogRuleVisible = true;
      }
    },
    //管理人员规则操作
    RoyaltyHandleBtn(row, type, index) {
      console.log(row);
      this.rowId = row.id;
      this.royaltyOperation = type;
      if (type == "del") {
        this.informationDelVisible = true;
      } else {
        this.RoyaltyRuleForm.lower = row.lower;
        this.RoyaltyRuleForm.upper = row.upper;
        this.RoyaltyRuleTitle = "编辑规则";
        this.RoyaltyRuleForm.rebateType = row.rebateType;
        this.RoyaltyRuleForm.founderReward = row.founderReward;
        this.RoyaltyRuleForm.founderRewardLimit = row.founderRewardLimit;
        this.RoyaltyRuleForm.inspectorReward = row.inspectorReward;
        this.RoyaltyRuleForm.inspectorRewardLimit = row.inspectorRewardLimit;
        this.RoyaltyVisible = true;
      }
    },
    outCalculateHandleBtn(row, type, index) {
      this.rowId = index;
      this.tableType = 'out';
      this.outTableOperation = type;
      if (type == "del") {
        this.delDialogTitle = "删除确认";
        this.dialogDelVisible = true;
      } else {
        this.calculateOutRuleForm.lower = row.lower;
        this.calculateOutRuleForm.upper = row.upper;
        this.dialogProfitRuleTitle = "编辑规则-外部压价提成设置";
        this.calculateOutRuleForm.shopownerCutPrice = row.shopownerCutPrice;
        this.calculateOutRuleForm.shopownerCutPriceLimit = row.shopownerCutPriceLimit;
        this.calculateOutRuleForm.clerkCutPrice = row.clerkCutPrice;
        this.calculateOutRuleForm.clerkCutPriceLimit = row.clerkCutPriceLimit;
        this.calculateOutRuleShow = true;
      }
    },
    profitHandleBtn(row, type, index) {
      this.rowId = index;
      this.tableType = 'profit';
      this.tableOperation = type;
      if (type == "del") {
        this.delDialogTitle = "删除确认";
        this.dialogDelVisible = true;
      } else {
        this.profitRuleForm.lower = row.lower;
        this.profitRuleForm.upper = row.upper;
        this.dialogProfitRuleTitle = "编辑规则-差价提成设置";
        this.profitRuleForm.shopownerCutPrice = row.shopownerCutPrice;
        this.profitRuleForm.shopownerCutPriceLimit = row.shopownerCutPriceLimit;
        this.profitRuleForm.clerkCutPrice = row.clerkCutPrice;
        this.profitRuleForm.clerkCutPriceLimit = row.clerkCutPriceLimit;
        this.dialogProfitRuleVisible = true;
      }
    },
    handleSuccessDel() {
      if (this.tableType == "red") {
        let changeArr = this.apRegionList.slice(
          (this.redPacketPage.pageNum - 1) * this.redPacketPage.pageSize,
          this.redPacketPage.pageNum * this.redPacketPage.pageSize
        );
        changeArr.splice(this.rowId, 1);
        this.apRegionList = this.apRegionList
          .slice(
            0,
            (this.redPacketPage.pageNum - 1) * this.redPacketPage.pageSize
          )
          .concat(changeArr);
        this.redPacketPage.total = this.apRegionList.length;
        if (!changeArr.length) {
          this.redPacketPage.pageNum =
            this.redPacketPage.pageNum > 1
              ? this.redPacketPage.pageNum - 1
              : this.redPacketPage.pageNum;
        }
        this.angPowTemplateList.forEach(item => {
          if (item.templateName === this.angPowActiveName) {
            item.regionList = this.apRegionList;
          }
        })
      } else if (this.tableType == "rebate") {
        let changeArr = this.rebateRegionList.slice(
          (this.rebatePage.pageNum - 1) * this.rebatePage.pageSize,
          this.rebatePage.pageNum * this.rebatePage.pageSize
        );
        changeArr.splice(this.rowId, 1);
        this.rebateRegionList = this.rebateRegionList
          .slice(0, (this.rebatePage.pageNum - 1) * this.rebatePage.pageSize)
          .concat(changeArr);
        this.rebatePage.total = this.rebateRegionList.length;
        if (!changeArr.length) {
          this.rebatePage.pageNum =
            this.rebatePage.pageNum > 1
              ? this.rebatePage.pageNum - 1
              : this.rebatePage.pageNum;
        }
        this.rebateTemplateList.forEach(item => {
          if (item.templateName === this.rebateActiveName) {
            item.regionList = this.rebateRegionList;
          }
        })
      } else if (this.tableType == "examine") {
        this.storePreRebateAuditList.splice(this.rowId, 1);
        this.preRebateAuditList = [];
        this.storePreRebateAuditList.forEach((item) => {
          this.preRebateAuditList.push({
            auditType: "02",
            staffId: item.staffId,
          });
        });
      } else if (this.tableType == "examineStore") {
        this.storeRebateAuditList.splice(this.rowId, 1);
        this.rebateAuditList = [];
        this.storeRebateAuditList.forEach((item) => {
          this.rebateAuditList.push({
            auditType: "01",
            staffId: item.staffId,
          });
        });
      } else if (this.tableType == "withdrawal") {
        this.withdrawalDataList.splice(this.rowId, 1);
        this.accountWithdrawStaff = [];
        this.withdrawalDataList.forEach((item) => {
          this.accountWithdrawStaff.push(item.staffId);
        });
        // this.currentRowId = null
      } else if (this.tableType == "examineRefund") {
        this.refundAuditList.splice(this.rowId, 1);
        this.refundAuditDataList = [];
        this.refundAuditList.forEach((item) => {
          this.refundAuditDataList.push({
            auditType: "03",
            staffId: item.staffId,
          });
        });
      } else if (this.tableType == "redStore") {
        this.angPowStoreList.splice(this.rowId, 1);
        this.angPowTemplateList.forEach(item => {
          if (item.templateName === this.angPowActiveName) {
            item.storeList = this.angPowStoreList;
          }
        })
      } else if (this.tableType == "batchRed") {
        let idArr = this.selectStoreDelList.map(item => item.id)
        this.angPowStoreList = this.angPowStoreList.filter(ele => !idArr.includes(ele.id));
        this.angPowTemplateList.forEach(item => {
          if (item.templateName === this.angPowActiveName) {
            item.storeList = this.angPowStoreList
          }
        })
        this.isRedStoreDel = true
      } else if (this.tableType == "redConfig") {
        this.angPowTemplateList.splice(
          this.angPowTemplateList.findIndex((v) => v.templateName === this.angPowActiveName),
          1
        );
        this.angPowActiveName = '公共配置（默认）'
      } else if (this.tableType == "rebateConfig") {
        this.rebateTemplateList.splice(
          this.rebateTemplateList.findIndex((v) => v.templateName === this.rebateActiveName),
          1
        );
        this.rebateActiveName = '公共配置（默认）'
      } else if (this.tableType == "batchRebate") {
        let idArr = this.selectStoreDelList.map(item => item.id)
        this.rebateStoreList = this.rebateStoreList.filter(ele => !idArr.includes(ele.id));
        this.rebateTemplateList.forEach(item => {
          if (item.templateName === this.rebateActiveName) {
            item.storeList = this.rebateStoreList
          }
        })
        this.isRebateStoreDel = true
      } else if (this.tableType == "rebateStore") {
        this.rebateStoreList.splice(this.rowId, 1);
        this.rebateTemplateList.forEach(item => {
          if (item.templateName === this.rebateActiveName) {
            item.storeList = this.rebateStoreList;
          }
        })
      } else if (this.tableType == "profit") {
        this.cutPriceRegionList.splice(this.rowId, 1);
      } else if (this.tableType == "out") {
        this.extraCutPriceRegionList.splice(this.rowId, 1);
      }
      this.dialogDelVisible = false;
    },
    // 回显显示效果
    toggleSelection(rows) {
      console.log(rows);
      Promise.resolve().then(() => {
        if (rows && rows.length) {
          rows.forEach((row) => {
            console.log(row);
            this.$refs.multipleTable.setSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    // 添加提现人
    addWithdraw(type) {
      this.addType = type;
      this.staffListChange();
      this.selectStaffList = []
      if (type == "01") {
        this.selectStaffList.push(...this.withdrawalDataList)
        this.staffDialogVisible2 = true;
        this.staffTitle = "添加提现人";
        this.staffAddTip =
          "说明: 提现人员仅返回创建者及子账号角色的有效账号数据（最多可选择3人提现）";
        // this.isSelection = true
      } else if (type == "02") {
        this.selectStaffList.push(...this.storePreRebateAuditList)
        this.staffDialogVisible = true;
        this.staffTitle = "添加审核人";
        this.staffAddTip =
          "说明: 预留返利审核人仅返回创建者及子账号角色的有效账号数据";
        // this.isSelection = true
      } else if (type == "03") {
        this.selectStaffList.push(...this.storeRebateAuditList)
        this.staffDialogVisible = true;
        this.staffTitle = "添加审核人";
        this.staffAddTip =
          "说明: 门店返利审核人仅返回创建者及子账号角色的有效账号数据";
        // this.isSelection = true
      } else if (type == "04") {
        this.selectStaffList.push(...this.refundAuditList)
        this.staffDialogVisible = true;
        this.staffTitle = "添加审核人";
        this.staffAddTip =
          "说明: 退款审核人仅返回创建者及子账号角色的有效账号数据";
      }
    },
    //管理人员提成指定人员
    administrationstaffListChange(val) {
      if (val) {
        this.staffPage.pageNum = val;
      } else {
        this.staffPage.pageNum = 1;
      }
      this.staffListLoading = true;
      const baseRequest = {
        staffTypes: ["01"],
        "isEnable": true,
        "isQuit": false,
        companyId: this.companyId,
        // pageNum: this.staffPage.pageNum,
        // pageSize: this.staffPage.pageSize,
        pageNum: 1,
        pageSize: 99999,
      };
      _api.getStaffList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.RoyaltystaffListData = res.data.records;
          this.staffPage.total = res.data.total;
          this.staffPage.pageNum = res.data.current;
          this.staffListLoading = false;
          //回显已选提成指定人员
          if (this.RoyaltyAuditList.length > 0) {
            let arralldata = []
            this.RoyaltyAuditList.forEach(irem => {
              this.RoyaltystaffListData.forEach(iremx => {
                if (iremx.staffId === irem.staffId) {
                  arralldata.push(iremx)
                }
              });
            });
            this.RoyaltySelectdata=arralldata
            this.$nextTick(() => {
              arralldata.forEach(irem => {
                this.$refs.multipleTable.$refs.tableGroup.toggleRowSelection(irem);
              });
            })
          }else{
            this.RoyaltySelectdata =this.RoyaltyAuditList
          }
        }
      });
    },
    staffListChange(val) {
      if (val) {
        this.staffPage.pageNum = val;
      } else {
        this.staffPage.pageNum = 1;
      }
      this.staffListLoading = true;
      const baseRequest = {
        staffTypes: ["01"],
        "isEnable": true,
        "isQuit": false,
        companyId: this.companyId,
        pageNum: this.staffPage.pageNum,
        pageSize: this.staffPage.pageSize,
      };
      _api.getStaffList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.staffListData = res.data.records;
          this.staffPage.total = res.data.total;
          this.staffPage.pageNum = res.data.current;
          this.staffListLoading = false;
          let _rows = [];
          this.staffListData.forEach((item) => {
            if (
              this.selectStaffList.some(
                (ele) => ele.staffId == item.staffId
              )
            ) {
              _rows.push(item);
            }
            return item;
          });
          this.toggleSelection(_rows);
        }
      });
    },
    // 切换单选框
    changeRedio(event, row) {
      this.selectWithdrawal = [];
      console.log("event,row:", event, row);
      this.currentRowId = event;
      this.selectWithdrawal.push(row);
    },
    //选择指定提成人员
    RoyaltystaffSelect(e) {
      console.log(e);
      this.RoyaltySelectdata = e
    },
    handleSelect(e) {
      console.log(e);
      this.selectStaffList = this.selectStaffList.filter(item => {
        return !this.staffListData.some(ee => ee.staffId == item.staffId)
      })
      let jsonArr = e;
      let newJson = this.selectStaffList; //盛放去重后数据的新数组
      for (let item1 of jsonArr) {
        //循环json数组对象的内容
        let flag = true; //建立标记，判断数据是否重复，true为不重复
        for (let item2 of newJson) {
          //循环新数组的内容
          if (item1.staffId == item2.staffId) {
            //让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
            flag = false;
          }
        }
        if (flag) {
          //判断是否重复
          newJson.push(item1); //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
        }
      }
      this.selectList = newJson;
      if (this.selectList.length >= 3 && this.addType == "01") {
        // 截取前3位
        this.selectStaffList = []
        this.selectList = newJson.slice(0, 3);
        // 截取3位之后的数组  禁止选中
        let tempArr = newJson.slice(3);
        if (tempArr.length !== 0) {
          this.$message.warning('最多只能选择3个提现人')
          tempArr.forEach((ele) => {
            this.$refs.multipleTable.setSelection(ele, false);
          });
        }
      }
      this.selectStaffList.push(...this.selectList);
      let obj = {};
      let peon = this.selectStaffList.reduce((cur, next) => {
        obj[next.staffId] ? "" : obj[next.staffId] = true && cur.push(next);
        return cur;
      }, [])
      this.selectStaffList = peon
      console.log(this.selectStaffList);
    },
    // 管理人员提成
    adRoyaltySuccess() {
      if (this.RoyaltySelectdata.length == 0) {
        return this.$message.warning('请选择管理人员')
      } else if (this.RoyaltySelectdata.length>3) {
        return this.$message.warning('最多只能添加3个管理人员')
      } else {
        this.RoyaltyAuditList=this.RoyaltySelectdata
        // //去重后加入
        // const uniqueArr = this.RoyaltyAuditList.filter(
        //   (item, index) =>
        //     this.RoyaltyAuditList.findIndex(
        //       (i) => i.staffId === item.staffId
        //     ) === index
        // );
        // this.RoyaltyAuditList = uniqueArr;
        this.RoyaltyDialogVisible = false
      }
    },
    // 提现人/审核人添加
    addHandleSuccess(type) {
      if (type == "01") {
        if (this.addType == "02") {
          this.preRebateAuditList = [];
          let selectStaffList = this.selectStaffList;
          this.storePreRebateAuditList = selectStaffList;
          this.storePreRebateAuditList.forEach((item) => {
            this.preRebateAuditList.push({
              auditType: "02",
              staffId: item.staffId,
            });
          });
        } else if (this.addType == "03") {
          this.rebateAuditList = [];
          let selectStaffList = this.selectStaffList;
          this.storeRebateAuditList = selectStaffList;
          this.storeRebateAuditList.forEach((item) => {
            this.rebateAuditList.push({
              auditType: "01",
              staffId: item.staffId,
            });
          });
        } else if (this.addType == "04") {
          this.refundAuditDataList = [];
          let selectStaffList = this.selectStaffList;
          this.refundAuditList = selectStaffList;
          this.refundAuditList.forEach((item) => {
            this.refundAuditDataList.push({
              auditType: "03",
              staffId: item.staffId,
            });
          });
        }

      } else {
        this.accountWithdrawStaff = [];
        let selectStaffList = this.selectStaffList;
        this.withdrawalDataList = selectStaffList;
        this.withdrawalDataList.forEach((item) => {
          this.accountWithdrawStaff.push(item.staffId);
        });
      }
      this.staffDialogVisible = false;
      this.staffDialogVisible2 = false;
    },
    staffDialogClosed() {
      this.selectStaffList = [];
      this.RoyaltystaffListData = []
    },
    // 提现人/审核人添加取消
    addClosed() {
      this.staffDialogVisible = false;
      this.staffDialogVisible2 = false;
    },
    commandDialogClosed() {
      this.commandDisableShow = false;
      this.command = "";
    },
    // 确认
    confirmBtn() {
      if (!this.rewardWithdrawChannel && this.apIntoMode === 'per') {
        return this.$message.error("请选择员工提现到账方式");
      }
      // if(this.apIntoMode  !== 'per'){
      //   this.rewardWithdrawChannel = null;
      // }
      if (!this.profitMode) {
        this.$message.error("请选择利润模式");
        return false;
      }
      // if (this.isAngPow && !this.apRegionList.length) {
      //   this.$message.error("红包设置开启状态必须配置金额");
      //   return false;
      // }
      if (
        this.source == "online" &&
        !this.refundAuditList.length
      ) {
        this.$message.error("退款指定审核人员不能为空");
        return false;
      }
      if (
        this.source == "online" &&
        this.accountWithdraw == "01" &&
        !this.withdrawalDataList.length
      ) {
        this.$message.error("可提现状态必须配置提现人");
        return false;
      }
      //管理人员提成
      if (this.isManagerReward) {
        if (this.Commissionofficer.length === 0) {
          return this.$message.error("请选择发放提成人员");
        }
        if (this.Commissionofficer.includes('2') && this.RoyaltyAuditList.length === 0) {
          return this.$message.error("请添加指定提成人员");
        }
        if (this.RoyaltyRegionList.length === 0) {
          return this.$message.error("请添加提成规则");
        }
      }
      // if (
      //   this.profitMode == "after" &&
      //   this.isPreRebateStoreAudit == "01" &&
      //   !this.storePreRebateAuditList.length
      // ) {
      //   this.$message.error("预留返利需审核状态必须添加审核人");
      //   return false;
      // }
      // if (
      //   this.profitMode == "after" &&
      //   this.isRebateStore == '01' &&
      //   this.isRebateStoreAudit == "01" &&
      //   !this.storeRebateAuditList.length
      // ) {
      //   this.$message.error("门店返利需审核状态必须添加审核人");
      //   return false;
      // }
      this.commandDisableShow = true;
    },
    // 设置差价利润弹性比例点击
    isCutPriceClick(e) {
      if (this.afterDepreciateStyle == '02') {
        this.$message.warning('请先选择支持降价成交')
      }

    },
    commandDisableSure() {
      if (!this.command) {
        this.$message.error("请输入谷歌验证码");
        return;
      }
      console.log(this.angPowTemplateList);
      console.log(this.rebateTemplateList);
      let angPowArr = []
      this.angPowTemplateList.forEach(item => {
        angPowArr.push({
          isDefault: item.templateName == '公共配置（默认）',
          regionList: item.regionList,
          storeIds: item.storeList && item.storeList.length ? item.storeList.map(item => item.id) : [],
          templateName: item.templateName,
          isAngPowAddPrice: item.isAngPowAddPrice || '02',
        })
      })
      let rebateArr = []
      this.rebateTemplateList.forEach(item => {
        rebateArr.push({
          isDefault: item.templateName == '公共配置（默认）',
          regionList: item.regionList,
          storeIds: item.storeList && item.storeList.length ? item.storeList.map(item => item.id) : [],
          templateName: item.templateName,
          addPriceUseType: item.addPriceUseType || '02',
          isCmccAddPrice: item.isCmccAddPrice || false,
          isPreRebateStore: item.isPreRebateStore || '02',
          isPreRebateStoreAudit: item.isPreRebateStoreAudit || '02',
          isRebateStore: item.isRebateStore || '02',
          isRebateStoreAudit: item.isRebateStoreAudit || '02',
          unUedAddToStaff: item.unUedAddToStaff || '02',
          addToStaffRate: item.addToStaffRate || '',
          preRebateAuditList: item.preRebateAuditList || [],
          rebateAuditList: item.rebateAuditList || [],
          rebateAuditRole: item.rebateAuditRole || "",
          machineTemplateList: item.machineTemplateList || []
        })
      })
      if (this.source == "online") {
        let baseRequest = {
          companyId: this.companyId,
          command: this.command,
          accountWithdraw: this.accountWithdraw,
          accountWithdrawStaff: this.accountWithdrawStaff,
          addPriceSettleStyle: this.addPriceSettleStyle,
          afterDepreciateStyle: this.afterDepreciateStyle,
          apIntoMode: this.apIntoMode,
          rewardLateArrivalType: this.rewardLateArrivalType,
          cutPriceRegionList: this.cutPriceRegionList,
          extraCutPriceRegionList: this.extraCutPriceRegionList,
          isAngPow: this.isAngPow ? "01" : "02",
          isOpenMiniMallShareReward: this.isOpenMiniMallShareReward ? "01" : "02",
          isOpenExtraDepreciateShare: this.isOpenExtraDepreciateShare ? "01" : "02",
          isOpenOutQuote: this.isOpenOutQuote,
          miniMallShareReward: this.miniMallShareReward,
          isCutPrice: this.isCutPrice,
          isDepreciateShare: this.isDepreciateShare ? "01" : "02",
          isStaffWithdrawalAudit: this.isStaffWithdrawalAudit,
          isStaffPayUserAudit: this.isStaffPayUserAudit,
          profitMode: this.profitMode,
          rebateEmbezzleMode: this.rebateEmbezzleMode,
          rebateEmbezzleStaff: this.rebateEmbezzleStaff,
          refundAuditList: this.refundAuditDataList,
          refundAuditRole: this.refundAuditRole,
          userPayeeMode: this.userPayeeMode,
          userPayeeModeTrade: this.userPayeeModeTrade,
          angPowTemplateList: angPowArr,
          rebateRateMethod: this.rebateRateMethod,
          rebateTemplateList: rebateArr,
          arrivalMethod: this.arrivalMethod,
          managerReward: null,
          isManagerReward: '02',
          rewardWithdrawChannel: this.apIntoMode === 'comp' ? 'wechat' : this.rewardWithdrawChannel
        };
        if (this.isManagerReward) {
          baseRequest.isManagerReward = this.isManagerReward ? "01" : "02",
            baseRequest.managerReward = {
              assignFounderList: this.Commissionofficer.includes('2') ? this.RoyaltyAuditList : [],//指定提成人
              managerRewardRegionList: this.RoyaltyRegionList,//管理者提成区间配置
              isManagerAssignFounder: this.Commissionofficer.includes('2') ? '01' : '02',	//管理者提成-是否指派提成人（是：01，否：02）
              isManagerInspector: this.Commissionofficer.includes('1') ? '01' : '02',//	管理者提成-总监是否可以提成（是：01，否：02）
              managerIntoMode: this.managerIntoMode,//	管理者提成-到账模式（到员工账户：per,到公司账户：comp）
              managerRewardWithdrawChannel: this.managerRewardWithdrawChannel,//	管理者提成-奖励提现渠道(alipay:支付宝，wechat:微信)
            }
          if (baseRequest.managerReward.assignFounderList.length > 0) {
            baseRequest.managerReward.assignFounderList = baseRequest.managerReward.assignFounderList.map(item => item.staffId)
          }
        }
        console.log(baseRequest);
        if (this.setType == "true") {
          _api.editOnlinePayConfig(baseRequest).then((res) => {
            if (res.code === 1) {
              this.$message.success("修改成功");
              if (this.$route.query.goback) {
                this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item => item.path === this.$route.path), 1)
                this.$router.push({ path: '/StoreShop/index' })
              } else {
                this.$router.go(-1);
              }
            }
          });
        } else {
          _api.addOnlinePayConfig(baseRequest).then((res) => {
            if (res.code === 1) {
              this.$message.success("设置成功");
              if (this.$route.query.goback) {
                this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item => item.path === this.$route.path), 1)
                this.$router.push({ path: '/StoreShop/index' })
              } else {
                this.$router.go(-1);
              }
            }
          });
        }
      } else {
        let baseRequest = {
          apRegionList: this.apRegionList,
          cutPriceRegionList: this.cutPriceRegionList,
          extraCutPriceRegionList: this.extraCutPriceRegionList,
          command: this.command,
          companyId: this.companyId,
          isAngPow: this.isAngPow ? "01" : "02",
          isOpenMiniMallShareReward: this.isOpenMiniMallShareReward ? "01" : "02",
          isOpenExtraDepreciateShare: this.isOpenExtraDepreciateShare ? "01" : "02",
          isOpenOutQuote: this.isOpenOutQuote,
          miniMallShareReward: this.miniMallShareReward,
          isCutPrice: this.isCutPrice,
          isDepreciateShare: this.isDepreciateShare ? "01" : "02",
          afterDepreciateStyle: this.afterDepreciateStyle,
          isPreRebateStore: this.isPreRebateStore,
          addPriceUseType: this.addPriceUseType,
          isCmccAddPrice: this.isCmccAddPrice,
          isPreRebateStoreAudit: this.isPreRebateStoreAudit,
          isRebateStore: this.isRebateStore,
          isAngPowAddPrice: this.isAngPowAddPrice,
          isRebateStoreAudit: this.isRebateStoreAudit,
          unUedAddToStaff: this.unUedAddToStaff,
          addToStaffRate: this.addToStaffRate,
          preRebateAuditList: this.preRebateAuditList,
          rebateAuditList: this.rebateAuditList,
          profitMode: this.profitMode,
          rebateAuditRole: this.rebateAuditRole,
          rebateRegionList: this.rebateRegionList,
          rebateRateMethod: this.rebateRateMethod,
        };
        if (this.setType == "true") {
          _api.editOfflinePayConfig(baseRequest).then((res) => {
            if (res.code === 1) {
              this.$message.success("修改成功");
              this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item => item.path === this.$route.path), 1)
              this.$router.push({ path: '/StoreShop/index' })
            }
          });
        } else {
          _api.addOfflinePayConfig(baseRequest).then((res) => {
            if (res.code === 1) {
              this.$message.success("设置成功");
              this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item => item.path === this.$route.path), 1)
              this.$router.push({ path: '/StoreShop/index' })
            }
          });
        }
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 单选取消
    rebateAuditRoleClick(e) {
      console.log(e);
      this.rebateAuditRole = e == this.rebateAuditRole ? '' : e
    },
    // 单选取消
    refundAuditRoleClick(e) {
      console.log(e);
      this.refundAuditRole = e == this.refundAuditRole ? '' : e
    },
    // tabs切换
    handleClick(e) {
      console.log(e);
    },
    addTemplate(type) {
      this.addTemplateType = type
      switch (type) {
        case 'red':
          this.addTemplateTitle = "添加个性配置（红包）"
          break;
        case 'machineTemplate':
          this.addTemplateTitle = "添加个性配置（按机型配置后返利）"
          break;
        default:
          this.addTemplateTitle = '添加个性配置（返利）'
      }
      // this.addTemplateTitle = type == 'red'?"添加个性配置（红包）":'添加个性配置（返利）'
      this.addTemplateShow = true
    },
    addTemplateSure() {
      if (this.addTemplateType == 'red') {
        let flag = false
        this.angPowTemplateList.forEach(item => {
          if (item.templateName == '公共配置（默认）' && !item.regionList.length) {
            flag = true
          }
        })
        if (flag) {
          this.$message.error('请先设置红包公共配置参数')
          return
        }
        if (!this.inputTemplateName) {
          this.$message.error('个性配置名称不能为空')
          return
        }
        try {
          this.angPowTemplateList.forEach(item => {
            if (this.inputTemplateName == item.templateName) {
              throw new Error('break')
            }
          })
        } catch (e) {
          this.$message.error('个性配置名称不能重复')
          return false
        }
        this.angPowTemplateList.push({
          templateName: this.inputTemplateName, regionList: this.angPowTemplateList[0].regionList, storeList: []
        })
        this.addTemplateShow = false
      } else if (this.addTemplateType == 'machineTemplate') {
        let flag = false
        this.rebateTemplateList.forEach(item => {
          if (item.templateName == '公共配置（默认）' && !item.regionList.length) {
            flag = true
          }
        })
        if (flag) {
          this.$message.error('请先设置返利公共配置参数')
          return
        }
        if (!this.inputTemplateName) {
          this.$message.error('个性配置名称不能为空')
          return
        }
        try {
          this.machineTemplateList.forEach(item => {
            if (this.inputTemplateName == item.templateName) {
              throw new Error('break')
            }
          })
        } catch (e) {
          this.$message.error('个性配置名称不能重复')
          return false
        }
        this.machineTemplateList.push({
          templateName: this.inputTemplateName,
          regionList: JSON.parse(JSON.stringify(this.rebateRegionList)),
          machineIds: []
        })
        this.rebateTemplateList.forEach((item) => {
          if (item.templateName === this.rebateActiveName) {
            item.machineTemplateList = this.machineTemplateList;
          }
        })
        this.addTemplateShow = false
      } else {
        let flag = false
        this.rebateTemplateList.forEach(item => {
          if (item.templateName == '公共配置（默认）' && !item.regionList.length) {
            flag = true
          }
        })
        if (flag) {
          this.$message.error('请先设置返利公共配置参数')
          return
        }
        if (!this.inputTemplateName) {
          this.$message.error('个性配置名称不能为空')
          return
        }
        try {
          this.rebateTemplateList.forEach(item => {
            if (this.inputTemplateName == item.templateName) {
              throw new Error('break')
            }
          })
        } catch (e) {
          this.$message.error('个性配置名称不能重复')
          return false
        }
        this.rebateTemplateList.push({
          templateName: this.inputTemplateName,
          regionList: this.rebateTemplateList[0].regionList,
          storeList: [],
          machineTemplateList: []
        })
        this.addTemplateShow = false
      }
    },
    machineTemplateListChange(index, data) {
      this.$set(this.machineTemplateList, index, {
        ...this.machineTemplateList[index],
        ...data
      })
      this.rebateTemplateList.forEach((item) => {
        if (item.templateName === this.rebateActiveName) {
          item.machineTemplateList = this.machineTemplateList;
        }
      })
    },
    machineTemplateDel(index) {
      this.machineTemplateList.splice(index, 1)
      this.rebateTemplateList.forEach((item) => {
        if (item.templateName === this.rebateActiveName) {
          item.machineTemplateList = this.machineTemplateList;
        }
      })
    },
    addTemplateClosed() {
      this.inputTemplateName = ""
      this.addTemplateShow = false
    },
    storeHandleSelect(e, type) {
      this.selectStoreDelList = e
      if (type == 'red') {
        this.isRedStoreDel = e.length ? false : true
      } else {
        this.isRebateStoreDel = e.length ? false : true
      }
    },
    addStore(type, val) {
      this.importType = val
      this.sureAddType = val
      if (val == '01') {
        this.angPowTemplateList.forEach(item => {
          if (item.storeList && item.storeList.length) {
            item.storeList.forEach(ele => {
              this.storeDialogForm.assignedStoreIds.push(ele.id)
            })
          }
        })
      } else {
        this.rebateTemplateList.forEach(item => {
          if (item.storeList && item.storeList.length) {
            item.storeList.forEach(ele => {
              this.storeDialogForm.assignedStoreIds.push(ele.id)
            })
          }
        })
      }
      if (type == 'select') {
        this.storeVisible = true
        this.storeHandleCurrentChangeDialog(1)
      } else {
        this.importDialogVisible = true
      }

    },
    storeHandleCurrentChangeDialog(val) {
      console.log(val);
      if (val) {
        this.storeDialogPage.pageNum = val
      }
      let params = {
        companyId: this.companyId,
        pageNum: this.storeDialogPage.pageNum,
        pageSize: this.storeDialogPage.pageSize,
        ...this.storeDialogForm
      }
      this.storeLoading = true
      _api.getApplyStoreList(params).then(res => {
        console.log(res);
        if (res.code === 1) {
          this.storeDialogDataList = res.data.records;
          this.storeDialogPage.pageNum = res.data.current;
          this.storeDialogPage.total = res.data.total;
        }
        this.storeLoading = false
      })
    },
    storeDialogClose() {
      this.storeDialogForm.assignedStoreIds = []
      this.storeVisible = false
    },
    searchStore() {
      this.storeHandleCurrentChangeDialog(1)
    },
    // 标签下拉
    getStoreTagSelect() {
      _api.getStoreTagSelect({ companyId: this.companyId }).then(res => {
        if (res.code == 1) {
          this.storeTagList = res.data
        }
      })
    },
    // 区域下拉
    inspectorAreaSelect() {
      _api.inspectorAreaSelect({ companyId: this.companyId }).then(res => {
        if (res.code == 1) {
          res.data.forEach(item => {
            if (item.areaId) {
              item.name = item.areaName + '-' + item.staffName
            } else {
              item.name = item.staffName
            }
          })
          this.inspectorAreaList = res.data
        }
      })
    },
    addStoreHandleSelect(e) {
      console.log(e);
      this.selectAddStoreList = e
    },
    // 确认添加门店
    addStoreSure() {
      if (!this.selectAddStoreList.length) {
        this.$message.error('请选择门店')
        return
      }
      if (this.sureAddType == '01') {
        this.angPowStoreList = this.selectAddStoreList.concat(this.angPowStoreList)
        this.angPowTemplateList.forEach(item => {
          if (item.templateName === this.angPowActiveName) {
            item.storeList = this.angPowStoreList;
          }
        })
      } else {
        this.rebateStoreList = this.selectAddStoreList.concat(this.rebateStoreList)
        this.rebateTemplateList.forEach(item => {
          if (item.templateName === this.rebateActiveName) {
            item.storeList = this.rebateStoreList;
          }
        })
      }

      this.storeVisible = false
    },
    batchDel(type) {
      this.tableType = type
      this.delDialogTitle = "删除确认";
      this.dialogDelVisible = true;
    },
    // 导入弹框关闭
    importDialogClosed() {
      this.importDialogVisible = false
      this.$refs.upload.clearFiles();
    },
    // 导入弹框确定
    importSuccess() {
      this.$refs.upload.submit();
    },
    download() {
      const link = document.createElement('a')
      link.href = "/static/支付配置门店导入模板.xlsx"
      link.setAttribute('download', '支付配置门店导入模板.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    onUpload(e) {
      this.headers.TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(), '63e42c2444e94c1ebca21d30d2aa39a5'), localStorage.getItem('token') || '')
    },
    uploadFile(params) {
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      formData.append("companyId", this.companyId);
      formData.append("assignedStoreIds", this.storeDialogForm.assignedStoreIds);
      // 发起请求
      _api.analysisStoreExcel(formData).then(res => {
        if (res.code === 1) {
          console.log(res);
          if (this.importType == '01') {
            this.angPowStoreList = res.data.concat(this.angPowStoreList)
            this.angPowTemplateList.forEach(item => {
              if (item.templateName === this.angPowActiveName) {
                item.storeList = this.angPowStoreList;
              }
            })
          } else {
            this.rebateStoreList = res.data.concat(this.rebateStoreList)
            this.rebateTemplateList.forEach(item => {
              if (item.templateName === this.rebateActiveName) {
                item.storeList = this.rebateStoreList;
              }
            })
          }
          this.importDialogVisible = false
        } else {
          this.$refs.upload.clearFiles();
          this.$message({
            type: "warning",
            message: res.msg
          });
        }
      }).catch(err => {
        this.$refs.upload.clearFiles();
      })
    },
    // 分享奖励输入框失焦
    shareRewardBlur() {
      if (this.miniMallShareReward == 0 || this.miniMallShareReward == "") {
        this.$message.error('获得奖励不能为空且必须大于0')
        this.miniMallShareReward = 1
      }
    },
    //限制一位小数
    numberCheck(prop) {
      var str = this.ruleForm[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      this.ruleForm[prop] = str;
    },
    numberCheckData(prop) {
      var str = this[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      this[prop] = str;
    },
    //转数字
    numberFn(prop) {
      if (!this.ruleForm[prop]) {
        return
      }
      var str = this.ruleForm[prop]
      let num = Number(str)
      if (prop == 'rebate' && num == 0) {
        this.ruleForm['rebateLimit'] = 0
      }
      if (prop == 'delayRebate' && num == 0) {
        this.ruleForm['delayRebateLimit'] = 0
      }
      if (prop == 'shopownerReward' && num == 0) {
        this.ruleForm['shopownerRewardLimit'] = 0
      }
      if (prop == 'clerkReward' && num == 0) {
        this.ruleForm['clerkRewardLimit'] = 0
      }
      if (prop == 'cutPriceScope' && num == 0) {
        this.ruleForm['cutPriceScopeLimit'] = 0
      }
      this.ruleForm[prop] = num;
    },
    //限制一位小数
    RoyaltyNumberCheck(prop) {
      var str = this.RoyaltyRuleForm[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      this.RoyaltyRuleForm[prop] = str;
    },
    //限制一位小数
    profitNumberCheck(prop) {
      var str = this.profitRuleForm[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      this.profitRuleForm[prop] = str;
    },
    //限制一位小数
    profitNumberCheck1(prop) {
      var str = this.calculateOutRuleForm[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      this.calculateOutRuleForm[prop] = str;
    },
    //限制两位小数
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this[name] = ""
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
    },
    //转数字
    RoyaltyNumberFn(prop) {
      if (!this.RoyaltyRuleForm[prop]) {
        return
      }
      var str = this.RoyaltyRuleForm[prop]
      let num = Number(str)
      if (prop == 'inspectorReward' && num == 0) {
        this.RoyaltyRuleForm['inspectorRewardLimit'] = 0
      }
      if (prop == 'founderReward' && num == 0) {
        this.RoyaltyRuleForm['founderRewardLimit'] = 0
      }
      this.RoyaltyRuleForm[prop] = num;
    },
    //转数字
    profitNumberFn(prop) {
      if (!this.profitRuleForm[prop]) {
        return
      }
      var str = this.profitRuleForm[prop]
      let num = Number(str)
      if (prop == 'shopownerCutPrice' && num == 0) {
        this.profitRuleForm['shopownerCutPriceLimit'] = 0
      }
      if (prop == 'clerkCutPrice' && num == 0) {
        this.profitRuleForm['clerkCutPriceLimit'] = 0
      }
      this.profitRuleForm[prop] = num;
    },
    //转数字
    profitNumberFn1(prop) {
      if (!this.calculateOutRuleForm[prop]) {
        return
      }
      var str = this.calculateOutRuleForm[prop]
      let num = Number(str)
      if (prop == 'shopownerCutPrice' && num == 0) {
        this.calculateOutRuleForm['shopownerCutPriceLimit'] = 0
      }
      if (prop == 'clerkCutPrice' && num == 0) {
        this.calculateOutRuleForm['clerkCutPriceLimit'] = 0
      }
      this.calculateOutRuleForm[prop] = num;
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .button_top {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 280px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .explain {
    width: 100%;
    background: #f5f6fa;
    border-radius: 10px;
    padding: 15px 30px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #666666;

    .remarks-title {
      width: max-content;
      height: 18px;
      padding: 0 9px;
      color: #ffffff;
      background: #ffaf29;
      border-radius: 10px 9px 9px 0px;
    }

    .remarks-li {
      position: relative;
      padding: 0 10px;
      margin-top: 12px;
    }

    .remarks-li::before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background: #ffaf29;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  /deep/.el-input-group__append {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;
    font-size: 12px;

    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }

  .tipColor {
    color: #ff687b;
  }

  .title-item {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    color: #333333;
    font-weight: 700;
  }

  .business-info {
    height: 50px;
    background: #f5f6fa;
    border-radius: 10px;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    padding: 0 10px;
  }

  .business-info>span {
    margin-right: 50px;
  }

  .profit-type {
    margin-bottom: 21px;
  }

  .profit-type>div {
    margin-bottom: 16px;

    span {
      color: #ff687b;
      font-size: 12px;
    }
  }

  .set-title {
    font-weight: bold;
    color: #333333;
    font-size: 16px;
    height: 16px;
    padding-left: 10px;
    border-left: 3px solid #0981ff;
    margin-bottom: 10px;
  }

  .set-title-little {
    position: relative;
    color: #333333;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .set-title-little::before {
    content: "";
    display: block;
    width: 64px;
    height: 6px;
    background: linear-gradient(to right, rgba(255, 175, 41, 0.61) 39%, rgba(255, 175, 41, 0) 100%);
    opacity: 0.65;
    border-radius: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .staff-dialog {
    /deep/.el-radio__label {
      display: none;
    }
  }

  /deep/.el-tabs__nav-wrap::after {
    display: none !important;
  }

  .command {
    display: flex;
    align-items: center;

    /deep/.el-input {
      width: 300px;
    }
  }

  .line {
    height: 1px;
    background: #edeffd;
    margin-bottom: 20px;
  }

  .tag-list {
    display: flex;
    flex-flow: wrap;
  }

  .tag-style {
    min-width: 88px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    line-height: 12px;
    height: 26px;
    font-size: 12px;
    color: #0981FF;
    background: #FFFFFF;
    border: 1px solid #0981FF;
    border-radius: 13px;
    margin: 10px 0;
    margin-right: 10px;
  }

  .add-store-form {
    .item {
      margin-right: 30px
    }
  }

  .store-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  .step {
    .step-title {
      color: #0981FF;
      margin-bottom: 10px;
      padding-left: 6px;
      border-left: 3px solid;
      border-radius: 1.5px;
    }

    .step-content,
    .step2-content {
      margin-bottom: 26px;
    }

    .step2-content>div {
      display: flex;
    }

    .step2-content {
      .upload-demo {
        display: flex;
        justify-content: start;

        .el-upload-list {
          width: 200px;
        }
      }
    }

  }

  .tip {
    margin-bottom: 12px;

    .tip-title {
      color: #666666;
      margin-right: 12px;
    }

    .tip-content {
      color: #FF687B;
      font-size: 12px;
    }
  }

}

/deep/.demo-ruleForm .el-form-item__content .el-input-group {
  vertical-align: middle;
}

.after-style {
  height: 80px;
  line-height: 30px;
  background: #F5F6FA;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  box-sizing: border-box;
}

.factor-rate {
  width: 100px;
  margin-left: 5px;
}

/deep/.factor-rate .el-input__inner {
  padding: 0 5px;
  text-align: center;
}

.polpleSetstyle {
  width: 100%;
  margin: 15px 0;

}

.dis_center_space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.flex1 {
  flex: 1;
}

.flex-jb {
  justify-content: space-between;
}

.flex-aa {
  align-items: flex-start;
}

.tip-color {
  color: #ff687b;
  font-size: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.normalShow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  padding: 5px;
  font-size: 14px;
  background-color: rgb(249, 230, 224);
}
</style>
