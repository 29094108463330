<template>
    <div class="review-settings">
      <GlobalInfoBar
        title="审单示例图"
        content="可在此处设置审单示例图"
      />
      <div class="merchant-list" style="margin-top: 20px">
        <div class="title">审单示例图</div>
        <div class="flex flex-je">
          <el-button size="small" type="primary" @click="openAddSampleImgDia"
            >添加示例图</el-button
          >
        </div>
        <div>
          <el-form ref="sampleImgForm" :model="sampleImgForm" inline size="small">
            <el-form-item label="使用状态" prop="isUse">
              <el-select
                v-model="sampleImgForm.isUse"
                clearable
                placeholder="请选择使用状态查询"
              >
                <el-option label="开启" :value="true" />
                <el-option label="关闭" :value="false" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round :loading="ampleImgLoading" @click="searchAmpleImg(1)"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <GlobalTable
            ref="GlobalTable"
            v-loading="ampleImgLoading"
            isIndex
            :columns="ampleImgColumns"
            :data="ampleImgDataList"
            :pageSizes="[5,10,20,50]"
            :currentPage="sampleImgForm.pageNum"
            :pageSize="sampleImgForm.pageSize"
            :total="sampleImgForm.total"
            layout="total,sizes, prev, pager, next, jumper"
            @handleCurrentChange="searchAmpleImg"
            @handleSizeChange="handleAmpleImgSizeChange"
          >
            <el-table-column label="示例图" slot="pictureUri" align="center">
              <template slot-scope="{ row }">
                <img :src="row.pictureUri" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
              </template>
            </el-table-column>
            <el-table-column label="说明" slot="instruction" align="center">
              <template slot-scope="{ row }">
                <template v-if="row.instruction">
                  <el-tooltip class="item" effect="dark" :content="row.instruction" placement="top">
                    <div class="nowrap-ellipsis">{{ row.instruction }}</div>
                  </el-tooltip>
                </template>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="使用范围" slot="useScope" align="center">
              <template slot-scope="{ row }">
                <el-select
                  size="small"
                  v-model="row.useScope"
                  @change="useScopeChange(row)"
                >
                  <el-option label="全部机型" value="00" />
                  <el-option label="部分机型" value="01" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="使用机型" slot="setMachineNum" align="center">
              <template slot-scope="{ row }">
                <template v-if="row.useScope === '00'">
                  <span>全部机型</span>
                </template>
                <template v-if="row.useScope === '01'">
                  <div class="txt-underline" @click="editModel(row,'see')">指定{{ row.setMachineNum }}个机型</div>
                  <div class="txt-underline" @click="editModel(row,'add')">添加机型</div>
                </template>
                
              </template>
            </el-table-column>
            <el-table-column label="使用状态" slot="isUse" align="center">
              <template slot-scope="{ row }">
                <el-switch
                  inactive-color="#bebebe"
                  @change="handleIsUseSwitch(row)"
                  v-model="row.isUse"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" slot="operation" align="center">
              <template slot-scope="{ row }">
                <el-button type="warning" size="small" @click="editAmpleImg(row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </GlobalTable>
        </div>
      </div>
      <el-dialog v-if="imageDialogVisible" :title="imageDialogTitle" :visible.sync="imageDialogVisible" :close-on-click-modal="false" width="600px">
        <el-form ref="imageForm" :model="imageFormData" :rules="imageFormRule" label-width="110px">
          <el-form-item label="示例图名称：" prop="pictureName">
            <el-input v-model.trim="imageFormData.pictureName" maxlength="30" placeholder="请输入示例图名称" type="text" size="small" />
          </el-form-item>
          <el-form-item label="示例图图片：" prop="pictureUri">
            <ElImgUpload
              :isBigImg="true"
              :exist-image="imageFormData.pictureUri"
              placeTxt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px"
              @handleChange="sampleHandleChange"/>
          </el-form-item>
          <el-form-item label="示例图说明：">
            <el-input
              size="small"
              type="textarea"
              placeholder="请输入说明"
              v-model="imageFormData.instruction"
              maxlength="50"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="imageDialogVisible = false">取消</el-button>
          <el-button type="primary" :loading="imageBtnLoading" @click.native="imageHandleSure">确定</el-button>
        </span>
      </el-dialog>
      <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div v-if="tipExplain" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;">{{ tipExplain }}</span>
      </div>
      <div class="tip-centent">
        <span>{{ commandTipText }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandSureAdd"
          :loading="commandButLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
      <el-image-viewer v-if="showViewer" :on-close="() => {
        showViewer = false;
      }
        " :url-list="imgList" />
      <SeeModelDia
        v-if="seeModelDiaShow"
        :id="imgRowId"
        :dialogVisible="seeModelDiaShow"
        :brandNoListData="brandNoListData"
        @close="closeSeeModelDia"
        @setMachineUpdate="setMachineUpdate"
        ></SeeModelDia>
      <ModelSelectDia
        v-if="modelSelectDiaShow"
        :id="imgRowId"
        :brandNoListData="brandNoListData"
        :dialogVisible="modelSelectDiaShow"
        @close="closeModelSelectDia"
        @setMachineUpdate="setMachineUpdate"
      ></ModelSelectDia>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  import ElImgUpload from "../../../../components/global/components/elements/upload-element";
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";
  import ModelSelectDia from "./dialog/ModelSelectDia.vue";
  import SeeModelDia from "./dialog/SeeModelDia.vue";
  export default {
    components: { ElImageViewer,ElImgUpload,ModelSelectDia,SeeModelDia },
    data() {
      return {
        brandNoListData: [],
        // 审单示例图
        sampleImgForm: {
          isUse: "",
          pageNum: 1,
          pageSize: 5,
          total: 0,
        },
        // 安全验证
        commandTipText: "",
        tipExplain: "",
        commandType: "",
        commandDisableShow: false,
        command: "",
        commandButLoading: false,
        rowObj: {}, //操作当前行
        ampleImgLoading: false,
        ampleImgDataList: [],
        ampleImgColumns: [
          { label: "示例图名称", prop: "pictureName" },
          { slotName: "pictureUri" }, // 示例图
          { slotName: "instruction" }, // 说明
          { slotName: "useScope" }, // 使用范围
          { slotName: "setMachineNum" }, // 使用机型
          { slotName: "isUse" }, // 使用状态
          { slotName: "operation" }, // 操作
        ],
        imgList: [],
        showViewer: false,
        // 示例图新增/编辑弹框
        imageDialogTitle: "",
        imageDialogVisible: false,
        imageFormData: {
          pictureName: "",
          pictureUri: "",
          instruction: ""
        },
        imageFormRule: {
          pictureName: [{ required: true, message: '请输入示例图名称', trigger: 'change' }],
          pictureUri: [{ required: true, message: '请上传图片', trigger: 'change' }],
        },
        imageBtnLoading: false,
        imgRowId: "",
        seeModelDiaShow: false,
        modelSelectDiaShow: false,
      };
    },
    created() {
      this.getoldBrandSelectList()
      this.searchAmpleImg(1)
    },
    methods: {
      // 获取品牌下拉
      getoldBrandSelectList() {
        _api.oldBrandSelectList().then((res) => {
          this.brandNoListData = res.data;
        });
      },
      handleIsUseSwitch(row) {
        this.commandTipText = row.isUse?'是否确认开启示例图':'是否确认关闭示例图';
        this.tipExplain = row.isUse?'开启后，此示例图将在审单时展示':'关闭后，此示例图将不在审单时展示';
        this.commandType = "imgStatus";
        this.commandDisableShow = true;
        this.rowObj = row
        },
      // 安全提示弹框关闭回调
      commandClosed() {
        this.command = ''
        this.tipExplain = ''
        if(this.commandType === 'imgStatus'){
          this.searchAmpleImg();
        }else{
          this.searchMerchant();
        }
      },
  
      // 审单示例图
      searchAmpleImg(pageNum) {
        this.ampleImgLoading = true;
        _api
          .samplePictureList({
            ...this.sampleImgForm,
            pageNum: pageNum || this.sampleImgForm.pageNum,
          })
          .then((res) => {
            this.ampleImgDataList = res.data.records;
            this.sampleImgForm.pageNum = res.data.current;
            this.sampleImgForm.total = res.data.total;
          })
          .finally(() => {
            this.ampleImgLoading = false;
          });
      },
      handleAmpleImgSizeChange(num) {
        this.sampleImgForm.pageSize = num
        this.searchAmpleImg(1)
      },
      // 添加示例图弹框显示
      openAddSampleImgDia() {
        this.imageDialogTitle = "添加示例图"
        this.imageFormData = {
          pictureName: "",
          pictureUri: "",
          instruction: ""
        }
        this.imageDialogVisible = true
      },
      // 编辑示例图
      editAmpleImg(row) {
        this.imageFormData.pictureName = row.pictureName
        this.imageFormData.pictureUri = row.pictureUri
        this.imageFormData.instruction = row.instruction
        this.imageFormData.samplePictureId = row.id
        this.imageDialogTitle = "编辑示例图"
        this.imageDialogVisible = true
      },
      // 查看示例图大图
      seeSampleImage(row) {
        this.imgList = [];
        this.showViewer = true;
        this.imgList.push(row.pictureUri);
      },
      // 圖片更換
      sampleHandleChange(e) {
        this.imageFormData.pictureUri = e;
      },
      // 示例图保存确定
      imageHandleSure() {
        this.$refs['imageForm'].validate((valid) => {
          if (valid) {
            this.imageBtnLoading = true;
            if(this.imageDialogTitle == '添加示例图'){
              _api.addSamplePicture(this.imageFormData).then((res) => {
                if (res.code === 1) {
                  this.$message.success(res.msg || "操作成功");
                  this.imageDialogVisible = false;
                }
                this.imageBtnLoading = false;
                this.searchAmpleImg(1)
              })
              .catch(() => {
                this.imageBtnLoading = false;
              });
            }else{
              _api.updateSamplePicture(this.imageFormData).then((res) => {
                if (res.code === 1) {
                  this.$message.success(res.msg || "操作成功");
                  this.imageDialogVisible = false;
                  this.searchAmpleImg()
                }
                this.imageBtnLoading = false;
              })
              .catch(() => {
                this.imageBtnLoading = false;
              });
            }
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      editModel(row, type) {
        this.imgRowId = row.id;
        if (type === "see") {
          this.seeModelDiaShow = true;
        }
        if (type === "add") {
          this.modelSelectDiaShow = true;
        }
      },
      // 关闭机型查看弹框
      closeSeeModelDia() {
        this.seeModelDiaShow = false;
      },
      // 关闭机型查看弹框
      closeModelSelectDia() {
        this.modelSelectDiaShow = false;
        this.searchAmpleImg()
      },
      useScopeChange(row) {
        console.log(row,'row');
        let params = {
          samplePictureId: row.id,
          useScope: row.useScope
        }
        _api.updateSamplePictureScope(params).then(res => {
          if(res.code === 1){
            this.$message.success(res.msg || '操作成功');
          }
        })
        if(row.useScope === '01' && row.setMachineNum<=0){
          this.imgRowId = row.id
          this.modelSelectDiaShow = true;
        }
      },
      setMachineUpdate(val) {
        if(val){
          this.searchAmpleImg()
        }
      },
      // 安全验证添加商家
    commandSureAdd() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      this.commandButLoading = true;
      if(this.commandType == "imgStatus") {
        let parame = {
          command: this.command,
          samplePictureId: this.rowObj.id,
          isUse: this.rowObj.isUse,
        };
        _api
          .updateSamplePictureState(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }
    },
    },
  };
  </script>
  <style scoped lang="scss" ref="stylesheet/scss">
  .review-settings {
    .txt-underline{
      color: #0981FF;
      text-decoration: underline;
      cursor: pointer;
    }
    .flex {
      display: flex;
      align-items: center;
    }
    .flex-je {
      display: flex;
      justify-content: flex-end;
    }
    .merchant-list {
      padding: 20px;
      background: white;
      border-radius: 20px;
      .title {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
      }
    }
    .addparto {
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff8080;
    }
    .tip-centent {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }
    .command {
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 300px;
      }
    }
    .tip-txt {
      color: #ff687b;
      font-size: 14px;
    }
    .label-S {
      min-width: 100px;
      text-align: right;
      margin-right: 20px;
      line-height: 32px;
      .label-must {
        color: #ff687b;
        margin-right: 4px;
      }
    }
    .nowrap-ellipsis{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
  </style>
  