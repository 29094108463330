<template>
  <div class="OrderDetails">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_lb@2x.png" alt />
            <div class="case-title_max">订单基本信息</div>
            <div class="right">
              <!-- 回收方式 -->
              <!-- <div class="rightTrue" v-if="pageShowInfo.channel == 'Store'" :style="pageShowInfo.businessType == '01' ? 'background: rgba(255, 102, 51, 1)' : ''">{{pageShowInfo.businessTypes}}</div> -->
              <div class="rightTrue" v-if="pageShowInfo.isNiceBiz == true" style="background: rgba(255, 102, 51, 1)">
                靓机订单
              </div>
              <div class="rightFalse" v-if="pageShowInfo.channel == 'Store'">
                {{ pageShowInfo.recycleWays }}
              </div>
              <div class="rightMid" v-if="pageShowInfo.state === '05' && pageShowInfo.channel == 'Store'
      ">
                差异订单
              </div>
              <div class="rightMid" v-if="pageShowInfo.channel == 'Merchant'">
                回收商自建订单
              </div>
              <div class="rightMid online" v-if="pageShowInfo.isEnablePay === true">
                线上订单
              </div>
              <div class="rightMid" style="background: #7DC165;"
                v-if="pageShowInfo.expressNo && pageShowInfo.expressChannel == '01'">
                繁星物流
              </div>
              <div class="rightMid" style="background: #84c0ff;"
                v-if="pageShowInfo.expressNo && pageShowInfo.expressChannel == '02'">
                线下物流
              </div>
              <div class="see-btn flex" style="margin-left: 20px" v-if="pageShowInfo.customerName && pageShowInfo.customerName != '--'
      " @click="seeCustomerInfo">
                查看用户信息
              </div>
            </div>
          </div>
        </div>
        <div class="details_section">
          <div>
            <span>订单编号：</span>
            <span>{{ this.pageShowInfo.orderNo }}</span>
            <!-- 和列表保持一致 -->
            <span v-if="pageShowInfo.isOpenMatrix && pageShowInfo.isOpenMatrix !== '--'"
              style="margin-left: 20px;font-size: 16px;color:#0981FF ">
              (矩阵订单号：<span class="fc_line" v-if="pageShowInfo.matrixOrderNo && pageShowInfo.matrixOrderNo !== '--'"
                style="color:#0981FF" @click="detailHref(pageShowInfo.matrixOrderNo)">{{ pageShowInfo.matrixOrderNo
                }}</span>
              <span v-else>--</span>
              )
            </span>
            <span
              v-if="(!pageShowInfo.isOpenMatrix || pageShowInfo.isOpenMatrix === '--') && (pageShowInfo.outType && pageShowInfo.outType !== '--')"
              style="margin-left: 20px;font-size: 16px;color: #FFA11B">
              (外部订单号：<span class="fc_line"
                v-if="pageShowInfo.orderNoOneline && pageShowInfo.orderNoOneline !== '--' && pageShowInfo.outType === 'fx'"
                style="color: #FFA11B" @click="detailHref(pageShowInfo.orderNoOneline)">
                {{ pageShowInfo.orderNoOneline }}
              </span>
              <span
                v-else-if="pageShowInfo.orderNoOneline && pageShowInfo.orderNoOneline !== '--' && pageShowInfo.outType !== 'fx'"
                style="color: #FFA11B;">
                {{ pageShowInfo.orderNoOneline }}
              </span>
              <span v-else>--</span>
              )
            </span>
            <!--        <span v-else style="margin-left: 20px;font-size: 12px">(外部订单号：{{pageShowInfo.originalOrderNo||'&#45;&#45;'}})</span>-->
            <!--        <span v-if="pageShowInfo.outType=='ygx' || pageShowInfo.outType=='xm'||pageShowInfo.outType=='lb'||pageShowInfo.outType=='lb2'" style="color:#999999;"-->
            <!--          >（{{pageShowInfo.outType=='ygx'?'一根线订单号':pageShowInfo.outType=='xm'?'零售通订单号':pageShowInfo.outType=='lb'?'SD订单号':'BD订单号'}}：{{ this.pageShowInfo.orderNoOneline }}）</span-->
            <!--        >-->
            <span class="see-btn" v-if="pageShowInfo.isEnablePay === true" style="margin-left: 10px;"
              @click="seeBill">查看交易账单</span>
            <span class="see-btn" v-if="pageShowInfo.expressNo && pageShowInfo.expressChannel == '01'"
              style="margin-left: 10px;" @click="JumpLogistics(pageShowInfo.expressNo)">运单详情</span>
          </div>
          <div>
            <span>订单状态：</span>
            <span>{{ pageShowInfo.states }}</span>
            <span style="color: #FF0000" v-if="pageShowInfo.state != '00' &&
      pageShowInfo.state != '03' &&
      pageShowInfo.isBindBarCode &&
      pageShowInfo.businessType != '01'
      ">(已绑码)</span>
            <span style="color: #FF0000" v-if="pageShowInfo.state != '00' &&
      pageShowInfo.state != '03' &&
      !pageShowInfo.isBindBarCode &&
      pageShowInfo.businessType != '01'
      ">(未绑码)</span>
          </div>
          <div>
            <span>下单时间：</span>
            <span>{{ pageShowInfo.orderTime }}</span>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="pageShowInfo.orderPayExtraView && pageShowInfo.orderPayExtraView !== '--'" name="2">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/yhsk.png" alt />
            <div class="case-title_max">用户收款</div>
          </div>
        </div>
        <div class="details_section" style="height: 160px">
          <div style="display: flex">
            <div style="width: 33%">
              <span>收款人：</span>
              <span>{{ pageShowInfo.orderPayExtraView.name || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>联系电话：</span>
              <span>{{ pageShowInfo.orderPayExtraView.phone || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>身份证号：</span>
              <span>{{ pageShowInfo.orderPayExtraView.userIdCard || "--" }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 33%">
              <span>金额：</span>
              <span>{{ pageShowInfo.orderPayExtraView.amount || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>时间：</span>
              <span>{{ pageShowInfo.orderPayExtraView.createTime || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>审核人：</span>
              <span>{{ pageShowInfo.orderPayExtraView.auditName || "--" }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 33%">
              <span>付款状态：</span>
              <span>{{
      pageShowInfo.orderPayExtraView.transferStatus == "2"
        ? "放款中"
        : pageShowInfo.orderPayExtraView.transferStatus == "4"
          ? "放款成功"
          : pageShowInfo.orderPayExtraView.transferStatus == "5"
            ? "放款失败"
            : pageShowInfo.orderPayExtraView.transferStatus == "6"
              ? "已失效"
              : "--"
    }}</span>
            </div>
            <div style="width: 33%">
              <span>收款人签名：</span>
              <span class="see-btn" v-if="pageShowInfo.orderPayExtraView.userSignImg &&
      pageShowInfo.orderPayExtraView.userSignImg != '--'
      " @click="
      DialoghandleBtn(
        pageShowInfo.orderPayExtraView.userSignImg,
        'userSignImg'
      )
      ">点击查看</span>
              <span v-else>--</span>
            </div>
            <div style="width: 33%">
              <span>身份证照片：</span>
              <span class="see-btn" v-if="pageShowInfo.orderPayExtraView.idcardNationalEmblemImg &&
      pageShowInfo.orderPayExtraView.idcardNationalEmblemImg != '--'
      " @click="
      DialoghandleBtn(pageShowInfo.orderPayExtraView, 'idCard')">点击查看</span>
              <span v-else>--</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 33%">
              <span>收款方式：</span>
              <span>{{
      pageShowInfo.orderPayExtraView.transferChannel == 'wechat' ? '微信' :
        pageShowInfo.orderPayExtraView.transferChannel == 'alipay' ? '支付宝' : '--'
    }}</span>
            </div>
            <div style="width: 33%"
              v-if="pageShowInfo.orderPayExtraView.alipayOrderId && pageShowInfo.orderPayExtraView.alipayOrderId != '--'">
              <span>业务订单号：</span>
              <span>{{ pageShowInfo.orderPayExtraView.alipayOrderId || '--' }}</span>
            </div>
            <div style="width: 33%" v-else>
              <span>收款账户：</span>
              <span>{{ pageShowInfo.orderPayExtraView.alipayAccount || '--' }}</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="pageShowInfo.orderRebateExtraView && pageShowInfo.orderRebateExtraView !== '--'" name="3">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_mdly.png" alt />
            <div class="case-title_max">门店领佣</div>
          </div>
        </div>
        <div class="details_section" style="height: 90px">
          <div style="display: flex">
            <div style="width: 33%">
              <span>领取人：</span>
              <span>{{ pageShowInfo.orderRebateExtraView.name || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>金额：</span>
              <span>{{ pageShowInfo.orderRebateExtraView.amount || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>比例：</span>
              <span v-if="pageShowInfo.orderRebateExtraView.staffRebateRate != '--'">{{
      pageShowInfo.orderRebateExtraView.staffRebateRate + "%" }}</span>
              <span v-else>--</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 33%">
              <span>时间：</span>
              <span>{{ pageShowInfo.orderRebateExtraView.createTime || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>审核人：</span>
              <span>{{ pageShowInfo.orderRebateExtraView.auditName || "--" }}</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="pageShowInfo.orderRefundExtraView && pageShowInfo.orderRefundExtraView !== '--'" name="4">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_jiage.png" alt />
            <div class="case-title_max">退款信息</div>
            <div :style="pageShowInfo.orderRefundExtraView.state == '01'
      ? 'margin-left: 10px;color: green;'
      : 'margin-left: 10px;color: red;'
      ">
              （{{
      pageShowInfo.orderRefundExtraView.state == "00"
        ? "待退款"
        : pageShowInfo.orderRefundExtraView.state == "01"
          ? "退款完成"
          : "退款失败"
    }}）
            </div>
          </div>
        </div>
        <div class="details_section" style="height: 90px">
          <div style="display: flex">
            <div style="width: 33%">
              <span>退款原因：</span>
              <span>{{ pageShowInfo.orderRefundExtraView.cause || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>退款金额：</span>
              <span>{{ pageShowInfo.orderRefundExtraView.amount || "--" }}</span>
            </div>
            <div style="width: 33%">
              <span>时间：</span>
              <span>{{
      pageShowInfo.orderRefundExtraView.createTime || "--"
    }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 33%">
              <span>审核人：</span>
              <span>{{
        pageShowInfo.orderRefundExtraView.auditName || "--"
      }}</span>
            </div>
            <div style="width: 33%">
              <span>退款备注：</span>
              <span>{{ pageShowInfo.orderRefundExtraView.note || "--" }}</span>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="pcaddPric && (pcaddPric.usdAllAngPow || pcaddPric.addPrice)" name="5">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_jiage.png" alt />
            <div class="case-title_max">门店加价</div>
          </div>
        </div>
        <div class="details_table">
          <el-table :data="[pcaddPric]" border style="width: 100%">
            <el-table-column prop="passAddPrice" label="返利加价金额" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.passAddPrice">{{ "￥" + row.passAddPrice }}</div>
                <div v-else>/</div>
              </template>
            </el-table-column>
            <el-table-column prop="addPriceRate" label="加价比例" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.addPriceRate ? row.addPrice + "%" : "/" }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="auditStaffName" label="审核人" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.auditStaffName">
                  {{ row.auditStaffName }}-{{ row.auditStaffMobile }}-{{
      row.auditIsMajor
        ? "创建人"
        : row.auditStaffType == "01"
          ? "子账号"
          : row.auditStaffType == "02"
            ? "总监"
            : row.auditStaffType == "03"
              ? "店长"
              : "店员"
    }}
                </div>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column prop="usdAllAngPow" label="红包加价金额" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.usdAllAngPow ? "￥" + row.usdAllAngPow : "/" }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="usdAllAngPow" label="红包加价明细" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.usdAllAngPow">
                  店员：￥{{ row.usdAngPowStaff || 0 }}，店长：￥{{
      row.usdAngPowSmStaff || 0
    }}
                </div>
                <span v-else>/</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- <div class="details_section" style="height: 50px">
        <div style="display: flex">
          <div style="width: 33%">
            <span>金额：</span>
            <span>{{pageShowInfo.orderRaisePriceExtraView.amount||'--'}}</span>
          </div>
          <div style="width: 33%">
            <span>比例：</span>
            <span v-if="pageShowInfo.orderRaisePriceExtraView.rate != '--'">{{pageShowInfo.orderRaisePriceExtraView.rate+'%'}}</span>
            <span v-else>--</span>
          </div>
          <div style="width: 33%">
            <span>审核人：</span>
            <span>{{pageShowInfo.orderRaisePriceExtraView.auditName||'--'}}</span>
          </div>
        </div>
      </div> -->
      </el-collapse-item>
      <el-collapse-item name="6">
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_shjjxx.png" alt />
            <div class="case-title_max">回收信息</div>
            <div class="left">
              <div>
                <span>询价商家：</span>
                <span>{{ this.pageShowInfo.companyName }}</span>
              </div>
              <div>
                <span>询价门店：</span>
                <span>{{ this.pageShowInfo.storeName }}</span>
              </div>
              <div>
                <span>询价人员：</span>
                <span v-if="pageShowInfo.isStaffSub == true" class="see-btn" @click="seeSubInfo">{{
      this.pageShowInfo.staffName }}-{{
      this.pageShowInfo.staffMobile
    }}</span>
                <span v-else>{{ this.pageShowInfo.staffName }}-{{
      this.pageShowInfo.staffMobile
    }}</span>
                <span class="copy" v-if="pageShowInfo.staffTypes != '--'"
                  @click="pageShowInfo.isStaffSub == true ? seeSubInfo : ''">
                  {{ pageShowInfo.staffTypes }}
                </span>
              </div>
              <div>
                <span style="color: #FF3300!important">用户成交金额：</span>
                <span style="color: #FF3300!important">￥{{ this.pageShowInfo.finalPrice }}</span>
              </div>
              <div>
                <span style="color: #FF3300!important">订单利润（不含红包）：</span>
                <span style="color: #FF3300!important">￥{{
      accAdd(
        this.pageShowInfo.rebateAfter,
        this.pageShowInfo.rebateDepreciate
      )
    }}</span>
              </div>
              <div v-if="pageShowInfo.isOneline">
                <span style="color: #FF3300!important">{{ pageShowInfo.externalName }}成交价：</span>
                <span style="color: #FF3300!important">￥{{ pageShowInfo.chxPrice }}</span>
              </div>
              <div v-if="pageShowInfo.makeUpState == '02'" style="color: rgb(255, 104, 123);">
                商家成交价{{ pageShowInfo.chxPriceSub }}元,平台补贴{{ pageShowInfo.chxPriceExtra }}元
              </div>
            </div>
          </div>
        </div>
        <!-- 回收信息 -->
        <div class="details_table">
          <el-table :data="[pageShowInfo]" border style="width: 100%">
            <el-table-column prop="typeName" label="旧机分类" align="center"></el-table-column>
            <el-table-column prop="brandName" label="旧机品牌" align="center"></el-table-column>
            <el-table-column prop="phoneName" label="旧机名称" align="center"></el-table-column>
            <el-table-column prop="storageMemory" label="旧机规格" align="center"></el-table-column>
            <el-table-column prop="remark" label="下单备注" align="center" :show-overflow-tooltip="true">
              <template slot-scope="{ row }">
                <span>{{ row.remark || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="againPhotoRemark" label="补拍备注" align="center" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="coreFaults" label="功能情况" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.detectionType == '02' &&
      scope.row.detectionInfo != '--'
      " style="color:#0981FF;">
                  旧机已通过系统检测
                </div>
                <div v-if="scope.row.unableDetectionCause &&
      scope.row.unableDetectionCause != '--'
      " style="color:#0981FF;">
                  旧机无法使用系统检测
                </div>
                <span v-if="(scope.row.coreFaults &&
      scope.row.coreFaults != '--' &&
      scope.row.coreFaults.length) ||
      scope.row.detectionType == '02' || pageShowInfo.isOneline
      " class="see-btn" @click="DialoghandleBtn(scope.row, 'faults')">点击查看</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="barCodes" label="绑定条码" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.barCodes != '--' && scope.row.barCodes">
                  <span style="display:block" v-for="(item, index) in scope.row.barCodes.split(',')" :key="index">{{
      item
    }}</span>
                </span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderUnique" label="订单唯一码" align="center">
              <template slot-scope="{row}">
                <span>{{ row.orderUnique || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="brandName" label="旧机照片" align="center">
              <template slot-scope="scope">
                <span class="see-btn" @click="DialoghandleBtn(scope.row, 'imgDialog')">点击查看</span>
              </template>
            </el-table-column>
            <el-table-column prop="imei" label="旧机串号" align="center"></el-table-column>
            <el-table-column prop="expressNo" label="快递单号" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.expressNo != '--' &&
      pageShowInfo.expressChannel == '01'
      " class="see-btn" @click="JumpLogistics(scope.row.expressNo)">{{ scope.row.expressNo }}</span>
                <span v-else-if="scope.row.expressNo != '--' &&
      pageShowInfo.expressChannel != '01'
      ">{{ scope.row.expressNo }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="renewSubsidyPrice" label="换新补贴" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.renewSubsidyPrice != '--' && scope.row.renewSubsidyPrice != 0"
                  style="display:flex;flex-direction:column;juctify-content:center;alihn-items:center;">
                  <span>{{scope.row.renewSubsidyWay=='00'?'旧机加价':'新机抵扣'}}{{ scope.row.renewSubsidyPrice }}元</span>
                  <span class="see-btn" @click="goRenewOrder(scope.row)">查看详情</span>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="one_line" v-if="pageShowInfo.sku &&
      pageShowInfo.sku != '--' && pageShowInfo.sku.skus && pageShowInfo.sku.skus != '--' &&
      pageShowInfo.sku.skus.length
      ">
          <div class="one_line_item">
            <div class="one_line_item_title">规格</div>
            <div class="one_line_item_conent flex">
              <div class="one_line_item_conent_item" v-for="(item, index) in pageShowInfo.sku.skus" :key="index">
                <span style="color:#999999;">{{ item.label }}：</span>
                <span style="color:#333333;" v-for="(ele, j) in item.value" :key="j">{{ ele.valueText }}<span
                    v-if="(j + 1) < item.value.length">/</span></span>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="orderRenewMachines.length > 0" name="7">
        <!-- 换新信息 -->
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_shjjxx.png" alt />
            <div class="case-title_max">换新信息</div>
          </div>
        </div>
        <!-- 换新信息 -->
        <div class="details_table">
          <el-table :data="orderRenewMachines" border style="width: 100%">
            <el-table-column prop="machineTypeName" label="新机分类" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.machineTypeName || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="brandName" label="新机品牌" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.brandName || "--" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="machineName" label="新机机型" align="center"></el-table-column>
            <el-table-column prop="imei" label="新机串号" align="center"></el-table-column>
          </el-table>
        </div>
      </el-collapse-item>
      <el-collapse-item name="8">
        <!-- 回收商家 -->
        <div slot="title" class="details_case" style="text-align: left;">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_shangjia.png" alt />
            <div class="case-title_max">回收商家</div>
            <div class="left">
              <div>
                <span>成交回收商家：</span>
                <span>{{ this.pageShowInfo.merchantName }};</span>
              </div>
              <div>
                <span>报价金额：</span>
                <span>{{ this.pageShowInfo.quotePrice }}</span>
                <span v-if="this.pageShowInfo.rebateEmbezzleMode == 'ack' ||
      this.pageShowInfo.rebateEmbezzleMode == 'ack_after'
      ">(返利金额:￥{{ this.pageShowInfo.allAddPrice }}，返利比例:{{
      this.pageShowInfo.allAddPriceRate
    }}%，差价返利:￥{{
        this.pageShowInfo.rebateDepreciate
      }}，店长红包:￥{{
        this.pageShowInfo.angPowSmStaff
      }}，店员红包:￥{{
        this.pageShowInfo.angPowStaff
      }}，管理提成:￥{{
        this.pageShowInfo.managerReward || 0
      }}，店长利润提成:￥{{
        pageShowInfo.cutPriceSmStaff
      }}，店员利润提成:￥{{ pageShowInfo.cutPriceStaff }}，创收奖励:￥{{
      pageShowInfo.addPriceToStaff
    }}，门店显示价:￥{{ this.pageShowInfo.userShowPrice }}<span
                    v-if="pageShowInfo.miniMallShareReward && pageShowInfo.miniMallShareReward != '--'">，分享奖励:￥{{
      pageShowInfo.miniMallShareReward
    }}</span>)</span>
                <span v-else>(差价返利:{{
        this.pageShowInfo.rebateDepreciate
      }}元，店长红包:￥{{
        this.pageShowInfo.angPowSmStaff
      }}，店员红包:￥{{
        this.pageShowInfo.angPowStaff
      }}，店长利润提成:￥{{
        pageShowInfo.cutPriceSmStaff
      }}，店员利润提成:￥{{ pageShowInfo.cutPriceStaff }}，创收奖励:￥{{
      pageShowInfo.addPriceToStaff
    }}，门店显示价:￥{{ this.pageShowInfo.userShowPrice }}<span
                    v-if="pageShowInfo.miniMallShareReward && pageShowInfo.miniMallShareReward != '--'">，分享奖励:￥{{
      pageShowInfo.miniMallShareReward
    }}</span>)</span>
              </div>
              <div>
                <span>收货确认金额：</span>
                <span>￥{{ this.pageShowInfo.newPrice }}</span>
              </div>
              <div v-if="this.pageShowInfo.state == '05'">
                <!-- 历史差异订单 -->
                <template v-if="this.pageShowInfo.isOldData">
                  <span @click="DialoghandleBtn(pageShowInfo, 'difference')" class="see-difference">查看差异</span>
                </template>
                <!-- 新差异订单 -->
                <!-- 成交商家是矩阵商家的话，带矩阵订单号过去查询；成交商家如果是非矩阵商家，带原订单号查询 -->
                <template v-else>
                  <!-- 平台 -->
                  <a v-if="this.pageShowInfo.isPlatformHandle" class="see-difference" target="_blank"
                    :href="`/difference/platformReview?orderNo=${this.pageShowInfo.isAckMatrix ? this.pageShowInfo.matrixOrderNo : this.pageShowInfo.orderNo}`">
                    查看差异
                  </a>
                  <!-- 门店 -->
                  <a v-else class="see-difference" target="_blank"
                    :href="`/difference/storeReview?orderNo=${this.pageShowInfo.isAckMatrix ? this.pageShowInfo.matrixOrderNo : this.pageShowInfo.orderNo}`">
                    查看差异
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pageShowInfo.businessType != '01' && pageShowInfo.channel != 'Merchant'
      " class="details_table">
          <el-table :data="storeList" border style="width: 100%">
            <el-table-column prop="recycleMerchantName" label="报价商家" align="center">
              <template slot-scope="scope">
                <div v-if="pageShowInfo.isNiceBiz == true">
                  <span class="isdeal" v-if="scope.row.state == '01'">靓机回收</span>
                  <el-tag type="warning" size="small" effect="dark" v-if="scope.row.isInside == true && scope.row.isOriginal == true
      ">内部加价成交
                  </el-tag>
                  <span class="isdeal" v-else-if="scope.row.isOriginal == true">成交</span>
                </div>
                <div v-else>
                  <el-tag type="warning" size="small" effect="dark"
                    v-if="scope.row.isInside == true && scope.row.state == '01'">内部加价成交
                  </el-tag>
                  <span class="isdeal" v-else-if="scope.row.state == '01'">成交</span>
                </div>
                <span class="isdeal1" v-if="scope.row.isOutMerchant">外部</span>
                <span>{{ scope.row.recycleMerchantName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quotePrice" label="报价金额" align="center">
              <template slot-scope="{ row }">
                <span>￥{{ row.quotePrice }}</span>
                <div>
                  <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
                </div>
                <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkQuotePrice}}</span></p>
              </template>
            </el-table-column>
            <el-table-column prop="quoteTime" label="报价时间" align="center"></el-table-column>
            <el-table-column prop="staffName" label="报价人" align="center">
              <template scope="{ row }">
                {{ row.isMockQuoteOpen ? row.mkStaffName : row.staffName }}
              </template>
            </el-table-column>
            <el-table-column prop="staffMobile" label="账号" align="center">
              <template scope="{ row }">
                {{ row.isMockQuoteOpen ? row.mkQuoteStaffPhone : row.staffMobile }}
              </template>
            </el-table-column>
            <el-table-column prop="staffType" label="角色" align="center">
              <template scope="{ row }">
                {{ row.isMockQuoteOpen ? (row.mkQuoteIsMajor === true ? '创建人' : row.mkQuotePositionType == '01' ? '子账号' : row.mkQuotePositionType == '02' ? '报价师' : '--') : row.staffType }}
              </template>
            </el-table-column>
            <el-table-column prop="quoteNum" label="报价次数" align="center">
              <template scope="{ row }">
                <div v-if="row.quoteNum" class="see-btn" @click="Bargain(row)">{{ row.quoteNum }}</div>
                <div v-else>{{ row.quoteNum }}</div>
              </template>
            </el-table-column>
            <!-- 新增 -->
            <el-table-column prop="state" label="报价状态" align="center">
              <template scope="{ row }">
                <span>{{
      row.state == "00-0"
        ? "待报价"
        : row.state == "00-1"
          ? "报价中"
          : row.state == "00-2"
            ? "已报价"
            : row.state == "01"
              ? "已确认"
              : row.state == "10" && row.exclusiveCancelTime != "--"
                ? `取消抢单${row.exclusiveCancelTime}`
                : "交易取消"
    }}</span>
                <p v-if="row.isMockQuoteOpen && row.mkQuoteState == '01'"><span class="price">虚拟报价</span></p>
              </template>
            </el-table-column>
            <el-table-column prop="quoteNote" label="报价备注" align="center">
              <template scope="{ row }">
                <el-tooltip v-if="row.quoteNote != '--'" class="item" effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote" placement="bottom">
                  <span class="quoteNotecss">{{ row.quoteNote || '--' }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="originalQuotePrice" label="报价金额（含差异基金）" align="center">
              <template slot-scope="{ row }">
                <span>￥{{ row.originalQuotePrice }}</span>
                <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkOriginalQuotePrice}}</span></p>
              </template>
            </el-table-column>
            <el-table-column prop="diffFundPrice" label="差异基金" align="center">
              <template slot-scope="{ row }">
                <span>￥{{ row.diffFundPrice }}</span>
                <p v-if="row.isMockQuoteOpen">虚拟：<span class="price">{{row.mkDiffFundPrice}}</span></p>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </el-collapse-item>
      <el-collapse-item name="9">
        <!-- <div class="details_double"> -->
        <!-- <div class="left"> -->
        <div slot="title" class="details_case">
          <div class="case-title">
            <img class="case-title_icon" src="@/assets/images/icon_dddt.png" alt />
            <div class="case-title_max">订单动态</div>
          </div>
        </div>
        <!-- </div> -->
        <div class="details_double">
          <div class="details_double_item" style="display:flex">
            <div class="details_section_record">
              <div class="details_tips">门店端商家</div>
              <div>
                <span>下单时间：</span>
                <span v-if="orderFlow.buildOrder">{{ orderFlow.buildOrder.orderTime }}({{
      orderFlow.buildOrder.staffName
    }}-{{ orderFlow.buildOrder.staffMobile }}-{{
      orderFlow.buildOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div>
                <span>确认时间：</span>
                <span v-if="orderFlow.ackOrder">{{ orderFlow.ackOrder.orderTime }}({{
      orderFlow.ackOrder.staffName
    }}-{{ orderFlow.ackOrder.staffMobile }}-{{
      orderFlow.ackOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div>
                <span>绑码时间：</span>
                <span v-if="orderFlow.bindBarOrder">{{ orderFlow.bindBarOrder.orderTime }}({{
      orderFlow.bindBarOrder.staffName
    }}-{{ orderFlow.bindBarOrder.staffMobile }}-{{
      orderFlow.bindBarOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div>
                <span>取消时间：</span>
                <span v-if="orderFlow.cancelOrder">{{ orderFlow.cancelOrder.orderTime }}({{
      orderFlow.cancelOrder.staffName
    }}-{{ orderFlow.cancelOrder.staffMobile }}-{{
      orderFlow.cancelOrder.staffType
    }})</span>
                <span v-else>--</span>
                <span v-if="orderFlow.cancelOrder" class="see-btn"
                  @click="DialoghandleBtn(orderFlow.cancelOrder, 'cancel')">取消原因</span>
              </div>
              <div>
                <span>作废时间：</span>
                <span v-if="orderFlow.abolishOrder">{{ orderFlow.abolishOrder.orderTime }}({{
      orderFlow.abolishOrder.staffName
    }}-{{ orderFlow.abolishOrder.staffMobile }}-{{
      orderFlow.abolishOrder.staffType
    }})</span>
                <span v-else>--</span>
                <span v-if="orderFlow.abolishOrder" class="see-btn"
                  @click="DialoghandleBtn(orderFlow.abolishOrder, 'tovoid')">作废原因</span>
              </div>
              <div>
                <span>退款时间：</span>
                <span v-if="orderFlow.refundOrder">{{ orderFlow.refundOrder.orderTime }}({{
      orderFlow.refundOrder.staffName
    }}-{{ orderFlow.refundOrder.staffMobile }}-{{
      orderFlow.refundOrder.staffType
    }})</span>
                <span v-else>--</span>
                <span v-if="orderFlow.refundOrder" class="see-btn"
                  @click="DialoghandleBtn(orderFlow.refundOrder, 'refund')">退款原因</span>
              </div>
            </div>
            <div class="details_section_record">
              <div class="details_tips">成交回收商</div>
              <div>
                <span>抢单时间：</span>
                <span v-if="orderFlow.exclusiveOrder">{{ orderFlow.exclusiveOrder.orderTime }}({{
      orderFlow.exclusiveOrder.staffName
    }}-{{ orderFlow.exclusiveOrder.staffMobile }}-{{
      orderFlow.exclusiveOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div>
                <span>报价时间：</span>
                <span v-if="orderFlow.quoteOrder">{{ orderFlow.quoteOrder.orderTime }}({{
      orderFlow.quoteOrder.staffName
    }}-{{ orderFlow.quoteOrder.staffMobile }}-{{
      orderFlow.quoteOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div>
                <span>收货时间：</span>
                <span v-if="orderFlow.takeDeliverOrder">{{ orderFlow.takeDeliverOrder.orderTime }}({{
      orderFlow.takeDeliverOrder.staffName
    }}-{{ orderFlow.takeDeliverOrder.staffMobile }}-{{
      orderFlow.takeDeliverOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div v-if="pageShowInfo.state == '05'">
                <span>申请追差时间：</span>
                <span v-if="orderFlow.aprOrder">{{ orderFlow.aprOrder.orderTime }}({{
      orderFlow.aprOrder.staffName
    }}-{{ orderFlow.aprOrder.staffMobile }}-{{
      orderFlow.aprOrder.staffType
    }})</span>
                <span v-else>--</span>
              </div>
              <div v-if="orderFlow.cancelReceiptOrder">
                <span>申请撤销收货：</span>
                <span v-if="orderFlow.cancelReceiptOrder">{{ orderFlow.cancelReceiptOrder.orderTime }}({{
      orderFlow.cancelReceiptOrder.staffName
    }}-{{ orderFlow.cancelReceiptOrder.staffMobile }}-{{
      orderFlow.cancelReceiptOrder.staffType
    }})<span style="margin-left:10px;"
                    :class="{ 'txt-red': orderFlow.cancelReceiptOrder.cancelReceiptState != '02', 'txt-green': orderFlow.cancelReceiptOrder.cancelReceiptState == '02' }">{{
      orderFlow.cancelReceiptOrder.cancelReceiptState == '01' ? '待审核' :
        orderFlow.cancelReceiptOrder.cancelReceiptState == '02' ? '审核通过' : '已驳回'
    }}</span></span>
                <span v-else>--</span>
                <span v-if="orderFlow.cancelReceiptOrder" class="see-btn" style="margin-left:10px;"
                  @click="DialoghandleBtn(orderFlow.cancelReceiptOrder, 'cancelReceipt')">查看详情</span>
              </div>
              <div>
                <span>退回时间：</span>
                <span v-if="orderFlow.returnOrder">{{ orderFlow.returnOrder.orderTime }}({{
      orderFlow.returnOrder.staffName
    }}-{{ orderFlow.returnOrder.staffMobile }}-{{
      orderFlow.returnOrder.staffType
    }})</span>
                <span v-else>--</span>
                <span v-if="orderFlow.returnOrder" class="see-btn"
                  @click="DialoghandleBtn(orderFlow.returnOrder, 'returns')">退回原因</span>
              </div>
            </div>
            <div class="details_section_record"
              v-if="(isOpenSelfBuyNiceMachine || pageShowInfo.isNiceBiz || niceBiz == '01') && (pageShowInfo.niceBizState == '01' || pageShowInfo.niceBizState == '02' || pageShowInfo.niceBizState == '03')">
              <div class="details_tips" style="background-color: #ffaf29;">靓机回收商</div>
              <div>
                <span>靓机收货时间：</span>
                <span v-if="orderFlow.ackNiceOrder">{{ orderFlow.ackNiceOrder.orderTime }}({{
      orderFlow.ackNiceOrder.staffName
    }}-{{ orderFlow.ackNiceOrder.staffMobile }}-{{
      orderFlow.ackNiceOrder.staffType
    }}) ({{ orderFlow.ackNiceOrder.niceChannel == 'Store' ? '门店确认' : '靓机商确认' }})</span>
                <span v-else>--</span>
              </div>
              <div v-if="orderFlow.cancelNiceOrder">
                <span>取消靓机时间：</span>
                <span>{{ orderFlow.cancelNiceOrder.orderTime }}({{
      orderFlow.cancelNiceOrder.staffName
    }}-{{ orderFlow.cancelNiceOrder.staffMobile }}-{{
      orderFlow.cancelNiceOrder.staffType
    }})({{ orderFlow.cancelNiceOrder.niceChannel == 'Store' ? '门店取消' : '靓机商取消' }})</span>
              </div>
              <div v-if="orderFlow.refundNiceOrder">
                <span>退回靓机时间：</span>
                <span>{{ orderFlow.refundNiceOrder.orderTime }}({{
      orderFlow.refundNiceOrder.staffName
    }}-{{ orderFlow.refundNiceOrder.staffMobile }}-{{
      orderFlow.refundNiceOrder.staffType
    }})({{ orderFlow.refundNiceOrder.niceChannel == 'Store' ? '门店退回' : '靓机商退回' }})</span>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <!-- 查看报价记录弹框 -->
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看报价记录" :visible="viewrecords"
      @close="viewrecordsClose" top="15%" bottom="10%">
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table :data="getUpdatedQuoteLog" max-height="300" border style="width: 100%" id="updatedOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="160" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
      row.isMajor
        ? "创建人"
        : row.positionType == "01"
          ? "子账号"
          : "报价师"
    }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
              <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkQuotePrice}}</span></p>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote || '--' }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.originalQuotePrice }}</span>
              <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkOriginalQuotePrice}}</span></p>
            </template>
          </el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.diffFundPrice }}</span>
              <p v-if="row.isMockQuoteOpen">虚拟：<span class="price">{{row.mkDiffFundPrice}}</span></p>
            </template>
          </el-table-column>
        </el-table>
        <div style="color: #0981ff; font-size: 16px; margin: 20px 0" v-if="remakeQuoteLog && remakeQuoteLog.length">
          补拍后报价记录
        </div>
        <el-table v-if="remakeQuoteLog && remakeQuoteLog.length" :data="remakeQuoteLog" max-height="300" border
          style="width: 100%" id="remakeOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="remakeTime" width="100" label="补拍时间" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="100" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
      row.isMajor
        ? "创建人"
        : row.positionType == "01"
          ? "子账号"
          : "报价师"
    }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
              <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkQuotePrice}}</span></p>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.originalQuotePrice }}</span>
              <p v-if="row.isMockQuoteOpen">虚拟报价：<span class="price">¥{{row.mkOriginalQuotePrice}}</span></p>
            </template>
          </el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.diffFundPrice }}</span>
              <p v-if="row.isMockQuoteOpen">虚拟：<span class="price">{{row.mkDiffFundPrice}}</span></p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--弹框-->
    <GlobalDialog :title="dialogTitle" :show="dialogVisibleOrder" :width="840" top="10vh"
      @resetPopupData="resetPopupData" @submitPopupData="submitPopupData" @onClose="resetPopupData">
      <div class="fault-box function_box" v-if="this.dialogTitle === '旧机功能情况'">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :externalorderType="externalorderType" :datainfo="detailData" />
        </div>
        <div>
         <!-- 估价单验机报告 -->
         <div class="test-box">
            <div class="test-result" v-if="detailData.functionView.errorList.length">
              <Title :title="'异常项（共'+ detailData.functionView.errorList.length +'项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="detailData.unableDetectionCause &&
                detailData.detectionType == '01'
                ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="detailData.detectionType == '02'">
                <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList"></TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
              <Title :title="'正常项（共'+ detailData.functionView.normalList.length +'项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList"></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0;">
          <Title title="店员备注" />
          <Remark :remark="detailData.remark" :againPhotoRemark="detailData.againPhotoRemark" />
        </div>
      </div>
      <div class="img-box" v-if="this.dialogTitle === '旧机图片' || this.dialogTitle === '收款人签名' || this.dialogTitle === '身份证照片'
      ">
        <div class="img-tips-box" v-if="this.dialogTitle === '旧机图片'">
          <div @click="chooseImages(0)" class="img-tips" :class="imgShow ? 'img-tips-choose' : 'img-tips-default'">
            旧机照片
          </div>
          <div @click="chooseImages(1)" v-if="this.DialogImgList[1].length > 0" class="img-tips"
            :class="imgShow ? 'img-tips-default' : 'img-tips-choose'">
            补拍照片
          </div>
        </div>
        <div class="img-tips-box" v-if="this.dialogTitle === '身份证照片'">
          <div @click="chooseImages(0)" class="img-tips" style="width:120px"
            :class="imgShow ? 'img-tips-choose' : 'img-tips-default'">
            身份证人像面
          </div>
          <div @click="chooseImages(1)" v-if="this.DialogImgList[1].length > 0" class="img-tips" style="width:120px"
            :class="imgShow ? 'img-tips-default' : 'img-tips-choose'">
            身份证国徽面
          </div>
        </div>
        <div class="block" v-if="this.dialogTitle === '旧机图片'">
          <div class="imgs-num" v-if="DialogImgList[imgIndex].length > 0
      ">
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <viewer :images="DialogImgList[imgIndex]">
            <el-carousel trigger="click" height="80vh" @change="getindex">
              <el-carousel-item v-for="item in this.DialogImgList[this.imgIndex]" :key="item.imgNo">
                <div class="sec_img">
                  <img :src="item.img" alt="" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </viewer>
        </div>
        <div class="block" v-else>
          <viewer :images="DialogImgList[imgIndex]">
            <el-carousel trigger="click" height="260px" @change="getindex">
              <el-carousel-item v-for="item in this.DialogImgList[this.imgIndex]" :key="item.imgNo">
                <div class="sec_img" style="height:260px;">
                  <img style="height:260px;" :src="item.img" alt="" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </viewer>
        </div>
      </div>
      <div class="difference" v-if="this.dialogTitle === '查看回收差异'">
        <div style="display:flex">
          <div class="mb-10" style="flex:0.3">
            <span style="font-weight: 700;"> 承 担 方:</span>
            <span class="block-ml-10">{{
      dialogDiff.undertaker == "00"
        ? "回收商承担"
        : dialogDiff.undertaker == "01"
          ? "门店商承担"
          : "双方承担"
    }}</span>
          </div>
          <div class="mb-10" style="flex:0.5">
            <span style="font-weight: 700;"> 处理状态:</span>
            <span class="block-ml-10">{{
        dialogDiff.depreciateState == "00"
          ? "待确认"
          : dialogDiff.depreciateState == "01"
            ? "已确认"
            : "已拒绝"
      }}</span>
          </div>
          <div style="flex:0.3"></div>
        </div>
        <div style="display:flex">
          <div class="mb-10" style="flex:0.3">
            <span style="font-weight: 700;"> 初始报价:</span>
            <span class="block-ml-10">￥{{ dialogDiff.quotePrice }}</span>
          </div>
          <div class="mb-10" style="display: flex; flex:0.5">
            <span style="font-weight: 700;">降价金额: </span>
            <div class="money" v-if="dialogDiff.undertaker == '00' || dialogDiff.undertaker == '02'
      ">
              ￥{{ dialogDiff.depreciatePriceRecycle }}
              <div>回收商承担</div>
            </div>
            <div class="money1" v-if="dialogDiff.undertaker == '01' || dialogDiff.undertaker == '02'
      ">
              ￥{{ dialogDiff.depreciatePriceStore }}
              <div>门店商承担</div>
            </div>
          </div>
          <div class="mb-10" style="flex:0.3">
            <span style="font-weight: 700;"> 实际回收:</span>
            <span class="block-ml-10">￥{{ dialogDiff.newPrice }}</span>
          </div>
        </div>

        <div class="mb-10" style="display: flex;">
          <span style="font-weight: 700;"> 差异凭证:</span>
          <div v-if="dialogDiff.depreciateImgss.length > 0">
            <viewer :images="dialogDiff.depreciateImgss">
              <img class="diff-imgs" v-for="(item, index) in dialogDiff.depreciateImgss" :key="index" :src="item"
                alt="" />
            </viewer>
          </div>
          <div v-else class="block-ml-10">无</div>
        </div>
        <div class="mb-10">
          <span style="font-weight: 700;"> 差异日志:</span>
          <!-- <span class="block-ml-10">{{dialogDiff.depreciateRemark}}</span> -->
          <div style="margin-top:10px">
            <div class="dialogDiff_double_item" style="display:flex">
              <div class="details_section">
                <div class="details_tips">成交回收商</div>
                <div>
                  <span>发起时间：</span>
                  <span v-if="orderFlow.depreciateInitiatorOrder">{{ orderFlow.depreciateInitiatorOrder.orderTime }}({{
      orderFlow.depreciateInitiatorOrder.staffName
    }}-{{ orderFlow.depreciateInitiatorOrder.staffType }})</span>
                  <span v-else>--</span>
                </div>
                <div>
                  <span>差异原因：</span>
                  <span v-if="dialogDiff.depreciateRemark">{{
      dialogDiff.depreciateRemark
    }}</span>
                  <span v-else>--</span>
                </div>
                <div v-if="orderFlow.depreciateCancelOrder">
                  <span>取消时间：</span>
                  <span>{{ orderFlow.depreciateCancelOrder.orderTime }}
                    ({{
      orderFlow.depreciateCancelOrder.staffName
    }}-{{ orderFlow.depreciateCancelOrder.staffType }})</span>
                </div>
              </div>
              <div class="details_section" v-if="dialogDiff.depreciateState != '00' &&
      dialogDiff.undertaker != '00'
      ">
                <div class="details_tips">门店端商家</div>
                <div>
                  <span>{{
      dialogDiff.depreciateState == "10" ? "拒绝" : "通过"
    }}时间：</span>
                  <span v-if="orderFlow.depreciateAckOrder">{{ orderFlow.depreciateAckOrder.orderTime || "--" }}({{
                    orderFlow.depreciateAckOrder.staffName
                    }}-{{ orderFlow.depreciateAckOrder.staffType }})</span>
                </div>
                <div v-if="dialogDiff.depreciateState == '10' && orderFlow.depreciateAckOrder">
                  <span>拒绝原因：</span>
                  <span>{{ orderFlow.depreciateAckOrder.remark }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.dialogTitle === '查看取消原因'">
        <span>{{ dialogcancel.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看作废原因'">
        <span>{{ dialogtovoid.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看退回原因'">
        <span>{{ dialogreturns.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看退款原因'">
        <span>{{ dialogreturns.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '撤销收货详情' && cancelReceiptInfo">
        <div class="flex-aa">
          <span style="color:#333">申请原因：</span>
          <span class="flex1">{{ cancelReceiptInfo.applyRemark }}</span>
        </div>
        <div class="flex-aa mt-20">
          <span style="color:#333">申请图片：</span>
          <div class="flex">
            <div v-for="(ele, index) in cancelReceiptInfo.applyProofImg" :key="index">
              <img style="width:100px;height:100px;margin-right:20px;border-radius: 10px;border: 1px dashed #eee;"
                :src="ele" alt="" @click="seeApplyProofImg(ele)">
            </div>
          </div>
        </div>
        <div class="flex-aa mt-20 cancel-receipt-info">
          <div style="flex:0.5">
            <div class="details_tips">成交回收商</div>
            <div>
              <span>申请人：</span>
              <span>{{ cancelReceiptInfo.applyStaffName }}</span>
            </div>
            <div class="mt-10">
              <span>申请时间：</span>
              <span>{{ cancelReceiptInfo.applyTime }}</span>
            </div>
          </div>
          <div style="flex:0.5">
            <div class="details_tips">门店端商家</div>
            <div>
              <span>审批人：</span>
              <span>{{ cancelReceiptInfo.auditStaffName || '--' }}</span>
            </div>
            <div class="mt-10">
              <span>审批时间：</span>
              <span>{{ cancelReceiptInfo.auditTime || '--' }}</span>
            </div>
            <div class="mt-10">
              <span>审批结果：</span>
              <span
                :class="{ 'txt-red': cancelReceiptInfo.auditStatus != '02', 'txt-green': cancelReceiptInfo.auditStatus == '02' }">{{
                cancelReceiptInfo.auditStatus == '01' ? '待审核' : cancelReceiptInfo.auditStatus == '02' ? '已通过' : '已驳回'
                }}</span>
            </div>
            <div v-if="cancelReceiptInfo.auditStatus == '03'" class="flex-aa mt-10">
              <span>拒绝原因：</span>
              <span class="flex1">{{ cancelReceiptInfo.auditRemark }}</span>
            </div>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <el-image-viewer :zIndex="99999" v-if="showViewer" :on-close="() => {
      showViewer = false;
    }
      " :url-list="imgList" />
    <el-dialog title="从账号信息" :visible.sync="subInfoShow" :close-on-click-modal="false" width="300px">
      <div style="font-size:14px;color:#333333;padding-bottom:21px">
        <div class="flex" style="margin-bottom:20px">
          <span style="margin-bottom: 10px;">微信头像：</span><img style="width:70px;height:70px;border-radius:50%"
            :src="pageShowInfo.createStaffSubImg" alt="" />
        </div>
        <div class="flex" style="margin-bottom:20px">
          <span>微信昵称：</span><span style="color:#666666;">{{
            pageShowInfo.createStaffSubNickName
            }}</span>
        </div>
        <div class="flex" style="margin-bottom:20px">
          <span>从账号姓名：</span><span style="color:#666666;">{{
            pageShowInfo.createStaffSubName || "--"
            }}</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="用户登记信息" :visible.sync="customerInfoShow" :close-on-click-modal="false" width="300px">
      <div style="font-size:14px;color:#333333;padding-bottom:21px">
        <div class="flex" style="margin-bottom:20px">
          <span>用户姓名：</span><span style="color:#666666;">{{ pageShowInfo.customerName }}</span>
        </div>
        <div class="flex" style="margin-bottom:20px">
          <span>手机号：</span><span style="color:#666666;">{{ pageShowInfo.customerPhone }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getExternalName, getOutNameMake } from "@/utils/common";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Title from "./componets/Title.vue"
import Remark from "@/views/RecallOrder/componets/Remark.vue";

export default {
  name: "OrderDetails",
  components: { TestTable, TestDeviceInfo, ElImageViewer, Title, Remark },
  data() {
    return {
      orderRenewMachines: [],
      externalorderType: "",
      // 功能情况接口
      detailData: {
        functionView:{
          normalList:[],
          errorList:[],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: []
      },
      // 报价记录弹框
      viewrecords: false,
      getUpdatedQuoteLog: [],//报价记录
      remakeQuoteLog: [],//补拍后报价记录
      // ****************
      // 功能情况
      functionList: [],
      dialogTitle: "",
      dialogVisibleOrder: false,
      customerInfoShow: false,
      orderNo: "",
      tabledata: [],
      faultsList: [],
      DialogImgList: [[], []],
      imgList: [],
      showViewer: false,
      imgShowIndex: 0,
      imgShow: true,
      imgIndex: 0,
      dialogDiff: {},
      // 自增放入页面的数据(经过处理后)
      pageShowInfo: {},
      // 报价回收商列表
      storeList: [],
      orderFlow: {
        refundNiceOrder: { orderTime: "--", niceChannel: "" },
        cancelNiceOrder: { orderTime: "--", niceChannel: "" },
        ackNiceOrder: { orderTime: "--", niceChannel: "" },
        buildOrder: { orderTime: "--" },
        ackOrder: { orderTime: "--" },
        bindBarOrder: { orderTime: "--" },
        cancelOrder: { orderTime: "--" },
        abolishOrder: { orderTime: "--" },
        quoteOrder: { orderTime: "--" },
        takeDeliverOrder: { orderTime: "--" },
        returnOrder: { orderTime: "--" },
        exclusiveOrder: { orderTime: "--" },
      },
      systemTestList: [
        {
          value: "01",
          key: "mobileNetworks",
          lable: "1.移动数据",
        },
        {
          value: "",
          key: "wlan",
          lable: "2.WI-FI",
        },
        {
          value: "",
          key: "bluetooth",
          lable: "3.蓝牙",
          // }, {
          //   value: "",
          //   key: 'conversation',
          //   lable: '4.通话功能',
        },
        {
          value: "",
          key: "camera",
          lable: "4.摄像头",
        },
        {
          value: "",
          key: "fingerprintOrFace",
          lable: "5.指纹/面容识别",
        },
        {
          value: "",
          key: "screenTouch",
          lable: "6.触屏功能",
        },
        {
          value: "",
          key: "screenDisplay",
          lable: "7.屏幕显示",
        },
        {
          value: "",
          key: "conversation",
          lable: "8.拨打电话"
        },
        {
          value: "",
          key: "charge",
          lable: "9.充电功能"
        }
      ],
      isOpenSelfBuyNiceMachine: false,
      niceBiz: "",
      isSystem: false,
      isUnableTest: false,
      detectionInfo: null,
      cameraCause: [],
      subInfoShow: false,
      pcaddPric: null, //加价详情
      lineFaults: [
        {
          text: "屏幕显示",
          code: "screenDisplayCode",
          describe: "screenDisplay",
        },
        {
          text: "触摸",
          code: "touchCode",
          describe: "touch",
        },
        {
          text: "拍摄",
          code: "shootCode",
          describe: "shoot",
        },
        {
          text: "WIFI/蓝牙",
          code: "wlanCode",
          describe: "wlan",
        },
        {
          text: "通话",
          code: "callCode",
          describe: "call",
        },
        {
          text: "面容/指纹",
          code: "faceFingerprintCode",
          describe: "faceFingerprint",
        },
      ],
      cancelReceiptInfo: null, //撤销订单详情
      activeNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    };
  },
  created() {
    this.$store.commit("tagsView/ROUTER_PATH", this.$route.path);
    this.ClearSeachParams();
    if (this.$route.query.type == "edit") {
      this.orderNo = this.$route.query.id
      _api.orderInfo({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          this.orderRenewMachines = res.data.orderRenewMachines
          this.isOpenSelfBuyNiceMachine = res.data.isOpenSelfBuyNiceMachine
          this.niceBiz = res.data.niceBiz
          const outMake = getOutNameMake(res.data.outType).make;
          const externalName = this.getExternalName(res.data.outType);
          const data = res.data ?? { fineness: { fineness: [] } };
          if (res.data.outType === 'lb' || res.data.outType === 'lb2') {
            data.fineness.fineness = data.fineness.fineness.map(v => {
              v.value = Array.isArray(v.value) ? v.value : [];
              v.value = v.value.map(v => {
                return {
                  isNormal: v.isNormal,
                  valueText: v.isNormal ? '正常' : '异常',
                }
              })
              return v;
            })
          }
          this.$store.commit("tagsView/GET_ORDERDETAILS", { ...data, outMake, externalName });
        } else {
          this.$message.error("res.msg");
        }
      });
      this.getStoreList();
      this.getOrderFlow();
      this.plusPrice();
    }
  },
  computed: {
    orderDetailsList() {
      return this.$store.state.tagsView.orderDetailsList;
    },
  },
  watch: {
    orderDetailsList(n) {
      const pageShowInfo = JSON.parse(JSON.stringify(n));
      this.pageShowInfo = this.handleData(pageShowInfo);
    },
  },
  methods: {
    // 跳转至换新订单
    goRenewOrder(row) {
      console.log(234, row);
      let routeData = this.$router.resolve({
        path: "/newMarkup/renewOrder",
        query: { type: "edit", orderNo: row.orderNo, activityId: row.activityId, companyId: row.companyId },
      });
      window.open(routeData.href, "_blank");
    },
    // 查看报价记录
    Bargain(row) {
      console.log(row, "------------");
      // this.storeShow = false;
      this.viewrecords = true;
      _api
        .getUpdatedQuoteLog({
          merchantId: row.recycleMerchantId,
          orderNo: row.orderNo,
        })
        .then((res) => {
          console.log(res.data, "报价信息");
          this.getUpdatedQuoteLog = res.data.normal;
          this.remakeQuoteLog = res.data.remake;
        });
    },
    viewrecordsClose() {
      this.viewrecords = false;
    },
    getExternalName,
    ClearSeachParams() {
      this.$store.dispatch("tagsView/ClearSeachParams", "");
    },
    DialoghandleBtn(row, type) {
      console.log(row);
      this.dialogVisibleOrder = true;
      if (type === "faults") {
        this.dialogTitle = "旧机功能情况";
        this.externalorderType = row.outType
        // 功能情况接口
        _api.getInspectionReport({ orderNo: row.orderNo }).then(res => {
          this.detailData = res.data;
          if(res.data.againPhotoRemark){
          this.detailData.againPhotoRemark=res.data.againPhotoRemark.split("//")
        }else{
          this.detailData.againPhotoRemark=[]
        }
        })
      } else if (type === "imgDialog") {
        this.dialogTitle = "旧机图片";
        this.DialogImgList = [
          [...(row.images == "--" || !row.images ? [] : row.images)],
          [...(row.reImages == "--" || !row.reImages ? [] : row.reImages)],
        ];
      } else if (type === "difference") {
        this.dialogTitle = "查看回收差异";
        this.dialogDiff = row;
      } else if (type === "cancel") {
        this.dialogTitle = "查看取消原因";
        this.dialogcancel = row;
      } else if (type === "tovoid") {
        this.dialogTitle = "查看作废原因";
        this.dialogtovoid = row;
      } else if (type === "returns") {
        this.dialogTitle = "查看退回原因";
        this.dialogreturns = row;
      } else if (type === "refund") {
        this.dialogTitle = "查看退款原因";
        this.dialogreturns = row;
      } else if (type == "userSignImg") {
        this.dialogTitle = "收款人签名";
        this.DialogImgList = [[{ img: row }], []];
      } else if (type === "cancelReceipt") {
        this.dialogTitle = "撤销收货详情";
        this.findCancelReceiptInfo()
      } else if (type == "idCard") {
        this.dialogTitle = "身份证照片";
        this.DialogImgList = [[{ img: row.idcardNationalEmblemImg }], [{ img: row.idcardPortraitImg }]];
      }
    },
    // -撤销收货详情
    findCancelReceiptInfo() {
      _api.findCancelReceiptInfo({ orderNo: this.orderNo }).then(res => {
        this.cancelReceiptInfo = res.data
      })
    },
    chooseImages(e) {
      if (e == 0) {
        this.imgIndex = 0;
        this.imgShow = true;
      }
      if (e == 1) {
        this.imgIndex = 1;
        this.imgShow = false;
      }
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    /** 获取报价商 */
    getStoreList() {
      _api.orderStoreList({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
    },
    JumpLogistics(v) {
      let id = v;
      this.$router.push({ path: "/logisticsManage/order", query: { id } });
    },
    // 处理详情第二层数据
    handleFlowData(param) {
      const arr = [
        "abolishOrder", // 作废
        "ackOrder", // 确认
        "bindBarOrder", // 绑码
        "buildOrder", // 下单
        "cancelOrder", // 取消
        "exclusiveOrder", // 抢单
        "quoteOrder", // 报价
        "returnOrder", // 退回
        "takeDeliverOrder", // 收货
      ];
      for (const item of arr) {
        if (!param[item] || param[item] == undefined || param[item] == "") {
          param[item] = false;
        } else {
          for (const key in param[item]) {
            if (
              !param[item][key] ||
              param[item][key] == undefined ||
              param[item][key] == ""
            ) {
              param[item][key] == "--";
            }
          }
        }
      }
      return param;
    },
    // 获取动态
    getOrderFlow() {
      _api.orderFlow({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.orderFlow = this.handleFlowData(res.data);
          console.log(this.orderFlow);
        }
      });
    },
    /*************** 数据处理(保留原数据,新数据只是原数据后添加了一个s) ********************/
    handleData(param) {
      for (const key in param) {
        if (param[key] === null || param[key] === undefined || param[key] === "") {
          if (param[key] != 0) {
            param[key] = "--";
          }
        }
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          for (const secondKey in param[key]) {
            if (
              !param[key][secondKey] ||
              param[key][secondKey] == undefined ||
              param[key][secondKey] == ""
            ) {
              param[key][secondKey] = "--";
            }
          }
        }
      }
      param.funcDetail = param.funcDetail ?? {};
      // 旧机规格
      param.phoneMemorys = !param.phoneMemory ? "--" : param.phoneMemory;
      param.phoneStorages = !param.phoneStorage ? "--" : param.phoneStorage;
      param.storageMemory = param.phoneMemorys + "-" + param.phoneStorages;
      // 报价方式
      param.businessTypes = !param.businessType
        ? "--"
        : param.businessType == "01"
          ? "系统报价"
          : "工程师报价";
      // 回收方式
      param.recycleWays = !param.recycleWay
        ? "--"
        : param.recycleWay == "01"
          ? "以旧换新"
          : "独立回收";
      // 订单状态
      param.states = !param.state
        ? "--"
        : param.state == "00"
          ? "待确认"
          : param.state == "01"
            ? "已确认"
            : param.state == "02"
              ? "待支付"
              : param.state == "03"
                ? "已绑码"
                : param.state == "04"
                  ? "已收货"
                  : param.state == "05"
                    ? "降价收货"
                    : param.state == "10"
                      ? "已取消"
                      : param.state == "20"
                        ? "已作废"
                        : "已退回";
      // 角色
      param.staffTypes =
        !param.staffType || param.staffType == "--"
          ? "--"
          : param.staffType == "01"
            ? "创建人"
            : param.staffType == "02"
              ? "总监"
              : param.staffType == "03" && param.isStaffSub
                ? "店长-从账号"
                : param.staffType == "03"
                  ? "店长"
                  : "店员";
      // 差异图片
      param.depreciateImgss =
        !param.depreciateImgs || param.depreciateImgs == "--"
          ? []
          : param.depreciateImgs.split(",");
      console.log(param);
      return param;
    },
    // 查看交易账单
    seeBill() {
      this.$router.push({
        path: "/payment/PayBill",
        query: { orderNo: this.pageShowInfo.orderNo },
      });
    },
    /*************** 数据处理 ********************/
    // 摄像头照片
    seeCamera(obj) {
      console.log(obj);
      this.imgList = [];
      this.showViewer = true;
      if (obj.cameraTestImgRear && obj.cameraTestImgRear != "--") {
        this.imgList.push(obj.cameraTestImgRear);
      }
      if (obj.cameraTestImgFront && obj.cameraTestImgFront != "--") {
        this.imgList.push(obj.cameraTestImgFront);
      }
    },
    seeApplyProofImg(item) {
      this.imgList = [item];
      this.showViewer = true;
      this.cancelReceiptInfo.applyProofImg.forEach(ele => {
        if (ele != item) {
          this.imgList.push(ele)
        }
      });
    },
    // 从账号弹框
    seeSubInfo(row) {
      this.subInfoShow = true;
    },
    seeCustomerInfo() {
      this.customerInfoShow = true;
    },
    //减法函数
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    // 加价详情接口
    plusPrice() {
      _api.pcaddPriceInfo({ orderNo: this.$route.query.id }).then((res) => {
        this.pcaddPric = res.data;
      });
    },
    // 详情跳转
    detailHref(id) {
      window.open(`/RecallOrder/Details?type=edit&id=${id}`);
    }
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.function_box {
  max-height: 700px;
  overflow: auto;
}

.autoshow::-webkit-scrollbar {
  display: none;
}

.fs16 {
  font-size: 14px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.quoteNotecss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.OrderDetails {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;

  .details_bottom {
    display: flex;
    flex-direction: column;

    div {
      width: 50%;
    }
  }

  .details_case {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .case-title {
      margin: 20px;
      display: flex;
      align-items: center;

      .case-title_icon {
        width: 20px;
        height: 20px;
      }

      .case-title_max {
        min-width: 72px;
        font-weight: bold;
        color: #333333;
        font-size: 18px;
        height: 17px;
        line-height: 17px;
        margin-left: 10px;
        font-family: FZLanTingHei-B-GBK;
      }

      .left {
        display: flex;
        position: relative;
        // justify-content: space-between;
        flex-wrap: wrap;

        div {
          margin-left: 15px;

          >div {
            width: 32px;
            height: 22px;
            background: #21d8c2;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 10px;
          }

          >span {
            font-family: FZLanTingHei-DB-GBK;
            font-size: 14px;
            font-weight: 400;
            color: #0981ff;
          }

          >input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          >.copy {
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #0981ff;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }

      .right {
        display: flex;
        color: #fff;

        .rightTrue {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ff687b;
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalse {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ffaf29;
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightMid {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: rgba(102, 204, 153, 1);
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;

          &.online {
            background: #ff687a !important;
          }
        }
      }
    }
  }

  .details_section {
    background: #f5f6fa;
    border-radius: 10px;
    height: 126px;
    margin: 0 20px;

    &>div {
      padding: 20px 10px 15px 20px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;

      span:not(:first-child) {
        color: rgb(77, 77, 77);
      }
    }
  }

  .details_table {
    margin: 0 20px;

    .remark {
      font-size: 12px;
      font-weight: 400;
      color: #ff8080;
      margin: 10px;
    }
  }

  .details_double {
    // height: 320px;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 50%;
    }

    .details_double_item {
      display: flex;

      >.details_section_record {
        padding-top: 20px;
        background: #f5f6fa;
        border-radius: 10px;
        margin: 0 20px 20px;
        width: 50%;

        div {
          padding: 10px 10px 10px 20px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;

          span:not(:first-child) {
            color: rgb(77, 77, 77);
          }
        }

        .details_tips {
          margin-left: 22px;
          padding: 0;
          background: #0981ff;
          color: #fff;
          height: 28px;
          box-sizing: border-box;
          width: 100px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .fault-box {
    color: #333333;
  }

  .faultBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;

    .faultBut_item {
      width: 46%;
      min-width: 46%;
      max-width: 46%;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      background: #F5F6FA;
      border: 1px solid #E4ECFD;
      margin-bottom: 10px;

      >span {
        color: #0099ff;
      }
    }

    .faultSystem_item {
      background-color: #e4ecfd;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 10px;
      width: 380px;

      >span {
        color: #0099ff;
      }
    }

    .oneline_item {
      width: 380px;
      min-height: 40px;
      background: #F5F6FA;
      border: 1px solid #E4ECFD;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 10px 18px;
      box-sizing: border-box;
      margin-bottom: 20px;
      text-align: justify;
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .sec_img {
    width: 20vw;
    height: 80vh;
    margin: auto;

    img {
      width: 20vw;
      height: 80vh;
      object-fit: contain;
    }
  }
}

.dialogDiff_double_item {
  display: flex;

  >.details_section {
    background: #fff;
    border-radius: 10px;
    height: 216px;
    margin: 0 20px 0 0;
    width: 50%;

    div {
      padding: 20px 10px 15px 20px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;

      span:not(:first-child) {
        color: rgb(77, 77, 77);
      }
    }

    .details_tips {
      padding: 5px 10px;
      background: #0981ff;
      color: #fff;
      height: 28px;
      box-sizing: border-box;
      width: 100px;
      border-radius: 10px;
    }
  }
}

.see-btn {
  color: #0981ff !important;
  cursor: pointer;
  text-decoration: underline;
}

.see-difference {
  color: #ff3300 !important;
  text-decoration: underline;
  cursor: pointer;
}

.money,
.money1 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.money>div {
  color: #fff;
  margin-left: 5px;
  background: rgba(255, 102, 0, 1);
  border-radius: 10px;
  padding: 0px 10px;
}

.money1>div {
  color: #fff;
  margin-left: 5px;
  background: rgba(11, 130, 255, 1);
  border-radius: 10px;
  padding: 0px 10px;
}

.diff-imgs {
  width: 98px;
  height: 98px;
  margin-left: 10px;
}

.block-ml-10 {
  display: inline-block;
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    width: max-content;
    height: 30px;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }
}

.hidden-2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.isdeal {
  background: #ff687b;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  margin-right: 5px;
}

.isdeal1 {
  border: 1px solid #f59a23;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  color: #f59a23;
  text-align: center;
  margin-left: 5px;
}

.cancel-receipt-info {
  .details_tips {
    padding: 0;
    background: #0981ff;
    color: #fff;
    height: 28px;
    box-sizing: border-box;
    width: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex-aa {
  display: flex;
  align-items: flex-start;
}

.flex1 {
  flex: 1;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.test-el-tag1 {
  background-color: #21d8c2 !important;
  border-color: #21d8c2 !important;
  color: white !important;
}

.test-el-tag2 {
  background-color: #ff687b !important;
  border-color: #ff687b !important;
  color: white !important;
}

.system_icon {
  font-size: 12px;
  width: max-content;
  padding: 0 6px;
  height: 16px;
  line-height: 16px;
  background: #21d8c2;
  border-radius: 11px 8px 8px 0px;
  color: #fff;
  margin-left: 10px;
}

.txt-base {
  color: #444cf0;
}

.flex {
  display: flex;
  align-items: center;
}

.one_line {
  box-sizing: border-box;
  padding: 0 40px;

  .one_line_item {
    margin-top: 10px;

    .one_line_item_title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-left: 15px;
    }

    .one_line_item_title::after {
      position: absolute;
      content: " ";
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: 3px;
      height: 14px;
      background: #0981ff;
      border-radius: 2px;
    }

    .one_line_item_conent {
      margin-top: 10px;

      .one_line_item_conent_item {
        margin-right: 40px;
        font-size: 14px;

        .one_line_item_conent_item_box {
          padding: 0 5px;
          height: 20px;
          background: #f5f6fa;
          border-radius: 3px;
          line-height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.txt-red {
  color: #FF3300 !important;
}

.txt-green {
  color: #44d886 !important;
}

.fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.test-function {
  margin-top: 20px;
}

/deep/.el-collapse-item__header {
  line-height: 1
}
.price{
  color: red;
}
</style>
