<template>
  <div class="StaffListDia">
    <el-dialog
      title="查看移动商户"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @close="close"
    >
      <div style="display: flex;margin-bottom: 10px;">
        <p style="margin-right: 40px;">门店：{{onRowObj.cmccStoreName}}</p>
        <p v-if="onRowObj.storeName">已绑定门店：{{onRowObj.storeName}}</p>
        <p v-else style="color: #ff8695;">未绑定门店</p>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="tableloading"
        :columns="tableColumns"
        :data="tableDataList"
        :isPagination="false"
        @handleCurrentChange="getDataList"
      >
        <el-table-column label="类型" slot="empType" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.empType==='1'?'店长':'店员'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" slot="state" align="center">
          <template slot-scope="{ row }">
            <span :style="row.state!=='1'?'color:#ff8695;':''">{{ row.state==='1'?'正常':row.state==='2'?'冻结':'注销'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定繁星账号" slot="staffName" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.staffId">{{ row.staffName }}-{{ row.mobile }}({{ row.isMajor?'创建人':row.staffType==='01'?'子账号':row.staffType==='02'?'总监':row.staffType==='03'?'店长':'店员' }})</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="繁星账号状态" slot="isEnable" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.staffId">{{ row.isEnable?'启用':'禁用' }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    onRowObj: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      tableloading: false,
      tableDataList: [],
      tableColumns: [
        { slotName: "empType" },
        { label: "姓名", prop: "empName" },
        { label: "手机号", prop: "empTel" },
        { slotName: "state" },
        { slotName: "staffName" },
        { slotName: "isEnable" },
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getDataList()
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getDataList() {
      this.tableloading = true
      _api.cmccEmployeeMumList({cmccStoreCode:this.onRowObj.cmccStoreCode}).then(res => {
        if(res.code === 1){
          this.tableDataList = res.data
          this.tableloading = false
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.StaffListDia {
  .tip {
    color: #ff687b;
    font-size: 14px;
    margin-bottom: 20px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  .txt-p {
    line-height: 20px;
  }
}
</style>
