<template>
  <div class="SeeMobileCompanyDia">
    <el-dialog
      title="查看移动商户"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @close="close"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="tableloading"
        :columns="tableColumns"
        :data="tableDataList"
        :currentPage="page.pageNum"
        :total="page.total"
        :pageSize="page.pageSize"
        @handleCurrentChange="handleCurrentChange"
      >
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        tableloading: false,
        tableDataList: [],
        tableColumns: [
            { label: "门店商", prop: "companyName" },
            { label: "联系人", prop: "contact" },
            { label: "联系电话", prop: "phone" },
            { label: "所属省份", prop: "provinceName" },
            { label: "省份编码", prop: "provinceCode" },
        ],
        page: {
            pageNum: 1,
            pageSize: 10,
            total: 0
        }
    };
  },
  created() {
    this.handleCurrentChange()
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCurrentChange(val) {
      if(val){
        this.page.pageNum = val
      }
      _api.cmccCompanyList({pageNum: this.page.pageNum, pageSize: this.page.pageSize}).then((res) => {
        if(res.code === 1){
          this.tableDataList = res.data.records
          this.page.pageNum = res.data.current
          this.page.total = res.data.total
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.SeeMobileCompanyDia {
  .tip {
    color: #ff687b;
    font-size: 14px;
    margin-bottom: 20px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  .txt-p {
    line-height: 20px;
  }
}
</style>
