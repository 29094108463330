<template>
  <div>
    <el-dialog
      title="回收商批量操作"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="1100px"
      top="50px"
      @close="close"
    >
      <el-form
        ref="filterForm"
        :model="filterForm"
        inline
        size="small"
        class="el-form-group_isRound"
      >
        <el-form-item label="选择回收商" prop="merchantId">
          <el-select
            v-model="filterForm.merchantId"
            clearable
            filterable
            placeholder="请输入回收商名称查询"
            @change="merchantIdChange"
          >
            <el-option
              v-for="item in merchantViewList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否外部渠道" prop="isOut">
          <el-select v-model="filterForm.isOut" clearable @change="isOutChange">
            <el-option
              v-for="item in isOutOption"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="getData(1)">查询</el-button>
        </el-form-item>
      </el-form>
      <template v-if="filterForm.merchantId">
        <GardenSlwct
          style="margin-bottom: 10px;"
          :tab-data="tabData"
          :activeIndex="tabActiveIndex"
          width="120px"
          border-radius="40px"
          @tabAction="tabAction"
        >
        </GardenSlwct>
        <div
          style="margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;"
        >
          <p style="font-size: 16px;color: #333;">选择门店商户</p>
          <div>
            <el-button
              :disabled="!selectedList.length"
              size="small"
              type="warning"
              @click="openSetCompanyDia"
              >批量配置合作门店</el-button
            >
            <el-button
              :disabled="!selectedList.length"
              size="small"
              v-if="filterForm.state === '00'"
              type="danger"
              @click="statusChange"
              >批量禁用</el-button
            >
            <el-button
              :disabled="!selectedList.length"
              size="small"
              v-if="filterForm.state === '01'"
              type="success"
              @click="statusChange"
              >批量启用</el-button
            >
          </div>
        </div>
        <el-table
          ref="merchantDia"
          :data="tableData"
          v-loading="tableLoading"
          tooltip-effect="dark"
          max-height="500px"
          border
          @selection-change="handSelect"
          :row-key="
            (row) => {
              return row.id;
            }
          "
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            align="center"
          >
          </el-table-column>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
            :index="
              (index) => {
                return (
                  index + 1 + (filterForm.pageNum - 1) * filterForm.pageSize
                );
              }
            "
          />
          <el-table-column label="矩阵名称" align="center">
            <template slot-scope="{ row }">{{
              row.matrixName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="矩阵门店商户" align="center">
            <template slot-scope="{ row }">{{
              row.companyName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="分转仓" align="center">
            <template slot-scope="{ row }">{{
              row.warehouseName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="配置合作门店" align="center">
            <template slot-scope="{ row }">{{
              row.configStores || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="添加时间" align="center">
            <template slot-scope="{ row }">{{
              row.createTime || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="最近启用时间" align="center">
            <template slot-scope="{ row }">{{
              row.lastOpenTime || "--"
            }}</template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: right; margin-top: 20px"
          @current-change="getData"
          :current-page="filterForm.pageNum"
          layout="total,  prev, pager, next, jumper"
          :total="filterForm.total"
          :background="true"
        >
        </el-pagination>
      </template>
      <el-empty v-else description="请先选择需要批量操作的回收商"></el-empty>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="批量配置门店"
      :visible.sync="setCompanyVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="setCompanyClosed"
    >
      <div class="set-company-form">
        <p class="mb-10">
          已选择门店商户：<span style="color: #0981FF;"
            >{{ selectedList.length }}个</span
          >
        </p>
        <div class="mb-10" style="display: flex;align-items: center;">
          <p>配置合作门店：</p>
          <el-checkbox-group v-model="storeRuleList">
            <el-checkbox label="01">选择奇数门店</el-checkbox>
            <el-checkbox label="02">选择偶数门店</el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="display: flex;align-items: center;">
          <p style="flex-shrink: 0;">动态口令：</p>
          <el-input
            size="small"
            v-model="command"
            placeholder="请输入谷歌动态口令"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setCompanyVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="setCompanyBtnLoading"
          @click.native="setCompanySure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="statusChangeTitle"
      :visible.sync="statusChangeVisible"
      :close-on-click-modal="false"
      width="450px"
      @closed="statusChangeClosed"
    >
      <p
        style="font-size: 16px;color: #666666;text-align: center;margin: 20px 0;"
      >
        是否确认{{ filterForm.state == "00" ? "禁用" : "启用"
        }}<span style="color: #0981FF;">{{ merchantName }}</span
        >在所选<span style="color: #0981FF;">{{ selectedList.length }}个</span
        >矩阵商户的报价？
      </p>
      <div style="display: flex;align-items: center;">
        <p style="flex-shrink: 0;">动态口令：</p>
        <el-input
          size="small"
          v-model="command"
          placeholder="请输入谷歌动态口令"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="statusChangeVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="statusChangeBtnLoading"
          @click.native="statusChangeSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
export default {
  components: { GardenSlwct },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    merchantViewList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      filterForm: {
        merchantId: "",
        isOut: "",
        state: "00",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      merchantName: "",
      isOutOption: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      tabActiveIndex: 0,
      tabData: [
        {
          text: "已启用",
          value: "00",
          hideNum: true,
        },
        {
          text: "已禁用",
          value: "01",
          hideNum: true,
        },
      ],
      selectedList: [],
      setCompanyVisible: false,
      storeRuleList: [],
      command: "",
      setCompanyBtnLoading: false,
      statusChangeTitle: "",
      statusChangeVisible: false,
      statusChangeBtnLoading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getData(pageNum) {
      this.tableLoading = true;
      _api
        .matrixListSample({
          ...this.filterForm,
          pageNum: pageNum || 1,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.filterForm.pageNum = res.data.current;
          this.filterForm.total = res.data.total;
          this.tableData.forEach((item) => {
            if (
              !this.selectedList.some(
                (selectedItem) => selectedItem.id === item.id
              )
            ) {
              this.$nextTick(() => {
                this.$refs.merchantDia.toggleRowSelection(item, false);
              });
            }
            if (!this.selectedList.length) {
              this.$refs.merchantDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    merchantIdChange() {
      if (this.filterForm.merchantId) {
        this.merchantViewList.forEach((item) => {
          if (item.merchantId === this.filterForm.merchantId) {
            this.merchantName = item.merchantName;
          }
        });
      }
      if (this.$refs.merchantDia) {
        this.$refs.merchantDia.clearSelection();
      }
      this.getData(1);
    },
    isOutChange() {
      if (this.$refs.merchantDia) {
        this.$refs.merchantDia.clearSelection();
      }
      this.getData(1);
    },
    tabAction(val) {
      this.filterForm.state = this.tabData[val].value;
      this.tabTwoActiveIndex = val;
      this.$refs.merchantDia.clearSelection();
      this.getData(1);
    },
    handSelect(selection, row) {
      this.selectedList = selection;
    },
    openSetCompanyDia() {
      this.setCompanyVisible = true;
    },
    setCompanyClosed() {
      this.command = "";
      this.storeRuleList = [];
    },
    setCompanySure() {
      if (!this.storeRuleList.length) {
        return this.$message.error("请选择配置合作门店");
      }
      if (!this.command.trim()) {
        return this.$message.error("请输入动态口令");
      }
      let matrixIdList = this.selectedList.map((item) => item.id);
      let params = {
        command: this.command,
        matrixIdList,
        merchantId: this.filterForm.merchantId,
        storeRule: this.storeRuleList.length > 1 ? "00" : this.storeRuleList[0],
      };
      this.setCompanyBtnLoading = true;
      _api
        .batchJoinStore(params)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success(res.msg || "操作成功");
            this.setCompanyVisible = false;
            this.getData(1);
          }
        })
        .finally(() => {
          this.setCompanyBtnLoading = false;
        });
    },
    statusChange() {
      this.statusChangeTitle =
        this.filterForm.state == "00" ? "批量禁用" : "批量启用";
      this.statusChangeVisible = true;
    },
    statusChangeClosed() {
      this.command = "";
    },
    statusChangeSure() {
      if (!this.command.trim()) {
        return this.$message.error("请输入动态口令");
      }
      this.statusChangeBtnLoading = true;
      if (this.filterForm.state === "00") {
        let companyIds = this.selectedList.map((item) => item.companyId);
        let params = {
          command: this.command,
          companyIds,
          merchantId: this.filterForm.merchantId,
        };
        _api
          .closeQuoteBatch(params)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.statusChangeVisible = false;
              this.$refs.merchantDia.clearSelection();
              this.getData(1);
            }
          })
          .finally(() => {
            this.statusChangeBtnLoading = false;
          });
      }else if(this.filterForm.state === "01"){
        let matrixIdList = this.selectedList.map((item) => item.id);
        let params = {
          command: this.command,
          matrixIdList,
          merchantId: this.filterForm.merchantId,
        };
        _api
          .openUnionMerchantBatch(params)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.statusChangeVisible = false;
              this.$refs.merchantDia.clearSelection();
              this.getData(1);
            }
          })
          .finally(() => {
            this.statusChangeBtnLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.set-company-form {
  color: #333;
}
</style>
