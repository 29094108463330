<template>
  <div class="OrderTable" v-loading="loading">
    <div class="top_bar" style="margin-bottom: 20px">
      <div class="case" style="text-align: right">
        <!-- tab切换 -->
        <GardenSlwct
          :tab-data="tabData"
          :activeIndex="activeIndex"
          width="120px"
          border-radius="40px"
          @tabAction="tabAction"
        >
        </GardenSlwct>
        <div class="zhengnfusty">
          <span>选择振幅</span>
          <el-select v-model="quoteAmplitude"  placeholder="请选择" @change="uoteAmplitude" clearable>
            <el-option
              v-for="item in cupatList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          @click="codeSubmit"
          >导出Excel</el-button
        >
      </div>
    </div>
    <div v-if="this.pages.total > 0">
      <el-table
        :data="orderTableList"
        border
        style="width: 100%"
        id="excelOrder"
      >
        <el-table-column type="index" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="name" label="旧机图片" align="center">
          <template slot-scope="{ row }">
            <div class="images-box" v-if="row.images && row.images.length > 0">
              <img :src="row.images[0].img" alt="" />
              <span @click="openImgBox(row)" class="text-down"
                >共{{ row.images.length }}张</span
              >
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" label="订单编号" align="center">
          <template slot-scope="{ row }">
            <div @click="handleClick(row)" class="lv_fc_line">
              {{ row.orderNo || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phoneName" label="机型" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.phoneName || "--" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="phoneMemory" label="规格" align="center">
          <template slot-scope="{ row }">
            <p v-if="row.phoneMemory||row.phoneStorage">
              <span>{{ row.phoneMemory ||""}}</span>
              <span v-if="row.phoneMemory&&row.phoneStorage">+</span>
              <span> {{ row.phoneStorage|| "" }}</span>
             </p>
             <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="imei" label="旧机串号" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.imei || "--" }}</div>
            <p
              class="lv_fc_line"
              @click="getFunction(row.orderNo, row.outType)"
            >
              功能情况
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="orderTime" label="下单时间" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.orderTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quoteTime" label="最新报价时间" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.quoteTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quoteNum" label="报价记录" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.quoteNum" style="cursor: pointer" class="text-down"
                  @click="Bargain(row)">{{ row.quoteNum ||0}}</span>
                <span v-else>{{ row.quoteNum }}</span>
              </template>
            </el-table-column>
        <el-table-column
          prop="quoteStaffName"
          label="报价人"
          align="center"
        >
          <template slot-scope="{ row }">
            <span style="display: block"
              >{{ row.quoteStaffName || "--" }}-{{
                row.quoteStaffPhone || "-"
              }}</span
            >
            <div v-if="row.quoteStaffName">
              <el-tag
                :type="row.quoteStaffPosition == '01' ? 'warning' : 'success'"
                size="mini"
                effect="dark"
                >{{
                  row.quoteStaffIsMajor &&
                  row.quoteStaffPosition == "01" &&
                  row.quoteStaffIsMajor
                    ? "创建人"
                    : !row.quoteStaffIsMajor && row.quoteStaffPosition == "01"
                    ? "子账号"
                    : row.quoteStaffPosition == "02"
                    ? "报价师"
                    : ""
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="quotePrice" label="报价金额" align="center">
          <template slot-scope="{ row }">
            <span><span v-if="row.quotePrice">￥</span>{{ row.quotePrice || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quotePrice" label="报价振幅" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.quoteAmplitude">{{ row.quoteAmplitude }}%</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="diff" label="报价备注" align="center">
          <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.quoteNote" placement="top">
            <div class="Remarks" v-if="row.quoteNote">{{ row.quoteNote }}</div>
            <span v-else>--</span>
          </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="prePrice" label="预估报价" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.healthName">
              <div>{{row.healthName}}</div>
              <div>（￥{{row.prePrice}}）</div>
            </template>
            <span v-else>未划分等级</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="healthCodeCus"
          label="报价预估机况"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{
              row.healthCodeCus == "Nice"
                ? "靓机"
                : row.healthCodeCus == "Little_Snow"
                ? "小花"
                : row.healthCodeCus == "Big_Snow"
                ? "大花"
                : row.healthCodeCus == "Out_Burst"
                ? "外爆"
                : row.healthCodeCus == "Inner_Burst"
                ? "内爆"
                : row.healthCodeCus == "Repair"
                ? "疑似维修"
                : row.healthCodeCus == "NoImage"
                ? "照片缺失"
                : row.healthCodeCus == "PhoneNoMatching"
                ? "机型与所选不一致"
                : "未划分等级"
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="state" label="订单状态" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.state === 'W01'">待报价</span>
            <span v-else-if="row.state === '00'">待成交</span>
            <span v-else-if="row.state === 'G03'">待收货</span>
            <span v-else-if="row.state === '04'">已收货</span>
            <span v-else-if="row.state === '05'">降价收货</span>
            <span v-else-if="row.state === '10'">已取消</span>
            <span v-else-if="row.state === '20'">已作废</span>
            <span v-else-if="row.state === '30'">已退回</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="orderPagina">
        <el-pagination
          background
          :current-page="pages.currentPage"
          :page-size="10"
          layout="total, prev, pager, next,jumper"
          :total="this.pages.total"
          class="el-pagination-c"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div v-else class="emptyStyle">暂无订单~</div>
    <!-- 修改等级 -->
    <el-dialog
      title="修改等级"
      :visible.sync="gradeShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="gradeClose"
    >
      <div class="cance-tip" style="color: #666666">请选择修改后的成色等级</div>
      <div class="gr_content">
        <div
          :class="selectgradeIndex !== index ? 'gradeiten' : 'grade_selected'"
          v-for="(item, index) in healthCodeOption"
          :key="index"
          @click="ActionedT(index)"
        >
          {{ item.label }}
        </div>
        <el-select
          v-if="selectgradeIndex == 6"
          v-model="gradereason"
          placeholder="请选择无法标记原因"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in caseoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="gradeClose">取消</el-button>
        <el-button
          type="primary"
          :loading="codeLoading"
          @click.native="grideSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 导出Excel谷歌验证 -->
    <el-dialog
      title="操作提示"
      :visible.sync="codeShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="closeExport"
    >
      <div class="cance-tip">
        <div class="tip-sure">是否确认导出订单？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="code"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="closeExport">取消</el-button>
        <el-button
          type="primary"
          :loading="codeLoading"
          @click.native="codeSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看旧机照片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看旧机照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div
            @click="chooseImages(0)"
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-choose' : 'img-tips-default'"
          >
            旧机照片
          </div>
          <div
            v-if="this.DialogImgList[1].length > 0"
            @click="chooseImages(1)"
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'"
          >
            补拍照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList[imgIndex].length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList[imgIndex]">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item
                  v-for="item in this.DialogImgList[this.imgIndex]"
                  :key="item.imgNo"
                >
                  <div class="sec_img">
                    <img :src="item.img" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>
    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisibleOrder"
      :width="600"
      top="10vh"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="resetPopupData"
    >
      <span>{{ dialogcancel.remark || "无" }}</span>
    </GlobalDialog>
    <!-- 点击查看一张图片 -->
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
    <!-- 旧机功能情况 -->
    <el-dialog
      v-bind="imeiTestProps"
      :before-close="() => (imeiTestProps.show = false)"
      :visible.sync="imeiTestProps.show"
    >
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo
            :externalorderType="externalorderType"
            :datainfo="detailData"
          />
        </div>
        <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
            <div
              class="test-result"
              v-if="detailData.functionView.errorList.length"
            >
              <Title
                :title="
                  '异常项（共' +
                  detailData.functionView.errorList.length +
                  '项）'
                "
              />
              <!-- 旧机无系统检测 -->
              <div
                class="mb10"
                v-if="
                  detailData.unableDetectionCause &&
                  detailData.detectionType == '01'
                "
              >
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div
                class="mb10"
                style="display: flex; justify-content: space-between"
                v-if="detailData.detectionType == '02'"
              >
                <span class="fs16"
                  >检测机型：{{ detailData.detectionModel || "--" }}</span
                >
                <span class="fs16"
                  >录入串号：{{ detailData.imei || "--" }}</span
                >
              </div>
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.errorList"
              ></TestTable>
            </div>
            <div
              class="test-result"
              style="margin-top: 20px"
              v-if="detailData.functionView.normalList.length"
            >
              <Title
                :title="
                  '正常项（共' +
                  detailData.functionView.normalList.length +
                  '项）'
                "
              />
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.normalList"
              ></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0">
          <Title title="店员备注" />
          <Remark
            :remark="detailData.remark"
            :againPhotoRemark="detailData.againPhotoRemark"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="imeiTestProps.type === '01'"
          @click="imeiTestProps.show = false"
          plain
          type="primary"
          >关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
      <!-- 查看报价记录弹框 -->
      <el-dialog :close-on-click-modal="false" :width="`${850}px`" title="查看报价记录" :visible="viewrecords"
      @close="viewrecordsClose" top="15%" bottom="10%">
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table :data="getUpdatedQuoteLog" max-height="300" border style="width: 100%" id="updatedOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="160" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote || '--' }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import _api from "@/utils/request";
import moment from "moment";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import { openAction } from "@/utils/common";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Title from "../componets/Title.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
export default {
  name: "OrderTable",
  components: {
    Title,
    GardenSlwct,
    TestTable,
    TestDeviceInfo,
    ElImageViewer,
    Remark,
  },
  data() {
    return {
      quoteAmplitude:"",
      merchantId: "",
      gradeShow: false,
      selectgradeIndex: "",
      gradereason: "",
      caseoptions: [
        {
          value: "NoImage",
          label: "照片缺失",
        },
        {
          value: "PhoneNoMatching",
          label: "机型与所选不一致",
        },
      ],
      cupatList: [
        { value: "0,5", label: "0≤振幅＜5%" },
        { value: "5,10", label: "5%≤振幅＜10%" },
        { value: "10,20", label: "10%≤振幅＜20%" },
        { value: "20,30", label: "20%≤振幅＜30%" },
        { value: "30,40", label: "30%≤振幅＜40%" },
        { value: "40,50", label: "40%≤振幅＜50%" },
        { value: "50,100", label: "50%≤振幅＜100%" },
      ],
      healthCodeOption: [
        //旧机情况
        { value: "Nice", label: "靓机" },
        { value: "Little_Snow", label: "小花" },
        { value: "Big_Snow", label: "大花" },
        { value: "Inner_Burst", label: "内爆" },
        { value: "Out_Burst", label: "外爆" },
        { value: "Repair", label: "疑似维修" },
        { value: "onWay", label: "无法标记等级" },
      ],
      tabData: [
        {
          text: "有效报价",
          value: "01",
          num: 0,
          key: "normalQuoteNum",
        },
        {
          text: "拒绝报价",
          value: "02",
          num: 0,
          key: "rejectQuoteNum",
        },
      ],
      activeIndex: 0, //tab初始选择索引
      configRefresh: true,
      storeLoading: false,
      orderNo: "",
      externalorderType: "", //订单类型
      switchid: "1",
      // 功能情况接口
      detailData: {
        functionView: {
          normalList: [],
          errorList: [],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
      },
      imeiTestProps,
      recorderList: [],
      codeShow: false, //导出Excel谷歌口令弹框
      code: "", //导出Excel谷歌口令
      codeLoading: false, //导出订单
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [[], []],
      imgShowIndex: 0,
      imgIndex: 0,
      pages: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      itemTable: [],
      orderTableList: [],
      loading: false,
      dialogVisibleOrder: false,
      dialogTitle: "",
      dialogcancel: {
        remark: "",
      },
      showViewer: false,
      imgList: [],
      command: "",
      params: {
        pageType: "01",
        quoteAmplitudeEnd:"",
        quoteAmplitudeStart:"",
      },
      gradeCopedata: {},
      getUpdatedQuoteLog: [],
      viewrecords:false,
    };
  },
  created() {
    this.merchantId = this.$route.query.id;
    // let startTime = moment()
    //   .day(moment().day() - 30)
    //   .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推一周的时间
    // let endTime = moment().format("YYYY-MM-DD 23:59:59");
    // this.ClearSeachParams();
    // const baseRequest = {
    //   endTime: moment(endTime).format("x"),
    //   startTime: moment(startTime).format("x"),
    // };
    // this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newval) {
      console.log(newval, "SeachParamsSeachParams");
      this.handleCurrentChange();
    },
  },
  methods: {
    viewrecordsClose() {
      this.viewrecords = false;
    },
    // 查看报价记录
    Bargain(row) {
      console.log(row, "------------");
      this.viewrecords = true;
      _api
        .getUpdatedQuoteLog({
          merchantId: row.merchantId,
          orderNo: row.orderNo,
        })
        .then((res) => {
          console.log(res.data, "报价信息");
          this.getUpdatedQuoteLog = res.data.normal;
          this.viewrecords = true;
        });
    },
    //关闭修改等级
    gradeClose() {
      this.selectgradeIndex = "";
      this.gradereason = "";
      this.gradeShow = false;
    },
    // tab状态操作
    tabAction(v) {
      console.log(v);
      this.activeIndex = v;
      this.params.pageType = this.tabData[v].value;
      console.log(this.params.pageType, "lllllllllljh");
      this.handleCurrentChange(1);
    },
    // 各种状态的数量设置
    setStateNum(data) {
      this.tabData = this.tabData.map((v) => {
        if (data[v.key] >= 1000) {
          v.num = (data[v.key] / 1000).toFixed(1) + "k";
        } else {
          v.num = data[v.key] || 0;
        }
        return v;
      });
    },
    // 根据状态设置列/和查询条件项
    setStateColumns(v) {
      this.tabActive = v;
    },
    openAction,
    //秒转化为分秒
    getTimes(t) {
      let h = parseInt((t / 60 / 60) % 24);
      let m = parseInt((t / 60) % 60);
      let s = parseInt(t % 60);
      //三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return `${m}分${s}秒`;
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then((res) => {
        this.detailData = res.data;
        if(res.data.againPhotoRemark){
          this.detailData.againPhotoRemark=res.data.againPhotoRemark.split("//")
        }else{
          this.detailData.againPhotoRemark=[]
        }
        this.imeiTestProps.isLoadding = false;
      });
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType;
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: "",
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "", //下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      };
      // 请求详情
      this.getDetail(v);
    },

    ClearSeachParams() {
      this.$store.dispatch("tagsView/ClearSeachParams", "");
    },
    // 导出Excel
    clickExcel() {
      this.codeShow = true;
    },
    //
    closeExport() {
      this.codeShow = false;
      (this.code = ""), (this.codeLoading = false);
    },
    //导表
    codeSubmit() {
      const params = {
        ...this.SeachParams,
        pageType:this.params.pageType,
        quoteAmplitudeStart:this.quoteAmplitude?Number(this.quoteAmplitude.split(",")[0]):"",
        quoteAmplitudeEnd:this.quoteAmplitude?Number(this.quoteAmplitude.split(",")[1]):"",
        merchantId: this.merchantId,
        pageNum: this.pages.currentPage,
        pageSize: this.pages.pageSize,
      };
      this.codeLoading = true;
      console.log(params);
      _api
        .platformbaoListExcel(params)
        .then((res) => {
          console.log(res);
          const content = res;
          this.codeShow = false;
          this.code = "";
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "报价单列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.codeLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codeLoading = false;
        });
    },
    gradAction(row, index) {
      console.log(row, index);
      row.gradeindex = index;
      row.healthCodeCus = this.healthCodeOption[index].value;
      if (index !== 6) {
        row.healthCodeCusNone = "";
      } else {
        row.healthCodeCus = "";
      }
    },
    updateClick(row) {
      if (
        !row.healthCodeCusNone &&
        (row.healthCodeCus == "No" || !row.healthCodeCus)
      ) {
        return this.$message.error("请选择等级或无法标记等级原因！");
      }
      let updata = {
        healthCodeCus: "",
        merchantId: this.merchantId,
        orderNo: row.orderNo,
      };
      if (row.healthCodeCusNone) {
        updata.healthCodeCus = row.healthCodeCusNone;
      } else {
        updata.healthCodeCus = row.healthCodeCus;
      }
      console.log(updata, row.healthCodeCusNone, row.healthCodeCus);
      _api.updateHealthCodeCus(updata).then((res) => {
        this.handleCurrentChange(1);
      });
    },
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    remakeHandleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.chxReimgOrderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 去重评订单详情
    toDetails(row) {
      this.$router.push({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.originalOrderNo },
      });
    },
    uoteAmplitude(e){
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      //  暂时
      this.loading = true;
      if (val) {
        this.pages.currentPage = val;
      } else {
        this.pages.currentPage = 1;
      }
      const vo = {
        pageType:this.params.pageType,
        quoteAmplitudeStart:this.quoteAmplitude?Number(this.quoteAmplitude.split(",")[0]):"",
        quoteAmplitudeEnd:this.quoteAmplitude?Number(this.quoteAmplitude.split(",")[1]):"",
        merchantId:this.merchantId,
        orderNo: this.SeachParams.orderNo,
        typeId: this.SeachParams.typeId,
        modelId: this.SeachParams.modelId,
        brandNo: this.SeachParams.brandNo,
        state: this.SeachParams.state,
        healthCodeCus: this.SeachParams.healthCodeCus,
        merchantStaffId: this.SeachParams.merchantStaffId,
        orderEndTime: this.SeachParams.orderEndTime,
        orderStartTime: this.SeachParams.orderStartTime,
        quoteEndTime: this.SeachParams.quoteEndTime,
        quoteStartTime: this.SeachParams.quoteStartTime,
        pageNum: this.pages.currentPage,
        pageSize: this.pages.pageSize,
      };
      _api.merchantQuoteList(vo).then((res) => {
        if (res.code === 1) {
          this.setStateNum(res.data);
          this.pages.total = res.data.ipage.total || 10;
          this.itemTable = res.data.ipage.records;
          this.orderTableList = res.data.ipage.records;
          this.loading = false;
        }
      });
    },
    copy(e) {
      const spanText = document.getElementById(e).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      this.DialogImgList = [
        [...(row.images == "--" || !row.images ? [] : row.images)],
        [...(row.reImages == "--" || !row.reImages ? [] : row.reImages)],
      ];
      console.log(this.DialogImgList);
    },
    chooseImages(e) {
      if (e == 0) {
        this.imgIndex = 0;
        this.imgShowOnly = true;
      }
      if (e == 1) {
        this.imgIndex = 1;
        this.imgShowOnly = false;
      }
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.gradeiten {
  cursor: pointer;
  margin: 0 10px 10px 0;
  padding: 0 13px;
  height: 22px;
  display: inline-block;
  line-height: 22px;
  background: #f7f9ff;
  border: 1px solid #c1d1ff;
  border-radius: 4px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #666666;
}

.grade_selected {
  cursor: pointer;
  margin: 0 10px 10px 0;
  padding: 0 13px;
  height: 22px;
  display: inline-block;
  line-height: 22px;
  background: #0981ff;
  border-radius: 4px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff;
}

.gr_content {
  width: 400px;
  text-align: left;
}

.emptyStyle {
  width: 100%;
  text-align: center;
  padding: 80px 0;
  font-size: 24px;
  color: #666666;
  background: white;
  font-weight: bold;
}

.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}

.handsStyle {
  cursor: pointer;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  text-decoration: underline;
  color: #4c84ff;
}

.biliStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}

.function_box {
  max-height: 700px;
  overflow: auto;
}

.img-el-heard {
  display: flex;
  justify-content: center;

  img {
    margin: 0px 0 -3px 3px;
  }
}

.foot_buttom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.OrderTable {
  background: white;

  .top_bar {
    background: white;
    display: flex;
    justify-content: space-between;

    .case {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      .case-title {
        display: flex;

        .case-title_icon {
          width: 20px;
          height: 20px;
        }

        .case-title_max {
          font-weight: bold;
          color: #333333;
          font-size: 18px;
          // width: 80px;
          height: 17px;
          line-height: 17px;
          margin-left: 5px;
          font-family: FZLanTingHei-B-GBK;
        }
      }
    }
  }

  .orderPagina {
    float: right;
    margin: 50px;
  }

  .order_list {
    .line_top {
      background-color: white;
      width: 100%;
      height: 10px;
    }

    .table_title {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;

      .left {
        flex: 1;
        display: flex;
        position: relative;
        align-items: center;

        // justify-content: space-between;
        div {
          margin-left: 30px;

          > div {
            margin-left: 0;
            width: 32px;
            height: 22px;
            background: #21d8c2;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 10px;
          }

          > span {
            font-family: FZLanTingHei-DB-GBK;
            font-size: 14px;
            font-weight: 400;
            color: #0981ff;
          }

          > input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          > .copy {
            width: 36px;
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #ff6600;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .right {
        font-size: 14px;
        display: flex;
        color: #fff;

        .rightTrue {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ff687b;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalse {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ffaf29;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalseline {
          width: max-content;
          padding: 5px 10px;
          border: 1px solid #ff687b;
          border-radius: 14px !important;
          margin-right: 10px;
          text-align: center;
          font-size: 12px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: #ff687b;
        }

        .blueFalseline {
          width: max-content;
          padding: 5px 10px;
          border: 1px solid #4c84ff;
          border-radius: 14px !important;
          margin-right: 10px;
          text-align: center;
          font-size: 12px;
          font-family: FZLanTingHei-DB-GBK;
          font-weight: 400;
          color: #4c84ff;
        }

        .rightMid {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: rgba(102, 204, 153, 1);
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }
      }
    }
  }
}

// 表格样式+
.position {
  width: max-content;
  display: block;
  padding: 0px 4px;
  font-size: 14px;
  background-color: rgba(102, 204, 153, 1);
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.order-nice {
  // position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  display: block;
  padding: 2px 8px;
  font-size: 14px;
  background-color: #81d3f8;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  margin-right: 10px;
}

.sty {
  margin-right: 10px;
  // position: absolute;
  // left: 0px;
  // top: -1px;
  width: max-content;
  background: #fca91f;
  border-radius: 14px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #0981ff;
  padding: 3px 10px;
}

.solo {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  background: #fca91f;
  border-radius: 11px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  padding: 3px 10px;
  margin-right: 10px;
}

.solo2 {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  background: #7dc165;
  border-radius: 11px;
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  padding: 3px 10px;
  margin-right: 10px;
}

.order-online {
  position: absolute;
  left: -80px;
  top: -1px;
  width: max-content;
  display: block;
  padding: 0 5px;
  font-size: 12px;
  margin: 0 auto;
  border: 1px solid #ff687b;
  border-radius: 20px;
  color: #ff687b !important;
  text-align: center;
  margin-right: 10px;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box > img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box > span {
  color: #0981ff;
  cursor: pointer;
}

.color-green {
  color: rgba(102, 204, 153, 1);
}

.color-red {
  color: #ff687b;
}

.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.noteLayout {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isdeal {
  background: #ff687b;
  margin-right: 5px;
  display: inline-block;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}

.cellnum {
}

.autoshow::-webkit-scrollbar {
  display: none;
}

.onlyul {
  li {
    margin-top: 10px;
  }

  .firstli {
    margin: 0;
    color: #3a9aff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .firstli_span {
      color: #ccc;
    }
  }

  .secondli {
    color: #ff6600;
    // font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.quoteNotecss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.add-price-title {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-left: 3px solid #0981ff;
  margin-bottom: 10px;
  color: #0981ff;
}

.add-price-conent {
  padding: 0 40px;
}

.expected_info {
  margin-bottom: 40px;

  .expected_info_title {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #0981ff;
    padding-left: 15px;
  }

  .expected_info_title::before {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    width: 3px;
    height: 21px;
    background: #0981ff;
    border-radius: 2px;
  }
}

.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.platform_markup {
  font-size: 16px;
  color: #0981ff;
  margin-bottom: 10px;

  .grey_box {
    width: 100%;
    padding: 10px 20px;
    background-color: #f5f6fa;
    border-radius: 10px;
    margin-top: 20px;
    line-height: 30px;
    color: #333;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.deal_detail_title {
  width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0c82ff;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_inner {
  margin-bottom: 20px;
  background: #f2f2f2;
  font-size: 12px;
  color: #111;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
}

.deal_detail_line {
  margin-bottom: 16px;
}

.deal_detail_line-title {
  padding-left: 10px;
  position: relative;
  margin-bottom: 6px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_line-tips {
  transform: translateX(-5px);
}

.deal_detail_line-tips-blue {
  color: #0981fe;
}

.deal_detail_line-tips-red {
  color: #ff0000;
}

.test-function {
  margin-top: 20px;
}

.artificial-test-info {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646464;
  margin-bottom: 10px;
}

.system-result-title {
  color: #646464;
  font-size: 16px;
  margin-bottom: 10px;
}

.topPriceshoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.writquotation {
  margin-top: 15px;
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;

  span {
    font-size: 14px;
    color: #666666;
  }
}

.title_wt {
  margin-left: 10px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff;
  width: 36px;
  height: 17px;
  background: #ffaf29;
  border-radius: 8px 8px 8px 1px;
}

.title_ht {
  background: #4c84ff;
}

.qb_act {
  display: flex;
  margin: 10px 0;

  .qukliy_box {
    cursor: pointer;
    font-size: 13px;
    margin-right: 10px;
    line-height: 13px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #e4ecfd;
    color: rgb(9, 129, 255);
  }
}

.center_ptcon {
  margin: 20px 0;
  font-size: 15px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: bold;
  color: #333333;
}

.record_content {
  display: flex;
  margin-bottom: 18px;
  width: 400px;
  height: 32px;
  background: #f9fbfd;
  border: 1px solid #d9e6ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 4px;
}

.scllo_center {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  width: 100px;
  height: 30px;
  border-radius: 4px;
}

.action_scoll {
  color: #ffffff;
  background: #4c84ff;
}

.tp_left {
  display: flex;
  width: 240px;
  height: 76px;
  background: #f5f6fa;
  border-radius: 8px 4px 4px 4px;
}

.tpl_first {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 76px;
  background: #e6edfd;
  border-radius: 8px 0px 0px 4px;
}

.flost_tf {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 60px;
  height: 16px;
  line-height: 15px;
  background: #ff687b;
  border-radius: 8px 8px 8px 1px;
  font-size: 12px;
  font-family: FZLanTingHeiS-R-GB;
  font-weight: 400;
  color: #ffffff;
}

.tpl_first > p {
  text-align: center;
  width: 90px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
// 备注说明
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
}

.center_tf {
  width: 134px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.baocunPrice {
  margin: 14px 0;
  display: flex;
  width: 651px;
  height: 70px;
  background: #f5f6fa;
  border-radius: 4px;
}

.xiuga_center {
  width: 120px;
  height: 70px;
  background: #f5f6fa;
  border-radius: 8px 4px 4px 4px;
  margin: 14px 0;
  margin-left: 12px;
}
.zhengnfusty {
  margin-left: 20px;
  span {
    text-align: right;
    font-size: 14px;
    color: #666666;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  /deep/.el-input .el-input__inner {
    border-radius: 40px;
  }
}

.bp_pstyle {
  p {
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #666666;
  }
}

.fs16 {
  font-size: 14px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
</style>
  