import request from './request'

export const http = {
    //是否限制本单继续追差
   setMatrixCode(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matrix/save-fx-channel-code',
            data: params
        })
    },
}