<template>
  <div class="addMobileCompanyDia">
    <el-dialog
      title="添加移动商户"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @close="close"
    >
      <p class="tip">
        说明：目前仅支持按省移动终端公司绑定，绑定后该门店商户仅允许通过移动会员平台专属链接下单
      </p>
      <div>
        <el-form ref="addform" :model="addform" label-width="80px">
          <el-form-item label="选择商家">
            <el-select
              size="small"
              v-model="addform.companyId"
              placeholder="请选择门店商户"
              @change="companyIdChange"
            >
              <el-option
                v-for="item in storeSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <div style="display: flex;align-items: center;" v-if="addform.companyId && contactName">
              <p class="txt-p" style="flex: 1;">联系人：{{contactName}}</p>
              <p class="txt-p" style="flex: 1;margin-left: 10px;">联系电话：{{contactPhone}}</p>
            </div>
          </el-form-item>
          <el-form-item label="选择省份">
            <el-select
              size="small"
              v-model="addform.provinceCode"
              placeholder="请选择所在省"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <p class="txt-p" v-if="addform.provinceCode">省份编码：{{addform.provinceCode}}</p>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="addCompanySubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    provinceList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addform: {
        companyId: "",
        provinceCode: "",
      },
      storeSelectList: [],
      butLoading: false,
      contactName: "",
      contactPhone: "",
    };
  },
  created() {
    this.getStoreSelectList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getStoreSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.storeSelectList = res.data;
        }
      });
    },
    addCompanySubmit() {
      if(!this.addform.companyId){
        return this.$message.error('请选择商家');
      }
      if(!this.addform.provinceCode){
        return this.$message.error('请选择省份');
      }
      this.butLoading = true;
      _api.cmccBindCompany(this.addform).then((res) => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.$emit('setSuccess')
        }
      }).finally(() => {
        this.butLoading = false;
      });
    },
    companyIdChange() {
      if(this.addform.companyId){
        _api.getCompanyAccount({ companyId: this.addform.companyId }).then((res) => {
          if (res.code === 1) {
            this.contactName = res.data.contactName
            this.contactPhone = res.data.contactPhone
          }
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.addMobileCompanyDia {
  .tip {
    color: #ff687b;
    font-size: 14px;
    margin-bottom: 20px;
  }
  /deep/.el-input__inner {
    border-radius: 100px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  .txt-p {
    line-height: 20px;
  }
}
</style>
