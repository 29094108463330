<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="机型标记" slot="mark" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.mark" style="color: red;">
            {{ row.mark==='00'?'折叠屏机型':'曲面屏机型' }}
          </div>
          <div v-else>
            --
          </div>
        </template>
      </el-table-column>
      <el-table-column label="机型来源" slot="channel" align="center">
        <template slot-scope="{ row }">
          <span>
            {{ row.channel}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否启用" slot="operationEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleSwitch(row,row.isEnable)" v-model="row.isEnable"></el-switch>
        </template>
      </el-table-column>

      <el-table-column v-for="item in channelList" :key="item.key" :label="'关联'+item.value+'机型'" slot="operationEnable" align="center">
        <template slot-scope="{ row }">
          <p v-if="!row.outModelObj[item.key]" class="link primary" @click="$refs['addModelBindDia'].open(item, row)">添加绑定</p>
          <template v-else>
            <p>{{ row.outModelObj[item.key].outModelName }}</p>
            <p class="link danger" @click="cancelBind(row.outModelObj[item.key], row)">取消绑定</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="询价使用规格" slot="operationSku" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.inquirySkuType=='00'?'统一规格':'本机规格' }}</span>
          <div class="link danger" @click="editSkuType(row)">修改</div>
        </template>
      </el-table-column>
      <el-table-column label="本机规格" slot="operationLocalSku" align="center">
        <template slot-scope="{ row }">
          <span class="link primary" @click="seeLocalSku(row)">查看</span>
        </template>
      </el-table-column>
      <el-table-column label="排序" slot="operationSort" align="center" min-width="130px">
        <template slot-scope="{ row }">
          <el-input-number size="mini" v-model="row.sort" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="250" label="操作" slot="operation" align="center" fixed="right">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row, 'edit')" :disabled="row.isEnable">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleBtn(row, 'del')" :disabled="row.isEnable">删除</el-button>
          <el-button size="mini" type="danger" plain v-if="row.mark" @click="handleBtn(row, 'cancel')" >取消标记</el-button>
          <el-button size="mini" type="success" v-else @click="handleBtn(row, 'mark')">机型标记</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 取消标记 -->
    <el-dialog
      title="操作提示"
      :visible.sync="dePhoneShow"
      :close-on-click-modal="false"
      width="550px"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：取消后，该机型不再为曲面屏或者折叠屏
      </p>
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认取消该机型的曲面屏标记？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dePhoneShow = false">取消</el-button>
        <el-button type="primary" @click="dePhnoeSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="handleDialogClosed"
    >
      <div v-if="dialogTitle!=='编辑机型'" style="font-size:16px;color:#666666;text-align:center;height:100px;line-height:100px">确认要执行该操作吗？</div>
      <el-form v-else ref="form" :model="modelform" label-width="80px">
        <el-form-item label="所属分类">
          <el-select v-model="modelform.machineTypeId" placeholder="请选择分类">
            <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属品牌">
          <el-select v-model="modelform.brandId" filterable placeholder="请选择品牌">
            <el-option v-for="ele in oldBrand" :key="ele.id" :label="ele.name" :value="ele.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机型名称">
          <el-input v-model="modelform.name"  placeholder="请输入机型名称（限20字以内）"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 机型标记弹框 -->
    <el-dialog title='机型标记' :visible.sync="markShow" :close-on-click-modal="false" width="585px"
      @closed=" markShow = false">
      <div style="color: #FF8080;font-size:14px;margin-bottom: 20px;">说明：标记后店员选择该机型下单时，细节示例图展示对应分类的图片；已标记不可取消标记。</div>
      <div class="mark">
        <div style="width: 160px;font-weight: 600;"><span style="color: #FF8080">*</span>请选择标记机型分类：</div>
        <div>
          <el-radio-group v-model="markParams.mark">
            <el-radio label="00">折叠屏机型</el-radio>
            <el-radio label="01">曲面屏机型</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native=" markShow = false">取消</el-button>
        <el-button type="primary" @click.native="showMarkSubmit">确定</el-button>
      </span>
    </el-dialog>
    <AddModelBindDia ref="addModelBindDia" @confirm="handleCurrentChange()" />
    <el-dialog
      title="修改信息"
      :visible.sync="skuTypeVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <el-form label-width="120px">
        <el-form-item label="询价使用规格:" required>
          <el-radio-group v-model="skuType">
            <el-radio label="00">统一规格</el-radio>
            <el-radio label="01">本机规格</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="skuTypeVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="skuTypeBtnLoading"
          @click.native="skuTypeSetSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="查看本机规格"
      :visible.sync="localSkuVisible"
      :close-on-click-modal="false"
      width="600px"
    >
      <div class="local-sku-list" v-loading="localSkuLoading">
        <div v-for="item in localSkuList" :key="item.optionKeyId">
          <p class="title">{{item.optionKeyName}}</p>
          <div>
            <el-tag type="info" v-for="ele in item.optionList" :key="ele.optionId">{{ele.optionName}}</el-tag>
          </div>
        </div>
        <div v-if="!localSkuList.length">
          <el-empty :image="require('@/assets/home/tjsjtb.png')"></el-empty>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="localSkuVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import { confirm } from '@/components/common/messagebox'
import AddModelBindDia from './dialog/AddModelBindDia.vue';

export default {
  components: { AddModelBindDia },
  name: "Table",
  mixins: [MinXinRequest],
  props:{
    classList:Array,
    oldBrand:Array,
    channelList: Array
  },
  data() {
    return {
      dePhoneShow:false,
      // 标记机型
      markShow:false,
      markParams:{
        mark:'',
        modelId:''
      },
      seachDataList: [],
      modelform: {},
      ModelEnable: {
        id: "",
        isEnable: ""
      },
      ResidentHot:{
        id: "",
        isHot: ""
      },
      pageNum:1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      tableColumns: [
        { label: "机型名称", prop: "name" },
        { label: "所属分类", prop: "typeName" },
        { label: "所属品牌", prop: "brandName" },
        { label: "报价次数", prop: "quotedNum" },
        { slotName: "mark" },
        { slotName: "channel" },
        { slotName: "operationEnable" },
        { slotName: "operationSku" },
        { slotName: "operationLocalSku" },
        { slotName: "operationSort" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "operation" }
      ],
      skuType: '',
      skuTypeVisible: false,
      skuTypeBtnLoading: false,
      rowId: "",
      localSkuVisible: false,
      localSkuLoading: false,
      localSkuList: [],
    };
  },
  created() {

  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    }
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1
      this.handleCurrentChange();
    }
  },
  methods: {
    dePhnoeSubmit(){
      _api.phonecancelMark({modelId:this.ModelEnable.id}).then(res=>{
        this.$message({
              type: 'success',
              message: '取消成功!'
            });
        this.dePhoneShow = false
        this.handleCurrentChange();
      })
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        name: this.SeachParams.name,
        machineTypeId: this.SeachParams.machineTypeId,
        brandId: this.SeachParams.brandId,
        mark:this.SeachParams.mark,
        channel:this.SeachParams.channel,
        inquirySkuType:this.SeachParams.inquirySkuType,
        pageNum: this.page.pageNum,
        pageSize: this.SeachParams.pageSize
      };
      this.$emit("handleCurrentChange", val);
      _api.modelList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records.map(item => {
            // 把数组处理成对象，方便访问
            item.outModelObj = item.outModelViews.reduce((prev, current) => {
              prev[current.channel] = current
              return prev
            }, {})
            return item
          });
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    cancelBind(model, row) {
      const { channel, channelName, outModelName } = model
      confirm({
        title: '取消绑定提示',
        width: '550px',
        desc: `说明：取消绑定后，无法通过选择该机型查询原${ channelName }关联机型的预估价`,
        message: `是否确认取消<span style="color: red">${row.name}</span>与${ channelName }机型<span style="color: red">${ outModelName }</span>的绑定关系？`,
        confirm: () => {
          _api.cancelBind({
            channel: channel,
            machineId: row.id
          }).then(() => {
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
            this.handleCurrentChange();
          })
        }
      })
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      if(type == 'edit'){
        this.dialogTitle = "编辑机型";
        this.dialogVisible = true;
        this.ModelEnable.id = row.id;
        this.modelform = {
          id:row.id,
          machineTypeId:row.typeId,
          brandId:row.brandId,
          name:row.name
        }
      }else if(type == 'del'){
        this.dialogTitle = "删除机型";
        this.dialogVisible = true;
        this.ModelEnable.id = row.id;
      }else if(type == 'cancel'){
        this.dePhoneShow = true;
        this.ModelEnable.id = row.id;
      }else{
        console.log(Boolean(row.mark),'aaa');
        this.markShow=true
        this.markParams.modelId=row.id
        this.markParams.mark = row.mark
      }
    },
    // 机型标记确定
    showMarkSubmit(){
      _api.modelMark(this.markParams).then(res => {
        if (res.code === 1) {
          this.markShow=false
          this.markParams.mark=''
          // 刷新列表
          this.handleCurrentChange(this.page.pageNum);
        }
      });
    },
    //禁止/启用机型
    handleSwitch(row,val) {
      if(val){
        this.dialogTitle = "启用机型";
      }else{
        this.dialogTitle = "禁用机型";
      }
      this.ModelEnable.id = row.id;
      this.ModelEnable.isEnable = row.isEnable;
      this.dialogVisible = true;

    },
    // 商品热门
    hotSwitch(row) {
        console.log(row);
        _api.ResidentHot({id:row.id,isHot:row.isHot}).then(res => {
          this.$message({
            message: res.msg,
            type: 'success'
          });
        })
    },
    // //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    //弹窗确定
    handleSuccess() {
      this.dialogVisible = false;
      if(this.dialogTitle === '启用机型' || this.dialogTitle === '禁用机型'){
        _api.modelDisable(this.ModelEnable).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
        });
      }else if(this.dialogTitle === '删除机型'){
        _api.modelDelete(this.ModelEnable).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.handleCurrentChange(this.pageNum)
          } else {
            this.$message.error("操作失败");
          }
        })
      }else{
        _api.modelEdit(this.modelform).then(res => {
          console.log(res);
        })
      }
      
      
    },
    handleDialogClosed() {
      this.dialogVisible = false;
      this.handleCurrentChange(this.pageNum);
    },
    //排序
    handleChangeNum(val) {
      const params = {
        id: val.id,
        sort: val.sort
      };
      _api.modelSort(params).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editSkuType(row) {
      this.rowId = row.id
      this.skuTypeVisible = true
      this.skuType = row.inquirySkuType || '00'
    },
    skuTypeSetSure() {
      this.skuTypeBtnLoading = true
      _api.updateInquirySkuType({inquirySkuType:this.skuType,id:this.rowId}).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功');
          this.skuTypeVisible = false
          this.handleCurrentChange(this.page.pageNum)
        }
      }).finally(()=>{
        this.skuTypeBtnLoading = false
      })
    },
    seeLocalSku(row) {
      this.localSkuVisible = true
      this.localSkuLoading = true
      _api.getModelSkuList({id:row.id}).then(res => {
        if(res.code === 1){
          this.localSkuList = res.data
        }
        this.localSkuLoading = false
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.mark{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.mark-color{
  background-color: #838383;
  border-color: #838383;
}
.el-button--info.is-disabled, .el-button--info.is-disabled:hover{
  background-color: #838383 !important;
  border-color: #838383 !important;
}
.link{
  text-decoration: underline;
  cursor: pointer;
  &.primary{
    color: #0981ff;
  }
  &.danger{
    color: #ff687b;
  }
}
.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 10px;

  .tip-sure {
    margin-top: 5px;
    color: #333333;
    font-size: 15px;
    text-align: center;
  }
}
.local-sku-list{
  .title{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  /deep/.el-tag.el-tag--info{
    margin-right: 20px;
    margin-bottom: 10px;
    color: #666666;
    border-color: #999999;
  }
}
</style>
