<template>
  <div class="mobile-store-manage">
    <GlobalInfoBar
      title="移动门店店员管理"
      content="绑定移动专属链渠道的门店和账号"
    >
      <div slot="right" style="text-align: right;flex:1;font-size:14px;">
        <p>
          <span style="color: #333; font-weight: bold;margin-right: 10px;">中国移动商户</span>
          <span>
            已添加
            <span style="text-decoration: underline;cursor: pointer;color: #0981FF;" @click="openSeeDia">{{bindCompanyNum}}</span>
            个
            <span style="text-decoration: underline;cursor: pointer;color: #ffaf29;" @click="openAddDia">添加</span>
          </span>
        </p>
      </div>
    </GlobalInfoBar>
    <GardenSlwct
      style="margin-bottom: 20px;"
      :tab-data="tabData"
      :activeIndex="tabActiveIndex"
      width="120px"
      border-radius="40px"
      @tabAction="tabAction"
    />
    <template v-if="tabActiveIndex === 0">
      <StoreTable :provinceList="provinceList" :bindCompanyList="bindCompanyList"/>
    </template>
    <template v-if="tabActiveIndex === 1">
      <StaffTable :provinceList="provinceList" :bindCompanyList="bindCompanyList"/>
    </template>
    <AddMobileComDia
      v-if="addMobileCompanyShow"
      :dialogVisible="addMobileCompanyShow"
      :provinceList="provinceList"
      @close="addMobileComClose"
      @setSuccess="addMobileComSure"
    />
    <SeeMobileComDia
      v-if="seeMobileCompanyShow"
      :dialogVisible="seeMobileCompanyShow"
      @close="seeMobileComClose"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import StoreTable from "./StoreTable.vue";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import AddMobileComDia from "./dialog/AddMobileComDia.vue";
import SeeMobileComDia from "./dialog/SeeMobileComDia.vue";
import StaffTable from "./StaffTable.vue";
export default {
  components: {StoreTable, GardenSlwct, AddMobileComDia, SeeMobileComDia,StaffTable},
  data() {
    return {
      bindCompanyNum: 0,
      bindCompanyList: [],
      tabActiveIndex: 0,
      tabData: [ {
        text: '门店',
        value: '00',
        hideNum: true,
      },{
        text: '店员',
        value: '01',
        hideNum: true,
      }],
      addMobileCompanyShow: false, //添加移动商家弹框
      seeMobileCompanyShow: false, //查看移动商家弹框
      provinceList: [], //省列表
    };
  },
  created() {
    this.cmccCompanyList()
    this.getProvince()
  },
  mounted() {},
  methods: {
    // 获取已绑定移动商家数量
    cmccOrgDashboard() {
      _api.cmccOrgDashboard().then((res) => {
        this.bindCompanyNum = res.data.bindCompanyNum
      })
    },
    // 获取已绑定移动商家列表
    cmccCompanyList() {
      _api.cmccCompanyList({pageNum: 1, pageSize: 9999}).then((res) => {
        if(res.code === 1){
          this.bindCompanyList = res.data.records
          this.bindCompanyNum = res.data.total
        }
      })
    },
    tabAction(val) {
        this.tabActiveIndex = val
    },
    addMobileComClose() {
      this.addMobileCompanyShow = false
    },
    // 添加商家成功
    addMobileComSure() {
      this.addMobileComClose()
      this.cmccCompanyList()
    },
    seeMobileComClose() {
      this.seeMobileCompanyShow = false
    },
    openAddDia() {
        this.addMobileCompanyShow = true
    },
    openSeeDia() {
        this.seeMobileCompanyShow = true
    },
    // 获取省
    getProvince() {
      _api.cmccDistrict({type:'00'}).then((r) => {
        this.provinceList = r.data.map((v) => {
          return {
            label: v.key,
            value: v.value,
          };
        });
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.mobile-store-manage {
}
</style>
