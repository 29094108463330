<template>
  <div class="GlobalFormDemo">
    <GlobalForm ref="orderForm" :init-data="initData"  :form-item-list="formItemList"
      :inline="true" :isResetdata="isResetForm" :labelWidth="80" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item label="门店商家" slot="companyId" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择" multiple filterable clearable>
          <el-option v-for="item in companyList" :key="item.id" :label="item.companyName"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属分类" slot="typeId" style="margin:0">
        <el-select v-model="typeId" placeholder="请选择" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.id" :label="item.name"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌名称" slot="brandName" style="margin:0">
          <el-input size="mini" clearable  v-model="brandName" placeholder="请输入品牌名称"></el-input>
      </el-form-item>
      <el-form-item label="机型名称" slot="phoneName" style="margin:0">
          <el-input size="mini" clearable v-model="phoneName" placeholder="请输入机型名称"></el-input>
      </el-form-item>
      <el-form-item label="选择时间" slot="entryTime" style="margin:0">
        <div class="flex-ac">
          <el-button class="flex-ac" :class="dateOn == item.value
              ? 'date-click-item active'
              : 'date-click-item'
            " v-for="item in dateList" :key="item.value" @click="setDateOn(item.value)">{{ item.text }}</el-button>
          <el-date-picker style="margin-left: 30px;" v-model="dateTime" :picker-options="pickerOptions" size="small"
            type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']" @change="dateTimeChange">
          </el-date-picker>
          <span class="add_time" @click="addTimeChoose">{{ addTimeText }}</span>
          <el-date-picker style="margin-left: 10px;" v-model="dateThenTime" :picker-options="pickerOptions" size="small"
            type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']" @change="dateThenTimeChange" v-if="addTimeTrue">
          </el-date-picker>
          <span @click="addTimeCancle" v-if="addTimeTrue == true"><img
              src="../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
        </div>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    default: () => {
      return [];
    },
    // tab切换
    tabIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
      dateOn: "01",
      dateList: [
        { value: "01", text: "今日" },
        { value: "03", text: "本周" },
        { value: "05", text: "本月" },
      ],
      isResetForm: false,
      companyId: [],
      merchantId: '',
      typeId: '',//所属分类
      companyList: [],
      InquirySelectList: [],
      shanpsSelectList: [],
      initData: {
      },
      startTime: "",
      endTime: "",
      formItemList: [
        { slotName: "companyId" },
        { slotName: "typeId" },//所属分类
        { slotName: "brandName" },//品牌名称
        { slotName: "phoneName" },//机型名称
        { slotName: "entryTime" },//下单时间
      ],
      healthCodeOption: [],
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      dateTime: null,
      dateThenTime: null,
      SeachParams: {},
      phoneName:"",
        brandName: "",
    };
  },
  created() {
    this.merchantId=this.$route.query.id
    this.handleConfirm()
    _api.selectList().then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    _api.listCompany({ merchantId: this.merchantId, pageSize: 99999 }).then((res) => {
      const list = res.data.ipage.records
      this.companyList = list.filter(item => item.applyStatus != '00')
    });
  },
  methods: {
    setDateOn(type) {
      console.log(type);
      this.dateTime = null
      this.dateOn = type;
      this.handleConfirm()
    },
    // 时间区间查询
    dateTimeChange(e) {
      if (e) {
        this.dateOn = ""
      } else {
        this.dateOn = "01"
      }
      this.handleConfirm()
    },
    dateThenTimeChange(e) {
      console.log(e);
        this.handleConfirm()
    },
    addTimeChoose() {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
    },
    addTimeCancle() {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.dateTime = null
      this.dateThenTime = null
      this.dateOn = '01'
      this.handleConfirm()
    },
    //查询****
    handleConfirm(data) {
      console.log("提交了form", data);
      console.log(this.dateTime);
      if (!this.dateTime) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.dateTime[0]).format("x")),
          (this.endTime = moment(this.dateTime[1]).format("x"));
      }
      const baseRequest = {
        dateType: this.dateOn, //	今日 01 本周：03，本月：05
        phoneName: this.phoneName,
        brandName: this.brandName,
        endTime: this.endTime,
        startTime: this.startTime,
        thanEndTime: this.dateThenTime ? this.dateThenTime[1] : "",
        thanStartTime: this.dateThenTime ? this.dateThenTime[0] : "",
        pageNum: 1,
        pageSize: 10,
        typeId: this.typeId,
        companyId: this.companyId
      };
      this.SeachParams = baseRequest;
      console.log(baseRequest, this.SeachParams, "参数333");
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);

    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.close_img {
  width: 30px;
  margin-left: 8px;
  margin-top: 2px;
}

/deep/.query .el-tag.el-tag--info {
  background-color: #fbfbfb;
  border-color: #cecdcd;
  color: #666666;
}

.GlobalFormDemo {
  /deep/.el-checkbox-group {
    margin-left: 15px;
  }

  // /deep/.el-form-item--small.el-form-item{
  //   margin-bottom: 0;
  // }
  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }

}

.txt-2 {
  color: #8492a6;
}

.flex {
  display: flex;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EFF2F7;
  padding: 0 0 16px 0;
}

.flex-ac {
  display: flex;
  align-items: center;
}

.date-click-item {
  margin-left: 10px;
  cursor: pointer;
  height: 35px
}

.date-click-item:hover {
  text-decoration: underline;
  color: #20a0ff;
  height: 35px
}

.date-click-item.active {
  color: white;
  background: #20a0ff;
  font-weight: 600;
  height: 35px
}

.add_time {
  width: 200px;
  color: #20A0FF;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer;
}
</style>
