import Layout from '@/layout/layout'

const elementRouter = {
  path: '/merchantExamine',
  component: Layout,
  redirect: 'noRedirect',
  name: '回收商审单',
  children: [
    {
      path: 'reviewSettings',
      component: () => import('@/views/merchant/views/reviewSettings/index'),
      name: '审单商家列表',
      meta: { title: '审单商家列表', noCache: true }
    },
    {
      path: 'sampleGraph',
      component: () => import('@/views/merchant/views/sampleGraph/index'),
      name: '审单示例图',
      meta: { title: '审单示例图', noCache: true }
    }
  ]
}

export default elementRouter
