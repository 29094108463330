<template>
    <div class="see-amount-dia">
      <el-dialog
        :title="'报价详情-'+(orderInfo.companyName || '')"
        :visible="dialogVisible"
        :close-on-click-modal="false"
        width="700px"
        top="3%"
        @close="close"
      >
        <div>
            <div class="flex-jb mb-10">
                <p>订单编号：{{ orderInfo.orderNo }}</p>
                <p>报价人：{{ orderInfo.quoterName }}</p>
            </div>
            <div class="flex-jb mb-10">
                <p>订单机型：{{ orderInfo.machineName }} {{ orderInfo.machineCapacity }}</p>
                <p>订单状态：
                  <span v-if="orderInfo.state === '00'">待确认</span>
                  <span v-else-if="orderInfo.state === '01'">已确认</span>
                  <span v-else-if="orderInfo.state === '02'">待支付</span>
                  <span v-else-if="orderInfo.state === '03'">已绑码</span>
                  <span v-else-if="orderInfo.state === '04'">已收货</span>
                  <span v-else-if="orderInfo.state === '05'">降价收货</span>
                  <span v-else-if="orderInfo.state === '10'">已取消</span>
                  <span v-else-if="orderInfo.state === '20'">已作废</span>
                  <span v-else-if="orderInfo.state === '30'">已退回</span>
                </p>
            </div>
            <div class="flex-jb mb-10">
                <p>出货价：￥{{ orderInfo.deliveryPrice }}（{{orderInfo.healthName}}）</p>
                <p v-if="orderInfo.perPrice">{{type=='01'?'预估':'审单'}}报价：￥{{ orderInfo.perPrice }}</p>
                <p style="color: #ff687b;">最终报价：￥{{ orderInfo.originalQuotePrice }}</p>
            </div>
        </div>
        <el-table
          ref="amountDia"
          :data="dialogTableData"
          v-loading="diaLoading"
          tooltip-effect="dark"
          max-height="500px"
          border
          :row-class-name="hideExpandIcon"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                ref="feeInfoList"
                :data="props.row.feeInfoList"
                tooltip-effect="dark"
                border
              >
                <el-table-column type="index" align="center" label="序号" width="50" />
                <!-- <el-table-column align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.channelName }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column  align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.optionName }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" width="150px">
                  <template slot-scope="scope">
                    <span style="color: #ff687b;" v-if="scope.row.opt == '00'">{{ scope.row.price }}</span>
                    <span style="color: #ff687b;" v-else-if="scope.row.opt == '01'">-{{ scope.row.price }}</span>
                    <span style="color: #67c23a;" v-else-if="scope.row.opt == '02'">+{{ scope.row.price }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column type="index" align="center" label="序号" width="50" />
          <el-table-column label="费用类型" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.channelName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="扣费项" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.optionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="150px" align="center">
            <template slot-scope="{ row }">
              <span style="color: #ff687b;" v-if="row.opt == '00'">{{ row.price }}</span>
              <span style="color: #ff687b;" v-else-if="row.opt == '01'">-{{ row.price }}</span>
              <span style="color: #67c23a;" v-else-if="row.opt == '02'">+{{ row.price }}</span>
              <div class="txt-underline" v-if="row.optionName=='毛利' && row.feeInfoList && row.feeInfoList.length" @click="seeAdjust">监控调整</div>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click.native="close">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="监控调整"
        :visible.sync="adjustDiaVisible"
        :close-on-click-modal="false"
        width="550px"
      >
        <div class="flex mb-10 flex-jb">
          <p>调整机型：{{orderInfo.machineName}} {{ orderInfo.machineCapacity }}</p>
          <p>调整成色：{{orderInfo.healthName}}</p>
        </div>
        <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="dataList"
          :isPagination="false"
        >
          <el-table-column label="调整值" slot="adjustPrice" align="center">
            <template slot-scope="{ row }">
              <p>{{row.adjustOpt=='02'?'-':'+'}}{{ row.adjustPrice }}</p>
            </template>
          </el-table-column>
          <el-table-column label="调整后" slot="afterPrice" align="center">
            <template slot-scope="{ row }">
              <p style="color: #ff687b;">{{ row.afterPrice }}</p>
            </template>
          </el-table-column>
        </GlobalTable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click.native="adjustDiaVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      merchantId: [Number,String],
      orderNo: [String],
      type: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        diaLoading: false,
        dialogTableData: [],
        orderInfo: {},
        adjustDiaVisible: false,
        dataList: [],
        tableColumns: [
          { label: "任务名称", prop: "taskName" },
          { label: "调整前", prop: "beforePrice" },
          { slotName: "adjustPrice" },
          { slotName: "afterPrice" },
        ]
      };
    },
  
    created() {
      this.dialogSearchAmount();
    },
    methods: {
      close() {
        this.$emit("close");
      },
      hideExpandIcon({ row, rowIndex }) {
        // 这里可以根据实际条件判断
        if (!row.feeInfoList.length || row.optionName==='毛利') {
          return 'hide-expand-icon';
        }
        return '';
      },
      dialogSearchAmount() {
        this.diaLoading = true;
        _api
          .getExamineOrQuickInfo({orderNo:this.orderNo,merchantId:this.merchantId})
          .then((res) => {
            this.orderInfo = res.data;
            this.dialogTableData = res.data.feeInfoList
            this.dataList = []
            res.data.feeInfoList.forEach(item => {
              if(item.optionName === '毛利' && item.feeInfoList && item.feeInfoList.length){
                let obj = {
                  taskName: '',
                  beforePrice: '',
                  adjustPrice: '',
                  adjustOpt: '',
                  afterPrice: item.price,
                  afterOpt: item.opt
                }
                item.feeInfoList.forEach(ele => {
                  if(ele.channelName === '监控报价调整-毛利'){
                    obj.taskName = ele.optionName
                    obj.adjustPrice = ele.price
                    obj.adjustOpt = ele.opt
                  }
                  if(ele.channelName && ele.channelName.substring(0, 4) === '渠道费用'){
                    obj.beforePrice = ele.price
                  }
                })
                this.dataList.push(obj)
              }
            })
          })
          .finally(() => {
            this.diaLoading = false;
          });
      },
      seeAdjust() {
        this.adjustDiaVisible = true
      }
    },
  };
  </script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
  .see-amount-dia {
    .nowrap-ellipsis {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .flex{
      display: flex;
      align-items: center;
    }
    .flex-jb{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .flex-je {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .mb-10 {
      margin-bottom: 10px;
    }
    .txt-underline {
      color: #0981ff;
      text-decoration: underline;
      cursor: pointer;
    }
    /deep/.el-form-item {
      margin-bottom: 10px !important;
    }
      /* 添加一个CSS类来隐藏展开图标 */
    /deep/.hide-expand-icon .el-table__expand-column .el-table__expand-icon {
      display: none;
    }
    /deep/.el-table__expanded-cell{
      padding-left: 48px;
    }
    /deep/.el-table__expanded-cell .el-table .el-table__header-wrapper {
      display: none;
    }
  }
  </style>
  