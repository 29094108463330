<template>
  <el-dialog title="添加提示" :visible.sync="visible" :close-on-click-modal="false" width="550px" @closed="closed">
    <p  style="color: #ff8080;margin-bottom: 25px;">说明：添加后，本地机型库将新增一条机型，需核实好是否有重复机型</p>
    <div style="text-align: center;margin-bottom: 20px">是否确认添加<span style="color: red">{{ row.outMachineName }}</span>机型到本地机型库？</div>
    <el-form v-if="formVisible" ref="formRef" :model="form" :rules="rules">
      <el-form-item label="外部所属品牌：">{{ row.outBrandName }}</el-form-item>
      <el-form-item label="选择所属分类：" v-if="row.channel === 'cmcc'">
        <el-select v-model="form.typeId" prop="typeId" placeholder="请选择所属分类" @change="typeIdChange">
          <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择所属品牌：">
        <el-select v-model="form.brandId" prop="brandId" filterable placeholder="请输入品牌名称查询">
          <el-option v-for="item in oldBrand" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'AddToLocalDia',
  props:{
    classList:Array,
  },
  data() {
    return {
      visible: false,
      row: {},
      oldBrand: [],
      form: {
        brandId: '',
        typeId: '',
      },
      rules: {
        typeId: [{ required: true, message: '请选择所属分类', trigger: 'change'}],
        brandId: [{ required: true, message: '请选择所属品牌', trigger: 'change'}]
      }
    }
  },
  computed: {
    formVisible() {
      return !['fh', 'chx'].includes(this.row.channel)
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.getResidentList()
      this.$refs['formRef']?.resetFields()
      this.visible = true
    },
    getResidentList() {
      if (!this.formVisible) return
      _api.ResidentList({typeId:this.form.typeId}).then(res => {
        this.oldBrand = res.data
      })
    },
    close() {
      this.visible = false
    },
    closed() {},
    async confirm() {
      if (this.$refs['formRef']) await this.$refs['formRef'].validate()
      const { row } = this
      _api.doSaveInPlatform({
        channel: row.channel,
        outMachineId: row.outMachineId,
        ...this.form
      }).then(res => {
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
        this.$emit('confirm')
        this.close()
      })
    },
    typeIdChange() {
      this.getResidentList()
    }
  }
}
</script>