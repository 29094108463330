<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div :class="this.auditStatus === '00' ? 'active' : 'none'" @click="btnClick('00')">
            全部({{ applyNumber.allCount }})
          </div>
          <div :class="this.auditStatus === '01' ? 'active' : 'none'" @click="btnClick('01')">
            待审核<span :class="this.auditStatus !== '01' && applyNumber.toAuditCount > 0 ? 'tipColor' : ''">({{
              applyNumber.toAuditCount
            }})</span>
          </div>
          <div :class="this.auditStatus === '02' ? 'active' : 'none'" @click="btnClick('02')">
            已通过({{ applyNumber.passAuditCount }})
          </div>
          <div :class="this.auditStatus === '03' ? 'active' : 'none'" @click="btnClick('03')">
            已驳回({{ applyNumber.refuseAuditCount }})
          </div>
        </div>
      </div>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="商家名称" width="130rpx" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.channel == '01'" class="channeltype1">后台</span>
          <span v-if="row.channel == '02'" class="channeltype2">前端</span>
          <span>{{ row.companyName || "--" }}</span>
          <div v-if="row.isOpenNiceBiz">
            <el-tag type="danger" size="mini">靓机商家</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="详细地址" width="130rpx" slot="companyAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.companyAddress" placement="top">
            <div class="Remarks">
              {{ row.companyAddress }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" slot="auditStatus" align="center">
        <template slot-scope="{ row }">
          <span
            :style="'font-size:12px;' + (row.auditStatus == '01' ? 'color:#FF687B;' : row.auditStatus == '02' ? 'color:#80C269;' : 'color:#FFAF29;')">{{
              row.auditStatus == '01' ? '待审核' : row.auditStatus == '02' ? '已通过' : '已驳回'
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合作回收商" slot="merchantNum" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.merchantNum" @click="seeRecycler(row, '01')"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">{{ row.merchantNum }}</span></span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="受邀回收商" slot="inviteMerchantNum" width="100" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.inviteMerchantNum" @click="seeRecycler(row, '02')"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">{{
                row.inviteMerchantNum
              }}</span></span>
          <span v-else>0</span>
          <span v-if="row.auditStatus != '01' && row.auditStatus != '03'"
            style="color: #FFAF29;margin-left:5px;cursor: pointer;" @click="Addpartner(row)">添加</span>
          <div v-if="row.passMerchantNum" style="color:#21D8C2;">{{ row.passMerchantNum }}家已授权同意</div>
        </template>
      </el-table-column>
      <el-table-column label="当前报价矩阵" slot="inviteMerchantNum" width="100" align="center">
        <template slot-scope="{ row }">
          <a v-if="row.matrixId" target="_blank"
            :href="`/matrix/config?matrixId=${row.matrixId}&matrixName=${row.matrixName}`">{{ row.matrixName }}</a>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="当前差异基金" slot="inviteMerchantNum" width="100" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.diffFundBalance }}</div>
          <p v-if="!row.isMatrix" class="lv_fc_line" @click="seeSeting(row)">查看设置</p>
        </template>
      </el-table-column>
      <el-table-column label="差异基金实际余额" slot="trueMerchantNum" width="130" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>差异基金实际余额</div>
            <div>
              <el-tooltip class="item" effect="dark" content="差异基金实际余额=当前差异基金+虚拟账户的余额" placement="top">
                <img src="../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="{row}">
          <span>{{ row.diffFundBalanceReal || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付设置" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;" v-if="row.auditStatus == '01'">通过后可设置</div>
          <div style="color:#666666;" v-if="row.auditStatus == '03'">无法设置</div>
          <div v-if="row.auditStatus == '02'" @click="goPaySet(row)"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">{{
                row.isPayConfig ? '查看设置' : '设置'
              }}</span>
          </div>
          <!-- <div>{{row.isAckPayConfig && row.auditStatus!='03'?'门店已同意该设置':''}}</div>
                <div v-if="row.isPayConfig && row.ackPayStatus=='01'" @click="sendPayMsg(row)"><span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">发送支付设置提醒</span></div>
                <div v-if="row.isPayConfig && (row.ackPayStatus=='02' || row.ackPayStatus=='03')"><span style="color:#0981ff;">{{row.ackPayStatus=='02'?'待门店同意设置':'门店已同意该设置'}}</span></div> -->
        </template>
      </el-table-column>
      <el-table-column label="支付开启/关闭" width="100" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;" v-if="row.auditStatus == '01'">通过后可设置</div>
          <div style="color:#666666;" v-if="row.auditStatus == '03'">无法设置</div>
          <el-switch v-if="row.auditStatus == '02'" inactive-color="#bebebe" v-model="row.isEnable"
            @change="handleSwitch(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="付款模式" slot="operation" width="100" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;" v-if="row.auditStatus == '01'">通过后可设置</div>
          <div style="color:#666666;" v-if="row.auditStatus == '03'">无法设置</div>
          <div v-if="row.auditStatus == '02'">
            {{ row.niceAckPayment == '01' || !row.isOpenNiceBiz ? '成交商家付款' : '靓机商家付款' }}
          </div>
          <div v-if="row.auditStatus == '02' && row.isOpenNiceBiz" @click="editNiceAckPayment(row)"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">修改</span></div>
        </template>
      </el-table-column>
      <el-table-column label="撤销收货设置" slot="operation" width="130" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;" v-if="row.auditStatus == '01'">通过后可设置</div>
          <div style="color:#666666;" v-if="row.auditStatus == '03'">无法设置</div>
          <div v-if="row.auditStatus == '02'" :style="row.canCancelReceipt == '02' ? 'color:#FF8080;' : ''">
            {{ row.canCancelReceipt == '01' ? '可撤销收货' : '不可撤销收货' }}
          </div>
          <div v-if="row.auditStatus == '02'" @click="setCancelReceipt(row)"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">设置</span></div>
        </template>
      </el-table-column>
      <el-table-column label="交易手续费" slot="operation" width="130" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;">{{ row.serviceFee || 0 }}‰</div>
          <div @click="TransactionAction(row)"><span
              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">设置</span></div>
        </template>
      </el-table-column>
      <el-table-column label="是否标记矩阵商户" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.isMatrix" style="color: #FF0000">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="备注说明" width="100px" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.masterNote" class="item" effect="dark" :content="row.masterNote" placement="top">
            <div class="Remarks">
              {{ row.masterNote }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" fixed="right" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row)">{{
            row.auditStatus == '01' ? '审核' : '审核记录'
          }}
          </el-button>
          <el-button size="mini" type="success" round @click="handleBtn(row, 'remark')">{{
            row.masterNote ? '修改备注' :
            '添加备注'
          }}
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 差异基金设置弹框 -->
    <el-dialog title="差异基金设置" :visible.sync="currentFundShow" :close-on-click-modal="false" width="900px"
      @closed="currentFundShowClose">
      <div class="addparto" style="margin-bottom: 15px;">
        说明：设置差异基金后，回收商报价时系统会自动扣减差异基金部分，剩余为回收商报价
      </div>
      <div>
        <span style="margin-right: 14px;">是否开启报价预留差异基金</span>
        <el-switch v-model="currentFundDataList.isOpenDiffFund" active-value="01" inactive-value="02" active-text="开"
          inactive-text="关">
        </el-switch>
      </div>
      <div class="fund-title" style="display: flex;align-items: center;justify-content: space-between;">
        <span>差异基金规则</span>
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="addRule('add')">添加规则</el-button>
      </div>
      <el-table border stripe size="small" :maxHeight="500"
        :data="currentFundDataList.regionList ? currentFundDataList.regionList : []" :isPagination="false">
        <!-- 序号 -->
        <el-table-column label="序号" width="50" prop="index" align="center">
          <template v-slot="{ row }">
            <div>{{ row.index }}</div>
          </template>
        </el-table-column>
        <!-- 报价区间 -->
        <el-table-column label="报价区间" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.lower }} ≤区间&lt; {{ row.upper }}</div>
          </template>
        </el-table-column>
        <el-table-column label="差异基金" prop="diffFund" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.diffFund }} <span v-if="row.diffFundType == '01'">%</span></div>
          </template>
        </el-table-column>
        <el-table-column label="差异基金上限" prop="diffFundLimit" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.diffFundLimit }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="{ row }">
          <el-button type="text" @click="addRule('edit', row)">编辑</el-button>
          <span class="delet-btn" @click="deleteCurrentFund(row)">删除</span>
        </el-table-column>
      </el-table>
      <div class="fund-bottom">
        <div>
          <div style="margin-bottom: 6px;">
            最近修改人：{{ currentFundDataList.operateUserName }}-{{ currentFundDataList.operateUserAcc }}
          </div>
          <div>
            修改时间：{{ currentFundDataList.updateTime || '--' }}
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="currentFundShow = false;
          ">取消</el-button>
          <el-button type="primary" @click.native="showCommand">确定</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 差异基金设置动态口令弹框 -->
    <el-dialog title="操作提示" :visible.sync="commandShow" :close-on-click-modal="false" width="480px">
      <div class="command">
        <div>谷歌验证码：</div>
        <el-input v-model="saveDiffCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="setFundSure">确定</el-button>
      </span>
    </el-dialog>
    <!--差异基金规则设置弹框  -->
    <el-dialog :title="ruleTitle" :visible.sync="addRuleShow" :close-on-click-modal="false" width="700px"
      @closed="addRuleShowClose">
      <!-- 报价区间 -->
      <div class="rule-display">
        <div class="rule-display-title"><span style="color: #FF0000;">*</span>报价区间</div>
        <div>
          <el-input-number class="input_w" controls-position="right" v-model="saveDiffFundParam.lower" :min="0"
            placeholder="输入起始值" :precision="0"></el-input-number>
          <span style="margin: 0 10px 0 10px;">~</span>
          <el-input-number class="input_w" controls-position="right" v-model="saveDiffFundParam.upper"
            :min="saveDiffFundParam.lower + 1" placeholder="输入结束值" :precision="0"></el-input-number>
        </div>
      </div>
      <!-- 返利方式 -->
      <div class="rule-display">
        <div class="rule-display-title"><span style="color: #FF0000;">*</span>返利方式</div>
        <el-radio-group :disabled="diffFundTypeDisabled" v-model="saveDiffFundParam.diffFundType">
          <el-radio label="01">按比例返回</el-radio>
          <el-radio label="02">按固定金额返回</el-radio>
        </el-radio-group>
      </div>
      <!-- 差异基金 -->
      <div class="rule-display">
        <div class="rule-display-title"><span style="color: #FF0000;">*</span>差异基金</div>
        <div v-if="saveDiffFundParam.diffFundType == '02'">
          <!-- 差异基金金额 -->
          <el-input-number class="input_w" controls-position="right" v-model="saveDiffFundParam.diffFund" :min="0"
            :max="99999" placeholder="输入差异基金金额" :precision="0" />
        </div>
        <div v-else>
          <!-- 差异基金比例-->
          <el-input-number class="input_w" controls-position="right" v-model="saveDiffFundParam.diffFund" :min="0"
            :max="100" placeholder="输入差异基金比例" :precision="1"></el-input-number>
          %
        </div>
      </div>
      <!-- 差异基金上限-->
      <div class="rule-display">
        <div class="rule-display-title"><span style="color: #FF0000;">*</span>差异基金上限</div>
        <div>
          <!-- 按比例+差异基金为0%时。差异基金上限金额只能为0 -->
          <el-input-number v-if="saveDiffFundParam.diffFundType == '01' && saveDiffFundParam.diffFund === 0"
            class="input_w" controls-position="right" v-model="saveDiffFundParam.diffFundLimit" :min="0" :max="0"
            placeholder="输入差异基金上限金额" :precision="0"></el-input-number>
          <!-- 按固定金额时，差异基金上限金额不能输入，值为差异基金的值 -->
          <!-- 其他情况 -->
          <el-input-number v-else class="input_w" controls-position="right" v-model="saveDiffFundParam.diffFundLimit"
            :min="0" :max="99999" placeholder="输入差异基金上限金额" :precision="0"
            :disabled="saveDiffFundParam.diffFundType == '02'"></el-input-number>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addRuleShow = false;
        ">取消</el-button>
        <el-button type="primary" @click.native="addRuleSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 备注弹出框 -->
    <el-dialog class="acc_dialog" :title='RemarksTitle' :visible.sync="RemarksShow" :close-on-click-modal="false"
      width="410px" @closed="remarksClosed">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="20" type="textarea" :rows="4" placeholder="请输入备注内容,最多20字" v-model="masterNote">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title='recyclerTitle' :visible.sync="recyclerShow" :close-on-click-modal="false" width="800px">
      <GlobalTable ref="GlobalTable" v-loading="recyclerloading" :columns="recyclerColumns" :maxHeight="500"
        :data="recyclerDataList" :isPagination="false">
        <el-table-column label="合作状态" slot="companyIsEnable" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.applyStatus ==
              '00' ? '邀请待同意' : row.applyStatus == '01' ? '合作中' : row.applyStatus == '02' ? '邀请未通过' : '取消合作'
            }}{{
  row.companyIsEnable == '02' &&
  row.applyStatus != '00' && row.applyStatus != '02' ? '(关闭报价)' : ''
}}</span>
          </template>
        </el-table-column>
        <el-table-column label="受邀状态" slot="onlinePayType" align="center" v-if="recyclerType == '02'">
          <template slot-scope="{ row }">
            <span>{{ row.onlinePayType == '01' ? '待同意' : row.onlinePayType == '02' ? '已同意' : '未邀请' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手续费承担方" slot="processFeeBear" align="center" v-if="recyclerType == '02'">
          <template slot-scope="{ row }">
            <span>{{ row.processFeeBear == '01' ? '门店' : '回收商' }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="recyclerClosed">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 启用禁用提示框 -->
    <el-dialog class="acc_dialog"
      :title="commandType != 'verify' && isEnable ? '启用提示' : commandType != 'verify' && !isEnable ? '禁用提示' : '审核确认'"
      :visible.sync="DisableShow" :close-on-click-modal="false" width="500px" @closed="commandDialogClosed">
      <div v-if="commandType != 'verify'">
        <div style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 10px 0" v-if="!isEnable">
          关闭后该商家将无法线上支付。
        </div>
        <div style="margin-bottom: 20px; font-weight: 500">
          {{ isEnable ? '是否确认启用该门店商家的线上支付？' : '是否确认禁用该门店商家的线上支付' }}
        </div>
      </div>
      <div class="command">
        <div style="width: max-content;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="DisableClose">取消</el-button>
        <el-button type="primary" @click.native="DisableSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="458px"
      @closed="handleDialogClosed">
      <div class="verify-title"><span>商家信息</span></div>
      <div style="font-size:14px;color:#333333">
        <div class="mb-10"><span style="margin-bottom: 10px;">商家名称：</span><span style="color:#666666;">{{
          merchantInfo.companyName
        }}</span></div>
        <div class="mb-10"><span>联系人：</span><span style="color:#666666;">{{ merchantInfo.contact }}</span><span
            style="margin-left:20px">联系电话：</span><span style="color:#666666;">{{ merchantInfo.phone }}</span></div>
      </div>
      <div class="verify-title" v-if="(verifyRecord && (idCardList && idCardList.length)) || !verifyRecord">
        <span>身份证照</span><span style="color:#666666;font-size:12px">{{
          verifyRecord ? '（点击可放大查看）' : '（请上传身份证正反面，限2张）'
        }}</span>
      </div>
      <div class="id-img" v-if="!verifyRecord">
        <div style="margin-right:10px;position: relative;">
          <ElImgUpload key="idCardUp" :exist-image="idCardUp" @handleChange="handleChange($event, 'idCardUp')" />
          <span class="id-tip">正面</span>
        </div>
        <div style="position: relative;">
          <ElImgUpload key="idCardDown" :exist-image="idCardDown" @handleChange="handleChange($event, 'idCardDown')" />
          <span class="id-tip">反面</span>
        </div>
      </div>
      <div class="demo-image__preview mb-10" v-if="verifyRecord && (idCardList && idCardList.length)">
        <el-image v-for="(item, index) in idCardList" :key="index"
          style="width: 120px; height: 120px; margin-right: 10px;border-radius: 10px;" :src="item"
          :preview-src-list="idCardList">
        </el-image>
      </div>
      <div class="verify-title" v-if="(verifyRecord && (payApplyLetterUrl && payApplyLetterUrl.length)) || !verifyRecord">
        <span>支付申请函</span><span style="color:#666666;font-size:12px">{{
          verifyRecord ? '（点击可放大查看）' : '（请上传支付申请函，限6张）'
        }}</span>
      </div>
      <div class="apply-letter" style="margin-buttom:10px;" v-if="!verifyRecord">
        <ElImgUploadGroup :length="6" :exist-img-list="payApplyLetterUrl"
          @handleChange="handleUploadGroupChange($event)" />
      </div>
      <div class="demo-image__preview mb-10" v-if="verifyRecord && (payApplyLetterUrl && payApplyLetterUrl.length)">
        <el-image v-for="(item, index) in payApplyLetterUrl" :key="index"
          style="width: 120px; height: 120px; margin-right: 10px;border-radius: 10px;" :src="item"
          :preview-src-list="payApplyLetterUrl">
        </el-image>
      </div>
      <div class="verify-title"><span>审核结果</span></div>
      <div class="mb-10" v-if="!verifyRecord">
        <el-radio v-model="auditStatusRadio" label="02">审核通过</el-radio>
        <el-radio v-model="auditStatusRadio" label="03">审核驳回</el-radio>
      </div>
      <div v-if="verifyRecord" class="mb-10" style="color:#333333">{{
        auditStatusRadio == '02' ? '已通过审核' : '已驳回'
      }}
      </div>
      <div v-if="!verifyRecord">
        <el-input maxlength="200" type="textarea"
          :placeholder="auditStatusRadio == '03' ? '请输入驳回原因（必填，驳回原因将反馈显示给门店商户）' : '审核备注（非必填）'" show-word-limit
          v-model="auditNote"></el-input>
      </div>
      <div class="verify-title" v-if="verifyRecord"><span>审核备注</span></div>
      <div class="mb-10" v-if="verifyRecord">{{ auditNote || '无' }}</div>
      <div class="verify-title" v-if="verifyRecord"><span>日志记录</span></div>
      <div class="mb-20" v-if="verifyRecord"><span>审核人：</span><span style="color:#666666;">{{
        auditLog.auditMasterName
      }}</span><span style="margin-left:20px">审核时间：</span><span style="color:#666666;">{{
  auditLog.auditTime
}}</span>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!verifyRecord">
        <el-button @click.native="handleClose">关闭</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加合作商家（线上支付）" :visible.sync="Addpartnershow" :close-on-click-modal="false" width="800px"
      @closed="Addpartnerclose">
      <div class="addparto" style="display: flex;flex-direction: row;align-items: center;">
        <img style="width:14px;height:14px;margin-right:5px;" src="../../../assets/images/ico_ts_red (2).png"
          alt="">说明:输入回收商名称进行模糊查询，选中回收商后点击确认完成添加。
      </div>
      <el-form :inline="true" class="demo-form-inline" size="small" style="margin-top: 13px">
        <el-form-item label="回收商名称">
          <el-input v-model="username" clearable placeholder="请输入回收商名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <GlobalTable ref="GlobalTable" :columns="tableColumns2" :data="this.tableData" :currentPage="page2.pageNum"
        :total="page2.total" :pageSize='5' :isIndex="false" @handleCurrentChange="handleCurrentChange2">

        <el-table-column label="单选" width="50" slot="rio" align="center">
          <template scope="scope">
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="scope.row.merchantId"
                @change="checkBoxChange(scope.row.merchantId, scope.row.merchantName)"></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </GlobalTable>


      <span slot="footer" class="dialog-footer">
        <el-button @click.native="
          username = '';
        Addpartnershow = false;
        checkList = [];
        ">取消</el-button>
        <el-button type="primary" @click.native="Addpartitle">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="手续费承担方确认" :visible.sync="Google" :close-on-click-modal="false" width="431px"
      @closed="Googleid = ''">
      <div class="addparto" style="display: flex;flex-direction: row;align-items: center;">
        <span>
          提示：请与连锁门店业务负责人核实确认手续费承担方
        </span>
      </div>
      <el-radio-group v-model="radiostore" style="margin-top: 18px; margin-bottom: 22px">
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div class="flex mb-20" v-if="!transfData" style=" display: flex;align-items: center;">
        <div style="width: 235px;text-align: right;">是否启用分转到繁星仓库(立即生效)：</div>
        <el-radio-group v-model="transfer">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
      <div v-else class="flex mb-20" style=" display: flex;align-items: center;">
        <div style="text-align: right;">已有分转关系(分转{{
          transfData.effectiveStatus == '00' ? "未生效" : transfData.effectiveStatus == '01' ? "生效中" : "已禁用"
        }})
        </div>
      </div>

      <el-form ref="ruleForm" label-width="85px" class="demo-ruleForm">
        <el-form-item label="谷歌验证码" prop="Googleid">
          <el-input v-model="Googleid" placeholder="输入谷歌动态验证码"></el-input>
        </el-form-item>
      </el-form>
      <div class="addparto" v-if="isShow" style="display: flex;flex-direction: row;align-items: center;">
        <i class="circle"></i>
        <span>
          该回收商已在门店使用的矩阵中报价，可能会重复推送订单
        </span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="
          Google = false;
        Googleid = '';
        isShow=false;
        ">取消</el-button>
        <el-button type="primary" @click.native="AddparcheckFn">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='付款模式设置' :visible.sync="ackPaymentShow" :close-on-click-modal="false" width="550px"
      @closed="ackPaymentClosed">
      <div class="enable-tip">
        <div>
          说明：选择靓机商家付款，则订单确认交易后统一划拨靓机商家账户余额；若选择成交商家付款，则订单确认交易后则划拨对应成交商家的账户余额。
        </div>
      </div>
      <div class="flex mb-20">
        <div style="width: 88px;">付款模式：</div>
        <el-radio-group v-model="niceAckPayment">
          <el-radio label="01">成交商家付款</el-radio>
          <el-radio label="02">靓机商家付款</el-radio>
        </el-radio-group>
      </div>
      <div class="command mb-20">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="ackPaymentCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="ackPaymentShow = false">取消</el-button>
        <el-button type="primary" :loading="ackPaymentBtnLoading" @click.native="ackPaymentSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='撤销收货设置' :visible.sync="cancelReceiptShow" :close-on-click-modal="false" width="550px"
      @closed="cancelReceiptClosed">
      <div class="enable-tip">
        <div>
          说明：设置允许撤销收货，该商家的线上支付订单，成交回收商完成收货后可由创建人可发起撤销收货申请，门店创建人/子账号审批同意后，订单状态由‘已收货’改为‘待收货’。
        </div>
      </div>
      <div class="flex mb-20">
        <div style="width: 100px;text-align: right;">撤销收货设置：</div>
        <el-radio-group v-model="canCancelReceipt">
          <el-radio label="02">不可撤销收货</el-radio>
          <el-radio label="01">可撤销收货</el-radio>
        </el-radio-group>
      </div>
      <div class="command mb-20">
        <div style="width: 100px;text-align: right;">动态口令：</div>
        <el-input v-model="cancelReceiptCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancelReceiptShow = false">取消</el-button>
        <el-button type="primary" :loading="cancelReceiptBtnLoading" @click.native="cancelReceiptSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 交易服务费设置 -->
    <el-dialog title='交易服务费设置' :visible.sync="TransactionShow" :close-on-click-modal="false" width="550px"
      @closed="cancelReceiptClosed">
      <div class="enable-tip">
        <div>说明：提交修改后，成交订单按照最新的设置计算交易服务费，请谨慎修改。</div>
      </div>
      <div class="flex mb-20">
        <div style="width: 120px;text-align: right;">设置交易服务费：</div>
        <el-radio-group v-model="serviceFee">
          <el-radio label="02">6‰</el-radio>
          <el-radio label="03">4‰</el-radio>
          <el-radio label="01">3‰</el-radio>
        </el-radio-group>
      </div>
      <div class="command mb-20">
        <div style="width: 120px;text-align: right;">动态口令：</div>
        <el-input v-model="cancelReceiptCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;
      justify-content: space-between;text-align: left;">
        <div style="color: #666666;font-size: 14px;">
          <p >最近修改：{{servicefeeData.serviceFeeSetManager||'-'}}-{{servicefeeData.serviceFeeSetManagerAcc||""}}</p>
          <p>时间：{{servicefeeData.serviceFeeSetTime||"--"}}</p>
        </div>
       <div>
        <el-button @click.native="TransactionShow = false">取消</el-button>
        <el-button type="primary" :loading="cancelReceiptBtnLoading" @click.native="serveseSubmit">确定</el-button>
       </div>
      </span>
    </el-dialog>
    <!-- 添加分转授权 -->
    <el-dialog title="添加分转授权" :visible.sync="sublicenseDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="fenClosed">
      <el-form :model="formData" label-position="left" :rules="formRulesDialog" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="选择分转商家" prop="companyName" label-width="110px">
          <el-input disabled v-model="formData.companyName" placeholder="请输入内容"></el-input>
        </el-form-item>
        <template>
          <div class="center_show">
            <div>联系人：{{ relation.contactName }}</div>
            <div>联系电话：{{ relation.contactPhone }}</div>
          </div>
        </template>
        <div class="merchantId_class">
          <el-form-item label="授权回收商" required></el-form-item>
        </div>
        <template>
          <el-table max-height="400px"  :data="optionsSm" border
            v-loading="loading" style="width: 100%">
            <el-table-column prop="merchantName" align="center" label="回收商">
              <template slot-scope="{ row }">
                <div>{{ row.merchantName }}</div>
                <el-tag type="danger" v-if="row.isMatrix">矩阵映射回收商</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="province" align="center" label="省市区">
              <template slot-scope="{row}">
                <span v-if="row.province">{{ row.province || "" }}{{ row.city || "" }}{{ row.region || "" }}</span>
                <span v-else>--</span>
                <span></span>
              </template>
            </el-table-column>
            <el-table-column prop="warehouseId" align="center" label="分转仓库" width="170px">
              <template slot-scope="{row}">
                <el-select
                  v-if="!row.isMatrix"
                  v-model="row.warehouseId"
                  placeholder="请选择分转仓"
                >
                  <el-option
                    v-for="item in entrepotList"
                    :key="item.id"
                    :label="item.warehouseName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <div
                  style="
                    text-decoration: underline;
                    color: #0981ff;
                    cursor: pointer;
                  "
                  v-else
                >
                  <span @click="seamatrixMiddleNum(row)">{{
                    row.matrixMiddleNum
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="waitSendOrderNum" align="center" label="当前待发货订单">
            </el-table-column>
            <el-table-column prop="sendNoDeliveryNum" align="center" label="已发货待收货订单">
            </el-table-column>
          </el-table>
        </template>
        <el-form-item label="设置收货地址" prop="price" label-width="105px">
          <span style="margin-left: -5px;">(若修改将同步更新该门店商家的全部的分转地址)</span>
        </el-form-item>
        <div class="ad_cstyle" style="margin-bottom: 30px;" v-for="item in platAddList" :key="item.id">
          <div class="adc_top">
            <div class="adc_line"></div>
            <span>{{ item.warehouseName }}</span>
            <div class="xiugai">
              <el-button @click="chansdreess(item)" type="text">修改</el-button>
            </div>
          </div>
          <p style="margin-bottom: 10px;">{{ item.contactName }}-{{ item.contactPhone }}</p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <el-form-item label="生效时间" prop="effectiveTime">
          <div class="addFenTime">
            <el-date-picker :picker-options="pickerOptions" v-model="formData.effectiveTime" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <div class="raido_inner" @click="addFenTimeChange">
              <div :class="isImmediate ? 'active_select' : ''"></div>
              <p>立即生效</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sublicenseDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fenConfirmDialog('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择修改地址 -->
    <el-dialog title="选择修改地址" :visible.sync="actionDialogVisible" :close-on-click-modal="false" width="700px"
      @closed="cangeClosed">
      <div class="ad_cstyle actionstyle displaghf" v-for="(item, index) in adressList" :key="index">
        <div>
          <div class="adc_top">
            <div class="adc_line"></div>
            <span v-if="item.id == platAdres.id">当前地址</span>
            <span v-else>地址{{ index + 1 }}</span>
          </div>
          <p style="margin-bottom: 10px;">{{ item.contactName }}-{{ item.contactPhone }}</p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <div class="im_actin" v-if="item.id !== platAdres.id" @click="adSlect(index, item)">
          <img v-if="adreeId === index" src="./image/sel_duox_pre.png" alt="">
          <img v-else src="./image/sel_duox.png" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="actionDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!--启用分转提示 -->
    <el-dialog title='启用分转提示' :visible.sync="enableShow" :close-on-click-modal="false" width="400px"
      @closed="insideClosed">
      <div class="enable-tip">
        <div>说明：添加授权分转后，默认启用该分转授权，按照设置生效时间进行设置。</div>
        <div class="tip-sure">是否确认启用发货分转到平台？</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="fenzhuanmmand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="insideSubmit">确定</el-button>
      </span>
    </el-dialog>
     <!-- 查看矩阵报价回收商 -->
     <el-dialog
      title="查看矩阵报价回收商"
      :visible.sync="seaDialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="marxtableColumns"
        :data="marxDataList"
        :currentPage="marxpage.pageNum"
        :total="marxpage.total"
        @handleCurrentChange="marxCurrentChange"
      >
        <el-table-column label="省市区" slot="copyType" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.province"
              >{{ row.province || "" }}{{ row.city || ""
              }}{{ row.region || "" }}</span
            >
            <span v-else>--</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from 'moment'
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import ElImgUploadGroup from '../../../components/global/components/elements/upload-group-element'

export default {
  name: "Table",
  components: { ElImgUpload, ElImgUploadGroup },
  data() {
    return {
      matrixCompanyId: "",
      marxpage: {
        pageNum: 1,
        pageSize: 10,
      },
      marxDataList: [],
      marxtableColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "copyType" },
        { label: "设置分转仓", prop: "warehouseName" },
      ],
      seaDialogVisible: false,
      platAddList:[],
      entrepotList: [],
      optionsSm:[],
      diffFundTypeDisabled: false,//返利方式是否可编辑
      currentFundShow: false,//差异基金设置弹框
      setCurrentFundShow: false,//差异基金添加规则
      // 差异基金规则列表
      currentFundColumns: [
        { slotName: "region" },
        // { label: "差异基金", prop: "diffFund" },
        { slotName: "diffFund" },
        { label: "差异基金上限", prop: "diffFundLimit" },
        { slotName: "operation" }
      ],
      currentFundDataList: [],//差异基金规则数据
      currentFundloading: false,
      addRuleShow: false,//添加/编辑规则弹框
      ruleTitle: '',//规则标题
      saveDiffFundParam: {
        diffFundType: ''
      },//添加规则数据传参
      saveDiffCommand: '',//动态口令
      commandShow: false,//动态口令弹框
      saveFundCompanyId: '',//商户Id
      // ********************
      optionsMerch: [],
      transfData: null,
      isShow:false, //提示语回收商知否使用中的矩阵
      transfer: true,
      adreeId: "",
      actionDialogVisible: false,
      relation: {},
      enableButLoading: false,
      fenzhuanmmand: "",
      enableShow: false,
      adressList: null,//平台地址
      platAdres: null,//分转地址
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      sublicenseDialogVisible: false,//分转发货
      formData: {
        "addressList": [],
        "command": "",
        "companyId": "",
        "contactName": "",
        "contactPhone": "",
        "effectiveTime": "",
        "id": "",
        "merchants": []
      },
      formRulesDialog: {
        companyId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择门店商家",
          },
        ],
        merchantId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择回收商家",
          },
        ],
        effectiveTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择生效时间",
          },
        ],
      },
      tableColumns2: [
        // { label: "申请商家", prop: "companyName" },
        { slotName: "rio" },
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "电话", prop: "contactPhone" },
        { label: "地址", prop: "fullAddress" },
        { label: "入驻时间", prop: "entryTime" },
        // { slotName: "Img" },
        { slotName: "auditStatus" },
      ],
      page2: {
        pageSize: 5,
        total: 0,
        pageNum: 1
      },
      Googleid: "",
      radiostore: "",
      checkList: [],
      checked: "",
      tableData: [],
      username: "",
      Addpartnershow: false,
      ackPaymentShow: false,
      cancelReceiptShow: false,
      servicefeeData:{},//交易服务费
      TransactionShow: false,//交易服务费弹窗
      ackPaymentBtnLoading: false,
      cancelReceiptBtnLoading: false,
      rowdata: {},
      Google: false,
      loading: false,
      seachDataList: [],
      auditStatus: '00',
      niceAckPayment: "01",
      canCancelReceipt: "02",
      serviceFee: "02",
      companyId: "",
      applyId: "",
      ackPaymentCommand: "",
      cancelReceiptCommand: "",
      RemarksTitle: "",
      RemarksShow: false,
      masterNote: "",
      butLoading: false,
      rowId: "",
      applyNumber: {},
      isEnable: false,
      DisableShow: false,
      recyclerType: '01',
      command: "",
      commandType: "",
      idCardUp: "",
      idCardDown: "",
      auditStatusRadio: "",
      auditNote: "",
      recyclerTitle: "",
      recyclerloading: false,
      recyclerShow: false,
      verifyRecord: false,
      auditLog: {},
      idCardList: [],
      payApplyLetterUrl: [],
      merchantInfo: {},
      page: {
        pageNum: 1,
        pageSize: 10
      },
      pageNum: 1,
      // 立即生效
      isImmediate: null,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "审核", // 标题
      dialogWidth: 380,
      dialongDecide: "",
      tableColumns: [
        // { label: "申请商家", prop: "companyName" },
        { slotName: "companyName" },

        { slotName: "companyAddress" },
        // { label: "申请地址", prop: "companyAddress" },
        { label: "申请人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { label: "申请时间", prop: "applyTime" },
        // { slotName: "Img" },
        { slotName: "auditStatus" },
        { slotName: "merchantNum" },
        { slotName: "inviteMerchantNum" },
        {slotName:'trueMerchantNum'},
        { label: "当前账户余额", prop: "balance" },
        { slotName: "diffFundBalance" },//当前差异基金
        { slotName: "operation" }
      ],
      recyclerDataList: [],
      recyclerColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { label: "地址", prop: "merchantAddress" },
        { slotName: "companyIsEnable" },
        { slotName: "onlinePayType" },
        { slotName: "processFeeBear" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
    // this.getplantAddress();
  },
  watch: {
    // 按固定金额时，差异基金上限金额不能输入，值为差异基金的值
    'saveDiffFundParam.diffFund'(val) {
      if (this.saveDiffFundParam.diffFundType === '02') {
        this.saveDiffFundParam.diffFundLimit = val;
        // this.saveDiffFundParam.diffFund = val;
      }
    },
    'saveDiffFundParam.diffFundType': function (newVal) {
      if (newVal === '02') {
        this.saveDiffFundParam.diffFund = Math.round(this.saveDiffFundParam.diffFund)
        this.saveDiffFundParam.diffFundLimit = this.saveDiffFundParam.diffFund;
      }
    },
    // ************
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
    isImmediate(v) {
      if (v) {
        this.formData.effectiveTime = moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
     //   切换页
     marxCurrentChange(val) {
      if (val) {
        this.marxpage.pageNum = val;
      } else {
        this.marxpage.pageNum = 1;
      }
      const baseRequest = {
        companyId: this.matrixCompanyId || "",
        pageNum: this.marxpage.pageNum,
        pageSize: this.marxpage.pageSize,
      };
      _api
        .unionMerchantList(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.marxDataList = res.data.records;
            this.marxpage.total = res.data.total;
            this.marxpage.pageNum = res.data.current;
          }
        })
    },
    //查看矩阵合作商家列表
    seamatrixMiddleNum(row) {
      this.matrixCompanyId = row.matrixCompanyId;
      this.seaDialogVisible = true;
      this.marxCurrentChange(1);
    },
      //获取仓库
      getwarehouseSelectList() {
      _api.getwarehouseSelectList().then(res => {
        this.entrepotList = res.data
      })
    },
    // 当前差异基金查看设置
    seeSeting(row) {
      this.currentFundShow = true
      this.saveDiffCommand = '' //动态口令
      this.saveFundCompanyId = row.companyId
      // 差异基金详情
      this.diffFundConfig(row.companyId)
    },
    // 差异基金详情
    diffFundConfig(companyId) {
      const params = {
        companyId: companyId,
      };
      _api.diffFundConfig(params).then((res) => {
        this.currentFundDataList = res.data ? res.data : [];
        this.currentFundDataList.regionList = this.currentFundDataList.regionList.map((v, index) => {
          return {
            ...v,
            index: index + 1
          }
        })
      });
    },
    // 关闭当前差异基金弹框
    currentFundShowClose() {
      this.currentFundShow = false
    },
    // 删除
    deleteCurrentFund(row, event, column) {
      console.log(row, '4444444444');
      const rowIndex = this.currentFundDataList.regionList.indexOf(row)
      console.log(rowIndex, '当前行index');
      this.currentFundDataList.regionList.splice(rowIndex, 1)
    },
    // 添加规则
    addRule(type, row) {
      this.addRuleShow = true;
      const { regionList } = this.currentFundDataList;
      if (type === 'add') {//添加规则
        this.ruleTitle = '添加规则'
        //重置数据
        this.saveDiffFundParam = {}
      } else {//编辑规则
        this.ruleTitle = '编辑规则'
        //重置数据
        this.saveDiffFundParam = row
      }
      // 已经添加一条后，默认选择按第一条选项的返利方式
      if (Array.isArray(regionList)) {
        if (regionList.length <= 0) {
          return this.diffFundTypeDisabled = false;
        } else {
          this.saveDiffFundParam.diffFundType = regionList[0].diffFundType
          return this.diffFundTypeDisabled = true;
        }
      }
      return this.diffFundTypeDisabled = false;
    },
    // 添加规则关闭弹框
    addRuleShowClose() {
      this.addRuleShow = false
    },
    // 添加规则确定
    addRuleSure() {
      const { diffFund, diffFundLimit, lower, upper, diffFundType } = this.saveDiffFundParam;
      if (!lower) {
        return this.$message.error("请输入差异基金报价区间起始值");
      }
      if (!upper) {
        return this.$message.error("请输入差异基金报价区间结束值");
      }
      if (Number(upper) < Number(lower)) {
        return this.$message.error("起始值不能大于结束值")
      }
      if (!diffFundType) {
        return this.$message.error("请选择差异基金返利方式");
      }
      if (!diffFund) {
        return this.$message.error("请输入差异基金比例");
      }
      if (diffFundType === '02') {
        this.saveDiffFundParam.diffFundLimit = diffFund;
      }
      if (!diffFundLimit) {
        return this.$message.error("请输入差异基金上限");
      }
      // 123≤区间<1231
      this.addRuleShow = false;
      if (this.ruleTitle === '添加规则') {
        this.currentFundDataList.regionList.push({
          ...this.saveDiffFundParam,
          region: `${lower}≤区间<${upper}`,
          index: this.currentFundDataList.regionList.length + 1,
        });
      }
    },
    // 打开动态口令弹框
    showCommand() {
      this.commandShow = true
    },
    // 保存差异基金设置确定
    setFundSure() {
      let regionList = this.currentFundDataList.regionList;
      // if(Object.keys(this.saveDiffFundParam).length !== 0){
      //  regionList=[this.saveDiffFundParam, ...this.currentFundDataList.regionList]
      // }else{
      //  regionList=[...this.currentFundDataList.regionList]
      // }
      const params = {
        companyId: this.saveFundCompanyId,
        command: this.saveDiffCommand,
        isOpenDiffFund: this.currentFundDataList.isOpenDiffFund,
        regionList: regionList
      }
      _api
        .saveDiffFundConfig(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 1) {
            // 刷新列表
            this.handleCurrentChange();
            this.commandShow = false
            this.currentFundShow = false
          }
        });
    },
    // **********************
    //查询门店商家下拉列表
    getCompanySelectList() {
      _api.getCompanySelectList().then(res => {
        this.optionsMerch = res.data
        this.optionsMerch.forEach(res => {
          if (res.id === this.rowdata.companyId) {
            this.relation = res
          }
        })
      })
    },
    //关闭选择修改地址
    cangeClosed() {
      this.adreeId = ""
      this.platAdres = {}
      console.log(this.adreeId);
    },
    //选择修改地址
    adSlect(index, data) {
      console.log(index, data);
      this.adreeId = index
    },
    handleConfirmDialog() {
      console.log(this.adreeId);
      if (this.adreeId !== "") {
        //修改仓库地址
        this.platAddList.forEach((item, index) => {
          if (this.platAdres.warehouseId == item.warehouseId) {
            this.platAddList[index] = JSON.parse(JSON.stringify(this.adressList[this.adreeId]))
          }
        })
      }
      this.actionDialogVisible = false
    },
    // 启用弹框关闭
    insideClosed() {
      this.enableShow = false
      this.fenzhuanmmand = ""
      // this.handleCurrentChange();
    },
    //关闭分转
    fenClosed() {
      this.formData = {
        "addressList": [],
        "command": "",
        "companyId": "",
        "contactName": "",
        "contactPhone": "",
        "effectiveTime": "",
        "id": "",
        "merchants": []
      };
      this.isImmediate=null
      this.adressList = []
      this.platAddList = []
      this.optionsSm = [];
    },
    //确认分转
    insideSubmit() {
      console.log(this.formData);
      if (!this.fenzhuanmmand) {
        this.$message.error("输入谷歌动态验证码!")
        return;
      }
      console.log(this.formData);
      this.formData.command = this.fenzhuanmmand
      this.fenParams = JSON.parse(JSON.stringify(this.formData))
      this.fenParams.effectiveTime = moment(this.fenParams.effectiveTime).format('x')
      this.fenParams.isImmediate = this.isImmediate;
      if (this.isImmediate) {
        this.fenParams.effectiveTime = null;
      }
      this.enableButLoading = true
      _api.saveMiddle(this.fenParams).then(res => {
        if (res.code == 1) {
          this.$message({
            message: '创建成功！',
            type: 'success'
          });
          this.fenzhuanmmand = ""
          this.handleCurrentChange();
          this.enableShow = false
          this.sublicenseDialogVisible = false
        }
        this.enableButLoading = false
      }).catch(err => {
        this.enableButLoading = false
      })
    },
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },
    //查询平台商家地址
    getplantAddress() {
      _api.queryFormAddress().then(res => {
        this.adressList = res.data
      })
    },
     //获取门店商平台收货地址
     getCompanyPlatformAddress(id) {
      _api.getCompanyPlatformAddress({ companyId: id }).then(res => {
        this.platAddList = res.data || []
      })
    },
    //修改地址
    chansdreess(data) {
      this.platAdres = data
      _api.queryFormAddress({ warehouseId: data.warehouseId }).then(res => {
        this.adressList = res.data
        this.actionDialogVisible = true
      })
    },
    //分转授权
    fenConfirmDialog(formName) {
      console.log(this.formData,this.platAddList);
        if (this.optionsSm.some(item => !item.warehouseId&&!item.isMatrix)) {
          return this.$message.error('请选择授权回收商的分转仓！');
        }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.addressList = this.platAddList.map(item => {
            return {
              "addressId": item.id,
              "warehouseId": item.warehouseId
            }
          })
          this.formData.merchants = this.optionsSm.map(item => {
            return {
              "merchantId": item.merchantId,
              "warehouseId": item.warehouseId
            }
          })
          console.log(this.rowdata)
          this.formData.companyId =this.rowdata.companyId
          this.formData.contactName = this.rowdata.contact
          this.formData.contactPhone = this.rowdata.phone
          this.enableShow = true
        } else {
          return false;
        }
      });
    },
    // 谷歌验证
    Addpartitle() {
      if (this.checkList.length) {
        this.Google = true;
        //重置
        this.radiostore = ''
        this.transfer = true;
        // companyId: this.rowdata.companyId,门店商id
        // merchantId: this.checkList[0],回收商id
        _api.getMiddleByCompanyAndMerchant(this.rowdata.companyId, this.checkList[0]).then(res => {
          if (res.code == 1) {
            this.transfData = res.data || false
            console.log(res);
          }
        })

        // 查询回收商知否使用中的矩阵里(true 在 false 不在)
        _api.getRecyclerMatrixInfo({companyId:this.rowdata.companyId, merchantId:this.checkList[0]}).then(res => {
           if (res.code == 1) {
            this.isShow = res.data
          }
        })

      } else {
        this.$message({
          message: "请选择合作商！",
          type: "warning",
        });
      }
    },

    // 单选
    checkBoxChange(val, name) {
      // 控制 单选
      if (this.checkList.indexOf(val) > -1) {
        this.checkList = [];
        this.checkList.push(val);
      }
      //获取授权回收商名称
      this.formData.merchantName = name
      console.log(this.checkList);
    },

    // 添加合作商
    AddparcheckFn() {
      if (this.rowdata.payMode == 'online' && !this.radiostore) {
        this.$message.error("请选择手续费承担方！")
        return;
      }
      if (!this.Googleid) {
        this.$message.error("输入谷歌动态验证码!")
        return;
      }
      let params = {
        command: this.Googleid,
        companyId: this.rowdata.companyId,
        merchantId: this.checkList[0],
        processFeeBear: this.radiostore,
      };

      _api.saveUnionMerchant(params).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.Googleid = "";
          this.Google = false;
          this.Addpartnershow = false;
          this.handleCurrentChange();
          //是否启用分转到繁星仓库
          if (this.transfer) {
            this.formData.companyName = this.rowdata.companyName;
            console.log(this.rowdata);
            _api
              .getptMerchantList({
                companyId: this.rowdata.companyId,
                merchantId: this.checkList[0],
              })
              .then((res) => {
                this.optionsSm = res.data;
              });
            //获取收货地址
            this.getCompanyPlatformAddress(this.rowdata.companyId);
            //获取仓库
            this.getwarehouseSelectList();
            this.sublicenseDialogVisible = true
          }
          this.checkList = [];
          this.radiostore = ""
        } else {
          this.Googleid = "";
          this.$message.error(res.msg || "添加失败！");
        }
      });
    },

    //addpar username find
    onSubmit() {
      let params = {
        pageNum: 1,
        pageSize: 5,
        merchantName: this.username,
        merchantType: "02",
        isNiceMerchant: '02',
        queryOrderInfo: false
      };
      this.Addpartner(this.rowdata, params);
    },
    //addpar close
    Addpartnerclose() {
      this.username = '';
      this.Addpartnershow = false;
      this.checkList = [];
    },
    // ----------------------->添加合作商
    Addpartner(v, paramss) {
      console.log(v);
      this.rowdata = v;
      this.Addpartnershow = true;
      let params = {};
      if (paramss) {
        params = paramss;
      } else {
        params = { pageNum: 1, pageSize: 5, merchantType: "02", isNiceMerchant: '02', queryOrderInfo: false };
      }
      params.isFilterMatrix = '01'
      _api.ShopTable(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = res.data.records;
          arr.forEach((item) => {
            item.checked = false;
          });
          this.tableData = arr;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
          this.getCompanySelectList()
        }
      });

    },

    handleCurrentChange2(val) {
      if (val) {
        this.page2.pageNum = val;
      } else {
        this.page2.pageNum = 1;

      }

      let params = {
        pageNum: this.page2.pageNum,
        pageSize: 5,
        merchantType: "02",
        merchantName: this.username,
        isNiceMerchant: '02',
        queryOrderInfo: false
      };
      params.isFilterMatrix = '01'
      _api.ShopTable(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = res.data.records;
          arr.forEach((item) => {
            item.checked = false;
          });
          this.tableData = arr;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },


    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        auditStatus: this.auditStatus,
        phone: this.SeachParams.phone,
        companyName: this.SeachParams.companyName,
        isEnable: this.SeachParams.isEnable,
        channel: this.SeachParams.channel,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.applyList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.applyNumber.allCount = res.data.allCount
          this.applyNumber.passAuditCount = res.data.passAuditCount
          this.applyNumber.refuseAuditCount = res.data.refuseAuditCount
          this.applyNumber.toAuditCount = res.data.toAuditCount
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 弹出备注弹框
    handleBtn(row, type) {
      console.log(row);
      this.rowId = row.id
      if (type == 'remark') {
        if (row.masterNote) {
          this.RemarksTitle = '修改备注'
        } else {
          this.RemarksTitle = '添加备注'
        }
        this.masterNote = row.masterNote
        this.RemarksShow = true
      } else {
        this.merchantInfo = {
          companyName: row.companyName,
          phone: row.phone,
          contact: row.contact
        }
        if (row.auditStatus == '01') {
          this.idCardUp = "https://img.fanxinghuishou.cn/20230728/736128088.png";
          this.idCardDown = "https://img.fanxinghuishou.cn/20230728/042358822.png";
          this.payApplyLetterUrl = ['https://img.fanxinghuishou.cn/20230728/880042087.png'];
          this.dialogTitle = '审核'
          this.verifyRecord = false
        } else {
          this.verifyRecord = true
          this.dialogTitle = '审核记录'
          if (row.idCardUp) {
            this.idCardList.push(row.idCardUp)
          }
          if (row.idCardDown) {
            this.idCardList.push(row.idCardDown)
          }
          this.payApplyLetterUrl = row.payApplyLetterUrl
          this.auditStatusRadio = row.auditStatus
          this.auditNote = row.auditNote || ''
          this.auditLog = {
            auditMasterName: row.auditMasterName,
            auditTime: row.auditTime
          }
        }
        this.dialogVisible = true
      }
    },
    // 关闭备注弹框
    remarksClosed() {
      this.RemarksShow = false
      this.masterNote = ""
    },
    // 备注确定提交
    remarksSure() {
      this.butLoading = true
      _api.applyMasterNote({ id: this.rowId, masterNote: this.masterNote }).then(res => {
        console.log(res);
        if (res.code === 1) {
          this.$message.success(res.msg)
          this.RemarksShow = false
          this.handleCurrentChange()
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    goPaySet(row) {
      this.$router.push({
        path: "/payment/PaySet",
        query: { companyId: row.companyId, type: row.isPayConfig, source: 'online' }
      })
    },
    // 禁用开关
    handleSwitch(row) {
      console.log(row);
      this.rowId = row.id
      this.isEnable = row.isEnable
      this.DisableShow = true
    },
    commandDialogClosed() {
      this.DisableShow = false
      this.command = ""
      if (this.commandType != 'verify') {
        this.handleCurrentChange()
      }
    },
    handleDialogClosed() {
      this.dialogVisible = false
      this.idCardUp = ""
      this.idCardDown = ""
      this.payApplyLetterUrl = []
      this.idCardList = []
      this.auditStatusRadio = ""
      this.auditNote = ""
      this.command = ""
      this.commandType = ""
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 禁用弹窗关闭
    DisableClose() {
      this.DisableShow = false
    },
    // 禁用弹窗提示确认
    DisableSure() {
      this.butLoading = true
      if (this.commandType == 'verify') {
        let verifyParams = {
          idCardUp: this.idCardUp,
          idCardDown: this.idCardDown,
          payApplyLetterUrl: this.payApplyLetterUrl,
          auditStatus: this.auditStatusRadio,
          auditNote: this.auditNote,
          id: this.rowId,
          command: this.command,
        }
        _api.auditCompanyPayApply(verifyParams).then(res => {
          if (res.code === 1) {
            this.$message.success(res.msg)
            this.DisableShow = false
            this.dialogVisible = false
            this.handleCurrentChange()
          }
          this.butLoading = false
        }).catch(err => {
          this.butLoading = false
        })
      } else {
        _api.enableCompanyPay({ id: this.rowId, command: this.command, isEnable: this.isEnable }).then(res => {
          if (res.code === 1) {
            this.$message.success(res.msg)
            this.DisableShow = false
          }
          this.butLoading = false
        }).catch(err => {
          this.butLoading = false
        })
      }
    },
    btnClick(type) {
      this.auditStatus = type
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    // 上传身份证照
    handleChange(e, val) {
      if (val == "idCardUp") {
        this.idCardUp = e
        console.log(e);
      } else {
        this.idCardDown = e
        console.log(e);
      }
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadGroupChange(list) {
      console.log(list);
      if (Array.isArray(list)) {
        this.payApplyLetterUrl = list
      }
    },
    // 审核确定
    handleSuccess() {
      this.commandType = 'verify'
      if (!this.auditStatusRadio) {
        this.$message.error('请选择审核结果');
        return false
      }
      if (this.auditStatusRadio == '02' && (!this.idCardUp || !this.idCardDown || !this.payApplyLetterUrl.length)) {
        this.$message.error('审核通过必须补全信息');
        return false
      }
      if (this.auditStatusRadio == '03' && !this.auditNote) {
        this.$message.error('审核驳回必须填写驳回原因');
        return false
      }
      this.DisableShow = true
    },
    // 关闭回收商列表
    recyclerClosed() {
      this.recyclerShow = false
    },
    // 查看回收商列表
    seeRecycler(row, type) {
      this.recyclerType = type
      if (type == '01') {
        this.recyclerTitle = '查看合作回收商-' + row.companyName
      } else {
        this.recyclerTitle = '查看受邀回收商-' + row.companyName
      }
      this.recyclerloading = true
      _api.getUnionMerchantList({ id: row.id, type }).then(res => {
        if (res.code === 1) {
          console.log(res);
          this.recyclerDataList = res.data
        }
        this.recyclerloading = false
      })
      this.recyclerShow = true
    },
    // 发送支付配置推送
    sendPayMsg(row) {
      _api.sendPayConfigMsg({ id: row.id }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg || '发送成功')
          this.handleCurrentChange()
        }
      })
    },
    // 付款方式修改
    editNiceAckPayment(row) {
      this.applyId = row.id
      this.ackPaymentShow = true
      this.niceAckPayment = row.niceAckPayment
    },
    // 付款模式修改弹框关闭
    ackPaymentClosed() {
      this.ackPaymentCommand = ""
      this.applyId = ""
      this.handleCurrentChange()
    },
    // 付款模式修改弹框确定
    ackPaymentSubmit() {
      if (!this.ackPaymentCommand) {
        this.$message.error('请输入动态口令')
        return
      }
      let params = {
        command: this.ackPaymentCommand,
        id: this.applyId,
        niceAckPayment: this.niceAckPayment
      }
      this.ackPaymentBtnLoading = true
      _api.updateNiceAckPayment(params).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '修改成功')
          this.ackPaymentShow = false
        }
        this.ackPaymentBtnLoading = false
      }).catch(() => {
        this.ackPaymentBtnLoading = false
      })
    },
    //交易服务费
    TransactionAction(data) {
      this.companyId = data.companyId
      this.servicefeeData=data
      console.log(data.serviceFee==6);
      if(data.serviceFee==6){
        this.serviceFee='02'
      }else if(data.serviceFee==4){
        this.serviceFee='03'
      }else{
        this.serviceFee='01'
      }
      this.TransactionShow = true
    },
    // 是否支持撤销收货设置
    setCancelReceipt(row) {
      this.cancelCompanyId = row.companyId
      this.cancelReceiptShow = true
      this.canCancelReceipt = row.canCancelReceipt
    },
    // 付款模式修改弹框关闭
    cancelReceiptClosed() {
      this.cancelReceiptCommand = ""
      this.cancelCompanyId = ""
      this.handleCurrentChange()
    },
    //设置交易服务费
    serveseSubmit() {
      if (!this.cancelReceiptCommand) {
        this.$message.error('请输入动态口令')
        return
      }
      let serveseparams = {
        command: this.cancelReceiptCommand,
        companyId: this.companyId,
        serviceFee: this.serviceFee=='02'?6:this.serviceFee=='03'?4:3
      }
      this.cancelReceiptBtnLoading = true
      _api.setServiceFee(serveseparams).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '操作成功')
          this.TransactionShow = false
        }
        this.cancelReceiptBtnLoading = false
      }).catch(() => {
        this.cancelReceiptBtnLoading = false
      })
    },
    // 付款模式修改弹框确定
    cancelReceiptSubmit() {
      if (!this.cancelReceiptCommand) {
        this.$message.error('请输入动态口令')
        return
      }
      let params = {
        command: this.cancelReceiptCommand,
        companyId: this.cancelCompanyId,
        canCancelReceipt: this.canCancelReceipt
      }
      this.cancelReceiptBtnLoading = true
      _api.canCancelReceipt(params).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '操作成功')
          this.cancelReceiptShow = false
        }
        this.cancelReceiptBtnLoading = false
      }).catch(() => {
        this.cancelReceiptBtnLoading = false
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.input_w {
  width: 210px;
}

.fund-title {
  margin-bottom: 10px;
  padding: 0 4px;
  font-size: 14px;
}

.rule-display {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;

}

.rule-display-title {
  margin-right: 12px;
  width: 104px;
  text-align: right;
}

.fund-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}

.delet-btn {
  font-size: 14px;
  font-weight: 500;
  color: #ff0000;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

.GlobalTable {
  .center_show {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 0 20px 20px;
  }

  .button_top {
    margin-bottom: 20px;
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ad_cstyle {
    width: 100%;
    padding: 15px;
    min-height: 110px;
    background: #F5F6FA;
    border-radius: 14px;
    margin-bottom: 15px;
    margin-top: -20px;

    .adc_top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .adc_line {
        width: 3px;
        height: 13px;
        background: #0981FF;
        border-radius: 2px;
        margin-right: 5px;
      }

      .xiugai {
        /deep/ .el-button {
          text-decoration: underline;
          padding: 5px 0 5px 10px;
        }
      }

    }
  }

  .im_actin {
    img {
      width: 15px;
      height: 15px;
    }
  }

  .actionstyle {
    margin: 20px 0;
  }

  .displaghf {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 432px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .command {
    display: flex;
    align-items: center;

    /deep/ .el-input {
      width: 300px;
    }
  }

  .verify-title {
    color: #0981FF;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .id-img {
    display: flex;
    margin-bottom: 10px;
  }

  .id-tip {
    color: #ffffff;
    font-size: 12px;
    padding: 0 7px;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: max-content;
    height: 16px;
    background: #0981FF;
    border-radius: 0px 10px 0px 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  /deep/ .el-img-upload .el-upload {
    border: 1px dashed #C1D1FF;
    border-radius: 10px;
  }

  /deep/ .el-img-upload-group .el-upload {
    border: 1px dashed #C1D1FF;
    border-radius: 10px;
  }

  .apply-letter {
    margin-bottom: 15px;

    /deep/ .el-img-upload {
      margin-right: 10px;
    }
  }

  .tipColor {
    color: #FF687B;
  }
}

.channeltype1 {
  height: 16px;
  background: #8080ff;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype2 {
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;

}

/deep/ .el-checkbox-group {
  // 隐藏 1
  .el-checkbox {
    .el-checkbox__label {
      display: none;
    }
  }
}

.addparto {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #ff8080;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 3px;
  background-color: #ff8080;
}

.enable-tip {
  color: #FF8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.flex {
  display: flex;
  align-items: center;
}

.fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.raido_inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  >div {
    border: 1px solid #0981FF;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

  >p {
    font-size: 14px;
    margin-left: 10px;
  }

  .active_select {
    border-color: #0981FF;
  }

  .active_select::after {
    background: #0981FF;
  }
}
.img-el-heard {
    display: flex;
    justify-content: center;

    img {
      margin: 0px 0 -3px 3px;
    }
  }
.addFenTime {
  width: 100%;
  display: flex;
  align-items: center;

  >div:first-of-type {
    width: 60% !important;
    margin-right: 20px;
  }

  >div:last-of-type {
    flex: auto;
  }
}
</style>
