<template>
  <div class="mobile-store-Manage-table">
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          class="global-form"
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="100"
          round
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item
            label="移动商户"
            slot="companyId"
            style="margin: 0"
            label-width="80px"
          >
            <el-select
              v-model="companyId"
              placeholder="请输入或选择商户"
              filterable
              clearable
            >
              <el-option
                v-for="item in bindCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="80px"
            label="省市查询"
            slot="provinceCode"
            style="margin:0"
          >
            <el-select
              style="margin-right: 10px;"
              size="small"
              v-model="provinceCode"
              @change="selectProvince"
              clearable
              filterable
              placeholder="请选择省"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              size="small"
              :disabled="!provinceCode"
              v-model="cityCode"
              clearable
              filterable
              :placeholder="provinceCode ? '请选择市' : '请先选择省'"
            >
              <el-option
                v-for="item in cityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加时间" slot="creatTime" style="margin-bottom: 0;" label-width="80px">
            <el-date-picker
              v-model="creatTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="绑定时间" slot="bindTime" style="margin-bottom: 0;" label-width="80px">
            <el-date-picker
              v-model="bindTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </GlobalForm>
      </div>
      <div class="flex flex-jb mb-20">
        <GardenSlwct
          :tab-data="tabData"
          :activeIndex="tabActiveIndex"
          width="80px"
          border-radius="40px"
          @tabAction="tabAction"
        />
        <div>
          <!-- <el-button
            size="small"
            type="danger"
            icon="el-icon-refresh"
            @click="updateData"
            >更新数据</el-button
          > -->
          <el-button
            icon="el-icon-download"
            size="small"
            type="success"
            :loading="exportLoading"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="tableloading"
        :columns="tableColumns"
        :data="tableDataList"
        :currentPage="page.pageNum"
        :total="page.total"
        :pageSize="page.pageSize"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column label="门店名称" slot="cmccStoreName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.cmccStoreName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.cmccStoreName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="员工数量" slot="empNum" align="center">
          <template slot-scope="{ row }">
            <span class="txt-underline" @click="openStaffListDia(row)">{{
              row.empNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="详细地址" slot="address" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.address"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.address }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="绑定繁星门店"
          slot="storeName"
          align="center"
          v-if="tabActiveIndex === 1"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.storeName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.storeName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="绑定繁星商户"
          slot="companyName"
          align="center"
          v-if="tabActiveIndex === 1"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.companyName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="绑定时间"
          slot="bindTime"
          align="center"
          v-if="tabActiveIndex === 1"
        >
          <template slot-scope="{ row }">
            <span>{{ row.bindTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="绑定人"
          slot="adminName"
          align="center"
          v-if="tabActiveIndex === 1"
        >
          <template slot-scope="{ row }">
            <span>{{ row.adminName }}-{{ row.adminAcc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="250"
          label="操作"
          slot="operation"
          align="center"
          v-if="tabActiveIndex === 0"
        >
          <template slot-scope="{ row }">
            <el-button
              style="color: #ffbf54;text-decoration: underline"
              type="text"
              @click="handleBtn(row, 'add')"
              >添加为新门店</el-button
            >
            <el-button
              style="text-decoration: underline"
              type="text"
              @click="handleBtn(row, 'bind')"
              >绑定到已有门店</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
    <el-dialog
      :title="operatStoreDiaTitle"
      :visible.sync="operatStoreDiaShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="operatStoreDiaClosed"
    >
      <div>
        <el-form ref="operatform" :model="operatform" label-width="80px">
          <el-form-item label="选择商家">
            <el-select
              size="small"
              v-model="operatform.companyId"
              placeholder="请选择商家"
              @change="companyIdChange"
            >
              <el-option
                v-for="item in bindCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <template v-if="operatStoreType === 'add'">
            <el-form-item label="门店名称">
              <el-input
                size="small"
                maxlength="20"
                show-word-limit
                placeholder="请输入门店名称"
                v-model.trim="operatform.storeName"
              ></el-input>
            </el-form-item>
            <el-form-item label="门店地址">
              <el-input
                size="small"
                maxlength="50"
                show-word-limit
                placeholder="请输入门店地址"
                v-model.trim="operatform.storeAddress"
              ></el-input>
            </el-form-item>
          </template>
          <el-form-item label="选择门店" v-else>
            <el-select
              size="small"
              :disabled="!operatform.companyId"
              v-model="operatform.storeId"
              :placeholder="
                operatform.companyId ? '请先选择商家' : '请选择门店'
              "
              @change="storeIdChange"
            >
              <el-option
                v-for="item in storeSelectList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
            <div class="flex" v-if="!storeIsEnable">
              <img
                style="width:14px;height:14px;margin-right:5px;"
                src="../../../assets/images/ico_ts_red (2).png"
                alt=""
              />
              <span class="tip" style="margin-right: 10px;"
                >门店已禁用，请先启用该门店</span
              >
              <!-- <el-button type="success" size="mini" round @click="enableStore"
                >启用</el-button
              > -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="operatStoreDiaShow = false">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click.native="operatingStoreSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="启用门店"
      :visible.sync="enableStoreDiaShow"
      :close-on-click-modal="false"
      width="400px"
    >
      <p>是否确定启用该门店？</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableStoreDiaShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="enableStoreBtnLoading"
          @click.native="enableStoreSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <StaffListDia
      v-if="staffListDiaShow"
      :dialogVisible="staffListDiaShow"
      :onRowObj="onRowObj"
      @close="staffListDiaShow = false"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import StaffListDia from "./dialog/StaffListDia.vue";
export default {
  components: {
    GardenSlwct,
    StaffListDia,
  },
  props: {
    provinceList: {
      type: Array,
      default: () => [],
    },
    bindCompanyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabActiveIndex: 0,
      state: "00",
      tabData: [
        {
          text: "待绑定",
          value: "00",
          hideNum: true,
        },
        {
          text: "已绑定",
          value: "01",
          hideNum: true,
        },
      ],
      seachParams: {},
      initData: null,
      companyId: "",
      provinceCode: "", //省
      cityCode: "", // 市
      cityList: [],
      creatTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      bindTime: null,
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      formItemList: [
        { slotName: "companyId" },
        {
          lableWidth: "80px",
          key: "cmccStoreCode",
          type: "input",
          labelName: "门店编码",
          placeholder: "请输入门店编码查询",
        },
        {
          lableWidth: "80px",
          key: "storeName",
          type: "input",
          labelName: "门店名称",
          placeholder: "请输入门店名称查询",
        },
        { slotName: "provinceCode" },
        { slotName: "creatTime" },
        { slotName: "bindTime" },
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      exportLoading: false,
      tableloading: false,
      tableDataList: [],
      tableColumns: [
        { label: "门店ID", prop: "cmccStoreId" },
        { label: "门店编码", prop: "cmccStoreCode" },
        { slotName: "cmccStoreName" },
        { slotName: "empNum" },
        { label: "省份", prop: "provinceName" },
        { label: "地市", prop: "cityName" },
        { label: "区县", prop: "districtName" },
        { slotName: "address" },
        { label: "添加时间", prop: "createTime" },
        { label: "最近修改时间", prop: "updateTime" },
        { slotName: "storeName" },
        { slotName: "companyName" },
        { slotName: "bindTime" },
        { slotName: "adminName" },
        { slotName: "operation" },
      ],
      operatStoreType: "", //操作的当前行事件类型
      staffListDiaShow: false, //员工数量弹框
      operatStoreDiaShow: false, // 添加门店/绑定门店弹框
      storeIsEnable: true,
      submitLoading: false,
      operatStoreDiaTitle: "",
      operatform: {
        companyId: "",
        storeName: "",
        storeAddress: "",
        storeId: "",
      },
      storeSelectList: [],
      enableStoreDiaShow: false, //启用门店弹框
      enableStoreBtnLoading: false,
      onRowObj: "",
    };
  },
  created() {
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data, cd) {
      let createTimeStart = "";
      let createTimeEnd = "";
      if (this.creatTime === null) {
        createTimeStart = "";
        createTimeEnd = "";
      } else {
        createTimeStart = moment(this.creatTime[0]).format("x");
        createTimeEnd = moment(this.creatTime[1]).format("x");
      }
      // 绑定时间
      let bindTimeStart = "";
      let bindTimeEnd = "";
      if (this.bindTime === null) {
        bindTimeStart = "";
        bindTimeEnd = "";
      } else {
        bindTimeStart = moment(this.bindTime[0]).format("x");
        bindTimeEnd = moment(this.bindTime[1]).format("x");
      }
      let SeachParams = {
        createTimeStart,
        createTimeEnd,
        bindTimeStart,
        bindTimeEnd,
      };
      if (data) {
        SeachParams.cmccStoreCode = data.cmccStoreCode || ""
        SeachParams.storeName = data.storeName || ""
        SeachParams.disuseLoding = () => {
          if (typeof cd === "function") {
            cd();
          }
        }
      }
      this.seachParams = SeachParams;
      this.handleCurrentChange(1);
    },
    // 获取市区
    getCity(v) {
      _api.cmccDistrict({ code: v, type: "01" }).then((r) => {
        this.cityList = r.data.map((v) => {
          return {
            label: v.key,
            value: v.value,
          };
        });
      });
    },
    selectProvince(e) {
      if (e) {
        this.cityCode = "";
        this.getCity(e);
      } else {
        this.cityCode = "";
        this.cityList = [];
      }
    },
    //   切换页
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        ...this.seachParams,
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        state: this.state,
        companyId: this.companyId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.tableloading = true
      _api
        .cmccStoreList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            this.tableDataList = res.data.records;
            this.page.total = res.data.total;
            this.page.pageNum = res.data.current;
          }
          this.tableloading = false;
        })
        .finally(() => {
          if (typeof this.seachParams.disuseLoding === "function") {
            this.seachParams.disuseLoding();
          }
        });
    },
    tabAction(val) {
      this.tabActiveIndex = val;
      this.state = this.tabData[val].value;
      if(this.state === '00'){
        this.creatTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.bindTime = null;
      }else{
        this.creatTime = null;
        this.bindTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
      }
      
      this.handleConfirm();
    },
    getStoreSelectList() {
      _api.getStoreSelectList2({companyId:this.operatform.companyId}).then((res) => {
        if (res.code === 1) {
          this.storeSelectList = res.data || [];
        }
      });
    },
    companyIdChange() {
      if(this.operatStoreType === 'bind'){
        this.operatform.storeId = ""
        this.getStoreSelectList()
      }
    },
    storeIdChange() {
      if(this.operatform.storeId){
        this.storeSelectList.forEach(item => {
          if(item.storeId === this.operatform.storeId){
            this.storeIsEnable = item.isEnable
          }
        })
      }
    },
    updateData() {},
    exportExcel() {
      this.exportLoading = true;
      let baseRequest = {
        ...this.seachParams,
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        state: this.state,
        companyId: this.companyId,
      };
      _api
        .excelCmccStore(baseRequest)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "服务异常");
              }
            } catch (err) {
              const fileName = "移动门店列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    },
    // 员工数量点击展开弹框
    openStaffListDia(row) {
      this.onRowObj = row
      this.staffListDiaShow = true;
    },
    handleBtn(row, type) {
      this.operatform.cmccStoreId = row.cmccStoreId
      this.operatform.storeName = row.cmccStoreName
      this.operatform.storeAddress = row.address
      this.operatform.type = type==='add'?'00':'01'
      this.operatStoreType = type;
      this.operatStoreDiaTitle =
        type === "add" ? "添加为新门店" : "绑定到已有门店";
      this.operatStoreDiaShow = true;
    },
    operatStoreDiaClosed() {
      this.operatform = {
        companyId: "",
        storeName: "",
        storeAddress: "",
        storeId: "",
      }
    },
    operatingStoreSubmit() {
      if(!this.operatform.companyId){
        return this.$message.error('请选择商家');
      }
      if(this.operatStoreType === 'add'){
        if(!this.operatform.storeName.trim()){
          return this.$message.error('门店名称不能为空');
        }
        if(!this.operatform.storeAddress.trim()){
          return this.$message.error('门店地址不能为空');
        }
      }else{
        if(!this.operatform.storeId){
          return this.$message.error('请选择门店');
        }
        if(!this.storeIsEnable){
          return this.$message.error('门店已禁用，请先启用该门店');
        }
      }
      this.submitLoading = true
      _api.cmccBindStore(this.operatform).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.operatStoreDiaShow = false
          this.handleCurrentChange()
        }
      }).finally(()=>{
        this.submitLoading = false
      })
    },
    // 打开启用门店弹框
    enableStore() {
      this.enableStoreDiaShow = true;
    },
    enableStoreSure() {},
  },
};
</script>

<style lang="scss" scoped>
.mobile-store-Manage-table {
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .txt-underline {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
  }
  .tip {
    color: #ff687b;
  }
  /deep/.el-form--inline {
    /deep/.el-form-item {
      margin-right: 0px !important;
    }
  }
}
</style>
