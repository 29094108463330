<template>
  <el-dialog v-bind="imeiTestProps" :before-close="() => imeiTestProps.show = false" :visible.sync="imeiTestProps.show">
    <div v-loading="imeiTestProps.isLoadding" class="function_box">
      <!-- 旧机基本情况所有情况都有--新旧/外部 -->
      <div class="test-info">
        <Title title="旧机基本情况" />
        <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
        <TestDeviceInfo :datainfo="detailData" />
      </div>
      <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
          <div class="test-result" v-if="detailData.functionView.errorList.length">
            <Title :title="'异常项（共'+ detailData.functionView.errorList.length +'项）'" />
            <!-- 旧机无系统检测 -->
            <div class="mb10" v-if="detailData.unableDetectionCause &&
              detailData.detectionType == '01'
              ">
              <span class="fs16">旧机无法系统检测原因：</span>
              <span class="lv_fc_red fs16">{{
                detailData.unableDetectionCause || "--"
              }}</span>
            </div>
            <div class="mb10" style="display: flex;justify-content: space-between;"
              v-if="detailData.detectionType == '02'">
              <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
              <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
            </div>
            <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList"></TestTable>
          </div>
          <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
            <Title :title="'正常项（共'+ detailData.functionView.normalList.length +'项）'" />
            <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList"></TestTable>
          </div>
        </div>
      </div>
      <!-- 新增店员备注--所有情况下都有 -->
      <div style="margin: 15px 0 25px 0;">
        <Title title="店员备注" />
        <Remark :remark="detailData.remark" :againPhotoRemark="detailData.againPhotoRemark" />
      </div>
    </div>
    <div slot="footer">
      <el-button v-if="imeiTestProps.type === '01'" @click="imeiTestProps.show = false" plain type="primary">关闭
      </el-button>
      <div v-else>
        <el-button @click="imeiTestProps.show = false">取 消</el-button>
        <el-button type="primary" @click="imeiTestProps.show = false">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "@/views/RecallOrder/componets/Title.vue"
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";

import _api from "@/utils/request";
export default {
  name: "FunctionDia",
  components: { TestDeviceInfo, Title, TestTable, Remark },
  data() {
    return {
      visible: false,
      row: {},

      imeiTestProps,
      detailData: {
        functionView:{
          normalList:[],
          errorList:[],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: []
      },
      externalorderType: '',

      loading: false,
      list: []
    };
  },
  methods: {
    /**
     * orderNo, outType
     */
    open(row) {
      this.row = row
      this.getFunction(row.orderNo, row.outType)
      // this.imeiTestProps.show = true
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then(res => {
        this.detailData = res.data;
        if(res.data.againPhotoRemark){
          this.detailData.againPhotoRemark=res.data.againPhotoRemark.split("//")
        }else{
          this.detailData.againPhotoRemark=[]
        }
        this.imeiTestProps.isLoadding = false
      })
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: '',
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "",//下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      }
      // 请求详情
      this.getDetail(v);
    },
    close() {
      this.imeiTestProps.show = false
    },
    confirm() {

    }
  }
};
</script>