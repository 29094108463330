<template>
  <div class="GlobalFormDemo">
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="机型来源" prop="channel">
        <el-select v-model="formData.channel" placeholder="请选择" @change="getBrandList">
          <el-option v-for="item in channelOptions" :key="item.key" :label="item.value" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item label="外部分类" prop="channel">
        <el-input v-model="formData.typeName" placeholder="请输入外部分类名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="外部品牌" prop="outBrandId">
        <el-select v-model="formData.outBrandId" filterable clearable placeholder="请输入品牌名称查询">
          <el-option v-for="item in brandList" :key="item.key" :label="item.value" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item label="查询机型" prop="machineName">
        <el-input v-model="formData.machineName" clearable placeholder="请输入机型名称" />
      </el-form-item>
      <el-button size="small" type="primary" :loading="searchLoading" round @click="handleConfirm">查询</el-button>
    </el-form>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'Form',
  data() {
    return {
      // butLoading: false,
      formData: {
        machineName: '',
        channel: 'fh',
        outBrandId: '',
        typeName: "",
      },
      channelOptions: [],
      brandList: [],
      searchLoading: false
    }
  },
  created(){
    this.getChannelList()
    this.getBrandList()
  },
  methods: {
    getChannelList() {
      _api.getMachineChannelList().then(res => {
        this.channelOptions = res.data
      })
    },
    getBrandList() {
      this.formData.outBrandId = ''
      _api.getOutMachineBrandSelectList({
        channel: this.formData.channel
      }).then(res => {
        console.log(res)
        this.brandList = res.data
      })
    },
    //查询
    handleConfirm() {
      console.log(this.formData);
      let _this = this
      this.searchLoading = true
      const SeachParams = {
        ...this.formData,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof _this.stopSearchLoading === 'function') {
            _this.stopSearchLoading()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    stopSearchLoading() {
      this.searchLoading = false
    }
  }
}
</script>
