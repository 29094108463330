import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/stockControl',
    component: Layout,
    redirect: 'noRedirect',
    name: '库存管理',
    children: [
        {
            path: 'IssueManage',
            component: () => import('@/views/stockControl/IssueManage/index'),
            name: '收货记录',
            meta: { title: '收货记录', noCache: true }
        },
        {
            path: 'StoreManage',
            component: () => import('@/views/stockControl/StoreManage/index'),
            name: '仓库管理',
            meta: { title: '仓库管理', noCache: true }
        }
    ]
}

export default classificationRouter